import React, { useState, useEffect } from 'react';
import './FocusProductsSelectBrands.scss';
import { ByzzerMultiSelect } from '@/components/form';
import { Tag } from 'antd';
import {useTenantApi} from '@/hooks/useTenantApi';

const FocusProductsSelectBrands = (props) => {
    const { fetchBrandList } = useTenantApi();
    const [brands, setBrands] = useState([]); // selected brands
    const [value, changeValue] = useState(0); //dummy state
    const [allBrands, setAllBrands] = useState([]); //option list
    useEffect(() => {
        if (props?.competitiveProductSetList && props?.competitiveProductSetList?.length > 0) {
            getBrands();
        } else {
            setBrands((props?.brands && props.displaySelectedBrands) ? props.brands : []);
            setAllBrands([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.competitiveProductSetList?.length]);
    const getBrands = async () => {
        setBrands((props?.brands && props.displaySelectedBrands) ? props.brands : []);
        setAllBrands([]);
        const requestBody = {
            categories: props.competitiveProductSetList || [],
        };
        props.setLoading(true);
        const brandsListResp = await fetchBrandList(requestBody);
        if (brandsListResp?.brands) {
            let brandsList = brandsListResp.brands.map(item => item.name);
            brandsList = [...new Set(brandsList)]; // Removing duplicate brands since there are brand overlaps for different categories
            setAllBrands(brandsList);
        }
        if (props?.brands?.length>0) 
            props.setEnableMultiBrandsSubmission(true);        
        props.setLoading(false);
    };
    const changeBrand = (val) => {
        setBrands(val);
        changeValue(value + 1);
        if (val.length > 0) {
            props.setEnableMultiBrandsSubmission(true);
        } else {
            props.setEnableMultiBrandsSubmission(false);
        }
        props.setProductSelectorData({ [props.brandType ?? 'focusBrand']: val });
    };
    const deselectBrand = (_e, index) => {
        const brandsData = JSON.parse(JSON.stringify(brands));
        brandsData.splice(index, 1);
        setBrands(brandsData);
        if (brands.length > 0) {
            props.setEnableMultiBrandsSubmission(true);
        } else {
            props.setEnableMultiBrandsSubmission(false);
        }
        changeValue(value + 1);
        props.setProductSelectorData({ [props.brandType ?? 'focusBrand'] : brandsData });
    };
    const renderBrandTags = () => {
        return brands.map((val, _index, _arr) => {
            return (
                <Tag key={_index} className={'orgTags'} closable onClose={() => deselectBrand(val, _index)}>
                    {val}
                </Tag>
            );
        });
    };

    return (
        <>
            <div className="focus-products-select-brands">
                <div className="focus-products-select-brands__title">Select the brands to include in your report.</div>
                <div className="focus-products-select-brands__sub-title">Up to 10 brands can be selected.</div>
                <div className="focus-products-select-brands__brand-selector">
                    <label className="focus-products-select-brands__brand-selector-label">Brands</label>
                </div>
                <div className="focus-products-select-brands__brand-selector-select">
                    {/* @ts-ignore*/}
                    <ByzzerMultiSelect
                        name={'name'}
                        placeholder="All"
                        selectedOptions={brands}
                        multiSelectWithCheckBox
                        onChange={(e) => changeBrand(e)}
                        maxSelection={10}
                        options={allBrands}
                        seperator={'OR'}
                        disabled={!(props?.competitiveProductSetList && props?.competitiveProductSetList?.length > 0)}
                    />
                </div>
            </div>
            <div className="focus-products-select-brands__brand-list-tags">
                {renderBrandTags()}
            </div>
        </>
    );
};

export default FocusProductsSelectBrands;
