import './DodLayoutBuilder.scss';
import React, {ReactNode, useEffect} from 'react';
import classnames from 'classnames';
import {DodLayoutConfig, DodRunConfig, FactCondition} from '@/types/DodRun';
import {ByzzerChangeEventHandler} from '@byzzer/ui-components';
import DodLayoutEditor from './DodLayoutEditor/DodLayoutEditor';
import DodExcelLayoutPreview from './DodExcelLayoutPreview/DodExcelLayoutPreview';
import {useDodWizard} from "@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext";

const baseClassName = 'dod-layout-builder';

export type DodLayoutBuilderProps = {
    className?: string;
    name?: string;
    value: DodRunConfig;
    tip?: ReactNode;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

export function DodLayoutBuilder({
                                     className,
                                     name,
                                     value,
                                     tip,
                                     ...props
                                 }: DodLayoutBuilderProps) {

    const {updateRowColConfigs} = useDodWizard();

    // apply the current runConfig to the row/col configs
    useEffect(updateRowColConfigs, []);

    const savedSelectionDescription = (
        <>
            <p>
                This will save the full set of selections currently in your selection panel so that you can easily reuse
                this layout in future extracts.
            </p>
            <p>Your saved layout will be available in the "Saved Layouts" section of the layout selector.</p>
        </>
    );

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <DodExcelLayoutPreview />
            <DodLayoutEditor
                value={value}
                title="Make Your Layout Selections"
                savedSelectionType='layout'
                savedSelectionDescription={savedSelectionDescription}
                {...props}
            />
        </div>
    );
}

export default DodLayoutBuilder;
