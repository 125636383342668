import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    ByzzerButton,
    ByzzerSelect,
    ByzzerRadio,
    ByzzerMultiSelect,
    ByzzerErrorModal,
    ByzzerLink,
    ByzzerInput,
    ByzzerModal,
} from '@/components/form';
import FocusProductsSelectBrands from '@/components/FocusProductsSelectBrands';
import { PlusOutlined, LoadingOutlined, CloseOutlined } from '@ant-design/icons';
import { useSetState } from '@/utils/utils';
import { openAggregationMoreDetail } from '@/views/AggregationMoreDetailModal';
import { useTenantApi } from '@/hooks/useTenantApi';
import {
    defaultPpgId,
    defaultPpgName,
    upcLevelPpgId,
    upcLevelPpgName,
    specificCharsExclusionReportSkus,
    charsToBeFilteredOut,
    salesThresholdOptions,
    growthThresholdOptions,
    salesThresholdInfoText,
    growthThresholdInfoText,
    specificFocusBrandOptionalSkus,
    focusProductLevels,
    brandManufacturerLevels,
    focusProductLevelLabelInfo,
    brandManufacturerLabelInfo,
    cpsBrandMandatorySkus,
    dynamicHierarchyEnabledSkus,
    aggregationHierarchyOptions,
    aggregationHierarchyInfoText,
    warningMessages,
    selectorLabels,
    selectorStates,
    storySkus,
} from '@/config/globalVars';
import {
    productLevelsHigherThanCategory,
    additionalCharDimensionSkus,
    additionalCharDimensions,
    productLevelInfoHoverMessage,
    productLevelOptions,
} from '@/config/reportSelectorVars.config';
import { useUser } from '@/contexts/UserContext';
import { message, Spin, Tooltip, Popover } from 'antd';
import './ProductSelector.scss';
import infoIcon from '@images/icons/InformationIcon.svg';
import ByzzerSearchableSelect from '@/components/form/ByzzerSearchableSelect';
import tickIcon from '@/images/icons/upc-green-tick.svg';
import crossIcon from '@/images/icons/upc-red-cross.svg';
import exclamationIcon from '@/images/icons/yellow-exclamation.svg';
import warningRed from '@images/extracts/dod_warning_r.svg';
import ProductScopeNew from '@/components/ProductScopeNew';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { storyAppendMore } from '@/utils/storiesUtil';
import classnames from 'classnames';
import { confirm } from '@/components/form/ByzzerModal';
import { useApp } from '@/contexts/UserContext';

const baseClassName = 'product-selector';

const DEFAULT_PPG_OPTION = {
    display: defaultPpgName,
    value: defaultPpgId,
};

const UPC_PPG_OPTION = {
    value: upcLevelPpgId,
    display: upcLevelPpgName,
};

function ProductSelector({
    fromBack = false,
    ppgReport = false,
    productType = 'report',
    selector: { subSelectors, datatype, includeProductLevel, requireFocusBrand },
    reportWrapperData,
    actionConfig,
    selectorState,
    sku,
    ...props
}) {
    const { getCompanyPreferences,    getCategoryByBrand,    getMyPPGs,    getPurchasedThemes,    getCategoriesForTheme,    getCharsForThemeAndCategories,    getCPSPanelReleasability,    lookup,    getCustomChars,    getCustomChar,    retailerHierarchies,    getCategoryCount,    getProductChars,    getCharacteristicValues, getCustomCharWithNASegment} = useTenantApi();
    const [productSelectorData, setProductSelectorData] = useSetState({
        focusBrand: [],
        brand: [],
        manufacturer: [],
        category: [],
        primaryCategory: [],
        characteristicDimension: '',
        ppgDefinition: defaultPpgName,
        characteristicTheme: '',
        charsFromTheme: [],
        subCategories: [],
        salesThreshold: [],
        growthThreshold: '',
        productSet: [],
        subCategoriesList: [],
        aggregationHierarchy: 'Category',
        productLevel: productLevelOptions[0],
        focusProduct: [],
    });
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [showSpinner, setShowSpinner] = useState(false);
    const [categorySelector, setCategorySelector] = useState(false);
    const [productSet, setProductSet] = useState([]);
    const [competitiveProductSetList, setCompetitiveProductSetList] = useState([]);
    const [brandManufacturerList, setBrandManufacturerList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [charConditionValuesData, setCharConditionValuesData] = useState([]);
    const charConditionValuesDataRef = useRef();
    charConditionValuesDataRef.current = charConditionValuesData;
    const [showCharacteristicFilters, setShowCharacteristicFilters] = useState(false);
    const [enableMultiBrandsSubmission, setEnableMultiBrandsSubmission] = useState(false);
    const [characteristicDimensionOptionsList, setCharacteristicDimensionOptionsList] = useState([]);
    const [characteristicsMapData, setCharacteristicsMapData] = useState([]);
    const [charCodesData, setCharCodesData] = useState([]);
    const [categInFocusBrand, setCategInFocusBrand] = useState([]);
    const [customChars, setCustomChars] = useState([]);
    const [showWarning, setShowWarning] = useState(false);
    const [showWarning1, setShowWarning1] = useState(false);
    const [messageType, setMessageType] = useState('Tip');
    const [isWarning1Displayed, setIsWarning1Displayed] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [warningMessage1, setWarningMessage1] = useState('');
    const [contentType, setContentType] = useState('warning');
    const [contentType1, setContentType1] = useState('warning');
    const [ppgs, setPpgs] = useState([]);
    const [characteristicThemeOptions, setCharacteristicThemeOptions] = useState([]);
    const [charsForThemesCategories, setCharsForThemesCategories] = useState([]);
    const [focusProductAlias, setFocusProductAlias] = useState('');
    const [brandManufacturerAlias, setBrandManufacturerAlias] = useState('');
    const [brandAlias, setBrandAlias] = useState('');
    const [characteristicsAlias, setCharacteristicsAlias] = useState('');
    const [categoryAlias, setCategoryAlias] = useState('');
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);
    const [productSetCPS, setProductSetCPS] = useState([]);
    const [showInfo, setShowInfo] = useState([false]);
    const [releasability, setReleasability] = useState('');
    const [showUpdate, setShowUpdate] = useState(false);
    const [productLevel, setProductLevel] = useState('Category');
    const [brandManufacturerLevel, setBrandManufacturerLevel] = useState('Focus Brand');
    const [coverageWarning, setCoverageWarning] = useState('');
    const [coverageWarningSymbol, setCoverageWarningSymbol] = useState(false);
    const [coverageInfoMessage, setCoverageInfoMessage] = useState('');
    const [companyPrefCategories, setCompanyPrefCategories] = useState([]);
    const [wholeCategoriesCount, setWholeCategoriesCount] = useState('');
    const [isCharDimPreDefined, setIsCharDimPreDefined] = useState(false);
    const [proTipsVisible, setProTipsVisible] = useState(false);
    const [ppgOptions, setPpgOptions] = useState([]);
    const [ppgId, setPpgId] = useState(ppgReport ? defaultPpgId : undefined);
    const [ppgData, setPpgData] = useState({});
    const [subsetOptionText, setSubsetOptionText] = useState('');
    const [resetFocusMarket, setResetFocusMarket] = useState(false);
    // const [nextButton,setNextButton]=useState(false)
    const { user } = useUser();
    const omniGrowthComparisonReportSku = '828'; // To Do: Move to config file as list and use from there
    const { companyPreferences } = useApp();
    const companyPrefCategoriesRef = useRef();
    companyPrefCategoriesRef.current = companyPrefCategories;
    const wholeCategoriesCountRef = useRef();
    wholeCategoriesCountRef.current = wholeCategoriesCount;

    const [byzzerCategorySearchValue, setByzzerCategorySearchValue] = useState();
    const [byzzerCategorySearchOptionsTest, setByzzerCategorySearchOptionsTest] = useState();
    const [byzzerCategorySearchFocusBrand, setByzzerCategorySearchFocusBrand] = useState();
    const navigate = useNavigate();

    const CUSTOM_CHAR_EXTENSION = ' (Custom)';

    const charConditionOptions = subSelectors.characteristicFilters?.alwaysPositiveLookup ? ['is'] : ['is', 'is not'];

    const themePurchaseType =
        props.runType === 'adhoc' ? 'product_addon_characteristic_theme' : 'subscription_addon_characteristic_theme';
    const isProductOption1 = props?.selectorConfigs !== undefined && props?.selectorConfigs.includes('product:option1');
    const isProductOption2 = props?.selectorConfigs !== undefined && props?.selectorConfigs.includes('product:option2');
    const isProductOption3 =
        props?.selectorConfigs !== undefined && props?.selectorConfigs?.includes('product:option3');
    const isProductOption4 =
        props?.selectorConfigs !== undefined && props?.selectorConfigs?.includes('product:option4');
    const isProductOption5 = props?.selectorConfigs !== undefined && props?.selectorConfigs.includes('product:option5');

    useEffect(() => {
        if (subSelectors.characteristicFilters?.required && isProductOption5) {
            setSubsetOptionText(
                'Select the key characteristics of your brand that will help determine whether a retailer is a good fit for you. For example, if you are a wellness-focused brand, you may want to select Organic is ORGANIC and Natural is NATURAL.'
            );
            //for initial renedering of characteristic filter in wellness story 
            if (categories.length === 0 && charConditionValuesData.length === 0) {
                renderCharFiltersRows([], [], 'initial');
                setShowCharacteristicFilters(true);
            }
        } else if (subSelectors.characteristicFilters?.required) {
            setSubsetOptionText(
                'Select the key characteristics of your brand that will help determine whether a retailer is a good fit for you. For example, if you are a wellness-focused brand, you may want to select Organic is ORGANIC and Natural is NATURAL. You are required to select at least 1, and at most 5.'
            );
        } else if (['focusBrandOnlyReport', 'focusBrandOptionalReport'].includes(subSelectors.subSelectorType)) {
            setSubsetOptionText(`
            Are you focused only on a particular product size, or specific brands? 
            Do you belong to a niche subset such as Organic or Natural only? 
            Apply characteristic filters to narrow your ${productType === 'story' ? 'story' : 'report'
                } to only the products that matter to you.`);
        } else {
            setSubsetOptionText(
                'Are you focused only on a particular size, or specific features? Apply characteristic filters to narrow your report to only a subset of products in the brand.'
            );
        }
    }, [subSelectors]);

    useEffect(() => {
        if (ppgReport && !(productType === 'story' && fromBackOnce)) {
            const filteredPpgs = ppgs
                .filter((ppg) => ppg.categories.some((category) => categories.includes(category)))
                .map(({ id, title }) => ({
                    display: title,
                    value: id,
                }));

            const ppgOptionsList = [DEFAULT_PPG_OPTION, UPC_PPG_OPTION, ...filteredPpgs].sort((a, b) => {
                return a.display.localeCompare(b.display, 'en', { sensitivity: 'base' });
            });
            setPpgOptions(ppgOptionsList);
            const ppgDataVal = ppgOptionsList.filter((e) => e.value == ppgId);
            setPpgData(...ppgDataVal);
        }
    }, [ppgs, categories]);

    useEffect(() => {
        const themeCategoriesResponse = async (sku) => {
            const categoriesThemeResponse = await getCategoriesForTheme(sku);
            // //("ProductSelector - useEffect / sku updated - 'sku', 'categoriesThemeResponse', ===>> ", sku, categoriesThemeResponse)
            setByzzerCategorySearchOptionsTest(categoriesThemeResponse);

            return categoriesThemeResponse;
        };
        themeCategoriesResponse('575');
    }, [sku]);

    useEffect(() => {
        //('ProductSelector - useEffect / byzzerCategorySearchValue ===>> ', byzzerCategorySearchValue);
    }, [byzzerCategorySearchValue]);

    useEffect(() => {
        if (isCharDimPreDefined) {
            const charDim =
                reportWrapperData?.focusProductSelections?.characteristicDimension ||
                selectorState?.focusProductSelections?.characteristicDimension;
            charDim?.forEach((char, index) => {
                let dimValue = '';
                if (char.isCustomFeature) {
                    const findDimValue = customChars.find((element) => element.id == char.featureId);
                    dimValue = findDimValue.label + CUSTOM_CHAR_EXTENSION;
                } else {
                    dimValue = char.featureId;
                }
                selectCharDimensionOption(dimValue, index);
            });
            setIsCharDimPreDefined(false);
        }
    }, [isCharDimPreDefined]);

    useEffect(() => {
        if (
            productSelectorData.primaryCategory?.length > 0 &&
            categInFocusBrand.length > 0 &&
            showSelector('focusBrand')
        ) {
            checkFocusBrandIn(productSelectorData.primaryCategory);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categInFocusBrand]);

    let fromBackOnce;
    useEffect(() => {
        //('in use effect ');
        renderData();
        fromBackOnce = fromBack;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        reportWrapperData,
        selectorState.focusProductSelections.customCharacteristicFilters,
        selectorState.focusProductSelections.characteristicFilters,
    ]);

    useEffect(() => {
        setPropsetForCPS();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productSelectorData]);

    /* Moving panel release check beside to generate report  button as per BYZ-7113*/
    useEffect(() => {
        getPanelReleasability(productSelectorData?.category, productSelectorData?.brand);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productSelectorData?.category, productSelectorData?.brand]);

    useEffect(() => {
        let retain = productType === 'story' && fromBack ? true : false;
        renderProductLevelOptions(productLevel, retain);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productLevel]);

    useEffect(() => {
        if (productSelectorData.focusBrand?.length > 0) {
            fetchCategoriesForFocusBrand(productSelectorData.focusBrand, companyPrefCategories);
        }
    }, [productSelectorData.focusBrand]);

    useEffect(() => {
        if (resetFocusMarket) {
            // To clear Focus market selections if already chosen
            let currentSelectorStatusState = [...selectorState.selectorsStatus];
            const currentStepIndex = currentSelectorStatusState?.findIndex(
                (item) => item.selectorName === selectorLabels.product
            );
            let clearFlag = false;
            selectorState.selectorsStatus?.forEach((statusItem, index) => {
                if (
                    index === currentStepIndex ||
                    statusItem.selectorName === selectorLabels.focusMarket ||
                    statusItem.selectorName === selectorLabels.channelOutlets
                ) {
                    currentSelectorStatusState[index].status = selectorStates.pending;
                    clearFlag = true;
                }
            });
            if (clearFlag) {
                props.setSelectorsData({
                    selectorsStatus: currentSelectorStatusState,
                    clearFocusMarket: true,
                });
            }
            setResetFocusMarket(false);
        }
    }, [resetFocusMarket]);

    const retainData = async () => {
        //('retain data called');
        setLoading(true);
        // set brand
        if (selectorState?.focusProductSelections?.focusBrand?.length)
            setProductSelectorData({ focusBrand: selectorState?.focusProductSelections?.focusBrand });
        if (selectorState?.focusProductSelections?.brand?.length)
            setProductSelectorData({ brand: selectorState?.focusProductSelections?.brand });

        // set category
        if (selectorState?.focusProductSelections?.competitiveProductSet?.categories?.length > 0) {
            setProductSelectorData({
                category: selectorState?.focusProductSelections?.competitiveProductSet?.categories,
                primaryCategory: [],
            });
        }

        if (subSelectors.competitiveProductSet) {
            let currentProductLevel =
                selectorState?.focusProductSelections?.productLevel !== undefined
                    ? selectorState?.focusProductSelections?.productLevel
                    : productLevel;
            let currentSubCategories = selectorState?.focusProductSelections?.competitiveProductSet.subcategories;
            let currentManufacturer = selectorState?.focusProductSelections?.manufacturer;
            let currentSubCategoriesList = selectorState?.focusProductSelections?.subCategoriesList;
            let currentProductSet =
                selectorState?.focusProductSelections?.productSet?.length > 0
                    ? selectorState?.focusProductSelections?.productSet
                    : selectorState?.focusProductSelections?.competitiveProductSet?.categories;

            setProductLevel(currentProductLevel);
            let categoriesList = currentProductSet;
            let totalCategCountInSelectedProductLevel;
            let subscrCategsCountInProdLevelSelections;
            if (currentProductLevel !== 'Category') {
                setLoading(true);
                await renderProductLevelOptions(currentProductLevel, true);
                categoriesList = await fetchSpecificProductLevelData(
                    'Category',
                    currentProductLevel,
                    currentProductSet
                );
                const categRequestPost = {
                    supercategories: currentProductLevel === 'Supercategory' ? currentProductSet : undefined,
                    departments: currentProductLevel === 'Department' ? currentProductSet : undefined,
                };
                const totalCategCountResp = await getCategoryCount(categRequestPost);
                totalCategCountInSelectedProductLevel = totalCategCountResp?.categoriesCount;
                categoriesList = categoriesList.filter((item) => companyPrefCategoriesRef.current.includes(item));
                subscrCategsCountInProdLevelSelections = categoriesList?.length;
                setLoading(false);
            }
            setProductSelectorData({
                category: categoriesList,
                primaryCategory: [],
                productSet: currentProductSet,
            });
            setCoverageWarning('');
            setCoverageInfoMessage('');
            // Reset Characteristics
            setCharacteristicsMapData([]);
            if (props.marketGroup === 'cps') {
                fetchSubCategory(categoriesList);
            }
            if (productType === 'story' && subSelectors?.checkCharacterLimit) {
                checkCategoryCharacterLimit(categoriesList);
            }
            if (currentProductLevel === 'Subcategory') {
                setProductSelectorData({ subCategories: currentProductSet });
            }
            if (
                currentProductLevel !== 'Category' &&
                currentProductLevel !== 'Subcategory' &&
                currentProductSet?.length > 0
            ) {
                setCoverageWarning(
                    `${subscrCategsCountInProdLevelSelections} of ${currentProductLevel === 'Total Store'
                        ? wholeCategoriesCountRef.current
                        : totalCategCountInSelectedProductLevel
                    } categories`
                );
                setCoverageWarningSymbol(
                    subscrCategsCountInProdLevelSelections /
                    (currentProductLevel === 'Total Store'
                        ? wholeCategoriesCountRef.current
                        : totalCategCountInSelectedProductLevel) <
                    1
                );
                setCoverageInfoMessage(`Your ${currentProductLevel} view is limited to the categories in your subscription.
                You have access to ${subscrCategsCountInProdLevelSelections}/${totalCategCountInSelectedProductLevel} categories in the selected ${currentProductLevel}`);
            } else {
                setCoverageWarning('');
                setCoverageInfoMessage('');
            }

            selectedProductSetData(true);
            // set subcategory
            if (
                props.marketGroup === 'cps' &&
                selectorState?.focusProductSelections?.competitiveProductSet?.subcategories?.length > 0
            ) {
                setProductSelectorData({
                    subCategories: currentSubCategories,
                });
            }
            //subcategory dropdown optional value
            setSelectedSubCategories(currentSubCategoriesList);

            // set brand
            if (selectorState?.focusProductSelections?.focusBrand?.length) {
                setProductSelectorData({ focusBrand: selectorState?.focusProductSelections?.focusBrand });
            }
            if (selectorState?.focusProductSelections?.brand?.length) {
                setProductSelectorData({ brand: selectorState?.focusProductSelections?.brand });
            }

            // set manufacturer
            if (
                showSelector('brandManufacturer') &&
                selectorState?.focusProductSelections?.manufacturer?.length &&
                selectorState?.focusProductSelections?.brandManufacturerLevel === 'Manufacturer'
            ) {
                setProductSelectorData({ manufacturer: currentManufacturer });
            }
        }
        // set filters
        if (
            (selectorState?.focusProductSelections?.characteristicFilters &&
                selectorState?.focusProductSelections?.characteristicFilters?.length > 0) ||
            (selectorState?.focusProductSelections?.customCharacteristicFilters &&
                selectorState?.focusProductSelections?.customCharacteristicFilters.length > 0)
        ) {
            handleCharacteristicFiltersDisplay(true, true);
        }
        // set alias
        if (selectorState?.focusProductSelections?.focusProductAlias) {
            setFocusProductAlias(selectorState?.focusProductSelections?.focusProductAlias);
        }

        // set brand/manufacturer alias
        if (showSelector('brandManufacturer') && selectorState?.focusProductSelections?.brandManufacturerAlias) {
            setBrandManufacturerAlias(selectorState?.focusProductSelections?.brandManufacturerAlias);
        }

        // set brand alias
        if (isProductOption4 && selectorState?.focusProductSelections?.brandAlias) {
            setBrandAlias(selectorState?.focusProductSelections?.brandAlias);
        }
        if (isProductOption5 && selectorState?.focusProductSelections?.characteristicsAlias) {
            setCharacteristicsAlias(selectorState?.focusProductSelections?.characteristicsAlias);
        }

        // set ppgdata
        if (
            selectorState?.focusProductSelections?.ppgSelected &&
            isProductOption4 &&
            selectorState?.focusProductSelections?.ppgData
        ) {
            setPpgData(selectorState?.focusProductSelections?.ppgData);
        } else if (!selectorState?.focusProductSelections?.ppgSelected) {
            setPpgData({});
            setPpgId(undefined);
        }

        setLoading(false);
    };

    const setPropsetForCPS = () => {
        if (props.marketGroup === 'cps') {
            //set categories to be passed to search brand
            const prodSetArrayCPS = productSelectorData?.category
                ? productSelectorData?.category.map((item) => {
                    return {
                        hierarchy: 'CATEGORY',
                        name: item,
                    };
                })
                : [];
            setProductSetCPS(prodSetArrayCPS);
        }
    };

    const checkFocusBrandIn = (categsSelected) => {
        if (message) {
            message.destroy();
        }
        if (
            productSelectorData.focusBrand.length > 0 &&
            categsSelected.length > 0 &&
            !categsSelected.some((v) => categInFocusBrand.includes(v))
        ) {
            showWarningMessages('categoryWithNoBrands');
        }
    };

    const checkCategoryCharacterLimit = (category) => {
        let categoryString = storyAppendMore(category, focusProductAlias.trim(), props?.sku, true, 'category');
        if (categoryString?.length >= 25 && focusProductAlias.trim() === '' && !isWarning1Displayed) {
            setShowWarning1(true);
            setContentType1('warning');
            setIsWarning1Displayed(true);
            setWarningMessage1(
                'You have selected a category name which has too many characters. This may result in overlapping of content in your story.  We suggest renaming the category in the input box provided.'
            );
        }
    };

    /**
     *
     * @param {*} productSet
     * @returns all product characteristics with custom chars appended. Custom chars will have '(Custom)' appended to the end.
     */
    const getAllProductCharacteristics = async (productSet) => {
        const [charsResp, customCharacteristics] = await Promise.all([getProductChars(productSet), getCustomChars()]);
        if (charsResp) {
            setCharCodesData(charsResp?.characteristics);
        }
        setCustomChars(customCharacteristics);
        const customCharsForCategories = customCharacteristics.filter((item) =>
            productSet?.categories?.map((categ) => categ.name)?.some((i) => item?.categories?.includes(i))
        );
        const allProdChars = [
            ...customCharsForCategories
                ?.map((item) => item?.label + CUSTOM_CHAR_EXTENSION)
                ?.sort((a, b) => a?.trim()?.toLowerCase()?.localeCompare(b?.trim()?.toLowerCase())),
            ...charsResp?.characteristics
                ?.map((item) => item?.chr_display_name)
                ?.sort((a, b) => a?.trim()?.toLowerCase()?.localeCompare(b?.trim()?.toLowerCase())),
        ];
        setCharacteristicsMapData(allProdChars);
        return allProdChars;
    };

    const preloadCharFilters = async (historyCharFilters, category = null) => {
        let categListForProductLevel = [];
        if (productLevel !== 'Category' && productType !== 'story' && productSelectorData?.productSet?.length) {
            categListForProductLevel = await fetchSpecificProductLevelData(
                'Category',
                productLevel,
                productSelectorData?.productSet
            );
            categListForProductLevel = categListForProductLevel.filter((item) => companyPrefCategories.includes(item));
        } else {
            categListForProductLevel = category
                ? category
                : productSelectorData?.category?.length
                    ? productSelectorData?.category
                    : productSelectorData?.primaryCategory;
        }
        const productSetObj = {
            categories: categListForProductLevel.map((item) => {
                return {
                    hierarchy: 'CATEGORY',
                    name: item,
                };
            }),
            themePurchaseType,
        };
        const characteristicsMap = await getAllProductCharacteristics(productSetObj);

        if (characteristicsMap) {
            const characteristicFiltersData = await Promise.all(
                historyCharFilters.map(async (charFil) => {
                    const charValuesOptions = await fetchCharsValues(charFil.characteristic, categListForProductLevel);
                    return {
                        characteristicValue: charFil.characteristic,
                        characteristicOptions: characteristicsMap,
                        conditionValue: charFil.condition,
                        conditionOptions: charConditionOptions,
                        valueItemValue: charFil.value,
                        valueItemOptions: charValuesOptions ?? [],
                    };
                })
            );
            setShowCharacteristicFilters(true);
            setCharConditionValuesData(characteristicFiltersData);
        }
    };

    async function handleAllCategoryByBrand(brand) {
        const res = await getCategoryByBrand(brand);
        return res?.categories;
    }

    const fetchCompetitiveProductSetList = async (prefBrand = [], themeSku = null) => {
        let categList = [];
        let companyPrefCateg = [];
        setLoading(true);
        const categListResp = JSON.parse(JSON.stringify(await getCompanyPreferences())); // await getCompanyPreferences(); - 2/1/23 - move to app context??
        if (categListResp && Object.keys(categListResp).length > 0) {
            const catResp = typeof categListResp === 'string' ? JSON.parse(categListResp) : categListResp;
            companyPrefCateg =
                catResp.categories?.sort((a, b) => a?.localeCompare(b, 'en', { sensitivity: 'base' })) || [];
        }

        setCompanyPrefCategories(companyPrefCateg);
        const brandName = prefBrand;

        if (brandName && !(productType === 'story' && brandManufacturerLevel === 'Manufacturer')) {
            let categsByBrand = await fetchCategoriesForFocusBrand(brandName, companyPrefCateg);
            categList = [...new Set([...categsByBrand, ...companyPrefCateg])]; // Update as per #1006
        } else {
            categList = companyPrefCateg;
        }

        if (showSelector('characteristicThemes') && themeSku) {
            const themeCategories = await getCategoriesForTheme(themeSku);
            if (themeCategories?.length > 0) {
                categList = themeCategories.filter((item) => companyPrefCateg.includes(item));
            } else {
                categList = [];
            }
        }

        setCompetitiveProductSetList(categList);

        if (
            productSelectorData.primaryCategory?.length < 1 ||
            (brandName &&
                productSelectorData.primaryCategory?.length > 0 &&
                !productSelectorData.primaryCategory?.some((v) => categList.includes(v)))
        ) {
            // Clear default selection if it is not there in the new list
            setProductSelectorData({
                primaryCategory: [],
            });
        }

        if (reportWrapperData) {
            setProductSelectorData({
                category: reportWrapperData?.focusProductSelections?.competitiveProductSet?.categories.filter((item) =>
                    categList.includes(item)
                ),
            });
        }

        const prodSetArray = categList
            ? categList.map((item) => {
                return {
                    hierarchy: 'CATEGORY',
                    name: item,
                };
            })
            : [];

        setProductSet(prodSetArray);

        // retain selected data
        retainData();


        setLoading(false);
    };

    const fetchProductCharacteristics = async (categList, clone = false, categUpdate = '') => {
        setLoading(true);
        const productSetObj = {
            categories: categList.map((item) => {
                return {
                    hierarchy: 'CATEGORY',
                    name: item,
                };
            }),
            themePurchaseType,
        };

        const characteristicsMap = await getAllProductCharacteristics(productSetObj);
        if (characteristicsMap) {
            let filteredCharacteristicsMap = [...characteristicsMap];
            if (specificCharsExclusionReportSkus.includes(sku)) {
                // BYZ-3311
                if (charsToBeFilteredOut.length > 0) {
                    filteredCharacteristicsMap = filteredCharacteristicsMap?.filter(
                        (item) => !charsToBeFilteredOut.includes(item)
                    );
                }
            }
            if (
                additionalCharDimensionSkus?.includes(sku) &&
                (productLevelsHigherThanCategory?.includes(productLevel) ||
                    (productLevel === 'Category' && productSelectorData.category?.length > 1))
            ) {
                // BYZ-5986, BYZ-6603
                filteredCharacteristicsMap = [...new Set([...filteredCharacteristicsMap, ...additionalCharDimensions])];
            }

            const dimListKeys = filteredCharacteristicsMap;
            if (categUpdate === '') {
                renderCharFiltersRows(filteredCharacteristicsMap, categList);
            }
            if (clone) {
                const nextitem = {
                    dimOptions: dimListKeys,
                    dimValue: '',
                };
                setCharacteristicDimensionOptionsList((prevState) => [...prevState, nextitem]);
            } else {
                let dimObjList = [];
                const upto =
                    categUpdate === 'brandLevelMultiChars' ? subSelectors.characteristicDimension?.maxLimit : 1;
                for (let j = 0; j < upto; j++) {
                    dimObjList.push({
                        dimOptions: dimListKeys,
                        dimValue: '',
                    });
                }
                setCharacteristicDimensionOptionsList(dimObjList);
            }
            if (
                reportWrapperData?.focusProductSelections?.characteristicDimension?.length ||
                selectorState?.focusProductSelections?.characteristicDimension?.length
            ) {
                setIsCharDimPreDefined(true);
            }
        }
        setLoading(false);
    };

    const renderData = async () => {
        try {
            setLoading(true);
            if (ppgReport) {
                setPpgs(await getMyPPGs());
            }
            if (wholeCategoriesCount === '') {
                await fetchWholeCategoriesCount();
            }
            let tempProductSelectorData = {};
            if (reportWrapperData) {
                // todo - eventually remove reportWrapperData with the aim of having its content merged into the parent component(s) selectorState(s).
                const characteristicFiltersData =
                    reportWrapperData?.focusProductSelections?.characteristicFilters ?? [];
                const customCharacteristicFiltersData =
                    reportWrapperData?.focusProductSelections?.customCharacteristicFilters ?? [];
                const allCharacteristicFilters = [...characteristicFiltersData, ...customCharacteristicFiltersData];
                if (allCharacteristicFilters?.length) {
                    tempProductSelectorData.characteristicFilters = allCharacteristicFilters;
                }

                if (reportWrapperData?.focusProductSelections?.focusBrand?.length) {
                    tempProductSelectorData.focusBrand = reportWrapperData.focusProductSelections.focusBrand;
                }

                if (reportWrapperData?.focusProductSelections?.brand?.length) {
                    tempProductSelectorData.brand = reportWrapperData.focusProductSelections.brand;
                }

                if (reportWrapperData?.focusProductSelections?.competitiveProductSet?.categories?.length) {
                    tempProductSelectorData.primaryCategory =
                        reportWrapperData.focusProductSelections.competitiveProductSet.categories;
                }
                if (reportWrapperData?.focusProductSelections?.salesThreshold?.length) {
                    tempProductSelectorData.salesThreshold = reportWrapperData.focusProductSelections.salesThreshold;
                }
                if (reportWrapperData?.focusProductSelections?.growthThreshold) {
                    tempProductSelectorData.growthThreshold =
                        (
                            parseFloat(reportWrapperData.focusProductSelections.growthThreshold.replace('>', '')) * 100
                        ).toString() + '%';
                }
                if (reportWrapperData?.focusProductSelections?.competitiveProductSet?.subcategories?.length) {
                    tempProductSelectorData.subCategories =
                        reportWrapperData.focusProductSelections.competitiveProductSet.subcategories;
                }
                if (reportWrapperData?.focusProductSelections?.competitiveProductSet?.categories?.length) {
                    tempProductSelectorData.category =
                        reportWrapperData.focusProductSelections.competitiveProductSet.categories;
                    fetchSubCategory(reportWrapperData?.focusProductSelections?.competitiveProductSet?.categories);
                }
                if (reportWrapperData?.focusProductSelections?.ppgId) {
                    setPpgId(reportWrapperData?.focusProductSelections?.ppgId);
                }
            } else {
                let focusProductData = JSON.parse(JSON.stringify(selectorState))?.focusProductSelections;
                const characteristicFiltersData = focusProductData?.characteristicFilters ?? [];
                const customCharacteristicFiltersData = focusProductData?.customCharacteristicFilters ?? [];
                const allCharacteristicFilters = [...characteristicFiltersData, ...customCharacteristicFiltersData];
                if (allCharacteristicFilters?.length) {
                    tempProductSelectorData.characteristicFilters = allCharacteristicFilters;
                }
                if (focusProductData?.focusBrand?.length && !isProductOption1) {
                    tempProductSelectorData.focusBrand = focusProductData.focusBrand;
                }
                if (focusProductData?.brand?.length) {
                    tempProductSelectorData.brand = focusProductData.brand;
                }
                if (focusProductData?.competitiveProductSet?.categories?.length && !isProductOption1) {
                    tempProductSelectorData.primaryCategory = focusProductData.competitiveProductSet.categories;
                }
                if (focusProductData?.competitiveProductSet?.subcategories?.length) {
                    tempProductSelectorData.subCategories = focusProductData.competitiveProductSet.subcategories;
                }
                if (focusProductData?.competitiveProductSet?.categories?.length) {
                    tempProductSelectorData.category = focusProductData.competitiveProductSet.categories;
                    fetchSubCategory(focusProductData?.competitiveProductSet?.categories);
                }
                if (focusProductData?.ppgId) {
                    setPpgId(focusProductData?.ppgId);
                }
            }
            // Checking User Preference Category is available in Company Preference Category.
            let savedPrefBrand = tempProductSelectorData?.focusBrand;
            let savedPrimaryCateg = [];
            if (
                Object.keys(tempProductSelectorData).length &&
                !(productType === 'story' && fromBack) &&
                datatype !== 'omni'
            ) {
                savedPrimaryCateg =
                    typeof tempProductSelectorData?.primaryCategory === 'string' &&
                        tempProductSelectorData?.primaryCategory !== ''
                        ? [tempProductSelectorData?.primaryCategory]
                        : tempProductSelectorData?.primaryCategory;
                let selectorData = JSON.parse(JSON.stringify(tempProductSelectorData));
                if (subSelectors?.focusBrand) {
                    savedPrefBrand = tempProductSelectorData?.focusBrand;
                }
                if (subSelectors?.competitiveProductSet && !showSelector('characteristicThemes')) {
                    selectorData = {
                        ...selectorData,
                        category: savedPrimaryCateg || [],
                    };
                    if (savedPrimaryCateg?.length > 0) {
                        handleCharacteristicsDisplayOptions(savedPrimaryCateg);
                    }
                }

                setProductSelectorData(selectorData);
                fetchSubCategory(selectorData?.category);
                if (props?.marketGroup === 'cps') {
                    //set categories to be passed to search brand
                    const prodSetArrayCPS = selectorData?.category
                        ? selectorData?.category?.map((item) => {
                            return {
                                hierarchy: 'CATEGORY',
                                name: item,
                            };
                        })
                        : [];
                    setProductSetCPS(prodSetArrayCPS);
                }
                if (
                    subSelectors?.characteristicFilters &&
                        !subSelectors?.characteristicFilters?.required &&
                        sku !== '39'
                        ? true
                        : false &&
                        (reportWrapperData?.focusProductSelections?.characteristicFilters?.length ||
                            reportWrapperData?.focusProductSelections?.customCharacteristicFilters?.length ||
                            selectorState?.focusProductSelections?.customCharacteristicFilters?.length ||
                            selectorState?.focusProductSelections?.characteristicFilters?.length)
                ) {
                    let charDataToLoad = [];

                    if (reportWrapperData?.focusProductSelections?.characteristicFilters?.length) {
                        charDataToLoad = [
                            ...charDataToLoad,
                            ...reportWrapperData?.focusProductSelections?.characteristicFilters,
                        ];
                    }
                    if (reportWrapperData?.focusProductSelections?.customCharacteristicFilters?.length) {
                        charDataToLoad = [
                            ...charDataToLoad,
                            ...reportWrapperData?.focusProductSelections?.customCharacteristicFilters,
                        ];
                    }
                    if (selectorState?.focusProductSelections?.customCharacteristicFilters?.length) {
                        charDataToLoad = [
                            ...charDataToLoad,
                            ...selectorState?.focusProductSelections?.customCharacteristicFilters,
                        ];
                    }
                    if (selectorState?.focusProductSelections?.characteristicFilters?.length) {
                        charDataToLoad = [
                            ...charDataToLoad,
                            ...selectorState?.focusProductSelections?.characteristicFilters,
                        ];
                    }

                    // Craig - this should be able to done without creating dupes ^.  This works for now as part of hotfix to prod (release/1.40) BYZ-6280 but should be DRY'ed

                    const charDataToLoad_deduped = [
                        ...new Set(
                            charDataToLoad.map((charFilterDatum) => {
                                return JSON.stringify(charFilterDatum);
                            })
                        ),
                    ].map((charFilterDatumString) => {
                        return JSON.parse(charFilterDatumString);
                    });
                    // do not call preloadCharFilters function for stories - Craig restored comment on 9/29/22 at 1:30pm
                    if (productType !== 'story' && charDataToLoad_deduped?.length) {
                        await preloadCharFilters(charDataToLoad_deduped || [], savedPrimaryCateg);
                    }
                }
            }

            await fetchCompetitiveProductSetList(savedPrefBrand);
            if (showSelector('characteristicThemes')) {
                const themeType =
                    props.runType === 'adhoc'
                        ? 'product_addon_characteristic_theme'
                        : 'subscription_addon_characteristic_theme';
                const themes = await getPurchasedThemes(themeType);
                setCharacteristicThemeOptions(themes);
            }
        } finally {
            setLoading(false);
        }
    };

    const unselectBrand = (value) => {
        changeBrand(value, false);
    };

    const changeBrand = async (brand, status) => {
        setResetFocusMarket(true); // To reset already selected focus market
        let oldBrands = productSelectorData.focusBrand;
        if (productType === 'story' && !subSelectors?.multiBrand) {
            // for single select
            if (status) {
                oldBrands = [brand];
            } else {
                oldBrands = [];
            }
        } else {
            // for multi select
            if (status) {
                oldBrands.push(brand);
            } else {
                var index = oldBrands.indexOf(brand);
                if (index !== -1) {
                    oldBrands.splice(index, 1);
                }
            }
        }
        setProductSelectorData({
            focusBrand: JSON.parse(JSON.stringify(oldBrands)),
            category: [],
            productSet: [],
            subCategories: [],
        });
        if (productType === 'story' && oldBrands.length > 1) {
            showWarningMessages('multiBrand');
        }
        setCompetitiveProductSetList([]);
        setProductLevel('Category');
        setCoverageWarning('');
        setCoverageInfoMessage('');
        setCharConditionValuesData([]);
        setCharacteristicDimensionOptionsList([]);
        handleCharacteristicFiltersDisplay(false);
        await fetchCompetitiveProductSetList(JSON.parse(JSON.stringify(oldBrands)));
        setShowUpdateButton();
        setLoading(false);
    };

    const changeBrandManufacturer = (val, status = false) => {
        setResetFocusMarket(true); // To reset already selected focus market
        let oldBrands = productSelectorData.focusBrand;
        let oldManufacturer = productSelectorData.manufacturer;
        if (brandManufacturerLevel === 'Focus Brand') {
            if (status) {
                oldBrands?.push(val);
            } else {
                var index = oldBrands?.indexOf(val);
                if (index !== -1) {
                    oldBrands.splice(index, 1);
                }
            }
            setProductSelectorData({
                focusBrand: JSON.parse(JSON.stringify(oldBrands)),
                category: [],
                productSet: [],
                subCategories: [],
                manufacturer: [],
            });
            if (oldBrands.length > 1) {
                showWarningMessages('multiBrand');
            }
        } else if (brandManufacturerLevel === 'Manufacturer') {
            // single select value
            if (status) {
                oldManufacturer = [val];
            } else {
                oldManufacturer = [];
            }
            setProductSelectorData({
                manufacturer: JSON.parse(JSON.stringify(oldManufacturer)),
                focusBrand: [],
                category: [],
                productSet: [],
                subCategories: [],
            });
        }

        let data = brandManufacturerLevel === 'Focus Brand' ? oldBrands : oldManufacturer;
        setCompetitiveProductSetList([]);
        setProductLevel('Category');
        setCoverageWarning('');
        setCoverageInfoMessage('');
        setCharConditionValuesData([]);
        setCharacteristicDimensionOptionsList([]);
        handleCharacteristicFiltersDisplay(false);
        fetchCompetitiveProductSetList(JSON.parse(JSON.stringify(data)));
        setShowUpdateButton();
    };

    const showWarningMessages = (val) => {
        let warningMessage = warningMessages[val] || '';
        if (val === 'categoryWithNoBrands')
            warningMessage = warningMessage + `${productType === 'story' ? ' story' : ' report'}.`;
        let mssgType = val === 'addAlias' ? 'Reminder' : 'Tip';
        setMessageType(mssgType);
        setShowWarning(true);
        setContentType('warning');
        setWarningMessage(warningMessage);
        return true;
    };

    const selectProductSet = async (productSet, retain = false) => {
        let totalCategCountInSelectedProductLevel;
        let subscrCategsCountInProdLevelSelections;
        let categories = [...productSet];
        if (!retain) setResetFocusMarket(true); // To reset already selected focus market
        if (productSet?.length && productLevel !== 'Category' && datatype !== 'omni') {
            setLoading(true);
            categories = await fetchSpecificProductLevelData('Category', productLevel, categories);
            const categRequestPost = {
                supercategories: productLevel === 'Supercategory' ? productSet : undefined,
                departments: productLevel === 'Department' ? productSet : undefined,
            };
            const totalCategCountResp = await getCategoryCount(categRequestPost);
            totalCategCountInSelectedProductLevel = totalCategCountResp?.categoriesCount;
            categories = categories.filter((item) => companyPrefCategories.includes(item));
            subscrCategsCountInProdLevelSelections = categories?.length;
            setLoading(false);
        }
        setProductSelectorData({ category: categories, primaryCategory: [], productSet });
        setCoverageWarning('');
        setCoverageInfoMessage('');
        setCharacteristicsMapData([]); // Reset Characteristics
        if (
            showSelector('focusBrand') ||
            (showSelector('focusBrandOptional') && productSelectorData.focusBrand.length > 0)
        ) {
            checkFocusBrandIn(categories);
        }
        if (
            productType === 'story' &&
            (isProductOption4 || showSelector('brandManufacturer')) &&
            categories?.length > 1
        ) {
            showWarningMessages('multiBrand');
        }
        if (showSelector('characteristicThemes')) {
            fetchCharsForThemeCategories(categories);
        }
        else {
            handleCharacteristicsDisplayOptions(categories);
        }
        if (props.marketGroup === 'cps') {
            setSelectedSubCategories([]);
            setProductSelectorData({ subCategories: [], focusBrand: [] });
            fetchSubCategory(categories);
        }
        if (productType === 'story' && subSelectors?.checkCharacterLimit) {
            checkCategoryCharacterLimit(categories);
        }
        if (productLevel === 'Subcategory') {
            setProductSelectorData({ subCategories: categories });
        }
        if (!retain) setShowUpdateButton();
        if (productLevel !== 'Category' && productLevel !== 'Subcategory' && categories?.length > 0) {
            setCoverageWarning(
                `${subscrCategsCountInProdLevelSelections} of ${productLevel === 'Total Store' ? wholeCategoriesCount : totalCategCountInSelectedProductLevel
                } categories`
            );
            setCoverageWarningSymbol(
                subscrCategsCountInProdLevelSelections /
                (productLevel === 'Total Store' ? wholeCategoriesCount : totalCategCountInSelectedProductLevel) <
                1
            );
            setCoverageInfoMessage(`Your ${productLevel} view is limited to the categories in your subscription.
            You have access to ${subscrCategsCountInProdLevelSelections}/${totalCategCountInSelectedProductLevel} categories in the selected ${productLevel}`);
        } else {
            setCoverageWarning('');
            setCoverageInfoMessage('');
        }

        setCategories(categories);
        if (!categories.length) {
            setShowCharacteristicFilters(false);
            setCharConditionValuesData([]);
        }
    };

    /**fetch sub-category based on category (CPS reports) */
    const fetchSubCategory = async (category) => {
        setCategories(category);
        if (category !== undefined) {
            let subCategory1 = [];
            category.forEach(async (item) => {
                let res = await lookup({ hierarchy: `category`, value: item });
                let subCategory = res.data?.subcategories;
                subCategory?.forEach((i) => {
                    subCategory1.push(i.name);
                });
                setSubCategories(subCategory1);
            });
        }
    };

    /**handle on change sub-category (CPS reports) */
    const changeSubCategory = (subCategory) => {
        setResetFocusMarket(true); // To reset already selected focus market
        setProductSelectorData({ subCategories: subCategory });
        setProductSelectorData({ subCategoriesList: subCategory });
        setShowUpdateButton();
    };

    const setProductLevelOnChange = (productLevel) => {
        setResetFocusMarket(true); // To reset already selected focus market
        setProductLevel(productLevel);
        setShowUpdateButton();
    };

    const setBrandLevelOnChange = (productLevel) => {
        setResetFocusMarket(true); // To reset already selected focus market
        setBrandManufacturerLevel(productLevel);
        //clear other selections
        setProductSelectorData({
            focusBrand: [],
            manufacturer: [],
            category: [],
            productSet: [],
            subCategories: [],
        });
        setShowUpdateButton();
    };

    /**handle change brand for CPS reports */
    const changeCPSBrand = (brand, status) => {
        setResetFocusMarket(true); // To reset already selected focus market
        let oldBrands = productSelectorData.brand;
        if (status) {
            oldBrands?.push(brand);
        } else {
            var index = oldBrands?.indexOf(brand);
            if (index !== -1) {
                oldBrands.splice(index, 1);
            }
        }
        setProductSelectorData({ brand: JSON.parse(JSON.stringify(oldBrands)) });
        setShowUpdateButton();
    };

    const unselectCPSBrand = (value) => {
        changeCPSBrand(value, false);
    };

    const getPanelReleasability = async (category = [], brand = []) => {
        setShowSpinner(true);
        let result;
        try {
            if (category?.length > 0 && brand.length > 0) result = await getCPSPanelReleasability(category, brand);
            else if (category?.length > 0 && brand.length === 0) result = await getCPSPanelReleasability(category);
            setReleasability(result?.releasability);
        } catch (err) {
            console.log(err);
        }
        setShowSpinner(false);
    };

    /**display releasability message for CPS reports */
    const checkRelesabilityMessage = (r) => {
        let categoryAndBrand =
            productSelectorData?.category?.length > 0 && productSelectorData.brand.length > 0 ? true : false;
        switch (r) {
            case 'green':
                // if (categoryAndBrand) return 'This brand is releasable in this category.';
                // else return 'This category is releasable.';
                if (categoryAndBrand)
                    return 'This brand is releasable in this category at the Total US, 52-week level with >150 occasions.';
                else return 'This category is releasable at the Total US, 52-week level with >150 occasions.';
            case 'yellow':
                // if (categoryAndBrand)
                //     return 'This brand is releasable in this category, but with low sample. We recommend running at All Outlets and 52 Weeks for maximum coverage, and use directionally.';
                // else
                //     return 'This category is releasable but with low sample. We recommend running at All Outlets and 52 Weeks for maximum coverage, and use directionally.';
                if (categoryAndBrand)
                    return 'This brand is releasable in this category but with low sample, between 75-150 occasions. Run at All Outlets and 52 Weeks for maximum coverage, and use directionally.';
                else
                    return 'This category is releasable but with low sample, between 75-150 occasions. Run at All Outlets and 52 Weeks for maximum coverage, and use directionally.';
            default:
                //red
                // if (categoryAndBrand)
                //     return 'This brand is not releasable in this category. Running these selections may result in no outputs.';
                // else
                //     return 'This category is not releasable due to low shopper sample. Running this selection may result in no outputs.';
                if (categoryAndBrand)
                    return 'This category is not releasable due to low sample. Choose a larger product set in order to run this report.';
                else
                    return 'This category is not releasable due to low sample. Choose a larger product set in order to run this report.';
        }
    };

    /**display releasability icon for CPS reports */
    const checkRelesabilityIcon = (r) => {
        switch (r) {
            case 'green':
                return tickIcon;
            case 'yellow':
                return exclamationIcon;
            default:
                //red
                return crossIcon;
        }
    };

    const handleCharacteristicsDisplayOptions = (categoryLists, triggerType = '') => {
        if (productType !== 'story' && !showSelector('characteristicFiltersRequired') ||
            (showSelector('characteristicFiltersRequired') && categoryLists?.length === 0)) {
            setCharConditionValuesData([]);
            setCharacteristicDimensionOptionsList([]);
            setShowCharacteristicFilters(false);
        }
        // added condition for characteristic filter retainment for stories
        else if (
            (productType === 'story' && (showSelector('characteristicFiltersRequired') || triggerType === 'initial')) && charConditionValuesData.length === 0
        ) {
            setCharConditionValuesData([]);
            setCharacteristicDimensionOptionsList([]);
            setShowCharacteristicFilters(false);
        }
        // else if (productType === 'story' && !selectorState?.focusProductSelections?.customCharacteristicFilters?.length &&
        //     !selectorState?.focusProductSelections?.characteristicFilters?.length)  {
        //     renderCharFiltersRows([], categoryLists, 'initial');
        //     setShowCharacteristicFilters(true);
        // } 
        else if (productType !== 'story') {
            renderCharFiltersRows([], categoryLists, 'initial');
            setShowCharacteristicFilters(true);
        }
        if (showSelector('characteristicDimension')) {
            if (categoryLists.length > 0) {
                fetchProductCharacteristics(categoryLists, false, 'categUpdate');
            } else {
                setCharacteristicDimensionOptionsList([]);
            }
        }
        if (showSelector('brandLevelMultiCharacteristicDimension')) {
            if (categoryLists.length > 0) {
                fetchProductCharacteristics(categoryLists, false, 'brandLevelMultiChars');
            } else {
                setCharacteristicDimensionOptionsList([]);
            }
        }
        if (productType === 'story' && showSelector('characteristicFiltersRequired')) {
            renderCharFiltersRows([], categoryLists, 'initial');
            setShowCharacteristicFilters(true);
        }

    };

    const selectCharFilterData = async (e, fieldName, index, selectedVal = null) => {
        let currentCharConditionValuesData = JSON.parse(JSON.stringify(charConditionValuesDataRef.current));
        let currentCharConditionValuesDefData = JSON.parse(JSON.stringify(charConditionValuesDataRef.current));

        switch (fieldName) {
            case 'characteristic':
                currentCharConditionValuesData[index].characteristicValue = e;
                currentCharConditionValuesData[index].valueItemOptions = [];
                currentCharConditionValuesData[index].valueItemValue = [];
                currentCharConditionValuesData[index].conditionValue = charConditionOptions[0];
                let categListForProductLevel = await getCategoriesListForProductLevel();
                const charValuesOptions = await fetchCharsValues(e, categListForProductLevel);
                currentCharConditionValuesData[index].valueItemOptions = charValuesOptions ?? [];
                if (e === 'Manufacturer' || e === 'Brand') {
                    showWarningMessages('includeBrandManufacturer');
                }
                break;
            case 'condition':
                currentCharConditionValuesData[index].conditionValue = e;
                break;
            case 'value':
                currentCharConditionValuesData[index].valueItemValue = e;
                break;
            default:
                break;
        }

        if (e) {
            const checkValidation = CharacteristicFormValidation(currentCharConditionValuesData);
            if (checkValidation) {
                const charValsList = currentCharConditionValuesData.map((item) => item.characteristicValue);
                if (charValsList.length > new Set(charValsList).size) {
                    showWarningMessages('multiCharacteristic');
                } else {
                    setCharConditionValuesData(currentCharConditionValuesData);
                }
            } else {
                currentCharConditionValuesDefData[index]?.valueItemValue?.splice(
                    currentCharConditionValuesDefData[index]?.valueItemValue?.indexOf(selectedVal),
                    1
                );
                setCharConditionValuesData(currentCharConditionValuesDefData);
                showWarningMessages('identicalFilters');
            }
        } else {
            setCharConditionValuesData(currentCharConditionValuesData);
        }

        setShowUpdateButton();
    };

    const removeCharacteristic = (index) => {
        if (index > 0) {
            let currentCharConditionValuesData = JSON.parse(JSON.stringify(charConditionValuesData));
            currentCharConditionValuesData.splice(index, 1);
            setCharConditionValuesData(currentCharConditionValuesData);
            setShowUpdateButton();
        }
    };

    const CharacteristicFormValidation = (currentCharConditionValuesData) => {
        let status = true;
        for (let index = 0; index < currentCharConditionValuesData.length; index++) {
            const findData = currentCharConditionValuesData.filter((val) => {
                return (
                    val.characteristicValue === currentCharConditionValuesData[index].characteristicValue &&
                    (val.valueItemValue.join() === currentCharConditionValuesData[index].valueItemValue.join() ||
                        val.valueItemValue.filter((value) => {
                            return currentCharConditionValuesData[index].valueItemValue.includes(value);
                        }).length > 0)
                );
            });
            if (findData.length > 1) {
                status = false;
                break;
            }
        }
        return status;
    };

    const renderCharFiltersOptions = () => {
        return (
            <>
                {charConditionValuesData.length > 0 && productType === 'story' && (
                    <span>Select up to {subSelectors?.characteristicFilters?.maxLimit} Characteristics</span>
                )}
                {charConditionValuesData.length > 0 &&
                    charConditionValuesData?.map((item, index) => {
                        return (
                            (productType !== 'story' || subSelectors?.characteristicFilters?.maxLimit > index) ? //rendering char filter as  set as max limit set for stories 
                                <div key={index + item}>
                                    {index > 0 && (
                                        <div key={index} className={`${baseClassName}__characteristics-and`}>
                                            {sku === '459' ? 'OR' : 'And'}
                                        </div>
                                    )}
                                    <div
                                        className={classnames(`${baseClassName}__characteristics`, {
                                            [`${baseClassName}__characteristics--removable`]: index > 0,
                                        })}
                                    >
                                        <ByzzerSelect
                                            className={`${baseClassName}__characteristics-item`}
                                            label={'Characteristic'}
                                            value={item.characteristicValue}
                                            placeholder={item.characteristicValue || 'Select from the list'}
                                            options={item.characteristicOptions || []}
                                            onChange={(e) => selectCharFilterData(e, 'characteristic', index)}
                                            allowClear={true}
                                        />
                                        <ByzzerSelect
                                            label={'Condition'}
                                            className={`${baseClassName}__characteristics-condition`}
                                            value={item.conditionValue}
                                            placeholder={item.conditionValue || ''}
                                            options={item.conditionOptions}
                                            onChange={(e) => selectCharFilterData(e, 'condition', index)}
                                        />
                                        <ByzzerMultiSelect
                                            label={'Value'}
                                            className={`${baseClassName}__characteristics-value`}
                                            selectedOptions={item.valueItemValue}
                                            name={'char-value'}
                                            value={item.valueItemValue}
                                            multiSelectWithCheckBox
                                            onChange={(e, selectedVal) =>
                                                selectCharFilterData(e, 'value', index, selectedVal)
                                            }
                                            options={item.valueItemOptions || []}
                                            seperator={'OR'}
                                        />

                                        <ByzzerButton
                                            className={`${baseClassName}__characteristics-remove`}
                                            type={'negative'}
                                            onClick={() => removeCharacteristic(index)}
                                        >
                                            <CloseOutlined />
                                        </ByzzerButton>
                                    </div>
                                </div>
                                : "");
                    })}
            </>
        );
    };

    const renderCharFiltersRows = async (charMap = [], compProdSet = [], triggerType = '', retainStoryData = false) => {
        setLoading(true);
        let characteristicsMap = [];
        if (charMap?.length > 0) {
            characteristicsMap = charMap;
        } else if (characteristicsMapData.length > 0) {
            characteristicsMap = characteristicsMapData;
        }
        if (
            characteristicsMap.length === 0 ||
            Object.keys(characteristicsMap).length === 0 ||
            compProdSet?.length > 0
        ) {
            let categListForProductLevel = [];
            if (productLevel !== 'Category') {
                categListForProductLevel = await fetchSpecificProductLevelData(
                    'Category',
                    productLevel,
                    productSelectorData.productSet
                );
                categListForProductLevel = categListForProductLevel.filter((item) =>
                    companyPrefCategories.includes(item)
                );
            } else {
                categListForProductLevel =
                    compProdSet?.length > 0
                        ? compProdSet
                        : productSelectorData?.category?.length > 0
                            ? productSelectorData?.category
                            : productSelectorData.primaryCategory;
            }
            const productSetObj = {
                categories: categListForProductLevel.map((item) => {
                    return {
                        hierarchy: 'CATEGORY',
                        name: item,
                    };
                }),
                themePurchaseType,
            };

            let productSetObjStory;
            if (productType === 'story' && fromBack === true) {
                productSetObjStory = {
                    categories: (selectorState?.focusProductSelections?.competitiveProductSet?.categories).map(
                        (item) => {
                            return {
                                hierarchy: 'CATEGORY',
                                name: item,
                            };
                        }
                    ),
                    themePurchaseType,
                };
            }

            if (productType === 'story' && fromBack === true) {
                characteristicsMap = await getAllProductCharacteristics(productSetObjStory);
            } else {
                characteristicsMap = await getAllProductCharacteristics(productSetObj);
            }

            if (productType === 'story') {
                if (characteristicsMap !== undefined || characteristicsMap?.length > 0) {
                    // send data to Story Data Specification Screen
                    props.setCharacteristicSelDataValue(characteristicsMap);

                    props.setSelectorsData({
                        characteristicOptions: characteristicsMap,
                        // characteristicSelections: '',
                    });
                } else {
                    // send data to Story Data Specification Screen
                    props.setCharacteristicSelDataValue([]);
                }
            }
        }
        if (characteristicsMap) {
            // setCharacteristicsMapData(characteristicsMap);
            const charConditionValuesObj = {
                characteristicValue: '',
                characteristicOptions: characteristicsMap,
                conditionValue: charConditionOptions[0],
                conditionOptions: charConditionOptions,
                valueItemValue: [],
                valueItemOptions: [],
            };
            if (
                triggerType === 'initial' &&
                !selectorState?.focusProductSelections?.customCharacteristicFilters?.length &&
                !selectorState?.focusProductSelections?.characteristicFilters?.length
            ) {
                setCharConditionValuesData([charConditionValuesObj]);
            }
            else {
                setCharConditionValuesData((prevState) => [...prevState, charConditionValuesObj]);
            }
            if (productType === 'story' && fromBackOnce === true && retainStoryData) {
                fetchCharacteristicsData(charConditionValuesObj);
            }
        }
        setLoading(false);
    };

    const fetchCharacteristicsData = async (charConditionValuesObj) => {
        let characteristicsFiltersDataTemp = [];
        setLoading(true);
        const characteristicFilters = selectorState?.focusProductSelections?.customCharacteristicFilters.concat(
            selectorState?.focusProductSelections?.characteristicFilters
        );
        for (let element of characteristicFilters) {
            // fetch characteristics values options
            let categListForProductLevel = await getCategoriesListForProductLevel();
            let charValuesOptions = await fetchCharsValues(element?.characteristic, categListForProductLevel);

            let characteristicsFiltersDataObj = {
                characteristicValue: element.characteristic,
                conditionValue: element.condition,
                valueItemValue: element.value,
                characteristicOptions: charConditionValuesObj?.characteristicOptions,
                conditionOptions: charConditionValuesObj?.conditionOptions,
                valueItemOptions: charValuesOptions ?? [],
            };
            characteristicsFiltersDataTemp.push(characteristicsFiltersDataObj);
        }
        setCharConditionValuesData(characteristicsFiltersDataTemp);
        setLoading(false);
    };

    const extractCharFiltersSelection = (charFiltersData) => {
        let tempArray = [];
        const charFiltersTemp = JSON.parse(JSON.stringify(charFiltersData));
        tempArray = charFiltersTemp
            .filter((x) => !x?.characteristicValue?.includes(CUSTOM_CHAR_EXTENSION))
            .map(function (item) {
                return {
                    characteristic: item.characteristicValue,
                    condition: item.conditionValue,
                    value: item.valueItemValue,
                };
            });
        let tempCustomCharFilterArray = charFiltersTemp
            .filter((x) => x?.characteristicValue?.includes(CUSTOM_CHAR_EXTENSION))
            .map(function (item) {
                return {
                    characteristic: item.characteristicValue,
                    condition: item.conditionValue,
                    value: item.valueItemValue,
                };
            });
        return { charFilters: tempArray, customCharFilters: tempCustomCharFilterArray };
    };

    const extractCustomSegmentIds = async (charFiltersData) => {
        let charFiltersTemp = JSON.parse(JSON.stringify(charFiltersData));
        let customCharsAndSegments = charFiltersTemp
            .filter((x) => x?.characteristicValue?.includes(CUSTOM_CHAR_EXTENSION))
            .map((x) => ({
                characteristic: x.characteristicValue.substring(
                    0,
                    x.characteristicValue.length - CUSTOM_CHAR_EXTENSION.length
                ),
                condition: x.conditionValue,
                segments: x.valueItemValue,
            }));
        let segmentIds = [];
        for (let i = 0; i < customCharsAndSegments?.length; i++) {
            let customChar = customChars.find((x) => x.label === customCharsAndSegments[i].characteristic);
            const charByIdResp = await getCustomChar(customChar?.id);
            let segments = (charByIdResp?.type === 'UPC' ? charByIdResp?.groups : charByIdResp?.segments)?.filter((x) =>
                customCharsAndSegments[i].segments.includes(x.name)
            );
            let currentSegment = '';
            let condition = customCharsAndSegments[i].condition === 'is' ? '' : '-';
            if (segments) {
                segments.forEach((item, index) => {
                    if (index !== segments.length - 1) {
                        currentSegment = currentSegment + condition + item.id + '|';
                    } else {
                        currentSegment = currentSegment + condition + item.id;
                    }
                });
                segmentIds.push(currentSegment);
            }
        }
        return segmentIds;
    };

    const submitProductSelections = async (submitType) => {
        if (
            productType === 'story' &&
            ((showSelector('brandManufacturer') &&
                ((productSelectorData?.focusBrand?.length > 1 && brandManufacturerAlias.trim() === '') ||
                    (productSelectorData?.category?.length > 1 && focusProductAlias.trim() === ''))) ||
                (isProductOption4 &&
                    ((productSelectorData?.focusBrand?.length > 1 && brandAlias.trim() === '') ||
                        (productSelectorData?.category?.length > 1 && focusProductAlias.trim() === ''))) ||
                (isProductOption5 &&
                    ((focusProductAlias.trim() === '' && productSelectorData?.category?.length > 1) ||
                        characteristicsAlias.trim() === '')))
        ) {
            if (
                isProductOption5 &&
                productSelectorData?.category?.length > 1 &&
                (characteristicsAlias.trim() !== '' || focusProductAlias.trim() === '')) {
                showWarningMessages('categoryAlert');
            } else if (
                isProductOption5 &&
                productSelectorData?.category?.length >= 1 &&
                characteristicsAlias.trim() === ''
            ) {
                showWarningMessages('characteristicAlert');
            } else {
                showWarningMessages('addAlias');
            }
            return true;
        }

        let categListForProductLevel = [];
        if (productLevel === 'Category') {
            categListForProductLevel = subSelectors.competitiveProductSet
                ? productSelectorData?.category?.length > 0
                    ? productSelectorData?.category
                    : productSelectorData.primaryCategory
                : [];
        } else {
            categListForProductLevel = await fetchSpecificProductLevelData(
                'Category',
                productLevel,
                productSelectorData.productSet
            );
        }
        const charFilters = extractCharFiltersSelection(charConditionValuesData);

        const prodSelections = {
            omniProductLevel: datatype === 'omni' ? productSelectorData.productLevel : undefined,
            omniFocusProduct: datatype === 'omni' ? productSelectorData.focusProduct : undefined,
            competitiveProductSet: {
                categories: datatype === 'omni' ? productSelectorData.category : categListForProductLevel,
                subcategories: props?.marketGroup === 'cps' ? productSelectorData?.subCategories : [],
            },
            characteristicDimension: subSelectors.characteristicDimension
                ? characteristicDimensionOptionsList.map((item) => {
                    if (item.dimValue.includes(CUSTOM_CHAR_EXTENSION)) {
                        const charItem = customChars.find((element) =>
                            element.label.includes(
                                item.dimValue.substring(0, item.dimValue.length - CUSTOM_CHAR_EXTENSION.length)
                            )
                        );
                        return { featureId: '' + charItem.id, isCustomFeature: true };
                    } else {
                        return { featureId: item.dimValue, isCustomFeature: false };
                    }
                })
                : [],
            characteristicFilters:
                showCharacteristicFilters && subSelectors.characteristicFilters ? [...charFilters.charFilters] : [],
            customCharacteristicFilters:
                showCharacteristicFilters && subSelectors.characteristicFilters
                    ? [...charFilters.customCharFilters]
                    : [],
            customSegmentIds: await extractCustomSegmentIds(charConditionValuesData),
            ppgId,
            showFocusBrand: showSelector('focusBrand'),
            themeSku: showSelector('characteristicThemes') ? productSelectorData?.characteristicTheme : undefined,
            themeAttribute: showSelector('characteristicThemes') ? productSelectorData?.charsFromTheme : undefined,
            allThemeAttributes: showSelector('characteristicThemes')
                ? productSelectorData?.charsFromTheme.length > 0
                    ? []
                    : charsForThemesCategories
                : undefined,
            salesThreshold: showSelector('salesThreshold') ? productSelectorData.salesThreshold : undefined,
            growthThreshold: showSelector('growthThreshold')
                ? growthThresholdData(productSelectorData?.growthThreshold)
                : undefined,
            aggregationHierarchy: showSelector('dynamicHierarchy')
                ? productSelectorData.aggregationHierarchy?.replace('Supercategory', 'Super-Category')
                : undefined,
        };

        const newFocusBrand = productSelectorData?.focusBrand?.length ? productSelectorData?.focusBrand : null;
        const newBrand = productSelectorData?.brand?.length ? productSelectorData?.brand : null;

        if (newFocusBrand) {
            prodSelections.focusBrand = newFocusBrand;
        }
        if (newBrand) {
            prodSelections.brand = newBrand; // Looker accepts brand for shopper report differently
        }

        let currentSelectorStatusState = JSON.parse(JSON.stringify(selectorState?.selectorsStatus));
        currentSelectorStatusState[props?.stepNo].status = selectorStates.completed;

        //Added for Stories Feature
        if (productType === 'story') {
            let ppgSelected = ppgData !== undefined && ppgData !== null && Object.keys(ppgData).length > 0;
            let ppgDataObj = ppgSelected ? ppgData : DEFAULT_PPG_OPTION;
            let prodSelectionsNew = {
                focusProductAlias,
                brandManufacturerAlias,
                brandAlias,
                characteristicsAlias,
                brandManufacturerLevel,
                productLevel,
                productSet: productSelectorData.productSet,
                manufacturer: productSelectorData?.manufacturer,
                subCategoriesList: props.marketGroup === 'cps' ? productSelectorData.subCategoriesList : [],
                ppgData: ppgDataObj,
                ppgSelected,
            };
            prodSelectionsNew = Object.assign(prodSelectionsNew, prodSelections);

            props.setSelectorsData({
                selectorsStatus: currentSelectorStatusState,
                focusProductSelections: prodSelectionsNew,
                refreshMarkets: true,
                refreshCharacteristicFeature: true,
            });

            if (fromBack !== true) {
                let selectionsToSelState = { ...selectorState };
                selectionsToSelState.selectorsStatus = currentSelectorStatusState;
                selectionsToSelState.focusProductSelections = prodSelectionsNew;
                selectionsToSelState.refreshMarkets = true;
                selectionsToSelState.refreshCharacteristicFeature = true;
                selectionsToSelState.activeCollapsePanel = submitType !== 'Generate' ? [props.stepNo + 1] : [];
                // Error validation for stories
                insightsValidation(selectionsToSelState);
            }

            // update
            if (fromBack === true) {
                let selectionsToSelState = { ...selectorState };
                selectionsToSelState.selectorsStatus = currentSelectorStatusState;
                selectionsToSelState.focusProductSelections = prodSelectionsNew;
                selectionsToSelState.activeCollapsePanel = [];
                selectionsToSelState.refreshMarkets = true;
                selectionsToSelState.refreshCharacteristicFeature = true;
                // Error validation for stories
                insightsValidation(selectionsToSelState);
            }
            if (submitType === 'Generate') {
                let selectionsToSelState = { ...selectorState };
                selectionsToSelState.selectorsStatus = currentSelectorStatusState;
                selectionsToSelState.focusProductSelections = prodSelectionsNew;
                selectionsToSelState.activeCollapsePanel = [];
                selectionsToSelState.refreshMarkets = true;
                selectionsToSelState.refreshCharacteristicFeature = true;
                props.generateReport(selectionsToSelState);
            }
        } else {
            props.setSelectorsData({
                selectorsStatus: currentSelectorStatusState,
                activeCollapsePanel: submitType !== 'Generate' ? [props.stepNo + 1] : [],
                focusProductSelections: prodSelections,
                refreshMarkets: true,
            });
        }

        if (submitType === 'Generate' && productType !== 'story') {
            let selectionsToSelState = { ...selectorState };
            selectionsToSelState.focusProductSelections = prodSelections;
            props.generateReport(selectionsToSelState);
        }

        // pass data to characteristicFeature selector
        if (productType && productType === 'story') {
            // resetting characteristicFeature data to empty values
            props.setSelectorsData({
                // characteristicOptions: [],
                characteristicSelections: '', // setting value as empty
            });

            // call only when user did not load api
            if (charConditionValuesData?.length === 0 && !showCharacteristicFilters) {
                renderCharFiltersRows([], [], 'initial');
            }
        }
    };

    const showSelector = (selectorField) => {
        let showFlag = false;
        switch (selectorField) {
            case 'focusBrand':
                //focusBrand for Product Selctor 2 & 3 of Ad-hoc should be true
                showFlag =
                    subSelectors.focusBrand &&
                        sku !== '240' &&
                        datatype !== 'omni'
                        ? true
                        : false;
                break;
            case 'focusBrandOptional':
                showFlag = specificFocusBrandOptionalSkus.includes(sku) ? true : false;
                break;
            case 'focusBrandfilter':
                //focusBrand for Product Selctor 2 & 3 of Ad-hoc should be true
                showFlag =
                    subSelectors.focusBrand && sku !== '240' && sku !== '832' && datatype !== 'omni' ? true : false;
                break;
            case 'competitiveSet':
                showFlag = subSelectors.competitiveProductSet || datatype === 'omni' ? true : false;
                break;
            case 'characteristicDimension':
                showFlag =
                    subSelectors.characteristicDimension && !subSelectors.characteristicDimension?.allRequired
                        ? true
                        : false;
                break;
            case 'brandLevelMultiCharacteristicDimension':
                showFlag =
                    subSelectors.characteristicDimension && subSelectors.characteristicDimension?.allRequired
                        ? true
                        : false;
                break;
            case 'characteristicFilters':
                showFlag =
                    subSelectors.characteristicFilters && !subSelectors.characteristicFilters.required && sku !== '39'
                        ? true
                        : false;
                break;
            case 'characteristicFiltersRequired':
                showFlag =
                    subSelectors.characteristicFilters && subSelectors.characteristicFilters.required && sku !== '39'
                        ? true
                        : false;
                break;
            case 'multiBrands':
                showFlag = subSelectors.brands?.multi && datatype !== 'omni' ? true : false;
                break;
            case 'characteristicThemes':
                showFlag = subSelectors.characteristicThemes ? true : false;
                break;
            case 'characteristicsForTheme':
                showFlag = subSelectors.characteristicsForTheme ? true : false;
                break;
            case 'salesThreshold':
                showFlag =
                    subSelectors.salesThreshold && Object.keys(subSelectors.salesThreshold).length !== 0 ? true : false;
                break;
            case 'growthThreshold':
                showFlag =
                    subSelectors.growthThreshold && Object.keys(subSelectors.growthThreshold).length !== 0
                        ? true
                        : false;
                break;
            case 'dynamicHierarchy':
                showFlag = dynamicHierarchyEnabledSkus.includes(sku) ? true : false;
                break;
            case 'brandManufacturer':
                showFlag = subSelectors.brandManufacturer ? true : false;
                break;
            default:
                break;
        }
        return showFlag;
    };

    const enableProductSelectorSubmit = () => {
        let enableFlag = false;
        if (datatype === 'omni') {
            return (
                productSelectorData.category?.length > 0 &&
                productSelectorData.productLevel &&
                !(requireFocusBrand && productSelectorData.focusProduct.length < 1)
            );
        }
        let productSetValidation =
            productLevel === 'Category'
                ? productSelectorData?.category?.length > 0 || productSelectorData?.primaryCategory?.length > 0
                : productSelectorData?.productSet?.length > 0;
        const focusOptional = showSelector('focusBrandOptional') ? true : false;
        const charFiltersStatus =
            (charConditionValuesData.length &&
                charConditionValuesData.filter(function (el) {
                    return (
                        el.characteristicValue !== '' &&
                        el.conditionValue !== '' &&
                        el.valueItemValue !== '' &&
                        el.valueItemValue.length
                    );
                })) ||
            [];
        switch (subSelectors.subSelectorType) {
            case 'focusBrandReport':
            case 'focusBrandOnlyReport':
                const firstTwoFields = showSelector('focusBrandOptional')
                    ? productSetValidation
                    : productSelectorData.focusBrand?.length > 0 && productSetValidation;
                if (showCharacteristicFilters) {
                    enableFlag =
                        firstTwoFields &&
                        charFiltersStatus &&
                        charFiltersStatus.length === charConditionValuesData.length;
                } else {
                    enableFlag = firstTwoFields;
                }
                break;
            case 'focusBrandOptionalReport':
            case 'fsReport':
            case 'noFocusBrandReport':
                const focBrandOptionalFirstTwoFields = productSetValidation;
                if (showCharacteristicFilters) {
                    enableFlag =
                        focBrandOptionalFirstTwoFields && charFiltersStatus?.length === charConditionValuesData?.length;
                }
                else {
                    enableFlag = focBrandOptionalFirstTwoFields;
                }
                if (showCharacteristicFilters && isProductOption5) {
                    enableFlag =
                        focBrandOptionalFirstTwoFields && charFiltersStatus?.length === subSelectors?.characteristicFilters?.maxLimit;
                }
                if (cpsBrandMandatorySkus.includes(sku) && productSelectorData?.brand?.length < 1) {
                    enableFlag = false;
                }
                break;
            case 'singleCharacteristicDimensionReport':
            case 'multiCharacteristicDimentionReport':
                enableFlag =
                    productSetValidation &&
                    characteristicDimensionOptionsList?.length ===
                    characteristicDimensionOptionsList.filter((item) => item.dimValue !== '').length &&
                    charFiltersStatus?.length === charConditionValuesData?.length;
                break;
            case 'multiBrandsReport':
                enableFlag = productSetValidation && enableMultiBrandsSubmission;
                break;
            case 'hwReport':
                enableFlag = productSelectorData?.category?.length > 0 && productSelectorData?.characteristicTheme;
                break;
            case 'brandLevelMultiCharacteristicDimensionReport':
                const brandCategDimen =
                    productSelectorData.focusBrand &&
                    productSetValidation &&
                    characteristicDimensionOptionsList?.length ===
                    characteristicDimensionOptionsList.filter((item) => item.dimValue !== '').length;
                if (showCharacteristicFilters) {
                    enableFlag =
                        brandCategDimen &&
                        charFiltersStatus &&
                        charFiltersStatus.length === charConditionValuesData.length &&
                        focusOptional;
                } else {
                    enableFlag = brandCategDimen;
                }
                break;
            //Added for Stories Feature
            case 'focusBrandManufacturerStory':
                const isStoryFieldValid =
                    brandManufacturerLevel === 'Manufacturer'
                        ? productSelectorData.manufacturer?.length > 0 && productSetValidation
                        : productSelectorData.focusBrand?.length > 0 && productSetValidation;
                if (showCharacteristicFilters) {
                    enableFlag =
                        isStoryFieldValid &&
                        charFiltersStatus &&
                        charFiltersStatus.length === charConditionValuesData.length;
                } else {
                    enableFlag = isStoryFieldValid;
                }
                break;
            case 'focusBrandStory':
            case 'focusBrandOnlyStory':
                const firstTwoStoryFields = productSelectorData.focusBrand?.length > 0 && productSetValidation;
                if (showCharacteristicFilters) {
                    enableFlag =
                        firstTwoStoryFields &&
                        charFiltersStatus &&
                        charFiltersStatus.length === charConditionValuesData.length;
                } else {
                    enableFlag =
                        firstTwoStoryFields;
                }
                break;
            case 'focusBrandOptionalStory':
                const categorySelectedCheck = productSelectorData.category?.length > 0 && productSetValidation;
                if (showCharacteristicFilters) {
                    enableFlag =
                        productSetValidation &&
                        charFiltersStatus &&
                        charFiltersStatus.length === charConditionValuesData.length
                } else {
                    enableFlag =
                        categorySelectedCheck;
                }
                break;

            default:
                break;
        }
        if (props.marketGroup === 'cps' && sku !== '39' && releasability === 'red') {
            enableFlag = false;
        }
        return enableFlag;
    };

    const setMultiBrandsSubmit = (flag) => {
        setEnableMultiBrandsSubmission(flag);
    };

    const renderCharDimensionOptions = () => {
        return (
            <div className={`${baseClassName}__characteristic-dimension-options`}>
                {characteristicDimensionOptionsList?.length > 0 &&
                    characteristicDimensionOptionsList.map((item, index) => {
                        return (
                            <div key={index} className={`${baseClassName}__characteristic-dimension-select`}>
                                <ByzzerSelect
                                    disabled={
                                        productSelectorData?.category?.length < 1 &&
                                        productSelectorData.primaryCategory?.length < 1
                                    }
                                    value={item.dimValue}
                                    placeholder={item.dimValue || 'Select from the list'}
                                    options={item?.dimOptions || []}
                                    onChange={(e) => selectCharDimensionOption(e, index)}
                                />
                            </div>
                        );
                    })}
            </div>
        );
    };

    const selectCharDimensionOption = async (e, index) => {
        let currentCharDimOptList = [...characteristicDimensionOptionsList];
        if (
            !showSelector('brandLevelMultiCharacteristicDimension') &&
            characteristicDimensionOptionsList?.filter(({ dimValue }) => dimValue === e)?.length
        ) {
            showWarningMessages('identicalCharacteristic');
        } else {
            currentCharDimOptList[index].dimValue = e;
            setCharacteristicDimensionOptionsList(currentCharDimOptList);
        }
    };

    const addCharDimensionRow = async () => {
        let categListForProductLevel = [];
        if (productLevel !== 'Category') {
            setLoading(true);
            categListForProductLevel = await fetchSpecificProductLevelData(
                'Category',
                productLevel,
                productSelectorData.productSet
            );
            categListForProductLevel = categListForProductLevel.filter((item) => companyPrefCategories.includes(item));
            setLoading(false);
        } else {
            categListForProductLevel =
                productSelectorData?.category?.length > 0
                    ? productSelectorData?.category
                    : productSelectorData.primaryCategory;
        }
        fetchProductCharacteristics(categListForProductLevel, true, '');
    };

    const handleCharacteristicFiltersDisplay = (val, retainStoryData = false) => {
        if ((val && showCharacteristicFilters) || (!val && !showCharacteristicFilters)) return; // dont do anything if seleting true and already true; false and already false...
        if (val === true) {
            if (
                (productLevel && productSelectorData.productSet.length > 0) ||
                productSelectorData?.category?.length > 0 ||
                productSelectorData.primaryCategory?.length > 0 ||
                (productType === 'story' && fromBack === true && retainStoryData)
            ) {
                renderCharFiltersRows([], [], 'initial', retainStoryData);
                setShowCharacteristicFilters(val);
            } else {
                showWarningMessages('productSetRequired');
            }
        } else {
            setShowCharacteristicFilters(val);
            setCharConditionValuesData([]);
        }
        if (!retainStoryData) setShowUpdateButton();
    };

    const changePPGDefinition = (e) => {
        setProductSelectorData({ ppgDefinition: e === '' ? defaultPpgName : e });
    };

    const redirectToPPG = () => {
        navigate('/dashboard/ppgs', { replace: true });
    };

    const getSelectedCharTheme = () => {
        let selectedCharThemName = '';
        if (characteristicThemeOptions.length > 0 && productSelectorData?.characteristicTheme) {
            const selectedObj = characteristicThemeOptions.find(
                (item) => item.values === productSelectorData?.characteristicTheme
            );
            selectedCharThemName = selectedObj ? selectedObj.display : '';
        }
        return selectedCharThemName;
    };

    const changeCharacteristicTheme = async (e) => {
        setCompetitiveProductSetList([]);
        setCharsForThemesCategories([]);
        setProductSelectorData({ charsFromTheme: [] });
        const selectedItem = characteristicThemeOptions.find((item) => item.display === e);
        const themeSku = selectedItem ? selectedItem.values : '';
        setProductSelectorData({ category: [], characteristicTheme: themeSku });
        if (themeSku) {
            fetchCompetitiveProductSetList('', themeSku);
        }
    };

    const selectedProductSetData = (initial = false) => {
        let selectedProdSet = [];
        if (productType === 'story' && fromBack && initial) {
            if (selectorState?.focusProductSelections?.productLevel === 'Category') {
                selectedProdSet = selectorState?.focusProductSelections?.competitiveProductSet?.categories;
            } else {
                selectedProdSet = selectorState?.focusProductSelections?.productSet;
            }
        } else {
            if (productLevel === 'Category') {
                selectedProdSet = productSelectorData?.category;
                if (!showSelector('characteristicThemes')) {
                    selectedProdSet =
                        productSelectorData.primaryCategory?.length > 0
                            ? productSelectorData.primaryCategory
                            : productSelectorData?.category;
                }
            } else {
                selectedProdSet = productSelectorData.productSet;
            }
        }
        return selectedProdSet;
    };

    const disableProductSetSelection = (productLevelField = false) => {
        let disable = false;
        if (showSelector('characteristicThemes')) {
            disable = !productSelectorData?.characteristicTheme;
        } else {
            disable =
                showSelector('focusBrand') &&
                !showSelector('focusBrandOptional') &&
                productSelectorData.focusBrand.length < 1;
        }
        if (productLevel === 'Total Store' && !productLevelField) {
            disable = true;
        }
        if (showSelector('brandManufacturer')) {
            disable = false;
        }
        return disable;
    };

    const fetchCharsForThemeCategories = async (categs) => {
        setCharsForThemesCategories([]);
        setProductSelectorData({ charsFromTheme: [] });
        if (categs?.length > 0) {
            let themeCategs = categs.length ? '{' + categs.join(', ') + '}' : '';
            const charsForThemeCategs = await getCharsForThemeAndCategories(
                productSelectorData?.characteristicTheme,
                themeCategs
            );
            if (charsForThemeCategs?.length > 0) {
                setCharsForThemesCategories(charsForThemeCategs);
            }
        }
    };

    const changeCharsForThemesCategories = (e) => {
        setProductSelectorData({ charsFromTheme: e });
    };

    const onChangeFocusProductAlias = (e, type = '') => {
        let alias = e.target.value;
        if (type === 'brandManufacturer') {
            setBrandManufacturerAlias(alias);
        } else if (type === 'brand') {
            setBrandAlias(alias);
        } else if (type === 'categoryTrendAnalysis') {
            setCharacteristicsAlias(alias);
        } else if (type === 'category') {
            setFocusProductAlias(alias);
        }
        setShowUpdateButton();
    };

    const getAliasInput = (productOption) => {
        let aliasHeader, aliasText, aliasName, aliasValue, aliasPlaceholder;
        if (productOption === 'brandManufacturer') {
            aliasHeader = `Name your Focus Brand/Manufacturer`;
            aliasText = subSelectors?.subSelectorDescription?.brandManufactureName;
            aliasName = `brandManufacturerAlias`;
            aliasValue = brandManufacturerAlias;
            aliasPlaceholder = `Focus Brand/Manufacturer Name`;
        } else if (productOption === 'brand') {
            aliasHeader = `Name your Focus Brand`;
            aliasText = subSelectors?.subSelectorDescription?.brandName;
            aliasName = `brandAlias`;
            aliasValue = brandAlias;
            aliasPlaceholder = `Focus Brand Name`;
        } else if (productOption === 'categoryTrendAnalysis') {
            aliasHeader = `Name your Characteristics`;
            aliasText = 'Rename the character to something that will be relevant to you and the retailer.';
            aliasName = `characteristicsAlias`;
            aliasValue = characteristicsAlias;
            aliasPlaceholder = `Characteristics  Name`;
        } else if (productOption === 'category') {
            aliasHeader = isProductOption2 ? `Name your Product` : `Name your Category`;
            aliasText = subSelectors?.subSelectorDescription?.categoryName;
            aliasName = `productSelectionAlias`;
            aliasValue = focusProductAlias;
            aliasPlaceholder = isProductOption2 ? 'Product Name' : 'Category Name';
        }

        return (
            <>
                <div className={`${baseClassName}__focus-brand-item-story`}>
                    <label className={`${baseClassName}__focus-brand-item-label`}>
                        <b>{aliasHeader}</b>
                    </label>
                </div>

                <div className={`${baseClassName}__subset-option-text-two`}>{aliasText}</div>

                <div className={`${baseClassName}__submit-btn-container__alias-container`}>
                    <ByzzerInput
                        style={{ width: '20em' }}
                        className={`${baseClassName}__alias-input`}
                        name={aliasName}
                        value={aliasValue}
                        placeholder={aliasPlaceholder}
                        onChange={(e) => onChangeFocusProductAlias(e, productOption)}
                        required
                    ></ByzzerInput>
                </div>
            </>
        );
    };

    const setShowUpdateButton = () => {
        setShowUpdate(true);
        let selectionsToSelState = JSON.parse(JSON.stringify(selectorState));
        if (productType === 'story') {
            selectionsToSelState.selectorsStatus[props.stepNo].status = selectorStates.pending;
            props.setSelectorsData({ selectorsStatus: selectionsToSelState.selectorsStatus });
            props.generateReport(selectionsToSelState);
        }
    };

    const handleInfoClick = () => {
        setShowInfo(true);
    };

    const onCategorySelected = async (category) => {
        let prodSetCategs = [...selectedProductSetData()];
        prodSetCategs.push(category);
        selectProductSet(prodSetCategs);
        setCategorySelector(false);
    };

    const changeSalesThreshold = (e) => {
        setProductSelectorData({ salesThreshold: e });
    };

    const changeGrowthThreshold = (e) => {
        setProductSelectorData({ growthThreshold: e });
    };

    const thresholdInfoText = (type) => {
        let infoText = '';
        if (type === 'sales') {
            infoText = salesThresholdInfoText;
        } else {
            infoText = growthThresholdInfoText;
        }
        return infoText;
    };

    const growthThresholdData = (growthThreshold) => {
        let gtData = '';
        if (growthThreshold) {
            gtData = `>${parseFloat(growthThreshold) / 100}`;
        }
        return gtData;
    };

    const renderAdvanceSearch = () => {
        if (categorySelector) {
            return (
                <ByzzerModal
                    show={categorySelector}
                    onClose={() => setCategorySelector(!categorySelector)}
                    size={'large'}
                    headerType={'none'}
                >
                    <ProductScopeNew
                        productScopeTitle={'Select a Category'}
                        productScopeSubtitle={'Select any 1 category for Top 10 Markets and Brands.'}
                        updateProductScope={(data) => onCategorySelected(data)}
                        maxCategoryCount={
                            productLevel === 'Category' && props.runType === 'adhoc'
                                ? advancedSearchMaxLimit()
                                : undefined
                        }
                        errorMessage={'Unselect the previously selected category.'}
                        submitButtonLabel={'Select Category'}
                        displayTreeButtonLabel={'Select Category'}
                        productScopeType={'Panel Releasability'}
                    />
                </ByzzerModal>
            );
        }
    };

    const renderProductLevelOptions = async (productLevel, retain = false) => {
        try {
            setLoading(true);
            let optionsList = [];
            setCompetitiveProductSetList([]);
            setProductSelectorData({ category: [], productSet: [], primaryCategory: [], subCategories: [] });
            setCoverageWarning('');
            setCoverageInfoMessage('');
            setCharacteristicsMapData([]); // Reset Characteristics
            setShowCharacteristicFilters(false);
            if (wholeCategoriesCount === '') {
                await fetchWholeCategoriesCount();
            }
            switch (productLevel) {
                case 'Total Store':
                    optionsList = ['Total Store'];
                    setCompetitiveProductSetList(optionsList);
                    setProductSelectorData({ productSet: optionsList });
                    selectProductSet(companyPrefCategories, retain);
                    setCoverageWarning(`${companyPrefCategories?.length} of ${wholeCategoriesCount} categories`);
                    setCoverageWarningSymbol(companyPrefCategories?.length / wholeCategoriesCount < 1);
                    setCoverageInfoMessage(`Your ${productLevel} view is limited to the categories in your subscription.
                You have access to ${companyPrefCategories?.length}/${wholeCategoriesCount} categories in the selected ${productLevel}`);
                    break;
                case 'Department':
                    optionsList = await fetchSpecificProductLevelData('Department', 'Category', companyPrefCategories);
                    optionsList = optionsList?.sort((a, b) => a?.localeCompare(b, 'en', { sensitivity: 'base' }));
                    if (productSelectorData.focusBrand?.length > 0) {
                        let categsByBrand = [...categInFocusBrand];
                        let departmentsInFocusBrand = await fetchSpecificProductLevelData(
                            'Department',
                            'Category',
                            categsByBrand
                        );
                        let departmentsOutFocusBrand =
                            optionsList.length > 0
                                ? optionsList.filter((item) => !departmentsInFocusBrand.includes(item))
                                : [];
                        optionsList = [
                            ...new Set([
                                ...departmentsInFocusBrand,
                                ...departmentsOutFocusBrand?.sort((a, b) =>
                                    a?.localeCompare(b, 'en', { sensitivity: 'base' })
                                ),
                            ]),
                        ];
                    }
                    setCompetitiveProductSetList(optionsList);
                    break;
                case 'Supercategory':
                    optionsList = await fetchSpecificProductLevelData(
                        'Supercategory',
                        'Category',
                        companyPrefCategories
                    );
                    optionsList = optionsList?.sort((a, b) => a?.localeCompare(b, 'en', { sensitivity: 'base' }));
                    if (productSelectorData.focusBrand?.length > 0) {
                        let categsByBrand = [...categInFocusBrand];
                        let superCategsInFocusBrand = await fetchSpecificProductLevelData(
                            'Supercategory',
                            'Category',
                            categsByBrand
                        );
                        let superCategsOutFocusBrand =
                            optionsList.length > 0
                                ? optionsList.filter((item) => !superCategsInFocusBrand.includes(item))
                                : [];
                        optionsList = [
                            ...new Set([
                                ...superCategsInFocusBrand,
                                ...superCategsOutFocusBrand?.sort((a, b) =>
                                    a?.localeCompare(b, 'en', { sensitivity: 'base' })
                                ),
                            ]),
                        ];
                    }
                    setCompetitiveProductSetList(optionsList);
                    break;
                case 'Category':
                    await fetchCompetitiveProductSetList(productSelectorData.focusBrand, getSelectedCharTheme());
                    break;
                case 'Subcategory':
                    optionsList = await fetchSpecificProductLevelData('Subcategory', 'Category', companyPrefCategories);
                    optionsList = optionsList?.sort((a, b) => a?.localeCompare(b, 'en', { sensitivity: 'base' }));
                    if (productSelectorData.focusBrand?.length > 0) {
                        let categsByBrand = [...categInFocusBrand];
                        let subCategsInFocusBrand = await fetchSpecificProductLevelData(
                            'Subcategory',
                            'Category',
                            categsByBrand
                        );
                        let subCategsOutFocusBrand =
                            optionsList.length > 0
                                ? optionsList.filter((item) => !subCategsInFocusBrand.includes(item))
                                : [];
                        optionsList = [
                            ...new Set([
                                ...subCategsInFocusBrand,
                                ...subCategsOutFocusBrand?.sort((a, b) =>
                                    a?.localeCompare(b, 'en', { sensitivity: 'base' })
                                ),
                            ]),
                        ];
                    }
                    setCompetitiveProductSetList(optionsList);
                    break;
                default:
                    await fetchCompetitiveProductSetList(productSelectorData.focusBrand, getSelectedCharTheme());
                    break;
            }
        } catch (err) {
            // todo: need to display something to the user
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const fetchSpecificProductLevelData = async (levelRequested, selectedLevel, selectedLevelData) => {
        let categAttribute;
        if (selectedLevel === 'Category') {
            categAttribute = selectedLevelData;
        } else if (selectedLevel === 'Total Store') {
            categAttribute = companyPrefCategories;
        }
        const hierarchyPostData = {
            hierarchyType: levelRequested?.toLowerCase(),
            supercategories: selectedLevel === 'Supercategory' ? selectedLevelData : undefined,
            categories: categAttribute || undefined,
            departments: selectedLevel === 'Department' ? selectedLevelData : undefined,
            subcategories: selectedLevel === 'Subcategory' ? selectedLevelData : undefined,
        };
        const prodLevelResp = await retailerHierarchies(hierarchyPostData);
        let productLevelDataResp = [];
        switch (levelRequested) {
            case 'Department':
                productLevelDataResp = prodLevelResp.departments;
                break;
            case 'Supercategory':
                productLevelDataResp = prodLevelResp.supercategories;
                break;
            case 'Category':
                productLevelDataResp = prodLevelResp.categories;
                break;
            case 'Subcategory':
                productLevelDataResp = prodLevelResp.subcategories;
                break;
            default:
                productLevelDataResp = prodLevelResp.categories;
                break;
        }
        return productLevelDataResp;
    };

    const displayProductLevel = () => {
        return productLevel !== 'Total Store' ? productLevel + '(s)' : productLevel;
    };

    const displayBrandLevel = () => {
        return brandManufacturerLevel === 'Focus Brand' ? brandManufacturerLevel + '(s)' : brandManufacturerLevel;
    };

    const fetchCategoriesForFocusBrand = async (brandList, companySubscrCategs) => {
        let categsByBrand = [];
        if (brandList) {
            let responseCateg = [];
            for (let i = 0; i < brandList.length; i++) {
                let result = await handleAllCategoryByBrand(brandList[i]);
                if (result) {
                    result.forEach((value) => {
                        responseCateg.push(value);
                    });
                }
            }
            let responseCategList = responseCateg;
            if (responseCategList) {
                categsByBrand = responseCategList.map((item) => item.name);
                if (!props.showAll) {
                    categsByBrand = categsByBrand.filter((item) => companySubscrCategs.includes(item));
                }
                setCategInFocusBrand(categsByBrand);
            }
        }
        return categsByBrand;
    };

    const categoriesSelectionLimit = () => {
        return (props.addons?.filter((item) => item.type === 'product_addon_category')?.length ?? []) + 1;
    };

    const productSetMaxSelection = () => {
        return props.runType === 'adhoc' && productLevel === 'Category' ? categoriesSelectionLimit() : undefined;
    };

    const advancedSearchMaxLimit = () => {
        let advMaxLimit = 0;
        if (productSetMaxSelection() - selectedProductSetData()?.length > 0) {
            advMaxLimit = productSetMaxSelection() - selectedProductSetData()?.length;
        }
        return advMaxLimit;
    };

    async function fetchWholeCategoriesCount() {
        const categRequestPost = {
            requireTotalCount: true,
        };
        const totalCategCountResp = await getCategoryCount(categRequestPost);
        setWholeCategoriesCount(totalCategCountResp?.totalCategoriesCount ?? '');
    }

    // Added for Stories: To validate characteristics and display warning msg to user
    const insightsValidation = async (selectedValues) => {
        setLoading(true);
        let selectedObjectives = props.selectedObjectives ?? [];
        let warningMsg = '';
        // [warningMsg] = await Promise.all([
        //     getInsightsWarningMsg(selectedValues, selectedObjectives, sku, props.storyType, 'characteristics'),
        // ]);
        setLoading(false);
        if (warningMsg !== '') {
            if (
                await confirm({
                    closeButton: false,
                    className: 'my-story__warning-container',
                    content: <div>{warningMsg}</div>,
                    yesLabel: 'Yes, run the story',
                    noLabel: 'No, I will change selections',
                })
            ) {
                props.setSelectorsData({
                    activeCollapsePanel: selectedValues.activeCollapsePanel,
                });
                props.generateReport(selectedValues);
            }
        } else {
            props.setSelectorsData({
                activeCollapsePanel: selectedValues.activeCollapsePanel,
            });
            props.generateReport(selectedValues);
        }
    };

    function changeAggregationHierarchy(e) {
        setResetFocusMarket(true); // To reset already selected focus market
        setProductSelectorData({ aggregationHierarchy: e });
    }

    function aggregationHierarchyInfoMessage() {
        return (
            <div className="aggregation-hierarchy-tooltip-container">
                <div className="aggregation-hierarchy-tooltip-info">{aggregationHierarchyInfoText}</div>
                <div className="aggregation-hierarchy-tooltip-more">
                    <ByzzerLink label={'More detail'} onClick={openAggregationMoreDetail} />
                </div>
            </div>
        );
    }

    async function fetchCharsValues(charItem, categoriesList = []) {
        setLoading(true);
        let charsValueOptions = [];
        let customCharacteristics = [];
        if (customChars?.length === 0) {
            customCharacteristics = await getCustomChars();
            setCustomChars(customCharacteristics);
        }
        if (charItem?.includes(CUSTOM_CHAR_EXTENSION)) {
            // Custom Chars
            customCharacteristics = customChars?.length > 0 ? customChars : customCharacteristics;
            const customCharId = customCharacteristics?.find((item) => item.label + CUSTOM_CHAR_EXTENSION === charItem)
                ?.id;
            charsValueOptions = await getCustomCharWithNASegment(customCharId);
        } else {
            const productSet = categoriesList?.map((item) => {
                return {
                    hierarchy: 'CATEGORY',
                    name: item,
                };
            });
            const productSetObj = {
                categories: productSet,
                themePurchaseType,
            };
            let charsResp = [];
            let charsAndCodes = [];
            if (charCodesData?.length === 0) {
                charsResp = await getProductChars(productSetObj);
                if (charsResp) {
                    charsAndCodes = [...charsResp?.characteristics];
                    setCharCodesData(charsResp?.characteristics);
                }
            } else {
                charsAndCodes = [...charCodesData];
            }
            const charValsResp = await getCharacteristicValues({
                categories: productSet,
                charCode: charsAndCodes?.find((item) => item.chr_display_name === charItem)?.chr_code,
            });
            charsValueOptions = [...charValsResp];
        }
        setLoading(false);
        return charsValueOptions;
    }

    async function getCategoriesListForProductLevel(category = null) {
        let categListForProductLevel = [];
        if (productLevel !== 'Category' && productSelectorData.productSet.length > 0) {
            if (productType === 'story' && fromBackOnce) {
                categListForProductLevel = await fetchSpecificProductLevelData(
                    'Category',
                    selectorState?.focusProductSelections?.productLevel ?? productLevel,
                    selectorState?.focusProductSelections?.productSet?.length > 0
                        ? selectorState?.focusProductSelections?.productSet
                        : selectorState?.focusProductSelections?.competitiveProductSet?.categories
                );
            } else {
                categListForProductLevel = await fetchSpecificProductLevelData(
                    'Category',
                    productLevel,
                    productSelectorData.productSet
                );
            }
            categListForProductLevel = categListForProductLevel.filter((item) => companyPrefCategories.includes(item));
        } else {
            if (productType === 'story' && fromBackOnce) {
                categListForProductLevel = category
                    ? category
                    : selectorState?.focusProductSelections?.competitiveProductSet?.categories?.length > 0
                        ? selectorState?.focusProductSelections?.competitiveProductSet?.categories
                        : selectorState?.focusProductSelections?.competitiveProductSet?.primaryCategory;
            } else {
                categListForProductLevel = category
                    ? category
                    : productSelectorData?.category?.length > 0
                        ? productSelectorData?.category
                        : productSelectorData.primaryCategory;
            }
        }
        return categListForProductLevel;
    }

    const closeProTips = () => {
        setProTipsVisible(false);
    };

    const toggleProTipsVisible = (toggler) => {
        setProTipsVisible(toggler);
    };

    const handlePpgIdChange = (ppgId) => {
        const ppgData = ppgOptions.filter((e) => e.value == ppgId);
        setPpgData(...ppgData);
        setPpgId(ppgId);
        changePPGDefinition(...ppgData)
        setShowUpdateButton();
    };

    function changeProductLevel(prodLevel) {
        setProductSelectorData({ productLevel: prodLevel, focusProduct: [] });
    }

    const changeFocusProduct = async (focusProd, status) => {
        setResetFocusMarket(true); // To reset already selected focus market
        let oldFocusProduct = productSelectorData.focusProduct;
        if (status) {
            oldFocusProduct.push(focusProd);
        } else {
            const index = oldFocusProduct.indexOf(focusProd);
            if (index !== -1) {
                oldFocusProduct.splice(index, 1);
            }
        }
        setProductSelectorData({
            focusProduct: [...oldFocusProduct],
        });
    };

    function focusProductLabel() {
        return subSelectors.brands?.maxLimit && subSelectors.brands?.maxLimit > 0
            ? `Select up to ${subSelectors.brands?.maxLimit} product${subSelectors.brands?.maxLimit > 1 ? 's' : ''}`
            : 'Select focus product';
    }

    const handleByzzerCategorySearchChange = (byzzerData) => {
        setByzzerCategorySearchValue(byzzerData.value);
    };

    return (
        <div className={`${baseClassName}`}>
            <ByzzerMask loading={loading} />

            {productType === 'story' && (
                <div className={`${baseClassName}__subset-option-text-two`}>
                    {`${subSelectors?.subSelectorDescription?.product}`}
                </div>
            )}

            {showSelector('focusBrandfilter') && (
                <div className={`${baseClassName}__focus-brand`}>
                    <div className={`${baseClassName}__focus-brand-item`}>
                        <label className={`${baseClassName}__focus-brand-item-label`}>Select your focus brand </label>
                        <label className={`${baseClassName}__competitive-set-item-optional`}>
                            ({showSelector('focusBrandOptional') ? `Optional` : `Required`}):
                        </label>
                    </div>
                    <div className={`${baseClassName}__focus-brand-select`}>
                        <ByzzerSearchableSelect
                            seperator={','}
                            enableToolTip={productSelectorData.focusBrand.length > 0 ? true : false}
                            options={productSelectorData.focusBrand.length > 0 ? productSelectorData.focusBrand : []}
                            placeholder={
                                productSelectorData.focusBrand.length > 0
                                    ? productSelectorData.focusBrand
                                    : 'Select your focus brands'
                            }
                            searchPlaceholder={'Search for brand'}
                            onChange={(e) => {
                                unselectBrand(e);
                            }}
                            productSet={productSet}
                            searchOnChange={(value, status) => changeBrand(value, status)}
                            searchValue={
                                productSelectorData?.focusBrand?.length > 0 ? productSelectorData?.focusBrand : ''
                            }
                            searchType={
                                props.runType === 'adhoc'
                                    ? 'brandSearchMultiSelect'
                                    : 'searchBrandForProductSetMultiSelect'
                            }
                            productType={productType}
                            multiBrandSelect={subSelectors?.multiBrand} // stories
                        />
                    </div>
                </div>
            )}

            {showSelector('characteristicThemes') && (
                <div className={`${baseClassName}__char-themes`}>
                    <div className={`${baseClassName}__char-themes-item`}>
                        <label className={`${baseClassName}__char-themes-item-label`}>
                            Choose your attribute group:
                        </label>
                    </div>
                    <div className={`${baseClassName}__char-themes-select`}>
                        <ByzzerSelect
                            value={getSelectedCharTheme()}
                            placeholder={productSelectorData?.characteristicTheme || 'Select from the list'}
                            options={characteristicThemeOptions.map((item) => item.display)}
                            onChange={(e) => changeCharacteristicTheme(e)}
                            disabled={false}
                            allowClear={true}
                        />
                    </div>
                </div>
            )}

            {/* brand manufactrer  */}
            {showSelector('brandManufacturer') && (
                <div className={`${baseClassName}__competitive-set-advance-search`}>
                    <div className={`${baseClassName}__competitive-set`}>
                        <div className={`${baseClassName}__competitive-set-item`}>
                            <div className={`${baseClassName}__competitive-set-item-labelsection`}>
                                <label className={`${baseClassName}__competitive-set-item-labelsection-label`}>
                                    Select your
                                </label>
                                <div className={`${baseClassName}__competitive-set-item-labelsection-dropdown`}>
                                    <ByzzerSelect
                                        value={displayBrandLevel()}
                                        placeholder={displayBrandLevel()}
                                        options={brandManufacturerLevels}
                                        onChange={(e) => setBrandLevelOnChange(e)}
                                        disabled={disableProductSetSelection(true)}
                                        no
                                        condition
                                        as
                                        of
                                        now
                                        linkedDropdown={true}
                                    />
                                    <Tooltip
                                        placement="bottom"
                                        overlayClassName={`${baseClassName}__sales-threshold-item-tooltip`}
                                        title={brandManufacturerLabelInfo}
                                    >
                                        <img
                                            className={`${baseClassName}__sales-threshold-item-tooltip-icon`}
                                            alt="info"
                                            src={infoIcon}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        {brandManufacturerLevel === 'Focus Brand' && (
                            <div className={`${baseClassName}__focus-brand-select`}>
                                <ByzzerSearchableSelect
                                    seperator={','}
                                    enableToolTip={productSelectorData.focusBrand.length > 0 ? true : false}
                                    options={
                                        productSelectorData.focusBrand.length > 0 ? productSelectorData.focusBrand : []
                                    }
                                    placeholder={
                                        productSelectorData.focusBrand.length > 0
                                            ? productSelectorData.focusBrand
                                            : 'Select your focus brands'
                                    }
                                    searchPlaceholder={'Search for brand'}
                                    onChange={(e) => {
                                        // unselectBrand(e);
                                        changeBrandManufacturer(e);
                                    }}
                                    productSet={productSet}
                                    searchOnChange={(value, status) => changeBrandManufacturer(value, status)}
                                    searchValue={
                                        productSelectorData?.focusBrand?.length > 0
                                            ? productSelectorData?.focusBrand
                                            : ''
                                    }
                                    searchType={
                                        props.runType === 'adhoc'
                                            ? 'brandSearchMultiSelect'
                                            : 'searchBrandForProductSetMultiSelect'
                                    }
                                    productType={productType}
                                    multiBrandSelect={brandManufacturerLevel === 'Focus Brand'}
                                    stories
                                />
                            </div>
                        )}

                        {brandManufacturerLevel === 'Manufacturer' && (
                            <div className={`${baseClassName}__focus-brand-select`}>
                                <ByzzerSearchableSelect
                                    seperator={','}
                                    enableToolTip={productSelectorData?.manufacturer.length > 0 ? true : false}
                                    options={
                                        productSelectorData?.manufacturer.length > 0
                                            ? productSelectorData?.manufacturer
                                            : []
                                    }
                                    placeholder={
                                        productSelectorData.manufacturer.length > 0
                                            ? productSelectorData.manufacturer
                                            : 'Select your Manufacturer'
                                    }
                                    searchPlaceholder={'Search for Manufacturer'}
                                    onChange={(e) => {
                                        changeBrandManufacturer(e);
                                    }}
                                    productSet={productSet}
                                    searchOnChange={(value, status) => changeBrandManufacturer(value, status)}
                                    searchValue={
                                        productSelectorData?.manufacturer?.length > 0
                                            ? productSelectorData?.manufacturer
                                            : ''
                                    }
                                    searchType={'manufactureFromCategoriesSearch'}
                                    productType={productType}
                                    multiBrandSelect={brandManufacturerLevel === 'Focus Brand'}
                                    stories
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}

            {showSelector('competitiveSet') && (
                <div className={`${baseClassName}__competitive-set-advance-search`}>
                    <div className={`${baseClassName}__competitive-set`}>
                        <div className={`${baseClassName}__competitive-set-item`}>
                            <div className={`${baseClassName}__competitive-set-item-labelsection`}>
                                <label className={`${baseClassName}__competitive-set-item-labelsection-label`}>
                                    Select your
                                </label>
                                <div className={`${baseClassName}__competitive-set-item-labelsection-dropdown`}>
                                    {datatype === 'omni' ? (
                                        <label>Category(s):</label>
                                    ) : (
                                        <>
                                            <ByzzerSelect
                                                value={displayProductLevel()}
                                                placeholder={displayProductLevel()}
                                                options={focusProductLevels}
                                                onChange={(e) => setProductLevelOnChange(e)}
                                                disabled={disableProductSetSelection(true)}
                                                linkedDropdown={true}
                                            />
                                            <Tooltip
                                                placement="bottom"
                                                overlayClassName={`${baseClassName}__sales-threshold-item-tooltip`}
                                                title={focusProductLevelLabelInfo}
                                            >
                                                <img
                                                    className={`${baseClassName}__sales-threshold-item-tooltip-icon`}
                                                    alt="info"
                                                    src={infoIcon}
                                                />
                                            </Tooltip>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={`${baseClassName}__competitive-set-select`}>
                            {/* <ByzzerCategorySearch
                                value={byzzerCategorySearchValue}
                                // themeSku={'575'}
                                onChange={handleByzzerCategorySearchChange}
                                name={'competitive-set-select'}
                                placeholder={'Select from the list '}
                                // labelText={'Craigs Cool Category Search'}
                                maxNumberOfSelections={productSetMaxSelection()}
                                // options={byzzerCategorySearchOptionsTest}
                                brand={productSelectorData.focusBrand}
                                companyPreferenceCategories={companyPreferences?.categories}
                            /> */}
                            <ByzzerMultiSelect
                                selectedOptions={selectedProductSetData()}
                                disabled={disableProductSetSelection()}
                                name={'competitive-productset'}
                                placeholder={'Select from the list'}
                                multiSelectWithCheckBox
                                onChange={(e) => selectProductSet(e)}
                                options={(datatype === 'omni' ? user?.omniCategories : competitiveProductSetList) || []}
                                seperator={'OR'}
                                allowClear={productLevel !== 'Total Store'}
                                enableToolTip={props.runType === 'adhoc' ? true : false}
                                productType={productType}
                                maxSelection={productSetMaxSelection()}
                            />
                        </div>

                        {coverageWarning && (
                            <div className={`${baseClassName}__competitive-set-warning`}>
                                <img
                                    className={`${baseClassName}__competitive-set-warning-img ${!coverageWarningSymbol ? `hide` : ``
                                        }`}
                                    src={warningRed}
                                    alt="competitive-set-warning-img"
                                />
                                <label>{coverageWarning}</label>
                                {coverageWarning && (
                                    <Tooltip
                                        placement="bottom"
                                        overlayClassName={`${baseClassName}__sales-threshold-item-tooltip`}
                                        title={coverageInfoMessage}
                                    >
                                        <img
                                            className={`${baseClassName}__sales-threshold-item-tooltip-icon`}
                                            alt="info"
                                            src={infoIcon}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        )}
                        {props.runType === 'adhoc' && (
                            <div className={`${baseClassName}__competitive-set-advance-search-section`}>
                                <ByzzerLink
                                    label={'Advanced Search'}
                                    onClick={(e) => {
                                        setCategorySelector(true);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}

            {showSelector('dynamicHierarchy') && (
                <div className={`${baseClassName}__aggregation-hierarchy-advance-search`}>
                    <div className={`${baseClassName}__aggregation-hierarchy`}>
                        <div className={`${baseClassName}__aggregation-hierarchy-item`}>
                            <label className={`${baseClassName}__aggregation-hierarchy-item-label`}>
                                Aggregate brands across:
                                <Tooltip
                                    placement="bottom"
                                    overlayClassName={`${baseClassName}__aggregation-hierarchy-item-tooltip`}
                                    title={aggregationHierarchyInfoMessage()}
                                >
                                    <img
                                        className={`${baseClassName}__aggregation-hierarchy-item-tooltip-icon`}
                                        alt="info"
                                        src={infoIcon}
                                    />
                                </Tooltip>
                            </label>
                        </div>
                        <div className={`${baseClassName}__aggregation-hierarchy-select`}>
                            <ByzzerSelect
                                value={productSelectorData.aggregationHierarchy}
                                placeholder={productSelectorData.aggregationHierarchy}
                                options={aggregationHierarchyOptions}
                                onChange={(e) => changeAggregationHierarchy(e)}
                                disabled={false}
                                allowClear={false}
                                readonly={true}
                            />
                        </div>
                    </div>
                </div>
            )}

            {includeProductLevel && (
                <div className={`${baseClassName}__product-level-advance-search`}>
                    <div className={`${baseClassName}__product-level`}>
                        <div className={`${baseClassName}__product-level-item`}>
                            <label className={`${baseClassName}__product-level-item-label`}>
                                Product level:
                                <Tooltip
                                    placement="bottom"
                                    overlayClassName={`${baseClassName}__product-level-item-tooltip`}
                                    title={productLevelInfoHoverMessage}
                                >
                                    <img
                                        className={`${baseClassName}__product-level-item-tooltip-icon`}
                                        alt="info"
                                        src={infoIcon}
                                    />
                                </Tooltip>
                            </label>
                        </div>
                        <div className={`${baseClassName}__product-level-select`}>
                            <ByzzerSelect
                                value={productSelectorData.productLevel}
                                placeholder={productSelectorData.productLevel}
                                options={productLevelOptions}
                                onChange={(e) => changeProductLevel(e)}
                                disabled={productSelectorData.category?.length < 1}
                                allowClear={false}
                                readonly={true}
                            />
                        </div>
                    </div>
                </div>
            )}

            {includeProductLevel && (
                <>
                    <div className={`${baseClassName}__competitive-set`}>
                        <div className={`${baseClassName}__competitive-set-item`}>
                            <label className={`${baseClassName}__competitive-set-item-label`}>
                                {focusProductLabel()}
                            </label>
                            <label className={`${baseClassName}__competitive-set-item-optional`}>
                                ({requireFocusBrand ? `Required` : `Optional`}):
                            </label>
                        </div>
                        <div className={`${baseClassName}__competitive-set-select`}>
                            <ByzzerSearchableSelect
                                seperator={','}
                                enableToolTip={productSelectorData.focusProduct.length > 0 ? true : false}
                                options={
                                    productSelectorData.focusProduct.length > 0 ? productSelectorData.focusProduct : []
                                }
                                placeholder={
                                    productSelectorData.focusProduct.length > 0
                                        ? productSelectorData.focusProduct
                                        : `Select your focus ${productSelectorData.productLevel}`
                                }
                                searchPlaceholder={`Search for Focus ${productSelectorData.productLevel}`}
                                onChange={(e) => {
                                    changeFocusProduct(e, false);
                                }}
                                categories={productSelectorData.category}
                                searchOnChange={(value, status) => changeFocusProduct(value, status)}
                                searchValue={
                                    productSelectorData?.focusProduct?.length > 0
                                        ? productSelectorData?.focusProduct
                                        : ''
                                }
                                searchType={'searchItemForFocusProduct'}
                                productType={productType}
                                productLevel={productSelectorData.productLevel}
                                maxLimit={
                                    subSelectors.brands?.maxLimit && +subSelectors.brands?.maxLimit > 0
                                        ? subSelectors.brands?.maxLimit
                                        : false
                                }
                            />
                        </div>
                    </div>
                </>
            )}

            {props.marketGroup === 'cps' && (
                <>
                    <div className={`${baseClassName}__competitive-set`}>
                        <div className={`${baseClassName}__competitive-set-item`}>
                            <label className={`${baseClassName}__competitive-set-item-label`}>
                                Select your subcategory
                            </label>
                            <label className={`${baseClassName}__competitive-set-item-optional`}> (Optional):</label>
                        </div>
                        <div className={`${baseClassName}__competitive-set-select`}>
                            <ByzzerMultiSelect
                                selectedOptions={productSelectorData?.subCategories}
                                name={'sub-category'}
                                placeholder={'All'}
                                multiSelectWithCheckBox
                                onChange={(e) => changeSubCategory(e)}
                                options={subCategories || []}
                                seperator={'OR'}
                                allowClear={true}
                                disabled={productSelectorData?.category?.length === 0 ? true : false}
                                productType={productType}
                            />
                        </div>
                    </div>

                    {sku !== '239' && (
                        <div className={`${baseClassName}__focus-brand`}>
                            <div className={`${baseClassName}__focus-brand-item`}>
                                <label className={`${baseClassName}__focus-brand-item-label`}>Select your brand </label>
                                {
                                    <label className={`${baseClassName}__competitive-set-item-optional`}>
                                        ({cpsBrandMandatorySkus.includes(sku) ? `Required` : `Optional`}):
                                    </label>
                                }
                            </div>
                            <div className={`${baseClassName}__focus-brand-select`}>
                                <ByzzerSearchableSelect
                                    options={productSelectorData?.brand?.length > 0 ? productSelectorData?.brand : []}
                                    placeholder={
                                        productSelectorData?.brand?.length > 0
                                            ? productSelectorData?.brand
                                            : cpsBrandMandatorySkus.includes(sku)
                                                ? 'Select Brand'
                                                : 'All'
                                    }
                                    searchPlaceholder={'Search for brand'}
                                    onChange={(e) => {
                                        unselectCPSBrand(e);
                                    }}
                                    productSet={[...productSetCPS]}
                                    searchOnChange={(value, status) => changeCPSBrand(value, status)}
                                    searchValue={
                                        productSelectorData?.brand?.length > 0 ? productSelectorData?.brand : ''
                                    }
                                    searchType={
                                        props.runType === 'adhoc'
                                            ? 'brandSearchMultiSelect'
                                            : 'searchBrandForProductSetMultiSelect'
                                    }
                                    disabled={productSelectorData?.category?.length === 0 ? true : false}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}

            {(showSelector('salesThreshold') || showSelector('growthThreshold')) && (
                <>
                    <div className={`${baseClassName}__subset-option`}>
                        <div className={`${baseClassName}__subset-option-text-thresholds`}>
                            <span>
                                Would you like to filter the manufacturers included in the{' '}
                                {productType === 'story' ? 'story' : 'report'} based on{' '}
                                {showSelector('salesThreshold') && showSelector('growthThreshold')
                                    ? 'sales or growth  '
                                    : showSelector('salesThreshold')
                                        ? 'sales '
                                        : 'growth '}{' '}
                                thresholds?
                            </span>
                            <Popover
                                placement="left"
                                overlayClassName={`${baseClassName}__protips`}
                                title={
                                    <div className={`${baseClassName}__protips-title`}>
                                        <span className={`${baseClassName}__protips-title-text`}>
                                            FS Trendsetters Report Pro Tips
                                        </span>
                                        <i
                                            className={`${baseClassName}__protips-close-icon`}
                                            alt={'close'}
                                            onClick={closeProTips}
                                        />
                                    </div>
                                }
                                trigger="click"
                                visible={proTipsVisible}
                                onVisibleChange={toggleProTipsVisible}
                                content={
                                    <div className={`${baseClassName}__protips-content`}>
                                        <p className={`${baseClassName}__protips-group`}>
                                            <span className={`${baseClassName}__protips-header`}>
                                                What time period are the $ Sales and Growth Thresholds based on?
                                            </span>
                                            <br />
                                            <span className={`${baseClassName}__protips-verbiage`}>
                                                The thresholds are based on the Latest 52 Weeks of data.
                                            </span>
                                        </p>
                                        <p className={`${baseClassName}__protips-group`}>
                                            <span className={`${baseClassName}__protips-header`}>
                                                What markets are the $ Sales and Growth Thresholds based on?
                                            </span>
                                            <br />
                                            <span className={`${baseClassName}__protips-verbiage`}>
                                                The thresholds are based on an aggregation of the Total FMCG Retailers,
                                                Total Convenience Stores, Total Pet Stores, and Total Liquor Plus
                                                markets. This allows us to create a total US market view.
                                            </span>
                                        </p>
                                        <p className={`${baseClassName}__protips-group`}>
                                            <span className={`${baseClassName}__protips-header`}>
                                                What categories are the $ Sales and Growth Thresholds based on?
                                            </span>
                                            <br />
                                            <span className={`${baseClassName}__protips-verbiage`}>
                                                The thresholds are based on a total store view, so all the categories
                                                the manufacturer sells in.
                                            </span>
                                        </p>
                                        <p className={`${baseClassName}__protips-group`}>
                                            <span className={`${baseClassName}__protips-header`}>
                                                Why don't my thresholds appear to be applied in the report?
                                            </span>
                                            <br />
                                            <span className={`${baseClassName}__protips-verbiage`}>
                                                Don't worry - the thresholds are applied! What you are likely noticing
                                                is the difference between the categories, markets, and time periods used
                                                to calculate the thresholds and the categories, market, and time period
                                                you have selected for that specific report run. We use all categories,
                                                Latest 52 Weeks, and a Total US market aggregation to help you filter
                                                the manufacturers based on the big picture sales and YOY growth. Then,
                                                you can run the report for the categories, time period, and market that
                                                you would like to zone in on.
                                            </span>
                                        </p>
                                    </div>
                                }
                            >
                                <ByzzerLink className={`${baseClassName}__protips-target`} label="Pro Tips" />
                            </Popover>
                        </div>
                    </div>

                    <div className={`${baseClassName}__sales-and-growth-thresholds`}>
                        {showSelector('salesThreshold') && (
                            <div className={`${baseClassName}__sales-threshold`}>
                                <div className={`${baseClassName}__sales-threshold-advance-search`}>
                                    <div className={`${baseClassName}__sales-threshold-item`}>
                                        <label className={`${baseClassName}__sales-threshold-item-label`}>
                                            Select your $ Sales Threshold (Optional):
                                            <Tooltip
                                                placement="bottom"
                                                overlayClassName={`${baseClassName}__sales-threshold-item-tooltip`}
                                                title={thresholdInfoText('sales')}
                                            >
                                                <img
                                                    className={`${baseClassName}__sales-threshold-item-tooltip-icon`}
                                                    alt="info"
                                                    src={infoIcon}
                                                />
                                            </Tooltip>
                                        </label>
                                    </div>

                                    <div className={`${baseClassName}__sales-threshold-select`}>
                                        <ByzzerMultiSelect
                                            selectedOptions={productSelectorData.salesThreshold}
                                            disabled={false}
                                            name={'sales-threshold'}
                                            placeholder={'Do not apply threshold'}
                                            multiSelectWithCheckBox
                                            onChange={(e) => changeSalesThreshold(e)}
                                            options={salesThresholdOptions}
                                            seperator={'OR'}
                                            allowClear={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {showSelector('growthThreshold') && (
                            <div className={`${baseClassName}__growth-threshold-advance-search`}>
                                <div className={`${baseClassName}__growth-threshold`}>
                                    <div className={`${baseClassName}__growth-threshold-item`}>
                                        <label className={`${baseClassName}__growth-threshold-item-label`}>
                                            Select your Growth Threshold (Optional):
                                            <Tooltip
                                                placement="bottom"
                                                overlayClassName={`${baseClassName}__growth-threshold-item-tooltip`}
                                                title={thresholdInfoText('growth')}
                                            >
                                                <img
                                                    className={`${baseClassName}__growth-threshold-item-tooltip-icon`}
                                                    alt="info"
                                                    src={infoIcon}
                                                />
                                            </Tooltip>
                                        </label>
                                    </div>

                                    <div className={`${baseClassName}__growth-threshold-select`}>
                                        <ByzzerSelect
                                            value={productSelectorData.growthThreshold}
                                            placeholder={'Do not apply threshold'}
                                            options={growthThresholdOptions}
                                            onChange={(e) => changeGrowthThreshold(e)}
                                            disabled={false}
                                            allowClear={true}
                                            readonly={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}

            {showSelector('characteristicsForTheme') && productSelectorData?.category?.length > 0 && (
                <div className={`${baseClassName}__charsfor-theme`}>
                    <div className={`${baseClassName}__charsfor-theme-title`}>
                        <label className={`${baseClassName}__charsfor-theme-title-text`}>
                            Select specific attributes from attribute group to include:
                        </label>
                    </div>
                    <div className={`${baseClassName}__charsfor-theme-description`}>
                        If you are only interested in a sub-set of attributes in the attribute group, you can limit the
                        list to make the report more applicable to your business. Attributes that are not applicable for
                        your categories will be excluded automatically.
                    </div>
                    <div className={`${baseClassName}__charsfor-theme-select`}>
                        <div className={`${baseClassName}__charsfor-theme-select-item`}>
                            <label className={`${baseClassName}__charsfor-theme-select-item-label`}>
                                Choose attributes:
                            </label>
                        </div>
                        <div className={`${baseClassName}__charsfor-theme-select-options`}>
                            <ByzzerMultiSelect
                                selectedOptions={productSelectorData?.charsFromTheme}
                                disabled={false}
                                name={'charsfor-theme'}
                                placeholder={'All'}
                                multiSelectWithCheckBox
                                onChange={(e) => changeCharsForThemesCategories(e)}
                                options={charsForThemesCategories}
                                seperator={'OR'}
                            />
                        </div>
                    </div>
                </div>
            )}

            {ppgReport && (
                <div className={`${baseClassName}__ppg`}>
                    <div className={`${baseClassName}__ppg-label`}>
                        <label className={`${baseClassName}__ppg-label-text`}>PPG Definition</label>
                    </div>
                    <div className={`${baseClassName}__ppg-description`}>
                        This report leverages PPG to provide recommendations at an actionable level.
                    </div>
                    <div className={`${baseClassName}__ppg-description`}>
                        {ppgs?.length === 0 && (
                            <>
                                Your Team has not created any PPG list for the selected categories. Head to{' '}
                                <ByzzerLink onClick={redirectToPPG}>PPGs</ByzzerLink> to define your own.
                            </>
                        )}
                        {ppgs?.length > 0 && <>Choose which PPG definition to use.</>}
                    </div>
                    <div className={`${baseClassName}__ppg-select`}>
                        <ByzzerSelect
                            value={ppgId}
                            placeholder={defaultPpgName}
                            options={ppgOptions}
                            onChange={handlePpgIdChange}
                            disabled={!categories?.length}
                            allowClear={true}
                        />
                    </div>
                </div>
            )}

            {showSelector('characteristicDimension') && (
                <div className={`${baseClassName}__characteristic-dimension`}>
                    <div className={`${baseClassName}__characteristic-dimension-item`}>
                        <label className={`${baseClassName}__characteristic-dimension-item-label`}>
                            Choose your characteristic dimension:
                        </label>
                    </div>
                    {renderCharDimensionOptions()}
                </div>
            )}

            {showSelector('characteristicDimension') &&
                subSelectors.characteristicDimension?.multi &&
                characteristicDimensionOptionsList?.length !== 0 &&
                characteristicDimensionOptionsList?.length < subSelectors.characteristicDimension?.maxLimit && (
                    <div className={`${baseClassName}__char-dim-add`}>
                        <span
                            onClick={() => addCharDimensionRow()}
                            className={`${baseClassName}__characteristics-add-icon`}
                        >
                            <PlusOutlined />
                        </span>
                        <span onClick={() => addCharDimensionRow()}>Add Another</span>
                    </div>
                )}

            {showSelector('characteristicDimension') && (
                <div className={`${baseClassName}__characteristic-dimension-text`}>
                    The dimension you choose will break the category down into comparative groups based on that
                    characteristic. Do you want to compare different brands, or the performance of different features
                    such as flavor or pack type?
                </div>
            )}

            {showSelector('characteristicFilters') && (
                <div className={`${baseClassName}__subset-option`}>
                    <div className={`${baseClassName}__subset-option-text-one`}>
                        Would you like to filter the {productType === 'story' ? 'story' : 'report'} to a subset of{' '}
                        {`${subSelectors.subSelectorType === 'focusBrandOnlyReport' ||
                            subSelectors.subSelectorType === 'focusBrandOptionalReport' ||
                            subSelectors.subSelectorType === 'noFocusBrandReport'
                            ? 'products?'
                            : 'comparison?'
                            }`}
                    </div>

                    <div className={`${baseClassName}__subset-option-text-two`}>{subsetOptionText}</div>

                    <div className={`${baseClassName}__subset-option-radio`}>
                        <ByzzerRadio
                            checked={!showCharacteristicFilters || !charConditionValuesData?.length}
                            name="subset-option"
                            label={`No, run my ${productType === 'story' ? 'story' : 'report'} with the selected ${subSelectors.subSelectorType === 'focusBrandOnlyReport' ||
                                subSelectors.subSelectorType === 'focusBrandOptionalReport'
                                ? 'products'
                                : 'comparison set'
                                }.`}
                            onChange={() => handleCharacteristicFiltersDisplay(false)}
                        />
                    </div>
                    <div className={`${baseClassName}__subset-option-radio`}>
                        <ByzzerRadio
                            checked={showCharacteristicFilters && charConditionValuesData?.length}
                            name="subset-option"
                            label={`Yes, add more filters to my ${productType === 'story' ? 'story' : 'report'}.`}
                            onChange={() => handleCharacteristicFiltersDisplay(true)}
                        />
                    </div>
                </div>
            )}
            {sku === '842' ? productType === 'story' && getAliasInput('category') : ''}
            <br></br>

            {showSelector('characteristicFiltersRequired') && (
                <div className={`${baseClassName}__subset-option`}>
                    <label className={`${baseClassName}__characteristic-dimension-item-label`}>
                        Key Characteristics:
                    </label>
                    <div className={`${baseClassName}__subset-option-text-two`}>{subsetOptionText}</div>
                </div>
            )}

            {((showSelector('characteristicFiltersRequired') ||
                (showSelector('characteristicFilters') && showCharacteristicFilters)) && (
                    <div className={`${baseClassName}__characteristics-container`}>
                        {renderCharFiltersOptions()}
                        {charConditionValuesData.length > 0 &&
                            charConditionValuesData.length < subSelectors.characteristicFilters.maxLimit && (
                                <div className={`${baseClassName}__characteristics-add`}>
                                    <span
                                        onClick={() => renderCharFiltersRows()}
                                        className={`${baseClassName}__characteristics-add-icon`}
                                    >
                                        <PlusOutlined fill="#000000" />
                                    </span>
                                    <span onClick={() => renderCharFiltersRows(undefined, undefined, 'addNewCondition')}>
                                        Add another condition
                                    </span>
                                </div>
                            )}
                    </div>
                )) ||
                null}

            {showSelector('brandLevelMultiCharacteristicDimension') && characteristicDimensionOptionsList?.length > 0 && (
                <div className={`${baseClassName}__characteristic-dimension brand-level`}>
                    <div className={`${baseClassName}__characteristic-dimension-main-text`}>
                        This report includes fields for characteristics that you get to define.
                    </div>
                    <div className={`${baseClassName}__characteristic-dimension-description`}>
                        Choose 7 characteristics to show in your report. Category, Subcategory, and Brand will always be
                        included by default.
                        <div>
                            The dimension you choose will break the category down into comparative groups based on that
                            characteristic. Do you want to compare different brands, or the performance of different
                            features such as flavor or pack type?
                        </div>
                    </div>
                    {renderCharDimensionOptions()}
                </div>
            )}

            {showSelector('multiBrands') && (
                <FocusProductsSelectBrands // Craig - 'Shopper Demo By Product' (CPS report) uses the FocusProductsSelectBrands component, which uses and sets its value in focusBrand.  Need to revisit it to allow it to set both focusBrand and brand.
                    competitiveProductSetList={productSelectorData?.category}
                    setEnableMultiBrandsSubmission={(flag) => setMultiBrandsSubmit(flag)}
                    setProductSelectorData={setProductSelectorData}
                    setLoading={(flag) => setLoading(flag)}
                    brands={props.marketGroup === 'cps' ? productSelectorData?.brand : productSelectorData?.focusBrand} // pizza
                    displaySelectedBrands={true}
                    brandType={props.marketGroup === 'cps' ? 'brand' : 'focusBrand'}
                />
            )}

            {productType === 'story' && isProductOption3 && getAliasInput('brandManufacturer')}
            {productType === 'story' && isProductOption4 && getAliasInput('brand')}
            {productType === 'story' && isProductOption5 && getAliasInput('categoryTrendAnalysis')}
            {sku !== '842' ? productType === 'story' && getAliasInput('category') : ''}
            {/* Moving panel release check beside to generate report  button as per BYZ-7113*/}
            {productType === 'story' && props.marketGroup === 'cps' && (
                <div className={`${baseClassName}__releasability-container`}>
                    <div>
                        <span className={`${baseClassName}__releasability-label`}>Panel releasability check:</span>
                        <span className={`${baseClassName}__releasability-info-icon`}>
                            <img src={infoIcon} onClick={handleInfoClick} alt="info" />
                        </span>
                    </div>
                    {productSelectorData?.category?.length === 0 &&
                        productSelectorData?.focusBrand === '' &&
                        productSelectorData?.subCategories?.length === 0 && (
                            <span className={`${baseClassName}__releasability-label`}>No selections made</span>
                        )}
                    {showSpinner ? (
                        <div className={'byzzer-search-spinner'}>
                            <Spin indicator={antIcon} />
                        </div>
                    ) : (
                        productSelectorData?.category?.length > 0 && (
                            <div className={`${baseClassName}__releasability-label`}>
                                <span className={`${baseClassName}__releasability-label-icon`}>
                                    <img src={checkRelesabilityIcon(releasability)} alt="" />
                                </span>
                                <span>{checkRelesabilityMessage(releasability)}</span>
                            </div>
                        )
                    )}
                </div>
            )}
            <div
                className={
                    productType === 'story'
                        ? `${baseClassName}__submit-btn-container alias-container`
                        : `${baseClassName}__submit-btn-container`
                }
            >
                {productType !== 'story' &&
                    showSelector('brandLevelMultiCharacteristicDimension') &&
                    characteristicDimensionOptionsList?.length > 0 && (
                        <div className={`${baseClassName}__seven_characteristics`}>
                            All seven characteristics must be populated to run this report.
                        </div>
                    )}

                {productType !== 'story' && (
                    <ByzzerButton
                        label={`${props.saveBtnType === 'Generate' ? 'Generate My Report' : 'Next'}`}
                        onClick={() => submitProductSelections(props.saveBtnType)}
                        disabled={!enableProductSelectorSubmit()}
                    />
                )}

                {productType === 'story' && (
                    <>
                        {!fromBack && (
                            <ByzzerButton
                                label={'Next'}
                                onClick={() => submitProductSelections(props.saveBtnType)}
                                disabled={!enableProductSelectorSubmit()}
                            />
                        )}
                        {fromBack && !props?.objectivesHasChanged && (
                            <ByzzerButton
                                label={showUpdate ? 'Update' : 'Next'} //showUpdate flag = true => any input changes && fromback=true
                                onClick={() => submitProductSelections(props.saveBtnType)}
                                disabled={showUpdate ? !enableProductSelectorSubmit() : true} // for nxt button disabled in data retention case
                            />
                        )}
                        {fromBack && props?.objectivesHasChanged && (
                            <ByzzerButton
                                label={showUpdate ? 'Update' : 'Next'} //showUpdate flag = true => any input changes && fromback=true
                                onClick={() => submitProductSelections(props.saveBtnType)}
                                disabled={!enableProductSelectorSubmit()} // for nxt button disabled in data retention case
                            />
                        )}
                    </>
                )}
            </div>

            {showWarning1 && (
                <ByzzerErrorModal
                    showWarning={showWarning1}
                    setShowWarning={setShowWarning1}
                    headerType={'none'}
                    contentType={contentType1}
                    warningMessage={warningMessage1}
                    size={'small'}
                />
            )}

            {showWarning && (
                <ByzzerErrorModal
                    showWarning={showWarning}
                    setShowWarning={setShowWarning}
                    headerType={'none'}
                    contentType={contentType}
                    warningMessage={warningMessage}
                    size={'small'}
                    messageType={messageType}
                />
            )}
            {/* Moving panel release check beside to generate report  button as per BYZ-7113*/}
            {productType === 'story' && showInfo === true && (
                <ByzzerModal
                    show={showInfo}
                    heading={'Panel releasability check'}
                    onClose={() => {
                        setShowInfo(!showInfo);
                    }}
                    closeOnClickOutside={true}
                    size={'extra-small'}
                    type={'info'}
                    headerType={'normal'}
                    className={'customModal'}
                >
                    <>
                        <p>
                            Some products cannot be released in the panel data due to lack of sample. This check is to
                            help ensure your report will have enough data to give you the value and insights you expect
                            from Byzzer. 150 occasions are required for insights. Between 75-150 occasions, the report
                            will run but is considered low sample and should be used directionally.
                        </p>
                        <p>
                            These validations have been run across all outlets for the latest 52 weeks. Actual sample
                            will vary by market and time period.
                        </p>
                    </>
                </ByzzerModal>
            )}

            {renderAdvanceSearch()}
        </div>
    );
}

export default ProductSelector;
