import React, { useEffect, useState } from 'react';
import { ByzzerCard, ByzzerLink, ByzzerModal, ByzzerMultiSelect, ByzzerSearch, ByzzerSelect } from '@/components/form';
import {useTenantApi} from '@/hooks/useTenantApi';
import tickIcon from '@/images/icons/upc-green-tick.svg';
import crossIcon from '@/images/icons/upc-red-cross.svg';
import exclamationIcon from '@/images/icons/yellow-exclamation.svg';
import ProductScopeNew from '@/components/ProductScopeNew';
import {TrackClick} from "@/analytics";
import { useUser } from '@/contexts/UserContext';

export function PanelReleasability(props) {
    // TODO: Rename these using camelCase
    const { PanelReleasabilityForBrand, PanelReleasabilityForCategory } = useTenantApi();
    const {categories: subscriptionCategories} = useUser();
    const [releasabilityBrand, setReleasabilityBrand] = useState<string>('');
    const [selectedReleasabilityCategory, setSelectedReleasabilityCategory] = useState<any[]>([]);
    const [brandReleasibilities, setBrandReleasibilities] = useState<any[]>([]);
    const [categoryReleasibilities, setCategoryReleasibilities] = useState<any[]>([]);
    const [categorySelector, setCategorySelector] = useState(false);
    const [busy, setBusy] = useState(false);
    useEffect(() => {
        if (subscriptionCategories.length > 0) {
            changeReleasabilityCategory(subscriptionCategories, true);
        }
    }, [subscriptionCategories]);
    const changeReleasabilityBrand = async (brand: string) => {
        if (!brand) {
            setBrandReleasibilities([]);
            setReleasabilityBrand('');
            return;
        }
        setBusy(true);
        try {
            const getPanelReleasibiltyForBrand = await PanelReleasabilityForBrand(brand);
            if (getPanelReleasibiltyForBrand?.panelReleasabilities) {
                setBrandReleasibilities(getPanelReleasibiltyForBrand.panelReleasabilities);
            }
            setReleasabilityBrand(brand);
        } catch (error) {
            // tbd
        } finally {
            setBusy(false);
        }
    };
    const changeReleasabilityCategory = async (category, fromProps = false, advanced = false) => {
        setCategorySelector(false);
        if (fromProps) {
            if (category.length > 1) {
                return true;
            }
        }
        if (category.length > 0) {
            const getPanelReleasibiltyForCategories = await PanelReleasabilityForCategory({ categories: advanced ? [...selectedReleasabilityCategory, ...category] : category });
            if (getPanelReleasibiltyForCategories?.panelReleasabilities) {
                setCategoryReleasibilities(getPanelReleasibiltyForCategories.panelReleasabilities);
            }
            setSelectedReleasabilityCategory(advanced ? [...selectedReleasabilityCategory, ...category] : category);
        } else {
            setCategoryReleasibilities([]);
        }
    };

    const onClear = () => {
        setCategoryReleasibilities([]);
        setSelectedReleasabilityCategory([]);
    }

    const onChangeCategory = async (e) => {
        if (e.openElasticSearch) setCategorySelector(true);
    };

    const iconCheck = (data) => {
        switch (data) {
            case 'green':
                return tickIcon;
            case 'yellow':
                return exclamationIcon;
            case 'red':
                return crossIcon;
            default:
                return crossIcon;
        }
    };

    const renderBrandReleasability = () => {
        if (busy) { // Not required, but could do this at some point.  If you don't use, need to factor in busy to below else-if logic
            return (
                // @ts-ignore
                <ByzzerCard className={'my-portfolio__releasability-card my-portfolio__releasability-card-empty'}>
                    <span>Loading...</span>
                </ByzzerCard>
            );
        } else if (brandReleasibilities?.length) {
            return (
                <ByzzerCard
                    title={'Brand Releasability in…'}
                    className={'my-portfolio__releasability-card'}
                    subtitle={'Understand brand loyalty in the category with the '}
                    subtitleHighlight={'Shopper Loyalty report'}
                >
                    {brandReleasibilities.map((item) => (
                        <div key={item.category} className="my-portfolio__panel-cards-list-item">
                            <img
                                className="my-portfolio__panel-instruction-label-icon"
                                src={iconCheck(item.releasability)}
                                alt={'icon'}
                            />
                            {item.category}
                        </div>
                    ))}
                </ByzzerCard>
            );
        } else if (releasabilityBrand) {
            return (
                // @ts-ignore
                <ByzzerCard className={'my-portfolio__releasability-card my-portfolio__releasability-card-empty'}>
                    <span>This Brand is Not Releasable.</span>
                </ByzzerCard>
            );
        }
        return (
            // @ts-ignore
            <ByzzerCard className={'my-portfolio__releasability-card my-portfolio__releasability-card-empty'}>
                <span>Please Select Brand to see brand releasability here...</span>
            </ByzzerCard>
        );
    };
    const renderCategoryReleasability = () => {
        if (categoryReleasibilities.length > 0) {
            return (
                <ByzzerCard
                    title={'Category Releasability'}
                    className={'my-portfolio__releasability-card'}
                    subtitle={'Dissect who the shoppers are in the category with the  '}
                    subtitleHighlight={'Shopper Demo reports'}
                >
                    {categoryReleasibilities.map((item) => (
                        <div key={item.category} className="my-portfolio__panel-cards-list-item">
                            <img
                                className="my-portfolio__panel-instruction-label-icon"
                                src={iconCheck(item.releasability)}
                                alt={'icon'}
                            />
                            {item.category}
                        </div>
                    ))}
                </ByzzerCard>
            );
        }
        return (
            // @ts-ignore
            <ByzzerCard className={'my-portfolio__releasability-card my-portfolio__releasability-card-empty'}>
                <span>Please Select Category to see category releasability here...</span>
            </ByzzerCard>
        );
    };
    const redirectPanel101 = () => {
        window.open('https://learn.byzzer.ai/en/articles/6289623-panel-101', '_panel101');
    };

    function renderModal() {
        if (categorySelector) {
            return (
                <ByzzerModal
                className="my-portfolio-advanced-search-popup"
                show={categorySelector}
                onClose={() => setCategorySelector(!categorySelector)}
                size={'large'}
                headerType={'none'}
            >
                <ProductScopeNew
                    productScopeTitle={'Select Categories'}
                    productScopeSubtitle={'Select multiple category for Category Releasability.'}
                    updateProductScope={(data) => changeReleasabilityCategory(data, false, true)}
                    errorMessage={'Unselect the previously selected category.'}
                    productScopeType={'Panel Releasability'}
                />
            </ByzzerModal>
            );
        }
    }

    return (
        <>
            <div className="my-portfolio__panel-title">Panel Releasability</div>

        <div className="my-portfolio__standings-grouping">
            <div className="my-portfolio__panel-cards">
                <div>
                    <div className="my-portfolio__panel-search">
                        <div className="my-portfolio__panel-search-label">Categories: </div>
                        {/* @ts-ignore */}
                        <ByzzerMultiSelect
                            options={subscriptionCategories}
                            value={selectedReleasabilityCategory}
                            onChange={(e) => changeReleasabilityCategory(e)}
                            onClear={onClear}
                            placeholder={'Select from the list'}
                            multiSelectWithCheckBox
                            disabled={subscriptionCategories.length === 1 } 
                            selectedOptions={selectedReleasabilityCategory}
                            advanceSearch={true}
                            seperator={','}
                            allowClear = {true}
                            advanceSearchLabel={'Advanced Search'}
                            advanceSearchOnClick={() => {
                            setCategorySelector(true);
                    }}
                            variant={'secondary'}
                />
                    </div>
                    {renderCategoryReleasability()}
                </div>
                <div className="my-portfolio__panel-cards-seperator"></div>
                <div>
                    <div className="my-portfolio__panel-search">
                        <div className="my-portfolio__panel-search-label">Brand: </div>
                        <ByzzerSearch
                            placeholder={'Search for Brand'}
                            onChange={changeReleasabilityBrand}
                            value={releasabilityBrand}
                            categories={[]}
                            type={'brandSearch'}
                            variant={'secondary'}
                        />
                    </div>
                    {renderBrandReleasability()}
                </div>
            </div>
            <div className="my-portfolio__panel-instruction">
                <div className="my-portfolio__panel-instruction-label">
                    <img className="my-portfolio__panel-instruction-label-icon" src={tickIcon} alt={'tick'} />
                    Meets recommended sample
                </div>
                <div className="my-portfolio__panel-instruction-label">
                    <img className="my-portfolio__panel-instruction-label-icon" src={exclamationIcon} alt={'!'} />
                    Use directionally
                </div>
                <div className="my-portfolio__panel-instruction-label">
                    <img className="my-portfolio__panel-instruction-label-icon" src={crossIcon} alt={'x'} />
                    Not releasable due to sample size
                </div>
            </div>
            <div className="my-portfolio__panel-note">
                These validations have been run across all outlets for the latest 52 weeks. Actual sample will vary by
                market and time period.
            </div>
            <div className="my-portfolio__panel-info">
                For more information on panel data and recommended sample, see &nbsp;
                <TrackClick name={`Prod Lookup Panel 101 Link clicked`}>
                    <ByzzerLink
                        className="my-portfolio__panel-info-link"
                        label={'Panel 101'}
                        onClick={() => redirectPanel101()}
                    />
                </TrackClick>
            </div>
            </div>
            {renderModal()}
        </>
    );
}

export default PanelReleasability;
