import React, { useState, useEffect } from 'react';
import { ByzzerCard, ByzzerModal, ByzzerSearch, ByzzerSelect } from '@/components/form';
import {useTenantApi} from '@/hooks/useTenantApi';
import ProductScopeNew from '@/components/ProductScopeNew';
import Loader from '@/components/Spin';
import {ByzzerMask} from "@/components/ByzzerMask/ByzzerMask";
import { useUser } from '@/contexts/UserContext';
import { myPortFolioStandingsFooterText } from '@/config/globalVars';

export function Standings(props) {
    const { getTopTenBrandsForCategory,    getTopTenMarketsForCategory,    getTopTenMarketsForBrand,    getTopCategoriesForBrands,} = useTenantApi();
    const {categories: subscriptionCategories} = useUser();
    const [standingsBrand, setStandingsBrand] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedStandingsCategory, setSelectedStandingsCategory] = useState('');
    const [categorySelector, setCategorySelector] = useState(false);
    const [loading, setLoading] = useState(false);
    const data = ['', '', '', '', '', '', '', '', '', ''];

    const [categoryTopTenMarkets, setCategoryTopTenMarkets] = useState(data);
    const [categoryTopTenBrands, setCategoryTopTenBrands] = useState(data);
    const [brandTopTenMarkets, setBrandTopTenMarkets] = useState(data);
    const [brandTopTenCategories, setBrandTopTenCategories] = useState(data);

    useEffect(() => {
        if (subscriptionCategories.length > 0 && subscriptionCategories.length === 1) {
            changeStandingsCategory(subscriptionCategories[0]);
        }
    }, [subscriptionCategories]);

    const onCategorySelected = async (category) => {
        let allCategories = true;
        changeStandingsCategory(category[0], allCategories);
        setCategorySelector(false);
    };

    // const onChangeCategory = async (e) => {
    //     if (e.openElasticSearch) setCategorySelector(true);
    // };

    const changeStandingsBrand = async (brand) => {
        // console.log("length",brand.length)
            setBrandTopTenCategories(data);
            setBrandTopTenMarkets(data);
            const getTopTenMarkets = await getTopTenMarketsForBrand(brand);
            if (getTopTenMarkets?.topMarkets) {
                // console.log(setCategoryTopTenMarkets(getTopTenMarkets?.topMarkets));
                setBrandTopTenMarkets(getTopTenMarkets?.topMarkets);
            }
            const getTopTenBrands = await getTopCategoriesForBrands([brand], 10);
            if (getTopTenBrands?.topCategories) {
                setBrandTopTenCategories(getTopTenBrands?.topCategories);
            }
            setStandingsBrand(brand);
    };
    const changeStandingsCategory = async (category, allCategories = false) => {
        setLoading(true);
        setCategoryTopTenBrands(data);
        setCategoryTopTenMarkets(data);
        const getTopTenMarkets = await getTopTenMarketsForCategory(category);
        if (getTopTenMarkets?.topMarkets) {
            // console.log(setCategoryTopTenMarkets(getTopTenMarkets?.topMarkets));
            setCategoryTopTenMarkets(getTopTenMarkets?.topMarkets);
        }
        const getTopTenBrands = await getTopTenBrandsForCategory(category);
        if (getTopTenBrands?.topBrands) {
            setCategoryTopTenBrands(getTopTenBrands?.topBrands);
        }
        if (allCategories) {
            setSelectedCategory(category);
            setLoading(false);
        } else {
            setSelectedCategory('');
            setSelectedStandingsCategory(category);
            setLoading(false);
        }
    };

    const changeCase = (item) => {
        return item
            ?.toString()
            .toLowerCase()
            .split(' ')
            .map((word) => {
                return word !== 'and' && word !== 'or'
                    ? word.charAt(0).toUpperCase() + word.substring(1) + ' '
                    : word + ' ';
            });
    };

    function renderModal() {
        if (categorySelector) {
            return (
                <ByzzerModal
                    className="my-portfolio-advanced-search-popup"
                    show={categorySelector}
                    onClose={() => setCategorySelector(!categorySelector)}
                    size={'large'}
                    headerType={'none'}
                >
                    <ProductScopeNew
                        productScopeTitle={'Select a Category'}
                        productScopeSubtitle={'Select any 1 category for Top 10 Markets and Brands.'}
                        updateProductScope={(data) => onCategorySelected(data)}
                        maxCategoryCount={1}
                        errorMessage={'Unselect the previously selected category.'}
                        submitButtonLabel={'Select Category'}
                        displayTreeButtonLabel={'Select Category'}
                        productScopeType={'Panel Releasability'}
                        //productScopeType={'Product Coding Form'}
                    />
                </ByzzerModal>
            );
        }
    }


    return (
        <>
            <ByzzerMask loading={loading}/>
            <div className="my-portfolio__standings-title">The Standings</div>


        <div className="my-portfolio__standings-grouping">
            <div className="my-portfolio__standings-search">
                <div className="my-portfolio__standings-search-label">Your Brand: </div>
                <ByzzerSearch
                    placeholder={'Search for Brand'}
                    onChange={(e) => {
                        changeStandingsBrand(e);
                    }}
                    value={standingsBrand.length === 0 ? standingsBrand[0] : standingsBrand}
                    categories={[]}
                    type={'brandSearch'}
                    variant={'secondary'}
                />
            </div>
            <div className="my-portfolio__standings-cards">
                {/* @ts-ignore */}
                <ByzzerCard
                    title={'Your Top 10 Markets'}
                    subtitle={'Investigate your performance across the top 10 markets with the '}
                    subtitleHighlight={'Market Performance Report'}
                >
                    <ol>
                        {brandTopTenMarkets.map((item, index) => (
                            <li key={index} className="my-portfolio__standings-cards-list-item">
                                {/* @ts-ignore */}
                                {item?.market}
                            </li>
                        ))}
                    </ol>
                </ByzzerCard>
                {/* @ts-ignore */}
                <ByzzerCard
                    title={'Your Top 10 Categories'}
                    subtitle={'Drill into the structure of each category with the '}
                    subtitleHighlight={'Category Breakout by Brand Report'}
                >
                    <ol>
                        {brandTopTenCategories.map((item, index) => (
                            <li key={index} className="my-portfolio__standings-cards-list-item">
                                {/* @ts-ignore */}
                                {changeCase(item?.category)}
                            </li>
                        ))}
                    </ol>
                </ByzzerCard>
            </div>
            <div className="my-portfolio__panel-note">
                {myPortFolioStandingsFooterText}
            </div>
        </div>
        <div className="my-portfolio__standings-grouping">
            <div className="my-portfolio__standings-search">
                <div className="my-portfolio__standings-search-label">Your Category: </div>
                {/* <ByzzerSearch
                    onChange={(e) => {
                        onChangeCategory(e);
                    }}
                    value={
                        selectedCategory.length === 0
                            ? selectedCategory[0]
                            : selectedCategory
                    }
                    //categories={category}
                    type={'elasticSearch'}
                    placeholder={'Search for Category'}
                    readOnly={true}
                /> */}
                {/* @ts-ignore */}
                <ByzzerSelect
                    placeholder={selectedCategory !== '' ? selectedCategory : 'Select from the list'}
                    options={subscriptionCategories}
                    value={selectedCategory === '' ? selectedStandingsCategory:selectedCategory}
                    onChange={changeStandingsCategory}
                    disabled={subscriptionCategories.length < 1}
                    advanceSearch={true}
                    advanceSearchLabel={'Advanced Search'}
                    advanceSearchOnClick={() => {
                        setCategorySelector(true);
                    }}
                    variant={'secondary'}
                />
            </div>
            <div className="my-portfolio__standings-cards">
                {/* @ts-ignore */}
                <ByzzerCard
                    title={'Category Top 10 Markets'}
                    subtitle={'Evaluate category level performance for various markets with the '}
                    subtitleHighlight={'Category Performance by Market Report'}
                >
                    <ol>
                        {categoryTopTenMarkets.map((item, index) => (
                            <li key={index} className="my-portfolio__standings-cards-list-item">
                                {/* @ts-ignore */}
                                {item?.market}
                            </li>
                        ))}
                    </ol>
                </ByzzerCard>
                {/* @ts-ignore */}
                <ByzzerCard
                    title={'Category Top 10 Brands'}
                    subtitle={'Know your competitors within a category and how you stack up against them with the '}
                    subtitleHighlight={'Brand Ranking Report'}
                >
                    <ol>
                        {categoryTopTenBrands.map((item, index) => (
                            <li
                                key={index}
                                className="my-portfolio__standings-cards-list-item my-portfolio__standings-cards-list-item-brand"
                            >
                                {/* @ts-ignore */}
                                {item?.brand?.toString().toLowerCase()}
                            </li>
                        ))}
                    </ol>
                </ByzzerCard>
            </div>
            <div className="my-portfolio__panel-note">
                {myPortFolioStandingsFooterText}
            </div>
            </div>
            {renderModal()}
        </>
    );
}
export default Standings;
