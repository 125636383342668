import {useOutlet} from "react-router-dom";
import React, {forwardRef, useMemo} from "react";

export const OutletWithProps = forwardRef((props = {}, ref) => {

    const outlet = useOutlet();
    // return useMemo(() => {

        if(outlet?.props?.children) {
            return React.cloneElement(outlet.props.children, {...props, ref});
        }
    // }, [outlet]);
});

export default OutletWithProps;