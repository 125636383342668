/* eslint-disable react-hooks/rules-of-hooks */
import { charsSelectorCustomlyNeededSkus } from '@/config/globalVars';
import { selectorLabels } from '@/config/globalVars';
import { AlertRunConfigOptions } from "@/types/AlertRunConfigOptions";
import {AllRunConfigOptions, ProductRunConfigOptions, RunConfigOptions} from "@/types/RunConfigOptions";
import { useTenantApi } from '@/hooks/useTenantApi';

const PRODUCTS_BY_SKU = {};
const LEGACY_PRODUCT_CONFIG_OPTIONS_BY_SKU = {};
const PRODUCT_CONFIG_OPTIONS_BY_SKU: Record<string, RunConfigOptions[]> = {};
let excludeMarkets: boolean;
let allProducts: ProductWithMeta[] = [];
let accessLevel: AccessLevel;

export function setAccessLevel(level: AccessLevel) {
    accessLevel = level;
}

export function setExcludeMarkets(exclude: boolean) {
    excludeMarkets = exclude;
}

export function addProducts(products: ProductWithMeta[]) {
    allProducts = products;
    allProducts.forEach((product) => {
        if (product.sku) {
            PRODUCTS_BY_SKU[product.sku] = product;
        }
    });
}

export function getAllStories() {
    return getAllProducts().filter(({type, metadata, subscriptionLevel}) => {
        return type === 'story' && Number.isFinite(subscriptionLevel);
    });
}

export function getAllReports(): ProductWithMeta[] {
    return getAllProducts()
        .filter(({ type, metadata, subscriptionLevel }) => {
            return type === 'report' && metadata?.reportOptions?.sectionHeader && Number.isFinite(subscriptionLevel);
        })
        .map((report) => ({
            ...report,
            reportType: report?.metadata?.reportOptions?.reportType,
        }));
}

export function getAllSimulators() {
    return getAllProducts()
        .filter(({ type, metadata, subscriptionLevel }) => {
            return type === 'simulator' // && metadata?.reportOptions?.sectionHeader && Number.isFinite(subscriptionLevel);
        })
}

export function getAllAlerts() {
    return getAllProducts()
        .filter(({ type, metadata, subscriptionLevel }) => {
            return type === 'alert' && Number.isFinite(subscriptionLevel);
        })
        .map((alert) => ({
            ...alert,
            alertType: alert?.metadata?.alertOptions?.alertType,
        }));
}

export function getProductBySku(sku: string): ProductWithMeta {
    return PRODUCTS_BY_SKU[sku];
}

export function getAllProducts(): ProductWithMeta[] {
    return allProducts.filter(({ accessLevels }) => accessLevels.includes(accessLevel));
}

export function getFlattenedRunConfigOptionsBySku(sku: string): AllRunConfigOptions {
    return getRunConfigOptionsBySku(sku).reduce((all, config) => ({
        ...all,
        ...config
    }));
}

// function getRunConfigOptionsByCreditId has been moved to useTenantApi.ts but should be move back here if the product.service is converted to hook, in order to use getCreditInfoById

export function getRunConfigOptionsBySku(sku: string): RunConfigOptions[] {

    const product = PRODUCTS_BY_SKU[sku];

    const emptyReturnArray: any = [];

    if (!product) return emptyReturnArray;

    const { metadata } = product;

    if (PRODUCT_CONFIG_OPTIONS_BY_SKU[sku]) {
        return PRODUCT_CONFIG_OPTIONS_BY_SKU[sku] as any;
    }

    if (metadata.configOptions) {
        const thing = PRODUCT_CONFIG_OPTIONS_BY_SKU[sku] = metadata.configOptions;
        return thing;
    }

    if (metadata.reportOptions?.configOptionKeys) {
        return LEGACY_PRODUCT_CONFIG_OPTIONS_BY_SKU[sku] =
            metadata.reportOptions.configOptionKeys.map(v => toRunConfigOptions(v, sku));
    }

    if (metadata.scorecardOptions?.configOptionKeys) {
        return LEGACY_PRODUCT_CONFIG_OPTIONS_BY_SKU[sku] =
            metadata.scorecardOptions.configOptionKeys.map(v => toRunConfigOptions(v, sku));
    }

    if (metadata.alertOptions) {
        return LEGACY_PRODUCT_CONFIG_OPTIONS_BY_SKU[sku] =
            metadata.alertOptions.map(v => toRunConfigOptions(v, sku));
    }

    return emptyReturnArray;
}

export function getLegacyConfigOptionsBySku(sku) {
    const product = PRODUCTS_BY_SKU[sku];

    if (!product) return [];

    const { metadata } = product;
    let options: any[] = []; // todo: maybe define the legacy config type
    if (LEGACY_PRODUCT_CONFIG_OPTIONS_BY_SKU[sku]) {
        options = LEGACY_PRODUCT_CONFIG_OPTIONS_BY_SKU[sku];
    } else if (metadata.configOptions && product.type !== 'alert') { // while working on Alerts refactoring, excluding alert product type from this routine as it was designed for reports (alby config related)
        options = LEGACY_PRODUCT_CONFIG_OPTIONS_BY_SKU[sku] = metadata.configOptions.map((config) => {
            return toLegacyReportConfigOptions(config, sku);
        });
    } else if (metadata.reportOptions?.configOptionKeys) {
        options = LEGACY_PRODUCT_CONFIG_OPTIONS_BY_SKU[sku] = metadata.reportOptions.configOptionKeys.map(function (config) {
            return toLegacyReportConfigOptions(config, sku);
        });
    } else if (metadata.scorecardOptions?.configOptionKeys) {
        options = LEGACY_PRODUCT_CONFIG_OPTIONS_BY_SKU[sku] = metadata.scorecardOptions.configOptionKeys.map(
            toLegacyScorecardConfigOptions
        );
    } else if (metadata.alertOptions) {
        options = LEGACY_PRODUCT_CONFIG_OPTIONS_BY_SKU[sku] = metadata.alertOptions;
    }

    if (excludeMarkets) {
        options = options.filter((option) => option.selectorType !== 'market');
    }
    return options;
}

// todo: add support for alerts working like scorecards and reports (storing a config key and expanding in the UI)
function toAlertConfigOptions(type) {}

function toLegacyOptions({ type, ...options }) {
    switch (type) {
        case 'product':
            return toLegacyProductOptions(options);
        case 'shopper_segment':
            return toLegacyShopperSegmentOptions(options);            
        case 'market':
            return toLegacyMarketOptions(options);
        case 'submarket':
            return toLegacySubmarketOptions(options);
        case 'time_period':
            return toLegacyTimePeriodOptions(options);
        case 'dimension':
            return toLegacyDimensionOptions(options);
    }
}

function toLegacyShopperSegmentOptions(options) {
    return expandAndRemoveUndefined({
        selectorLabelName: options.title,
        selectorType: 'shopper_segment',
        datatype: options.datatype,
    });
}
function toLegacyProductOptions(options) {
    return expandAndRemoveUndefined({
        selectorType: 'product',
        datatype: options.datatype,
        selectorLabelName: options.title,
        'subSelectors.subSelectorType': options.subtype,
        includeProductLevel: options.includeProductLevel,
        'subSelectors.focusBrand': options.includeFocusBrand,
        requireFocusBrand: options.requireFocusBrand,
        'subSelectors.brands.maxLimit': options.maxBrands,
        'subSelectors.brands.multi': options.maxBrands > 1,
        'subSelectors.competitiveProductSet': options.includeCompetitiveProducts,
        'subSelectors.characteristicFilters.alwaysPositiveLookup': useIfDefined(
            options.includeCharacteristics,
            options.excludeCharacteristicIsNot
        ),
        'subSelectors.characteristicFilters.maxLimit': useIfDefined(
            options.includeCharacteristics,
            options.maxCharacteristics
        ),
        'subSelectors.characteristicFilters.multi': useIfDefined(
            options.includeCharacteristics,
            options.maxCharacteristics !== 1
        ),
        'subSelectors.characteristicFilters.required': useIfDefined(
            options.includeCharacteristics,
            options.requireCharacteristics
        ),
        'subSelectors.characteristicDimension.allRequired': useIfDefined(
            options.includeCharacteristicDimensions,
            options.requireCharacteristicDimensions
        ),
        'subSelectors.characteristicDimension.maxLimit': useIfDefined(
            options.includeCharacteristicDimensions,
            options.maxCharacteristicDimensions
        ),
        'subSelectors.characteristicDimension.multi': useIfDefined(
            options.includeCharacteristicDimensions,
            options.maxCharacteristicDimensions !== 1
        ),
        keyCharacteristics: options.includeKeyCharacteristic,
        'subSelectors.salesThreshold.multi': useIfDefined(
            options.includeSalesThresholds,
            options.maxSalesThresholds > 1
        ),
        'subSelectors.salesThreshold.required': useIfDefined(
            options.includeSalesThresholds,
            options.salesThresholdsRequired
        ),
        'subSelectors.growthThreshold.multi': useIfDefined(
            options.includeGrowthThresholds,
            options.maxGrowthThresholds > 1
        ),
        'subSelectors.growthThreshold.required': useIfDefined(
            options.includeGrowthThresholds,
            options.growthThresholdsRequired
        ),
        selectedItem: options.selectedItem, // this needs to broken up but I have not idea what the hells its supposed to mean
    });
}

function useIfDefined(what, use) {
    if (what) {
        return use;
    }
}

function toLegacyMarketOptions(options) {
    return expandAndRemoveUndefined({
        selectorType: 'market',
        datatype: options.datatype,
        selectorLabelName: options.title,
        'subSelectors.subSelectorType': options.subtype,
        includePanelTotal: options.includePanelTotal,
        includePanelChannel: options.includePanelChannel,
        'subSelectors.markets.marketGroup': options.datatype,
        'subSelectors.markets.marketSections.embodyFmcgRetailers': options.includeFmcgRetailers,
        'subSelectors.markets.marketSections.embodyGeographyMarkets': options.includeGeographyMarkets,
        'subSelectors.markets.marketSections.embodyPanelByChannel': options.includePanelChannel,
        'subSelectors.markets.marketSections.embodyPanelTotal': options.includePanelTotal,
        'subSelectors.markets.marketSections.embodySpecialityRetailers': options.includeSpecialityRetailers,
        'subSelectors.markets.marketSections.embodyTotalMarkets': options.includeTotalUSMarkets,
        'subSelectors.markets.marketoptions': options.allowMarketTypeSelection,
        'subSelectors.markets.maxLimit': options.maxMarkets,
        'subSelectors.markets.multi': options.maxMarkets && options.maxMarkets !== 1,
        'subSelectors.markets.noSelection': options.noSelection, // I have no idea what the hell this does.  It's very poorly named,
        'subSelectors.markets.requiredRemMarketForEachRetailer': options.requireRemainingMarkets,
        'subSelectors.markets.requiredRemMarketForGeography': options.requireGeographyRemainingMarkets,
        'subSelectors.markets.requiredRemMarketForTotalMarkets': options.requireTotalMarketRemainingMarkets,
        'subSelectors.markets.smartAccount': options.smartAccount, // same, no idea what this does, needs to be renamed
    });
}

function toLegacySubmarketOptions(options) {
    return {
        ...toLegacyMarketOptions(options),
        selectorType: 'submarket',
    };
}

function toLegacyTimePeriodOptions(options) {
    return expandAndRemoveUndefined({
        selectorType: 'timePeriod',
        datatype: options.datatype,
        selectorLabelName: options.title,
    });
}

function toLegacyDimensionOptions(options) {
    return expandAndRemoveUndefined({
        selectorType: 'dimension',
        datatype: options.datatype,
        selectorLabelName: options.title,
        selectedItem: '',
    });
}

function expandAndRemoveUndefined(obj) {
    const cleanObj = JSON.parse(JSON.stringify(obj));
    const result = {};

    // For each object path (property key) in the object
    for (const objectPath in cleanObj) {
        // Split path into component parts
        const parts = objectPath.split('.');

        // Create sub-objects along path as needed
        let target = result;
        while (parts.length > 1) {
            const part = parts.shift();
            target = target[part!] = target[part!] || {};
        }

        // Set value at end of path
        target[parts[0]] = cleanObj[objectPath];
    }
    return result;
}

function toRunConfigOptions(config: any, sku: string): Partial<RunConfigOptions> | undefined {
    switch (config) {
        case 'product:option1':
        case 'product:option1A':
        case 'product:option1B':
            return {
                type: 'product',
                title: selectorLabels.product,
                includeBrands: true,
                includeCategories: true,
                includeCharacteristics: true,
                maxCharacteristics: 5
            };


        case 'product:option2':
            return {
                title: selectorLabels.product,
                type: 'product',
                includeBrands: false,
                includeCategories: true,
                includeCharacteristics: true,
                maxCharacteristics: 5
            };
        case 'product:option3':
            return {
                title: selectorLabels.product,
                type: 'product',
                includeBrands: false,
                includeCategories: true,
                includeCharacteristics: charsSelectorCustomlyNeededSkus.includes(sku),
                maxCharacteristics: 5,
                includeCharacteristicDimensions: true,
                maxCharacteristicDimensions: 1,
            };
        case 'product:option4':
            return {
                title: selectorLabels.product,
                type: 'product',
                includeBrands: false,
                includeCategories: true,
                includeCharacteristicDimensions: true,
                maxCharacteristicDimensions: 5,
            };

        case 'product:option4a':
            return {
                title: selectorLabels.product,
                type: 'product',
                includeBrands: true,
                includeCategories: true,
                includeCharacteristics: true,
                maxCharacteristics: 5,
                includeCharacteristicDimensions: true,
                requireCharacteristicDimensions: true,
                maxCharacteristicDimensions: 7,
            };

        case 'product:option5':
            return {
                title: selectorLabels.product,
                type: 'product',
                includeBrands: true,
                maxBrands: 10
            };

        case 'product:option6':
            return {
                title: selectorLabels.product,
                type: 'product',
                includeBrands: true,
                includeCategories: true,
                includeCharacteristics: true,
                excludeCharacteristicIsNot: true,
                maxCharacteristics: 5
            };

        case 'product:option7':
            return {
                title: selectorLabels.product,
                type: 'product',
                includeCharacteristicThemes: true,
                includeCategories: true,
            };

        case 'product:option-fs':
            return {
                title: selectorLabels.product,
                type: 'product',
                requireFocusBrands: false,
                includeGrowthThresholds: true,
                includeSalesThresholds: true,
                requireGrowthThresholds: false,
                requireSalesThresholds: false,
                maxGrowthThresholds: 1,
                includeCompetitiveProducts: true,
                maxCharacteristics: 5

                // subSelectors: {
                //     // Financial Services report : Option-fs
                //     subSelectorLabelId: 'Financial Services report : Option-fs', // Just to distinguish, not used in code
                //     subtype: 'fsReport', // This property is validated in code to identify which type of selector is to be rendered
                //     focusBrand: false,
                //     salesThreshold: {
                //         multi: true,
                //         required: false,
                //     },
                //     growthThreshold: {
                //         multi: false,
                //         required: false,
                //     },
                //     competitiveProductSet: true,
                //     characteristicFilters: {
                //         multi: true,
                //         maxLimit: 5,
                //     },
                // },
            };

        case 'market:option1':
            return {
                title: selectorLabels.focusMarket,
                type: 'market',
                datatype: 'rms',
                maxMarkets: 1,
                includeTotalUSMarkets: true,
                includeFmcgRetailers: true,
                includeSpecialityRetailers: true,
                includeGeographyMarkets: true,

                // subSelectors: {
                //     // Select Focus Market: Option 1
                //     subSelectorLabelId: 'Focus Market report: Option 1', // Just to distinguish, not used in code
                //     subtype: 'focusMarketReport', // This property is validated in code to identify which type of selector is to be rendered
                //     markets: {
                //         marketGroup: 'rms',
                //         multi: false,
                //         maxLimit: 1,
                //         // just passthrough parameters for the backend
                //         marketSections: {
                //             embodyTotalMarkets: true,
                //             embodyFmcgRetailers: true,
                //             embodySpecialityRetailers: true,
                //             embodyGeographyMarkets: true,
                //         },
                //     },
                // },
            };

        case 'market:option2':
            return {
                title: selectorLabels.focusMarkets,
                type: 'market',
                datatype: 'rms',
                maxMarkets: 20,
                includeTotalUSMarkets: true,
                includeFmcgRetailers: true,
                includeSpecialityRetailers: true,
                includeGeographyMarkets: true,
                // subSelectors: {
                //     // Select multiple markets report: Option 2
                //     subSelectorLabelId: 'Select multiple markets report: Option 2', // Just to distinguish, not used in code
                //     subtype: 'multiMarketsReport', // This property is validated in code to identify which type of selector is to be rendered
                //     markets: {
                //         marketGroup: 'rms',
                //         multi: true,
                //         maxLimit: 20, //changed to allow choosing up to 20 rather than 10
                //         marketSections: {
                //             embodyTotalMarkets: true,
                //             embodyFmcgRetailers: true,
                //             embodySpecialityRetailers: true,
                //             embodyGeographyMarkets: true,
                //         },
                //     },
                // },
            };

        case 'market:option2a':
            return {
                title: selectorLabels.focusMarkets,
                type: 'market',
                datatype: 'rms',
                maxMarkets: 5,
                includeTotalUSMarkets: true,
                includeFmcgRetailers: true,
                includeSpecialityRetailers: true,
                includeGeographyMarkets: true,
                // subSelectors: {
                //     // Select multiple markets report: Option 2
                //     subSelectorLabelId: 'Select multiple markets report: Option 2a', // Just to distinguish, not used in code
                //     subtype: 'smartReportMarkets', // This property is validated in code to identify which type of selector is to be rendered
                //     markets: {
                //         marketGroup: 'rms',
                //         multi: true,
                //         maxLimit: 5, //changed to allow choosing up to 5
                //         marketSections: {
                //             embodyTotalMarkets: true,
                //             embodyFmcgRetailers: true,
                //             embodySpecialityRetailers: true,
                //             embodyGeographyMarkets: true,
                //         },
                //     },
                // },
            };

        case 'market:option2b':
            return {
                title: selectorLabels.focusMarkets,
                type: 'market',
                datatype: 'rms',
                maxMarkets: 10,
                includeTotalUSMarkets: true,
                includeFmcgRetailers: true,
                includeSpecialityRetailers: true,
                includeGeographyMarkets: true,
                requireRemainingMarket: true,
                //??smartAccount: true,

                // subSelectors: {
                //     // Select multiple markets report: Option 2
                //     subSelectorLabelId: 'Select multiple markets report: Option 2b', // Just to distinguish, not used in code
                //     subtype: 'smartReportMarkets', // This property is validated in code to identify which type of selector is to be rendered
                //     markets: {
                //         marketGroup: 'rms',
                //         multi: true,
                //         maxLimit: 10, //changed to allow choosing up to 5 and each remaining markets must
                //         requiredRemMarketForEachRetailer: true,
                //         requiredRemMarketForTotalMarkets: true,
                //         requiredRemMarketForGeography: true,
                //         smartAccount: true,
                //         marketSections: {
                //             embodyTotalMarkets: true,
                //             embodyFmcgRetailers: true,
                //             embodySpecialityRetailers: true,
                //             embodyGeographyMarkets: true,
                //         },
                //     },
                // },
            };

        /*******
         Market Selector: Option 2 - but should be 5 markets instead of 20 and should only show options where Market Type=Account.
         The user also must choose a Remaining market for each market they select, so will actually make 10 selections,
         but only 5 different Accounts. This will be the below Option market:option2c
         ********/
        case 'market:option2c':
            return {
                title: selectorLabels.focusMarkets,
                type: 'market',
                datatype: 'rms',
                includeTotalUSMarkets: true,
                includeFmcgRetailers: true,
                includeSpecialityRetailers: true,
                includeGeographyMarkets: true,
                requireRemainingMarket: true,
                //??marketoptions: true,
                
                // subSelectors: {
                //     // Select multiple markets report: Option 2
                //     subSelectorLabelId: 'Select multiple markets report: Option 2c', // Just to distinguish, not used in code
                //     subtype: 'smartReportMarkets', // This property is validated in code to identify which type of selector is to be rendered
                //     markets: {
                //         marketGroup: 'rms',
                //         multi: true,
                //         requiredRemMarketForEachRetailer: true,
                //         marketoptions: true,
                //         marketSections: {
                //             embodyTotalMarkets: true,
                //             embodyFmcgRetailers: true,
                //             embodySpecialityRetailers: true,
                //             embodyGeographyMarkets: true,
                //         },
                //     },
                // },
            };
        case 'market:option2d':
            return {
                title: selectorLabels.focusMarkets,
                type: 'market',
                datatype: 'rms',
                includeTotalUSMarkets: false,
                includeFmcgRetailers: true,
                includeSpecialityRetailers: true,
                includeGeographyMarkets: true,
                requireRemainingMarket: true,
                //??marketoptions: true,
                //??noSelection: true,

                // subSelectors: {
                //     // Select multiple markets report: Option 2
                //     subSelectorLabelId: 'Select multiple markets report: Option 2d', // Just to distinguish, not used in code
                //     subtype: 'smartReportMarkets', // This property is validated in code to identify which type of selector is to be rendered
                //     markets: {
                //         marketGroup: 'rms',
                //         multi: true,
                //         requiredRemMarketForEachRetailer: true,
                //         marketoptions: true,
                //         noSelection: true,
                //         marketSections: {
                //             embodyTotalMarkets: false,
                //             embodyFmcgRetailers: true,
                //             embodySpecialityRetailers: true,
                //             embodyGeographyMarkets: false,
                //         },
                //     },
                // },
            };

        case 'market:option3':
            return {
                title: selectorLabels.channelOutlets,
                type: 'market',
                datatype: 'cps',
                maxMarkets: 20,
                includePanelTotal: true,
                includePanelChannel: true,
                // subSelectors: {
                //     // CPS Markets report: Option 3
                //     subSelectorLabelId: 'CPS Markets report: Option 3', // Just to distinguish, not used in code
                //     subtype: 'cpsMarketsReport', // This property is validated in code to identify which type of selector is to be rendered
                //     markets: {
                //         marketGroup: 'cps',
                //         multi: true,
                //         maxLimit: 20,
                //         marketSections: {
                //             embodyPanelTotal: true,
                //             embodyPanelByChannel: true,
                //         },
                //     },
                // },
            };

        case 'market:option4':
            return {
                title: selectorLabels.outlets,
                type: 'market',
                datatype: 'cps',
                maxMarkets: 10,
                includePanelTotal: true,
                includePanelChannel: true,
                // subSelectors: {
                //     // CPS Markets report: Option 4
                //     subSelectorLabelId: 'CPS Markets report: Option 4', // Just to distinguish, not used in code
                //     subtype: 'cpsAccountsMarketsReport', // This property is validated in code to identify which type of selector is to be rendered
                //     markets: {
                //         marketGroup: 'cps',
                //         multi: true,
                //         maxLimit: 10,
                //         marketSections: {
                //             embodyPanelTotal: false,
                //             embodyPanelByChannel: true,
                //         },
                //     },
                // },
            };

        case 'market:option5':
            return {
                title: selectorLabels.focusMarkets,
                type: 'market',
                // subSelectors: {
                //     // Market Type report: Option 5
                //     subSelectorLabelId: 'Market Type report: Option 5', // Just to distinguish, not used in code
                //     subtype: 'marketTypeReport', // This property is validated in code to identify which type of selector is to be rendered
                // },
            };

        case 'timePeriod:default':
            return {
                title: selectorLabels.timePeriod,
                type: 'time_period',
            };

        case 'dimension:default':
            return {
                title: 'Select Comparison Type',
                type: 'dimension'
            };
    }
}

function toLegacyReportConfigOptions(config, sku) {
    if (config instanceof Object) {
        return toLegacyOptions(config);
    }
    // eslint-disable-next-line default-case
    switch (config) {
        case 'product:option1':
            return {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // Focus Brand Report : Option 1
                    subSelectorLabelId: 'Focus Brand Report : Option 1', // Just to distinguish, not used in code
                    subSelectorType: 'focusBrandReport', // This property is validated in code to identify which type of selector is to be rendered
                    focusBrand: true,
                    competitiveProductSet: true,
                    characteristicFilters: {
                        multi: true,
                        maxLimit: 5,
                    },
                },
            };

        case 'product:option1A':
            return {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // Focus Brand Only Report : Option 1A
                    subSelectorLabelId: 'Focus Brand Only Report : Option 1A', // Just to distinguish, not used in code
                    subSelectorType: 'focusBrandOnlyReport', // This property is validated in code to identify which type of selector is to be rendered
                    focusBrand: true,
                    competitiveProductSet: true,
                    characteristicFilters: {
                        multi: true,
                        maxLimit: 5,
                    },
                },
            };

        case 'product:option1B':
            return {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // Focus Brand Optional Report : Option 1B
                    subSelectorLabelId: 'Focus Brand Optional Report : Option 1B', // Just to distinguish, not used in code
                    subSelectorType: 'focusBrandOptionalReport', // This property is validated in code to identify which type of selector is to be rendered
                    focusBrand: true,
                    competitiveProductSet: true,
                    characteristicFilters: {
                        multi: true,
                        maxLimit: 5,
                    },
                },
            };

        case 'product:option2':
            return {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // No Focus Brand report : Option 2
                    subSelectorLabelId: 'No Focus Brand report : Option 2', // Just to distinguish, not used in code
                    subSelectorType: 'noFocusBrandReport', // This property is validated in code to identify which type of selector is to be rendered
                    focusBrand: false,
                    //adhocFocusBrand:true,
                    competitiveProductSet: true,
                    characteristicFilters: {
                        multi: true,
                        maxLimit: 5,
                    },
                },
            };

        case 'product:option3':
            return {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // Select 1 characteristic dimension report : Option 3
                    subSelectorLabelId: 'Select 1 characteristic dimension report : Option 3', // Just to distinguish, not used in code
                    subSelectorType: 'singleCharacteristicDimensionReport', // This property is validated in code to identify which type of selector is to be rendered
                    focusBrand: false,
                    //adhocFocusBrand:true,
                    competitiveProductSet: true,
                    characteristicDimension: {
                        multi: false,
                        maxLimit: 1,
                    },
                    characteristicFilters: charsSelectorCustomlyNeededSkus.includes(sku)
                        ? {
                              multi: true,
                              maxLimit: 5,
                          }
                        : undefined,
                },
            };

        case 'product:option4':
            return {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // Select multiple characteristic dimension report : Option 4
                    subSelectorLabelId: 'Select multiple characteristic dimension report : Option 4', // Just to distinguish, not used in code
                    subSelectorType: 'multiCharacteristicDimentionReport', // This property is validated in code to identify which type of selector is to be rendered
                    focusBrand: false,
                    competitiveProductSet: true,
                    characteristicDimension: {
                        multi: true,
                        maxLimit: 5,
                    },
                },
            };

        case 'product:option4a':
            return {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // Select 7 characteristic dimensions report : Option 4a
                    subSelectorLabelId: 'Select 7 characteristic dimensions report : Option 4a', // Just to distinguish, not used in code
                    subSelectorType: 'brandLevelMultiCharacteristicDimensionReport', // This property is validated in code to identify which type of selector is to be rendered
                    focusBrand: true,
                    competitiveProductSet: true,
                    characteristicFilters: {
                        multi: true,
                        maxLimit: 5,
                    },
                    characteristicDimension: {
                        multi: true,
                        allRequired: true,
                        maxLimit: 7,
                    },
                },
            };

        case 'product:option5':
            return {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // Select up to 10 Brands Report : Option 5
                    subSelectorLabelId: 'Select up to 10 Brands : Option 5', // Just to distinguish, not used in code
                    subSelectorType: 'multiBrandsReport', // This property is validated in code to identify which type of selector is to be rendered
                    competitiveProductSet: true,
                    brands: {
                        multi: true,
                        maxLimit: 10,
                    },
                },
            };

        case 'product:option6':
            return {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // Focus Brand Only Report : Option 1A
                    subSelectorLabelId: 'Focus Brand Only Report : Option 6', // Just to distinguish, not used in code
                    subSelectorType: 'focusBrandOnlyReport', // This property is validated in code to identify which type of selector is to be rendered
                    focusBrand: true,
                    competitiveProductSet: true,
                    characteristicFilters: {
                        required: true,
                        alwaysPositiveLookup: true,
                        multi: true,
                        maxLimit: 5,
                    },
                },
            };

        case 'product:option7':
            return {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // H&W Report : Option 7
                    subSelectorLabelId: 'H&W Report : Option 1', // Just to distinguish, not used in code
                    subSelectorType: 'hwReport', // This property is validated in code to identify which type of selector is to be rendered
                    characteristicThemes: {
                        multi: false,
                    },
                    competitiveProductSet: true,
                    characteristicsForTheme: {
                        multi: true,
                    },
                },
            };

        case 'product:option-fs':
            return {
                selectorLabelName: selectorLabels.product,
                selectorType: 'product',
                subSelectors: {
                    // Financial Services report : Option-fs
                    subSelectorLabelId: 'Financial Services report : Option-fs', // Just to distinguish, not used in code
                    subSelectorType: 'fsReport', // This property is validated in code to identify which type of selector is to be rendered
                    focusBrand: false,
                    salesThreshold: {
                        multi: true,
                        required: false,
                    },
                    growthThreshold: {
                        multi: false,
                        required: false,
                    },
                    competitiveProductSet: true,
                    characteristicFilters: {
                        multi: true,
                        maxLimit: 5,
                    },
                },
            };

        case 'market:option1':
            return {
                selectorLabelName: selectorLabels.focusMarket,
                selectorType: 'market',
                subSelectors: {
                    // Select Focus Market: Option 1
                    subSelectorLabelId: 'Focus Market report: Option 1', // Just to distinguish, not used in code
                    subSelectorType: 'focusMarketReport', // This property is validated in code to identify which type of selector is to be rendered
                    markets: {
                        marketGroup: 'rms',
                        multi: false,
                        maxLimit: 1,
                        // just passthrough parameters for the backend
                        marketSections: {
                            embodyTotalMarkets: true,
                            embodyFmcgRetailers: true,
                            embodySpecialityRetailers: true,
                            embodyGeographyMarkets: true,
                        },
                    },
                },
            };

        case 'market:option2':
            return {
                selectorLabelName: selectorLabels.focusMarkets,
                selectorType: 'market',
                subSelectors: {
                    // Select multiple markets report: Option 2
                    subSelectorLabelId: 'Select multiple markets report: Option 2', // Just to distinguish, not used in code
                    subSelectorType: 'multiMarketsReport', // This property is validated in code to identify which type of selector is to be rendered
                    markets: {
                        marketGroup: 'rms',
                        multi: true,
                        maxLimit: 20, //changed to allow choosing up to 20 rather than 10
                        marketSections: {
                            embodyTotalMarkets: true,
                            embodyFmcgRetailers: true,
                            embodySpecialityRetailers: true,
                            embodyGeographyMarkets: true,
                        },
                    },
                },
            };

        case 'market:option2a':
            return {
                selectorLabelName: selectorLabels.focusMarkets,
                selectorType: 'market',
                subSelectors: {
                    // Select multiple markets report: Option 2
                    subSelectorLabelId: 'Select multiple markets report: Option 2a', // Just to distinguish, not used in code
                    subSelectorType: 'smartReportMarkets', // This property is validated in code to identify which type of selector is to be rendered
                    markets: {
                        marketGroup: 'rms',
                        multi: true,
                        maxLimit: 5, //changed to allow choosing up to 5
                        marketSections: {
                            embodyTotalMarkets: true,
                            embodyFmcgRetailers: true,
                            embodySpecialityRetailers: true,
                            embodyGeographyMarkets: true,
                        },
                    },
                },
            };

        case 'market:option2b':
            return {
                selectorLabelName: selectorLabels.focusMarkets,
                selectorType: 'market',
                subSelectors: {
                    // Select multiple markets report: Option 2
                    subSelectorLabelId: 'Select multiple markets report: Option 2b', // Just to distinguish, not used in code
                    subSelectorType: 'smartReportMarkets', // This property is validated in code to identify which type of selector is to be rendered
                    markets: {
                        marketGroup: 'rms',
                        multi: true,
                        maxLimit: 10, //changed to allow choosing up to 5 and each remaining markets must
                        requiredRemMarketForEachRetailer: true,
                        requiredRemMarketForTotalMarkets: true,
                        requiredRemMarketForGeography: true,
                        smartAccount: true,
                        marketSections: {
                            embodyTotalMarkets: true,
                            embodyFmcgRetailers: true,
                            embodySpecialityRetailers: true,
                            embodyGeographyMarkets: true,
                        },
                    },
                },
            };

        /*******
         Market Selector: Option 2 - but should be 5 markets instead of 20 and should only show options where Market Type=Account.
         The user also must choose a Remaining market for each market they select, so will actually make 10 selections,
         but only 5 different Accounts. This will be the below Option market:option2c
         ********/
        case 'market:option2c':
            return {
                selectorLabelName: selectorLabels.focusMarkets,
                selectorType: 'market',
                subSelectors: {
                    // Select multiple markets report: Option 2
                    subSelectorLabelId: 'Select multiple markets report: Option 2c', // Just to distinguish, not used in code
                    subSelectorType: 'smartReportMarkets', // This property is validated in code to identify which type of selector is to be rendered
                    markets: {
                        marketGroup: 'rms',
                        multi: true,
                        requiredRemMarketForEachRetailer: true,
                        marketoptions: true,
                        marketSections: {
                            embodyTotalMarkets: true,
                            embodyFmcgRetailers: true,
                            embodySpecialityRetailers: true,
                            embodyGeographyMarkets: true,
                        },
                    },
                },
            };
        case 'market:option2d':
            return {
                selectorLabelName: selectorLabels.focusMarkets,
                selectorType: 'market',
                subSelectors: {
                    // Select multiple markets report: Option 2
                    subSelectorLabelId: 'Select multiple markets report: Option 2d', // Just to distinguish, not used in code
                    subSelectorType: 'smartReportMarkets', // This property is validated in code to identify which type of selector is to be rendered
                    markets: {
                        marketGroup: 'rms',
                        multi: true,
                        requiredRemMarketForEachRetailer: true,
                        marketoptions: true,
                        noSelection: true,
                        marketSections: {
                            embodyTotalMarkets: false,
                            embodyFmcgRetailers: true,
                            embodySpecialityRetailers: true,
                            embodyGeographyMarkets: false,
                        },
                    },
                },
            };

        case 'market:option3':
            return {
                selectorLabelName: selectorLabels.channelOutlets,
                selectorType: 'market',
                subSelectors: {
                    // CPS Markets report: Option 3
                    subSelectorLabelId: 'CPS Markets report: Option 3', // Just to distinguish, not used in code
                    subSelectorType: 'cpsMarketsReport', // This property is validated in code to identify which type of selector is to be rendered
                    markets: {
                        marketGroup: 'cps',
                        multi: true,
                        maxLimit: 20,
                        marketSections: {
                            embodyPanelTotal: true,
                            embodyPanelByChannel: true,
                        },
                    },
                },
            };

        case 'market:option4':
            return {
                selectorLabelName: selectorLabels.outlets,
                selectorType: 'market',
                subSelectors: {
                    // CPS Markets report: Option 4
                    subSelectorLabelId: 'CPS Markets report: Option 4', // Just to distinguish, not used in code
                    subSelectorType: 'cpsAccountsMarketsReport', // This property is validated in code to identify which type of selector is to be rendered
                    markets: {
                        marketGroup: 'cps',
                        multi: true,
                        maxLimit: 10,
                        marketSections: {
                            embodyPanelTotal: false,
                            embodyPanelByChannel: true,
                        },
                    },
                },
            };

        case 'market:option5':
            return {
                selectorLabelName: selectorLabels.focusMarkets,
                selectorType: 'market',
                subSelectors: {
                    // Market Type report: Option 5
                    subSelectorLabelId: 'Market Type report: Option 5', // Just to distinguish, not used in code
                    subSelectorType: 'marketTypeReport', // This property is validated in code to identify which type of selector is to be rendered
                },
            };

        case 'timePeriod:default':
            return {
                selectorLabelName: selectorLabels.timePeriod,
                selectorType: 'timePeriod',
                subSelectors: {}, // TBD
            };

        case 'dimension:default':
            return {
                selectorLabelName: 'Select Comparison Type',
                selectorType: 'dimension',
                selectedItem: '',
                subSelectors: {}, // TBD
            };
    }
}

function toLegacyScorecardConfigOptions(key) {
    // eslint-disable-next-line default-case
    switch (key) {
        case 'product:option1':
            return {
                category: {
                    multi: true,
                    required: true,
                },
                brand: {
                    multi: false,
                    required: true,
                },
                characteristicFilters: {
                    limit: 5,
                    required: false,
                },
            };

        case 'product:option2':
            return {
                category: {
                    multi: true,
                    required: true,
                },
                brand: {
                    multi: false,
                    required: true, // upc is required if this is not filled
                },
                upc: {
                    required: true, // brand is required if this is not filled
                },
                characteristicFilters: {
                    required: false,
                },
            };

        case 'market:option1':
            return {
                markets: {
                    required: true,
                    multi: false,
                    remaining: false,
                },
            };

        case 'market:option2':
            return {
                markets: {
                    required: true,
                    multi: true,
                    remaining: false,
                },
            };

        case 'market:option3':
            return {
                markets: {
                    required: true,
                    multi: false,
                    remaining: true,
                },
            };

        case 'market:option4':
            return {
                markets: {
                    required: true,
                    multi: true,
                    remaining: true,
                },
            };

        case 'timePeriod:default':
            return {
                timePeriod: true,
            };
    }
}
