import React, { forwardRef, ReactNode, useState, useEffect, useRef } from 'react';
import classnames from "classnames";
import {
    ByzzerChangeEventHandler,
    ByzzerSelect,
    ByzzerSelectOption,
    WithValue,
    ByzzerTipIcon,
    ByzzerSelectOptionGroup
} from '@byzzer/ui-components';
import {useTenantApi} from '@/hooks/useTenantApi';
import { RunConfigMarket } from "@/types/ReportRun";
import './OmniMarketSelect.scss';

const baseClassName = 'byz-omni-market-select';

export type OmniMarketSelectProps = {
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<RunConfigMarket[]>;
    value?: RunConfigMarket[];
    // disabledOptions?: string[]; // Todo see if additional disabled options are needed.  Already disabling based on entitlement, but using this we could allow further disabling...
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    maxSelections?: number;
    categories?: string[];
    runType: RunType;
    allowMultiple?: boolean;
} & OnlyRenderableIf;

export const OmniMarketSelect = forwardRef<WithValue<RunConfigMarket>, OmniMarketSelectProps>((
{
    onChange,
    name,
    label,
    className,
    // disabledOptions = [],
    placeholder,
    value,
    disabled,
    onlyRenderIf,
    maxSelections,
    categories,
    allowMultiple,
    runType
}, ref
) => {
        if (onlyRenderIf === false) return <></>;
        const { getOmniMarkets } = useTenantApi();

        const [internalValue, setInternalValue] = useState<ByzzerSelectOption[]>([]);
        const [internalOptions, setInternalOptions] = useState<ByzzerSelectOptionGroup[]>([]);
        const [internalDisabledOptions, setInternalDisabledOptions] = useState<ByzzerSelectOptionGroup[]>([]);
        const initialValueLoaded = useRef<Boolean>(false);

        const AVAILABLE_MARKETS_GROUP_LABEL = 'Available Markets';

        useEffect(() => {
            if (value && !initialValueLoaded.current) {
                const newInternalValue = value?.map((marketValue) => {
                    return {
                        display: marketValue.name!,
                        value: marketValue.key!,
                    }
                })
                setInternalValue(newInternalValue ?? []);
                initialValueLoaded.current = true;
            } else if (!value || !value?.length) {
                setInternalValue([])
            }
        }, [value]);

        useEffect(() => {
            const suppressedOptions = internalOptions.filter((optionGroup) => {
                return optionGroup.label !== AVAILABLE_MARKETS_GROUP_LABEL;
            })
            setInternalDisabledOptions(suppressedOptions);
        }, [internalOptions])

        useEffect(() => {
            const getAndSetMarketsAsOptions = async (categories: string[]) => {
                const marketsResponse = await getOmniMarkets(
                    runType, // subscription or adhoc
                    categories
                );

                const createMarketOptions = (market) => {
                    const marketOption = {
                        display: market?.name,
                        value: market?.key,
                        data: {
                            ...market
                        }
                    }
                    delete marketOption.data?.name;
                    delete marketOption.data?.key;
                    return marketOption;
                }

                const isOpen = {
                    label: AVAILABLE_MARKETS_GROUP_LABEL,
                    options: marketsResponse.filter((market) => market.isOpen).map(createMarketOptions)
                };
                const requiresPurchaseMissingPriorApproval = {
                    label: 'Premium Market & Requires Prior Approval',
                    options: marketsResponse.filter((market) => market.requiresPurchase && market.missingPriorApproval).map(createMarketOptions)
                };
                const requiresPurchase = {
                    label: 'Premium Market, Requires Purchase',
                    options: marketsResponse.filter((market) => market.requiresPurchase && !market.missingPriorApproval).map(createMarketOptions)
                };
                const missingPriorApproval = {
                    label: "Requires Prior Approval",
                    options: marketsResponse.filter((market) => market.missingPriorApproval && !market.requiresPurchase).map(createMarketOptions)
                };
                const categoryNotCovered = {
                    label: "Category Not Covered",
                    options: marketsResponse.filter((market) => market.categoryNotCovered).map(createMarketOptions)
                };
                setInternalOptions([
                    isOpen,
                    requiresPurchaseMissingPriorApproval,
                    requiresPurchase,
                    missingPriorApproval,
                    categoryNotCovered,
                ])
            }
            categories?.length && getAndSetMarketsAsOptions(categories)
        }, [categories]);


        const handleChange = (e: ByzzerChangeEvent<any>) => {
            setInternalValue(e?.data);

            const newMarketValue = {
                value: e?.data?.map((omniMarketData) => {
                    return {
                        key:  omniMarketData?.value,
                        name: omniMarketData?.display
                    }
                }),
                name,
                data: e?.data
            }

            onChange?.(newMarketValue);

        }

        return (
            <div className={classnames(baseClassName)}>
                <ByzzerSelect // Todo - Integrate ByzzerSingleSelect and ByzzerMultiSelect and allow choice between single and multi select when using this component
                    ref={ref}
                    name={name}
                    className={classnames(baseClassName, className)}
                    options={internalOptions}
                    disabled={disabled}
                    placeholder={placeholder}
                    label={label}
                    value={internalValue}
                    disabledOptions={internalDisabledOptions}
                    onChange={handleChange}
                    optionComponent={React.memo(MarketIntersectionValueLabel)}
                    allowMultiple={allowMultiple}
                    maxSelections={allowMultiple ? maxSelections : undefined}
                />
            </div>
        );
    }
);

export default OmniMarketSelect;

OmniMarketSelect.displayName = 'OmniMarketSelect';

function MarketIntersectionValueLabel({
    data: { data: metadata },
    children,
    ...props
}: any) { // Todo - build a generic option component, post-refactor
    const entitlementMap = {
        "missingPriorApproval": `This market requires prior approval by category from the retailer.`,
        "requiresPurchase": `${children} is a premium market. You can purchase access to this market as an add on in our Shop.`,
        "categoryNotCovered": `${children} does not have coverage in your selected category.`,
        "premiumAndMissingPriorApproval": `This market requires prior approval by category from the retailer. ${children} is also a premium market. You can purchase access to this market as an add on in our Shop.`
    }

    return (<>
        <div className={classnames(`${baseClassName}__indicator-container`)}>
            {metadata?.isOpen ? <span className={`${baseClassName}__mrkt-entitlement--open`} /> : undefined}
            {metadata?.categoryNotCovered ? <ByzzerTipIcon tip={entitlementMap['categoryNotCovered']} className={baseClassName}/> : undefined}
            {metadata?.missingPriorApproval ? <ByzzerTipIcon tip={entitlementMap['missingPriorApproval']} className={baseClassName}/> : undefined}
            {metadata?.requiresPurchase ? <ByzzerTipIcon tip={entitlementMap['requiresPurchase']} className={baseClassName}/> : undefined}
            {metadata?.missingPriorApproval && metadata?.requiresPurchase ? <ByzzerTipIcon tip={entitlementMap['premiumAndMissingPriorApproval']} className={baseClassName}/> : undefined}
        </div>
        <div className={classnames(`${baseClassName}__label-wrapper`)}>
            {children}
        </div>
    </>)
};
