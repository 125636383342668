import { ByzzerButton, ByzzerDateRange, ByzzerSelect } from '@/components/form';
import { format } from 'date-fns';
import React, { useEffect, useRef } from 'react';
import { usePagination, useTable, useSortBy } from 'react-table';
import upArrow from '@images/icons/upArrowDark.svg';
import downArrow from '@images/icons/downArrowDark.svg';

export const RenderTableView = ({
    columns,
    data,
    // fetchData,
    filterTableData,
    filterValues,
    filterSelectedValues,
    isFilterSelected,
    clearAllFilters,
    loading,
    pageCount: controlledPageCount,
    pageIndexCount: controlledPageIndex,
    onClickClear,
    setDateSelectedFn,
    dateSelected,
    byzzerDateRange,
    storyHistoryFound,
    refreshTableData,
    updateTableStateStatus,
    clearDateRange,
    allStoriesData
}) => {
    const baseClass = 'story__container';

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        state,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useSortBy,
        usePagination,
    );
    const { pageIndex, pageSize } = state;
    const dateRangeRef = useRef();

    useEffect(() => {
        if (refreshTableData) {
            gotoPage(0);
            updateTableStateStatus('resetPage');
        }
    }, [refreshTableData]);

    function onChangeDateRange(startDate, endDate) {
        let fromDate = startDate === null || startDate === undefined ? null : format(new Date(startDate), 'yyyy/MM/dd');
        let toDate = endDate === null || startDate === undefined ? null : format(new Date(endDate), 'yyyy/MM/dd');
        const selectedDateRange = { runFrom: fromDate, runTo: toDate };
        if (fromDate !== dateRangeRef.current?.runFrom || toDate !== dateRangeRef.current?.runTo) {
            filterTableData(selectedDateRange, 'dateRange', pageSize);
            dateRangeRef.current = { runFrom: fromDate, runTo: toDate };
        }
    }

    return (
        <>
            <div className={`${baseClass}__history`}>
                <div className={`${baseClass}__history__filters`}>
                    <div className={`${baseClass}__history__filters__field`}>
                        <ByzzerSelect
                            allowClear={true}
                            placeholder="Story Name"
                            name="storyName"
                            value={filterSelectedValues.storyName}
                            options={filterValues.storyName || []}
                            showSearchResultStatus={true}
                            clearAllFilters={clearAllFilters}
                            onChange={(e) => {
                                filterTableData(e, 'storyName', pageSize);
                            }}
                        />
                    </div>
                    <div className={`${baseClass}__history__filters__field`}>
                        <ByzzerSelect
                            allowClear={true}
                            placeholder="Story Type"
                            name="storyType"
                            value={filterSelectedValues.storyType}
                            options={filterValues.storyType || []}
                            showSearchResultStatus={true}
                            clearAllFilters={clearAllFilters}
                            onChange={(e) => {
                                filterTableData(e, 'storyType', pageSize);
                            }}
                        />
                    </div>
                    <div className={`${baseClass}__history__filters__field`}>
                        <ByzzerSelect
                            allowClear={true}
                            placeholder="Run by"
                            name="ranBy"
                            value={filterSelectedValues.ranBy}
                            options={filterValues.ranBy || []}
                            showSearchResultStatus={true}
                            clearAllFilters={clearAllFilters}
                            onChange={(e) => {
                                filterTableData(e, 'ranBy', pageSize);
                            }}
                        />
                    </div>
                    <div className={`${baseClass}__history__filters__field`}>
                        <ByzzerDateRange
                            ref={byzzerDateRange}
                            initialMonths={2}
                            onChange={onChangeDateRange}
                            setDateSelected={setDateSelectedFn}
                            dateSelected={dateSelected}
                            placeholder="Date Run"
                            clearDateRange={clearDateRange}
                        />
                    </div>
                    <div className={`${baseClass}__history__filters__field`}>
                        <ByzzerSelect
                            allowClear={true}
                            placeholder="Search by Market"
                            name="market"
                            value={filterSelectedValues.market}
                            options={filterValues.markets || []}
                            showSearchResultStatus={true}
                            clearAllFilters={clearAllFilters}
                            onChange={(e) => {
                                filterTableData(e, 'market', pageSize);
                            }}
                        />
                    </div>
                    <div className={`${baseClass}__history__filters__field`}>
                        <ByzzerSelect
                            allowClear={true}
                            placeholder="Search by Brand"
                            name="brand"
                            value={filterSelectedValues.brand}
                            options={filterValues.brands || []}
                            showSearchResultStatus={true}
                            clearAllFilters={clearAllFilters}
                            onChange={(e) => {
                                filterTableData(e, 'brand', pageSize);
                            }}
                        />
                    </div>
                    <div className={`${baseClass}__history__filters__field`}>
                        <ByzzerSelect
                            allowClear={true}
                            placeholder="Search by Category"
                            name="categories"
                            value={filterSelectedValues.categories}
                            options={filterValues.categories || []}
                            showSearchResultStatus={true}
                            clearAllFilters={clearAllFilters}
                            onChange={(e) => {
                                filterTableData(e, 'categories', pageSize);
                            }}
                        />
                    </div>
                    <div className={`${baseClass}__history__filters__field ${baseClass}__history__filters__btn-clear`}>
                        <ByzzerButton
                            className={`${baseClass}__history__btn-clear`}
                            type="default"
                            label={'Clear Filters'}
                            disabled={!isFilterSelected}
                            onClick={(e) => {
                                onClickClear(e, pageSize, pageIndex);
                            }}
                        />
                    </div>
                </div>
            </div>

            <table className={'story-history-table'} {...getTableProps()}>
                <thead className="story-history-table-col">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                >
                                    <div className="story-history-table__header-wrapper">
                                    {column.render('Header')}
                                    {
                                        column.canSort && <span>
                                        <i className={`${baseClass}-table__header-i`}>
                                            <img
                                                alt="asc"
                                                className={`${baseClass}-table__header-i ${column.isSortedDesc === false
                                                    ? ''
                                                    : `${baseClass}-table__header-filter-disable`
                                                    }`}
                                                src={upArrow}
                                            />
                                            <img
                                                alt="des"
                                                className={`${column.isSortedDesc
                                                    ? ''
                                                    : `${baseClass}-table__header-filter-disable`
                                                    }`}
                                                src={downArrow}
                                            />
                                        </i>
                                    </span>
                                    }
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                {storyHistoryFound && (
                    <tbody {...getTableBodyProps()}>
                        {page.length > 0 &&
                            page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td
                                                    {...cell.getCellProps({
                                                        style: {
                                                            minWidth: cell.column.minWidth,
                                                            width: cell.column.width,
                                                        },
                                                    })}
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        {!page.length && <div>No Story history found.{page}</div>}
                    </tbody>
                )}
                {!storyHistoryFound && <div>No Story history found.</div>}
            </table>
            <div className="story-pagination">
                <button
                    className="byzzer-button-primary-button"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                >
                    {'<<'}
                </button>{' '}
                <button
                    className="byzzer-button-primary-button"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                >
                    Previous
                </button>{' '}
                <button className="byzzer-button-primary-button" onClick={() => nextPage()} disabled={!canNextPage}>
                    Next
                </button>{' '}
                <button
                    className="byzzer-button-primary-button"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                >
                    {'>>'}
                </button>{' '}
                <select value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                    {[10, 25].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>{' '}
                {
                    <span>
                        Page{' '}
                        <strong>
                            {pageOptions.length === 0 && 0} {pageOptions.length !== 0 && pageIndex + 1} of{' '}
                            {pageOptions.length}
                        </strong>{' '}
                    </span>
                }
            </div>
        </>
    );
};
