import './TimePeriodSelector.scss';
import { DatePicker } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { ByzzerButton, ByzzerInput } from '@/components/form';
import calendarIcon from '@images/icons/calendar.svg';
import { ByzzerRadio, ByzzerSelect } from './form';
import { timePeriodOptions, selectorStates } from '@/config/globalVars';
import { omniTimePeriodOptions } from '@/config/reportSelectorVars.config';
import {useTenantApi} from '@/hooks/useTenantApi';
import classnames from 'classnames';

const baseClassName = 'time-period-selector';
const TimePeriodSelector = ({ setSelectorsData, datatype, ...props }) => {
    const { getLatestMaxDataDate } = useTenantApi();
    const [numberOfWeeks, setNumberOfWeeks] = useState<any>('');
    const [endDate, setEndDate] = useState<any>('');
    const [timePeriodType, setTimePeriodType] = useState(datatype === 'omni' ? 'pre-defined' : '');
    const [predefinedPeriod, setPredefinedPeriod] = useState('');
    const [latestAvailableData, setLatestAvailableData] = useState('');
    const latestAvailableDataRef = useRef<any>();
    latestAvailableDataRef.current = latestAvailableData;
    const [showUpdate, setShowUpdate] = useState(false);
    const selectorData = useRef();
    selectorData.current = JSON.parse(JSON.stringify(props?.selectorState));

    useEffect(() => {
        let selectorStateTimePeriodSelections = JSON.parse(JSON.stringify(props.selectorState.timePeriodSelections));
        selectorStateTimePeriodSelections.endDate = '';
        selectorStateTimePeriodSelections.weeks = '';
        setTimePeriodType('pre-defined');
        if (props?.selectorState?.timePeriodSelections || props?.userPrefData?.defaultTimePeriod) {
            setPredefinedPeriod(props?.selectorState?.timePeriodSelections ?? props?.userPrefData?.defaultTimePeriod);
            selectorStateTimePeriodSelections.timePeriod = props?.selectorState?.timePeriodSelections ?? props?.userPrefData?.defaultTimePeriod;
            setTimePeriodType('pre-defined');
            selectorStateTimePeriodSelections.selectionType = 'pre-defined';
        }
        if (props?.userPrefData?.customTimePeriod && props?.userPrefData?.customTimePeriod !== null) {
            selectorStateTimePeriodSelections.selectionType = 'custom';
            setTimePeriodType('custom');
            validateNumberOfWeeks(props?.userPrefData?.customTimePeriod?.weeks, true);
            setNumberOfWeeks(props?.userPrefData?.customTimePeriod?.weeks);
            setEndDate(moment(props?.userPrefData?.customTimePeriod?.endDate?.split(' to ')[1], 'YYYY-MM-DD'));
            selectorStateTimePeriodSelections.endDate = props?.userPrefData?.customTimePeriod?.endDate;
            selectorStateTimePeriodSelections.weeks = props?.userPrefData?.customTimePeriod?.weeks;
        }

        props.timePeriodType === 'panelTimePeriod'
            ? setSelectorsData({
                panelTimePeriodSelections: selectorStateTimePeriodSelections,
            })
            : setSelectorsData({
                timePeriodSelections: selectorStateTimePeriodSelections,
            });
        retainData(); // retain selected data
        getMaxDataDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let selectorStateTimePeriodSelections = JSON.parse(JSON.stringify(props.selectorState.timePeriodSelections));
        selectorStateTimePeriodSelections.endDate = '';
        selectorStateTimePeriodSelections.weeks = '';
        if (props?.selectorState?.timePeriodSelections || props?.userPrefData?.defaultTimePeriod) {
            setPredefinedPeriod(props?.selectorState?.timePeriodSelections ?? props?.userPrefData?.defaultTimePeriod);
            selectorStateTimePeriodSelections.timePeriod = props?.selectorState?.timePeriodSelections ?? props?.userPrefData?.defaultTimePeriod;
            setTimePeriodType('pre-defined');
            selectorStateTimePeriodSelections.selectionType = 'pre-defined';
        }
        if (props?.userPrefData?.customTimePeriod && props?.userPrefData?.customTimePeriod !== null) {
            setTimePeriodType('custom');
            validateNumberOfWeeks(props?.userPrefData?.customTimePeriod?.weeks, true);
            setNumberOfWeeks(props?.userPrefData?.customTimePeriod?.weeks);
            setEndDate(moment(props?.userPrefData?.customTimePeriod?.endDate?.split(' to ')[1], 'YYYY-MM-DD'));
            selectorStateTimePeriodSelections.endDate = props?.userPrefData?.customTimePeriod?.endDate;
            selectorStateTimePeriodSelections.weeks = props?.userPrefData?.customTimePeriod?.weeks;
            selectorStateTimePeriodSelections.selectionType = 'custom';
        }

        props.timePeriodType === 'panelTimePeriod'
            ? setSelectorsData({
                panelTimePeriodSelections: selectorStateTimePeriodSelections,
            })
            : setSelectorsData({
                timePeriodSelections: selectorStateTimePeriodSelections,
            });
        if (props.productType === 'story' && props.fromBack === true) {
            retainData();
        }
    }, [props.userPrefData]);

    const retainData = () => {
        if (props.productType === 'story' && props.fromBack === true) {
            let currentTimePeriodSelections =
                props.timePeriodType === 'panelTimePeriod'
                    ? props.selectorState?.panelTimePeriodSelections
                    : props.selectorState?.timePeriodSelections ?? props?.userPrefData?.defaultTimePeriod;
            //set time period type
            if (currentTimePeriodSelections?.selectionType !== undefined) {
                setTimePeriodType(currentTimePeriodSelections?.selectionType);
            } else {
                setTimePeriodType('pre-defined');
            }
            //set weeks and end date
            if (currentTimePeriodSelections?.selectionType === 'pre-defined') {
                setPredefinedPeriod(props?.selectorState?.timePeriodSelections ?? props?.userPrefData?.defaultTimePeriod);
            } else if (currentTimePeriodSelections?.selectionType === 'custom') {
                validateNumberOfWeeks(currentTimePeriodSelections?.weeks, true);
                setEndDate(moment(currentTimePeriodSelections?.endDate, 'YYYY-MM-DD'));
            }
        }
    };

    const setShowUpdateButton = () => {
        setShowUpdate(true);
        let selectionsToSelState: any = selectorData.current;
        if (props?.productType === 'story') {
            selectionsToSelState.selectorsStatus[props.stepNo].status = selectorStates.pending;
            setSelectorsData({ selectorsStatus: selectionsToSelState.selectorsStatus });
            selectorData.current = selectionsToSelState;
            props.generateReport(selectionsToSelState);
        }
    };

    const getMaxDataDate = async () => {
        const mrktGroupSel = props.reportSelectors.find((item) => item.selectorType === 'market');
        const timeGroupSel = props.reportSelectors.find((item) => item.selectorType === 'panelTimePeriod');
        let marketGroup;
        if (props.timePeriodType === 'panelTimePeriod') {
            marketGroup = timeGroupSel?.subSelectors?.marketGroup || 'cps';
        } else {
            marketGroup = mrktGroupSel?.subSelectors?.markets?.marketGroup || 'rms';
        }
        let embedMaxDataDate: any = await getLatestMaxDataDate(datatype === 'omni' ? datatype : marketGroup);
        if (embedMaxDataDate === 'error') {
            console.log('Latest Data Date failed');
        } else {
            var d = moment.utc(embedMaxDataDate.maxDataDate).format('YYYY-MM-DD');
            setLatestAvailableData(d);
            return;
        }
    };

    function handleDateChange(date) {
        setEndDate(date);
        let selectorStateTimePeriodSelections =
            props.productType === 'story' && props.timePeriodType === 'panelTimePeriod'
                ? JSON.parse(JSON.stringify(props.selectorState.panelTimePeriodSelections))
                : JSON.parse(JSON.stringify(props.selectorState.timePeriodSelections));
        selectorStateTimePeriodSelections.endDate = moment(date).format('YYYY-MM-DD');
        selectorStateTimePeriodSelections.timePeriod = '';
        //Added for Stories Feature
        props.timePeriodType === 'panelTimePeriod'
            ? setSelectorsData({
                panelTimePeriodSelections: selectorStateTimePeriodSelections,
            })
            : setSelectorsData({
                timePeriodSelections: selectorStateTimePeriodSelections,
            });
        setShowUpdateButton();
    }

    function onNextClick() {
        let currentSelectorStatusState = JSON.parse(JSON.stringify(props.selectorState.selectorsStatus));
        currentSelectorStatusState[props.stepNo].status = selectorStates.completed;

        setSelectorsData({
            selectorsStatus: currentSelectorStatusState,
            activeCollapsePanel: [props.stepNo + 1],
        });
    }

    function handleClickGenerateReport(submitType = '') {
        //Added for Stories Feature
        if (props.productType === 'story') {
            let currentSelectorStatusState = JSON.parse(JSON.stringify(props.selectorState.selectorsStatus));
            currentSelectorStatusState[props.stepNo].status = selectorStates.completed;

            setSelectorsData({
                selectorsStatus: currentSelectorStatusState,
                activeCollapsePanel: submitType !== 'Generate' ? [props.stepNo + 1] : [],
            });
            if (props.fromBack === true) {
                let selectionsToSelState = { ...props.selectorState };
                selectionsToSelState.selectorsStatus = currentSelectorStatusState;
                selectionsToSelState.activeCollapsePanel = [];
                props.generateReport(selectionsToSelState);
            }
            if (props.fromBack !== true) {
                let selectionsToSelState = { ...props.selectorState };
                selectionsToSelState.selectorsStatus = currentSelectorStatusState;
                props.generateReport(selectionsToSelState); /////new
            }
        } else {
            props.generateReport();
        }
    }

    //Added for Stories Feature
    const enableButton = () => {
        let enableFlag = false;

        return enableFlag;
    };

    function validateNumberOfWeeks(weeks, fromRetainData = false) {
        let weekValue = weeks;
        if (weeks >= 1 && weeks <= 52) {
            setNumberOfWeeks(weeks);
        } else if (weeks > 52) {
            weekValue = 52;
            setNumberOfWeeks(52);
        } else {
            weekValue = '';
            setNumberOfWeeks('');
        }
        let selectorStateTimePeriodSelections =
            props.productType === 'story' && props.timePeriodType === 'panelTimePeriod'
                ? JSON.parse(JSON.stringify(props.selectorState.panelTimePeriodSelections))
                : JSON.parse(JSON.stringify(props.selectorState.timePeriodSelections));
        selectorStateTimePeriodSelections.weeks = parseInt(weekValue);
        selectorStateTimePeriodSelections.timePeriod = '';
        //Added for Stories Feature
        props.timePeriodType === 'panelTimePeriod'
            ? setSelectorsData({
                panelTimePeriodSelections: selectorStateTimePeriodSelections,
            })
            : setSelectorsData({
                timePeriodSelections: selectorStateTimePeriodSelections,
            });
        if (!fromRetainData) setShowUpdateButton();
    }

    const OnPeriodChange = (e) => {
        if (e.target.value === 'custom') {
            setPredefinedPeriod('');
        } else {
            setEndDate('');
            setNumberOfWeeks('');
        }
        setTimePeriodType(e.target.value);
        let selectorStateTimePeriodSelections = JSON.parse(JSON.stringify(props.selectorState.timePeriodSelections));
        selectorStateTimePeriodSelections.selectionType = datatype === 'omni' ? datatype : e.target.value;
        //Added for Stories Feature
        props.timePeriodType === 'panelTimePeriod'
            ? setSelectorsData({ panelTimePeriodSelections: selectorStateTimePeriodSelections })
            : setSelectorsData({ timePeriodSelections: selectorStateTimePeriodSelections });
        setShowUpdateButton();
    };

    const OnPredefinedPeriodChange = (e) => {
        setPredefinedPeriod(e);
        let selectorStateTimePeriodSelections = JSON.parse(JSON.stringify(props.selectorState.timePeriodSelections));
        selectorStateTimePeriodSelections.selectionType = datatype === 'omni' ? datatype : timePeriodType;
        selectorStateTimePeriodSelections.timePeriod = datatype !== 'omni' ? e : '';
        if (datatype === 'omni') {
            selectorStateTimePeriodSelections.omniTimePeriod = e;
        }
        selectorStateTimePeriodSelections.weeks = '';
        selectorStateTimePeriodSelections.endDate = '';
        if (props.timePeriodType === 'timePeriod' && timePeriodType === 'pre-defined') {
            selectorStateTimePeriodSelections.rmsDate = latestAvailableDataRef.current;
        }
        //Added for Stories Feature
        props.timePeriodType === 'panelTimePeriod'
            ? setSelectorsData({
                panelTimePeriodSelections: selectorStateTimePeriodSelections,
            })
            : setSelectorsData({
                timePeriodSelections: selectorStateTimePeriodSelections,
            });
        setShowUpdateButton();
    };
    return (
        <>
            <div className={`${baseClassName}`} key={timePeriodType}>
                {/* Added for Stories Feature */}
                {props.productType === 'story' && props.timePeriodType === 'timePeriod' && (
                    <div className={`${baseClassName}__subset-option-text`}>
                        {`${props.selector?.subSelectors?.subSelectorDescription}`}
                    </div>
                )}

                {props.productType === 'story' && props.timePeriodType === 'panelTimePeriod' && (
                    <div className={`${baseClassName}__subset-option-text`}>
                        {`${props.selector?.subSelectors?.subSelectorDescription}`}
                    </div>
                )}

                <div className={`${baseClassName}__time-periods`}>
                    <div className={`${baseClassName}__time-period`}>
                        {datatype !== 'omni' && (
                            <div className="component-content-header">
                                {/* @ts-ignore */}
                                <ByzzerRadio
                                    label={`Preset Time Periods`}
                                    value={'pre-defined'}
                                    checked={timePeriodType === 'pre-defined'}
                                    onChange={OnPeriodChange}
                                    name="time_period_type"
                                />
                            </div>
                        )}
                        {/* @ts-ignore */}
                        <ByzzerSelect
                            label={'Time Period'}
                            key={predefinedPeriod}
                            options={datatype === 'omni' ? omniTimePeriodOptions : timePeriodOptions}
                            value={predefinedPeriod}
                            disabled={timePeriodType === '' || timePeriodType === 'custom'}
                            onChange={OnPredefinedPeriodChange}
                            placeholder={'Select time period'}
                        />
                        <div className={`${baseClassName}-latest-data`}>{`Latest date: ${latestAvailableData}`}</div>
                    </div>
                    {datatype !== 'omni' && <div className={`${baseClassName}__seperator`}>OR</div>}
                    {datatype !== 'omni' && (
                        <div className={`${baseClassName}__time-period`}>
                            <div className="component-content-header">
                                {/* @ts-ignore */}
                                <ByzzerRadio
                                    label={'Custom Time Period'}
                                    checked={timePeriodType === 'custom'}
                                    value={'custom'}
                                    onChange={OnPeriodChange}
                                    name="time_period_type"
                                />
                            </div>
                            <div className={`${baseClassName}__custom-period`}>
                                {/* @ts-ignore */}
                                <ByzzerInput
                                    label={'Number Of Weeks'}
                                    className={`${baseClassName}__weeks`}
                                    disabled={timePeriodType === '' || timePeriodType === 'pre-defined'}
                                    type="number"
                                    onInput={(e) => (e.target.value = e.target.value.slice(0, 2))}
                                    onChange={(e) => validateNumberOfWeeks(parseInt(e.target.value))}
                                    value={numberOfWeeks}
                                />
                                <div className={`${baseClassName}__end-date`}>
                                    <div className={`${baseClassName}__label`}>End Date</div>
                                    <DatePicker
                                        className={`${baseClassName}__calendar`}
                                        placeholder="Select An End Date"
                                        disabled={timePeriodType === '' || timePeriodType === 'pre-defined'}
                                        // style={{
                                        //     marginLeft: '15px',
                                        //     zIndex: 999,
                                        //     width: '175px',
                                        //     height: '39px',
                                        //     fontSize: '16px',
                                        //     color: '#707070',
                                        // }}
                                        format="YYYY-MM-DD"
                                        suffixIcon={<img src={calendarIcon} alt="icon" />}
                                        allowClear={false}
                                        onChange={(date) => handleDateChange(date)}
                                        value={endDate}
                                        defaultPickerValue={moment(latestAvailableData)}
                                        inputReadOnly
                                        disabledDate={(current) => {
                                            return moment(latestAvailableData).add(1, 'days').isAfter(current)
                                                ? current && current.weekday() !== 6
                                                : true; //needs moment data date plus one
                                        }}
                                        dateRender={(current) => {
                                            // if (moment(latestAvailableData).add(1, 'days').isAfter(current)) {
                                            //     if (current < moment().endOf('month')) {
                                            //         //Data date needs to add one more to show properly.  The real date is the 1/16/21 as of 1/29/21
                                            //         if (current.weekday() === 6) {
                                            //             style.color = '#000000';
                                            //             style.fontWeight = '500';
                                            //         }
                                            //         if (current.isSame(endDate)) {
                                            //             style.width = '27.5px';
                                            //             style.height = '27px';
                                            //             style.color = 'white';
                                            //             style.backgroundColor = '#000000';
                                            //             style.fontWeight = '500';
                                            //             style.fontSize = '14px';
                                            //             style.display = 'flex';
                                            //             style.textAlign = 'center';
                                            //             style.alignItems = 'center';
                                            //             style.justifyContent = 'center';
                                            //             style.marginLeft = '3px';
                                            //             style.textDecoration = 'underline';
                                            //         }
                                            //     } else {
                                            //         if (current.weekday() === 6) {
                                            //             style.color = '#2C3038';
                                            //             style.fontWeight = '500';
                                            //         }
                                            //     }
                                            // }

                                            return (
                                                <div
                                                    className={classnames('ant-picker-cell-inner', {
                                                        'date--weekday': current.weekday() === 6,
                                                        'date--end-date': current.isSame(endDate),
                                                    })}
                                                >
                                                    {current.date()}
                                                </div>
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={`${baseClassName}__actions`}>
                    {/* Button for reports */}
                    {props?.productType !== 'story' && (
                        <ByzzerButton
                            label={`${props.saveBtnType === 'Generate' || !setSelectorsData
                                ? 'Generate My Report'
                                : 'Continue'
                                }`}
                            disabled={!predefinedPeriod && !(endDate && numberOfWeeks)}
                            className={`${baseClassName}-component-btn-get-my-report`}
                            onClick={onNextClick}
                        />
                    )}

                    {/* Update Button for Stories */}
                    {props?.productType === 'story' && (
                        <>
                            {!props.fromBack && (
                                <ByzzerButton
                                    label={'Next'}
                                    className={`${baseClassName}-component-btn-get-my-report`}
                                    onClick={() => handleClickGenerateReport(props.saveBtnType)}
                                    disabled={!(predefinedPeriod || (endDate && numberOfWeeks))}
                                />
                            )}
                            {props?.fromBack && (
                                <ByzzerButton
                                    label={showUpdate ? 'Update' : 'Next'}
                                    className={`${baseClassName}-component-btn-get-my-report`}
                                    onClick={() => handleClickGenerateReport(props.saveBtnType)}
                                    // disabled={}
                                    disabled={showUpdate ? !(predefinedPeriod || (endDate && numberOfWeeks)) : true}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
export default TimePeriodSelector;
