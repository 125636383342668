import './DoDHistoryPage.scss';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import errorIcon from '@images/icons/warningIcon.svg';
import warningIcon from '@images/icons/Warning-Icon.svg';
import OutletWithProps from '@/components/OutletWithProps';
import { DashboardContent } from '@/components/dashboard';
import { SubscriptionInfo } from '@/constants/subscription.constants';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useUser } from '@/contexts/UserContext';
import { toRunsText } from '@/utils';
import { useTenantApi } from '@/hooks';
import { ByzzerButton, ByzzerRadio } from '@byzzer/ui-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { createRunButtonHoverMessage } from '@/config/extracts.config';
import { Modal, Popover } from 'antd';
import { ByzzerInput, ByzzerSelect, confirm, openErrorModal } from '@/components/form';
import infoIconBlack from '@images/icons/InformationIcon.svg';
import classNames from 'classnames';
import ByzzerUploadButton from '@/components/form/ByzzerUploadButton';
import { useDropzone } from 'react-dropzone';
import { DodExcelTemplate } from '@/types/DodRun';

const baseClass = 'dod-history-page';

export type PageLink = {
    text: string;
    url: string;
};
export function DoDHistoryPage() {
    const [links, setLinks] = useState<PageLink[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { subscription, user, dodExcelTemplates } = useUser();
    const [remainingCoreRuns, setRemainingCoreRuns] = useState(0);
    const [scheduledRuns, setScheduledRuns] = useState(0);
    const [uploadDialogVisible, setUploadDialogVisible] = useState<boolean>(false);
    const [file, setFile] = useState<File>();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isUploadReady, setIsUploadReady] = useState(true);
    const [dodHistoryData, setDodHistoryData] = useState<any[]>([]);
    const { getUploadTemplateUrl, uploadExcelTemplate, confirmTemplateUpload, getMySubscriptionUsage, getExtractRunCount, getExtracts, deleteMultipleExtractReports, getExtractSignedUrls, deleteTemplates, getTemplateDownloadLinks } = useTenantApi();
    const [templateData, setTemplateData] = useState<any>({
        radio: 'save',
    });
    const navigate = useNavigate();
    const userRole = user?.role ?? 'user';
    const dodTemplatesRef = useRef(dodExcelTemplates);
    const [selectedRows, setSelectedRows] = useState([]);

    const location = useLocation();
    const currentPath = location.pathname;
    const isHistory = currentPath.includes('/dashboard/extracts/history');
    const isScheduled = currentPath.includes('/dashboard/extracts/scheduled');
    const isTemplates = currentPath.includes('/dashboard/extracts/templates');
    const isRunActionsEnabled = useMemo(() => selectedRows.length > 0, [selectedRows]);

    const handleSelectionChange = (selectedRows) => {
        setSelectedRows(selectedRows);
    };
    useEffect(() => {
        setLinks([
            {
                text: 'History',
                url: '/dashboard/extracts/history',
            },
            {
                text: 'Scheduled',
                url: '/dashboard/extracts/scheduled',
            },
            {
                text: 'Templates',
                url: '/dashboard/extracts/templates',
            },
        ]);
    }, []);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {
                await Promise.all([loadSubscription(), getScheduledRunsCount()]);
                const dodExtractRecords = await getExtracts();
                const sortedDodExtractRecords = dodExtractRecords.sort((a, b) => {
                   return b.id > a.id ? 1 : -1
                });
                const sortedResult = sortedDodExtractRecords.map((item) => {
                    return { ...item };
                })
                setDodHistoryData(sortedResult);
            } finally {
                setIsLoading(false);
                setSelectedRows([]);
            }
        })();
    }, []);
    
    useEffect(() => {
        setIsUploadReady(templateData.templateName && file?.name && templateData.extractId);
    }, [templateData, file]);

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        maxFiles: 1,
        accept: [
            '.xls',
            '.xlsx',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
        ],
    });

    useEffect(() => {
        const [file] = acceptedFiles;
        setFile(file);
    }, [acceptedFiles]);

    function removeTemplate(deletedTemplateId: number) {
        dodTemplatesRef.current = dodTemplatesRef.current.filter(template => template.id !== deletedTemplateId);
    }

    const isUserMode = () => {
        return userRole !== 'viewer';
    };

    const openUploadTemplate = () => {
        setUploadDialogVisible(true);
    };

    const restrictDoDAccess = remainingCoreRuns < 1 || !subscription?.active;

    const onFilesSelected = (e) => {
        const [file] = e.target?.files;
        setFile(file);
    };

    const uploadTemplate = async () => {
        try {
            setIsUploading(true);
            const {url, templateId} = await getUploadTemplateUrl(templateData.extractId, templateData.templateName);
            await uploadExcelTemplate(file, url, (e) => {
                console.log(e);
            });
            if (Boolean(await confirmTemplateUpload(templateId, templateData.radio))) {
                dodTemplatesRef.current = [
                    ...dodTemplatesRef.current,
                    {
                        id: templateId,
                        displayName: templateData.templateName,
                        reportId: templateData.extractId,
                        reportName: dodHistoryData.filter((run: any) => run.id === templateData.extractId)[0]
                            .reportName,
                    } as DodExcelTemplate,
                ];
                setUploadDialogVisible(false);
                setFile(null);
                setTemplateData({
                    radio: 'save',
                });
                setUploadDialogVisible(false);
                navigate('/dashboard/extracts/history');
            } else {
                throw new Error();
            }
        } catch (err:any) {
            openErrorModal({
                title: `Something Unexpected Happened`,
                content: (
                    <>
                        <p>Fear not our engineering team is on the job.</p>
                    </>
                ),
                errorId: err.id
            });
        } finally {
            setIsUploading(false);
        }
    };

    const deleteExtract = async() => {

        const confirmed = await confirm({
            title: 'Delete Reports',
            content: (
                <div className="byzzer-allocation-warning">
                    Do you want to delete the {selectedRows?.length} selected Items?
                </div>
            ),
            yesLabel: 'Yes',
            noLabel: 'No',
        });

        if (!confirmed) return;
        
        if(isHistory || isScheduled) {
            deleteHistoryReport();
        }else if(isTemplates) {
            deleteTemplateReport();
        }
        
    }
    function getLinkedTemplateNameAndIdForRunIds(reportIds: number[]) {
        const linkedTemplates = dodTemplatesRef.current.filter(template => reportIds.includes(template.reportId));
        return linkedTemplates.map(linkedTemplate => {
            return linkedTemplate.id
        })
    }
    const deleteHistoryReport = async() => {
        
        try {
            const ids = selectedRows.map(row => row?.id).filter(Boolean);
            if(!ids?.length){
                return;
            }


            setIsLoading(true);
            if(isHistory){
                const linkedTemplates= getLinkedTemplateNameAndIdForRunIds(ids);
                if(linkedTemplates.length > 0){
                    await deleteTemplates(linkedTemplates);
                }
            }
            let resp = await deleteMultipleExtractReports(ids);
            if (resp === 'All deleted') {
                // TODO: refresh dodrecords
                // getDodRecords();
            }
            setSelectedRows([]);
            setIsLoading(false);
        }  catch (err) {
            openErrorModal({
                title: 'Delete Failed!',
                content: (
                    <>
                        <p>Fear not our engineering team is on the job.</p>
                    </>
                ),
            });
            setIsLoading(false);
        }
    }
    const deleteTemplateReport = async() => {
        try {
            const ids = selectedRows.map(row => row?.id).filter(Boolean);
            if(!ids?.length){
                return;
            }
            setIsLoading(true);

            let resp = await deleteTemplates(ids);
            setSelectedRows([]);
           
            setIsLoading(false);
            dodTemplatesRef.current = dodTemplatesRef.current.filter(template => !ids.includes(template.id));

        }  catch (err) {
            openErrorModal({
                title: 'Delete Failed!',
                content: (
                    <>
                        <p>Fear not our engineering team is on the job.</p>
                    </>
                ),
            });
            setIsLoading(false);
        }
    }
    

    const downloadDod = async(documentType = 'excel') => {

        const confirmed = await confirm({
            title: 'Download Reports',
            content: (
                <div className="byzzer-allocation-warning">
                    Do you want to download the {selectedRows?.length} selected Items?
                </div>
            ),
            yesLabel: 'Yes',
            noLabel: 'No',
        });

        if (!confirmed) return;

        if(isHistory){
            downloadHistoryReport(documentType);
        }else if(isTemplates) {
            downloadTemplatesReport();
        }
        
    }
    const downloadHistoryReport = async(documentType) => {
        try {
            const ids = selectedRows.map(row => row?.id).filter(Boolean);
            if(!ids?.length){
                return;
            }
            setIsLoading(true);
            const downloadLinks = await getExtractSignedUrls(ids, 0, documentType);
            setIsLoading(false);
            const downloadFile = (link, index) => {
                setTimeout(() => {
                    const downloadLink = document.createElement('a');
                    downloadLink.href = link.url;
                    downloadLink.download = `extract_${link.extractId}.${documentType}`;
                    downloadLink.style.display = 'none';
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                }, index * 1000); // 500ms delay between each download
            };
    
            downloadLinks.forEach((link, index) => {
                downloadFile(link, index);
            });
            setSelectedRows([]);
            
        } catch (err) {
            openErrorModal({
                title: 'Download Failed!',
                content: (
                    <>
                        <p>Fear not our engineering team is on the job.</p>
                    </>
                ),
            });
            setIsLoading(false);
        }
    }

    const downloadTemplatesReport = async() => {
        try {
            const ids = selectedRows.map(row => row?.id).filter(Boolean);
            if (!ids?.length) {
                return;
            }
            setIsLoading(true);
            const downloadLinks = await getTemplateDownloadLinks(ids);
            setIsLoading(false);

            const downloadFile = (url, index) => {
                setTimeout(() => {
                    const downloadLink = document.createElement('a');
                    downloadLink.href = url;
                    downloadLink.download = `template_${ids[index]}.xlsx`;
                    downloadLink.style.display = 'none';
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                }, index * 1000); // 500ms delay between each download
            };
    
            downloadLinks.forEach((link, index) => {
                downloadFile(link, index);
            });
            setSelectedRows([]);

        } catch (error: any) {
            openErrorModal({
                title: 'Something Unexpected Happened',
                content: <p>Fear not our engineering team is on the job.</p>,
                errorId: error.id
            });
        } finally {
            setIsLoading(false);
        }
    }
    async function getScheduledRunsCount() {
        const scheduledRuns = await getExtractRunCount();
        setScheduledRuns(scheduledRuns.schedule_run);
    }

    async function loadSubscription() {
        // !loading && setIsLoading(true);
        const { basicReports } = await getMySubscriptionUsage();
        setRemainingCoreRuns(basicReports ? basicReports?.limit - basicReports.used : 0);
    }

    const limitedRunsWarning = (
        <>
            {remainingCoreRuns - scheduledRuns < 0 ? (
                <div className={`${baseClass}__error`}>
                    <div>
                        <img src={errorIcon} className={`${baseClass}__war-icon`} />
                    </div>{' '}
                    <div className={`${baseClass}__war-text`}>
                        You have {scheduledRuns} reports scheduled and only {remainingCoreRuns} report runs left in your
                        account which means you do not have enough runs for all your scheduled reports. You will need to
                        purchase more runs or upgrade your subscription by contacting your account executive or
                        messaging us on the chat.{' '}
                    </div>{' '}
                </div>
            ) : (
                <div className={`${baseClass}__warning`}>
                    <div>
                        <img src={warningIcon} className={`${baseClass}__war-icon`} />
                    </div>{' '}
                    <div className={`${baseClass}__war-text`}>
                        You have {scheduledRuns} reports scheduled and only {remainingCoreRuns} report runs left in your
                        account which means you only have {remainingCoreRuns - scheduledRuns} runs more to use. To run
                        more, you will need to purchase more runs or upgrade your subscription by contacting your
                        account executive or messaging us on the chat.{' '}
                    </div>
                </div>
            )}
        </>
    );

    let subTitle;
    let isWarningVisible = false;
    if (!subscription?.active) {
        subTitle = <p>{SubscriptionInfo.SUBSCRITPION_EXPIRED}</p>;
    } else if (
        remainingCoreRuns !== undefined &&
        scheduledRuns !== undefined &&
        scheduledRuns !== 0 && remainingCoreRuns - scheduledRuns < 5
    ) {
        subTitle = limitedRunsWarning;
        isWarningVisible = true;
    } else {
        subTitle = (
            <p>
                {toRunsText(remainingCoreRuns) === 'unlimited'
                    ? `You have unlimited core runs for Data On Demand.`
                    : `You have ${toRunsText(remainingCoreRuns)} Data On Demand runs remaining for your team.`}
            </p>
        );
    }
    return (
        //@ts-ignore
        <DashboardContent title={'Data On Demand'} links={links} className={'dod-history'}>
            <ByzzerMask show={isLoading} loading={isLoading}>
                Loading your Data On Demand runs
            </ByzzerMask>
            {/* @ts-ignore */}
            <Modal
                className={`${baseClass}__upload-template`}
                title={'Upload Excel Template'}
                footer={null}
                visible={uploadDialogVisible}
                onCancel={() => {
                    if (isUploading) return;
                    setTemplateData({
                        radio: 'save',
                    });
                    setFile(undefined);
                    setUploadDialogVisible(false);
                }}
            >
                <div className={`${baseClass}__upload-template__row panel`}>
                    DOD data will load in Tab #1 of the uploaded template. Avoid formulas or hard coded values in this
                    tab.
                </div>
                <div className={`${baseClass}__upload-template__row label`}>Template Name:</div>
                <div className={`${baseClass}__upload-template__row`}>
                    {/* @ts-ignore */}
                    <ByzzerInput
                        placeholder={'Type Here'}
                        className={'input'}
                        onChange={({ target }) => {
                            setTemplateData((prev) => {
                                prev.templateName = target.value ?? '';
                                return JSON.parse(JSON.stringify(prev));
                            });
                        }}
                        value={templateData.templateName ?? ''}
                    ></ByzzerInput>
                </div>
                <div className={`${baseClass}__upload-template__row label`}>
                    Linked DOD run:{' '}
                    <Popover
                        className="info-popover"
                        content={
                            'Please select the series name of the DOD run used to build the template being uploaded.'
                        }
                        placement="right"
                    >
                        <img src={infoIconBlack} alt={'info'} />
                    </Popover>
                </div>
                <div className={`${baseClass}__upload-template__row`}>
                    {/* @ts-ignore */}
                    <ByzzerSelect
                        placeholder={'Select series name from the list'}
                        options={dodHistoryData?.map((val: any) => ({ display: val?.reportName, value: val?.id }))}
                        value={templateData?.extractId}
                        onChange={(val) => {
                            setTemplateData((prev) => {
                                prev.extractId = val;
                                return JSON.parse(JSON.stringify(prev));
                            });
                        }}
                    ></ByzzerSelect>
                </div>
                <div className={`${baseClass}__upload-template__row`}>
                    <div className="char-editor__file">
                        <div
                            {...getRootProps({
                                className: classNames('char-editor__target', {
                                    'char-editor__target--has-file': file,
                                }),
                            })}
                        >
                            <input {...getInputProps()} />
                            <span>{file ? file.name : 'Drag and drop files here to upload'}</span>
                        </div>
                    </div>
                </div>
                <div className={`${baseClass}__upload-template__row`}>
                    {/* @ts-ignore */}
                    <ByzzerUploadButton
                        type={'file'}
                        maxFiles={1}
                        accept={
                            '.xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
                        }
                        label={'Or Select Files to Upload'}
                        onChange={onFilesSelected}
                    />
                </div>
                <div className={`${baseClass}__upload-template__row`}>
                    <ByzzerRadio
                        label={'Save Template'}
                        className={'radioBtn'}
                        name={'templateGroup'}
                        onChange={() => {
                            setTemplateData((prev) => {
                                prev.radio = 'save';
                                return JSON.parse(JSON.stringify(prev));
                            });
                        }}
                        checked={templateData.radio === 'save'}
                    ></ByzzerRadio>
                    <Popover className="info-popover" content={'No run used'} placement="bottom">
                        <img src={infoIconBlack} alt={'info'} />
                    </Popover>
                    <ByzzerRadio
                        label={'Save & Preview Template'}
                        className={'radioBtn'}
                        name={'templateGroup'}
                        onChange={() => {
                            setTemplateData((prev) => {
                                prev.radio = 'preview';
                                return JSON.parse(JSON.stringify(prev));
                            });
                        }}
                        checked={templateData.radio === 'preview'}
                    ></ByzzerRadio>
                    <Popover className="info-popover" content={'No run used'} placement="bottom">
                        <img src={infoIconBlack} alt={'info'} />
                    </Popover>
                    <ByzzerRadio
                        label={'Save & Run with latest data'}
                        className={'radioBtn'}
                        name={'templateGroup'}
                        onChange={() => {
                            setTemplateData((prev) => {
                                prev.radio = 'run';
                                return JSON.parse(JSON.stringify(prev));
                            });
                        }}
                        checked={templateData.radio === 'run'}
                    ></ByzzerRadio>
                    <Popover className="info-popover" content={'Uses 1 run'} placement="bottom">
                        <img src={infoIconBlack} alt={'info'} />
                    </Popover>
                    {/* <ByzzerRadio label={'Save & Schedule run'} className={'radioBtn'} name={'templateGroup'} onChange={() => {
                        setTemplateData(prev => {
                            prev.radio = 'schedule';
                            return JSON.parse(JSON.stringify(prev));
                        });
                    }} checked={templateData.radio === 'schedule'}>
                    </ByzzerRadio>
                    <Popover className='info-popover' content={'On run will be deducted at the time of each scheduled delivery'} placement='bottom'>
                        <img
                            src={infoIconBlack}
                            alt={'info'}
                        />
                    </Popover> */}
                </div>
                <div className={`${baseClass}__upload-template__row`}>
                    <ByzzerButton
                        className={'savebtn'}
                        disabled={!isUploadReady || isUploading}
                        onClick={() => {
                            uploadTemplate();
                        }}
                        disabledTip={isUploading ? <p>Uploading...</p> : undefined}
                    >
                        Save
                    </ByzzerButton>
                </div>
            </Modal>
            <div
                className={classNames(`${baseClass}__run-control-wrapper`, {
                    [`${baseClass}__bulk-actions-visible`]: isRunActionsEnabled,
                    [`${baseClass}__warning-visible`]: isWarningVisible
                })}
            >
                <div className={`${baseClass}__run-summary`}>{subTitle}</div>
                <div className={`${baseClass}__controls`}>
                    <div className={`${baseClass}__actions`}>
                        <ByzzerButton
                            label={'Create a Run'}
                            disabled={!isUserMode() || restrictDoDAccess}
                            disabledTip={!isUserMode() ? createRunButtonHoverMessage : undefined}
                            onClick={() => navigate('/dashboard/extract_editor')}
                        />
                        <ByzzerButton type="negative" label={'Upload Template'} onClick={() => openUploadTemplate()} />
                        {isRunActionsEnabled && (
                            <>
                                {(isHistory || isTemplates) && (
                                    <ByzzerButton className={`${baseClass}__actions_btn`} onClick={() => downloadDod()} ><span className={`${baseClass}__actions_download`}></span>Download Excel</ByzzerButton>
                                )}
                                {isHistory && (
                                    <ByzzerButton
                                        className={`${baseClass}__actions_btn`}
                                        onClick={() => downloadDod('csv')}
                                    ><span className={`${baseClass}__actions_download`}></span>Download CSV</ByzzerButton>
                                )}
                                <ByzzerButton
                                    className={`${baseClass}__actions_btn`}
                                    onClick={() => deleteExtract()}
                                ><span className={`${baseClass}__actions_delete`}></span>Delete</ByzzerButton>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {/* @ts-ignore */}
            {!isLoading && (
                <OutletWithProps
                    setIsLoading={setIsLoading}
                    loadSubscription={loadSubscription}
                    getScheduledRunsCount={getScheduledRunsCount}
                    dodTemplatesRef={dodTemplatesRef}
                    removeTemplate={removeTemplate}
                    handleSelectionChange={handleSelectionChange}
                />
            )}
        </DashboardContent>
    );
}
export default DoDHistoryPage;

DoDHistoryPage.displayName = 'DoDHistoryPage';
