export const DUMMY_CASE_STUDIES = [
    {
        heading: 'How Serenity Kids convinced a key retailer to increase distributions?',
        thumbnail:'https://nielseniq.com/wp-content/uploads/sites/4/2022/08/Serenity.jpeg',
        link:"https://nielseniq.com/global/en/landing-page/smb-us-serenity-kids-case-study/"
    },
    {
        heading: "Shameless Pets knows that CPG data doesn’t just win you a spot on the shelf – it keeps you there.",
        thumbnail:'https://nielseniq.com/wp-content/uploads/sites/4/2022/08/shameless-pets-photo-1200x800-1.jpeg',
        link:"https://nielseniq.com/global/en/landing-page/smb-us-shameless-pets/"
    },
    {
        heading: 'The CPG data package that’s just right for emerging sandwich brand Raybern’s',
        thumbnail:'https://nielseniq.com/wp-content/uploads/sites/4/2022/08/Case-Study-SRayberns-1200x800-1.jpg',
        link:"https://nielseniq.com/global/en/landing-page/smb-us-rayberns/"
    },
   
];


