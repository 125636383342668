import React from 'react';

export function ByzzerRadio({children, label = children, name, value, placeholder, onChange, disabled, className, ...props }) {
    return (
        <label className={`${className} byzzer-radio`}>
            <input type={'radio'} onChange={onChange} name={name} value={value} disabled={disabled} {...props} />
            <div className={disabled ? 'byzzer-radio__toggle-disabled' : 'byzzer-radio__toggle'} />
            {label && <span className={'byzzer-radio__label'}>{label}</span>}
        </label>
    );
}

export default ByzzerRadio;
