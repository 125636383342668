import { useTenantApi } from '@/hooks/useTenantApi';
import parse from 'react-html-parser';

const formatMessage = (placeholderValue, placeholders, message) => {
    let formattedPlaceholder = placeholders.concat('.');
    let msgArray = message.split(formattedPlaceholder);
    if (msgArray.length === 1) {
        msgArray = message.split(placeholders);
    }
    let formattedString = `${msgArray[0]}<br><ul style='padding-left: 26px;list-style: disc;'>`;
    placeholderValue.forEach((value) => {
        formattedString += `<li>${value}</li>`;
    });
    formattedString += `</ul>${msgArray[1]}`;
    return parse(formattedString);
};
export const getInsightsWarningMsg = async (
    selectedSpecification,
) => {
    const { getStoryInsights } = useTenantApi();

    let [response] = await Promise.all([getStoryInsights(selectedSpecification)]);
    let message = response?.status ? '' : response?.errorMessage ?? '';

    if (message !== '' && response.placeholders !== undefined && response?.placeholders.length !== 0) {
        let placeholderObj = response.placeholders;
        let placeholders = '';
        let placeholderValue = [];

        placeholderObj.forEach((data) => {
            placeholders = placeholders.concat(data.placeholder);
            placeholderValue = placeholderValue.concat(data.value);
        });
        return formatMessage(placeholderValue, placeholders, message);
    }

    return message;
};