import './DodTimePeriodFilterPreview.scss';
import React, {useMemo} from 'react';
import classnames from 'classnames';
import {DodFilterPreview, DodSelectionPreviewNode} from '@/components/DodConfigEditor/common';
import {DodFilters} from '@/types/DodRun';
import {ByzzerChangeEventHandler} from '@byzzer/ui-components';
import {useUser} from '@/contexts/UserContext';
import {timePeriodToString} from "@/services/timePeriod.service";
import {format as formatDate} from "date-fns";
import { DodSavedSelectionTypes, DodTimePeriodFilterTypeValueMap, DodTimePeriodFilterTypes } from '@/components/DodConfigEditor/types';
import { useDodTimePeriodService } from '@/services/dodTimePeriod.service';
import { TimePeriodRange } from '@/utils/timePeriod/TimePeriodRange';

const baseClassName = 'dod-time-period-filter-preview';

export type DodTimePeriodFilterPreviewProps = {
    value: DodFilters;
    onDeleteFilterGroup: (type: string) => void;
    onDeleteFilterValue: (type: string, val: string) => void;
    onChange?: ByzzerChangeEventHandler<DodFilters>;
    onEditSummedSelection?: (val: any, index: number, filterType: any) => void;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const savedSelectionDescription = <>
    <p>This will save the full set of selections currently in your Selection panel so that you can easily reuse them in
        future extracts.</p>
    <p>Your saved selections will be available in the "My Saved Time Periods" folder on the left.</p>
</>;

export function DodTimePeriodFilterPreview({className, ...props}: DodTimePeriodFilterPreviewProps) {

    const {maxDataDates} = useUser();

    const maxDate = useMemo<string>(() => {
        return formatDate(maxDataDates.rms!, 'yyyyMMdd');
    }, [maxDataDates]);
    const count = useMemo<number>(() => {
        const {values, summedSelections} = props.value.timePeriods;
        return values.length + summedSelections.length;
    }, [props.value]);
    const {
        latestDates,
        weekEndingDates,
        fourWeeksEndingDates,
        yearEndingDates,
        monthEndingDates,
        quarterEndingDates,
        fourWeeksStaticEndingDates,
        previousPeriodDates
    } = useDodTimePeriodService();

    const sortTimePeriodValues = (data) => {
        return data
            .map((tp: string) => new TimePeriodRange(tp, maxDate))
            .sort(TimePeriodRange.compareDesc)
            .map((tp) => `${tp.type}:${tp.duration}:${tp.offset}`);
    };

    const checkAllValuesSelected = (filteredData, type: DodTimePeriodFilterTypeValueMap) => {
        switch (type) {
            case DodTimePeriodFilterTypeValueMap.latestDates:
                return filteredData.length === latestDates.length;
            case DodTimePeriodFilterTypeValueMap.weeks:
                return filteredData.length === weekEndingDates.length;
            case DodTimePeriodFilterTypeValueMap['4weeks']:
                return filteredData.length === fourWeeksEndingDates.length;
            case DodTimePeriodFilterTypeValueMap.months:
                return filteredData.length === monthEndingDates.length;
            case DodTimePeriodFilterTypeValueMap.quarters:
                return filteredData.length === quarterEndingDates.length;
            case DodTimePeriodFilterTypeValueMap.years:
                return filteredData.length === yearEndingDates.length;
            case DodTimePeriodFilterTypeValueMap['4StaticWeeks']:
                return filteredData.length === fourWeeksStaticEndingDates.length;
            case DodTimePeriodFilterTypeValueMap.previousPeriods:
                return filteredData.length === previousPeriodDates.length;
            default:
                return false;
        }
    };

    const getValueForFolder = (type: DodTimePeriodFilterTypeValueMap, filters) => {
        const filteredData = filters.timePeriods.values.filter((v) => typeof v === 'string' && v.startsWith(type));
        return checkAllValuesSelected(filteredData, type) ? 'all' : sortTimePeriodValues(filteredData);
    };

    const timePeriodInitialGroups = useMemo<DodSelectionPreviewNode[]>(() => [
        {
            label: 'Time Periods',
            filterType: 'timePeriods',
            loadChildren(filters) {
                let summedMarkets = filters.timePeriods.summedSelections;
                return [
                    {
                        label: 'Periods ending',
                        filterType: DodTimePeriodFilterTypes.LATEST_DATES,
                        values: getValueForFolder(DodTimePeriodFilterTypeValueMap.latestDates, filters),
                        summedSelections: [],
                    },
                    {
                        label: 'Previous Periods',
                        filterType: DodTimePeriodFilterTypes.PREVIOUS_PERIOD_DATES,
                        values: getValueForFolder(DodTimePeriodFilterTypeValueMap.previousPeriods, filters),
                        summedSelections: [],
                    },
                    {
                        label: 'Weeks',
                        filterType: DodTimePeriodFilterTypes.WEEKS,
                        values: getValueForFolder(DodTimePeriodFilterTypeValueMap.weeks, filters),
                        // values: filters.timePeriods.values?.filter(val => val?.period?.weeks === 1).map((val) => `Week ending ${val?.period?.endDate}`),
                        summedSelections: [],
                    },
                    {
                        label: '4 Weeks (Trended)',
                        filterType: DodTimePeriodFilterTypes.FOUR_WEEKS,
                        values: getValueForFolder(DodTimePeriodFilterTypeValueMap['4weeks'], filters),
                        // values: filters.timePeriods.values?.filter(val => val?.period?.weeks === 4).map((val) => `4 weeks ending ${val?.period?.endDate}`),
                        summedSelections: [],
                    },
                    {
                        label: '4 Weeks (Static)',
                        filterType: DodTimePeriodFilterTypes.FOUR_WEEKS_STATIC,
                        values: getValueForFolder(DodTimePeriodFilterTypeValueMap['4StaticWeeks'], filters),
                        // values: filters.timePeriods.values?.filter(val => val?.period?.weeks === 4).map((val) => `4 weeks ending ${val?.period?.endDate}`),
                        summedSelections: [],
                    },
                    {
                        label: 'Months',
                        filterType: DodTimePeriodFilterTypes.MONTHS,
                        values: getValueForFolder(DodTimePeriodFilterTypeValueMap.months, filters),
                        // values: filters.timePeriods.values?.filter(val => (val?.period?.weeks === 5 || val?.period?.weeks === 4)).map((val) => `4 weeks ending ${val?.period?.endDate}`),
                        summedSelections: [],
                    },
                    {
                        label: 'Quarters',
                        filterType: DodTimePeriodFilterTypes.QUARTERS,
                        values: getValueForFolder(DodTimePeriodFilterTypeValueMap.quarters, filters),
                        // values: filters.timePeriods.values?.filter(val => (val?.period?.weeks === 13)).map((val) => `13 weeks ending ${val?.period?.endDate}`),
                        summedSelections: [],
                    },
                    {
                        label: 'Years',
                        filterType: DodTimePeriodFilterTypes.YEARS,
                        values: getValueForFolder(DodTimePeriodFilterTypeValueMap.years, filters),
                        // values: filters.timePeriods.values?.filter(val => (val?.period?.weeks === 52)).map((val) => `52 weeks ending ${val?.period?.endDate}`),
                        summedSelections: [],
                    },
                    // dynamically add entries for custom time periods
                    ...filters.timePeriods.values
                        .filter(v => typeof v !== 'string')
                        .map(customTp => ({
                            label: 'Custom Time Period',
                            filterType: 'customTimePeriod',
                            values: sortTimePeriodValues(customTp),
                        })),
                    {
                        label: 'Summed Time Periods',
                        values: [],
                        filterType: 'summedTimePeriods',
                        summedSelections: summedMarkets.map((sum) => {
                            return {
                                name: sum.name,
                                values: sum.values,
                            };
                        }),
                    },
                ];
            },
            // valueToStrings(filters) {
            //     return filters.timePeriods.values.map((val) => val?.period?.endDate)
            // }
        },
    ], []);

    return (
        <DodFilterPreview
            className={classnames(baseClassName, className)}
            emptyStateContent={'No Time Periods Selected'}
            savedSelectionType={DodSavedSelectionTypes.TIME_PERIOD}
            initialGroups={timePeriodInitialGroups}
            title={count > 0 ? `${count} Selected` : ''}
            selectionCount={count}
            savedSelectionDescription={savedSelectionDescription}
            skipSorting={true}
            {...props}
        />
    );
}

export default DodTimePeriodFilterPreview;
