import React, { useState, useRef, useEffect } from 'react';
import { ByzzerButton, ByzzerSelect } from '@/components/form';
import {ByzzerMask} from "@/components/ByzzerMask/ByzzerMask";
import { selectorLabels, selectorStates } from '@/config/globalVars';

function CharacteristicSelector(props) {
    const [loading, setLoading] = useState(true);
    const [characteristicValue, setCharacteristicValue] = useState('');
    const [globalEnable, setGlobalEnable] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const propsData = useRef();
    propsData.current = props ? props : {};

    useEffect(() => {
        if (props.refreshCharacteristicFeature) {
            let selectionsToSelState = { ...props.selectorState };
            let currentSelectorStatusState = selectionsToSelState.selectorsStatus;
            if (props.productType === 'story') {
                const charSelIndex = currentSelectorStatusState.findIndex(
                    (element) => element.selectorName === selectorLabels.characteristic
                );
                if (charSelIndex >= 0) {
                    currentSelectorStatusState[charSelIndex].status = selectorStates.pending;
                    setGlobalEnable(false);
                } else {
                    setGlobalEnable(true);
                }
                props.setSelectorsData({
                    selectorsStatus: currentSelectorStatusState,
                    characteristicSelections: '',
                    refreshCharacteristicFeature: false,
                });
                setCharacteristicValue('');
            }
            setShowUpdate(true);
            selectionsToSelState.characteristicSelections = '';
            selectionsToSelState.refreshCharacteristicFeature = false;
            selectionsToSelState.selectorsStatus = currentSelectorStatusState;
            props.generateStory(selectionsToSelState);
        }
    }, [props.refreshCharacteristicFeature]);

    useEffect(() => {
        if (props.fromBack === true && props?.selectorState?.characteristicSelections !== '') {
            setGlobalEnable(true);
            setCharacteristicValue(props?.selectorState?.characteristicSelections);
        }
    }, [props.fromBack]);

    const [currentSelection, setCurrentSelection] = useState({
        characteristicValue: '',
    });

    const selectCharFilterData = (e, fieldname, index = 0, selectedVal = null) => {
        setCharacteristicValue(e);
        if (e?.length > 0) {
            setGlobalEnable(true);
        } else {
            setGlobalEnable(false);
        }
        setShowUpdateButton();
    };

    const setShowUpdateButton = () => {
        setShowUpdate(true);
        let selectionsToSelState = JSON.parse(JSON.stringify(propsData?.current?.selectorState));
        if (props?.productType === 'story') {
            selectionsToSelState.selectorsStatus[props.stepNo].status = selectorStates.pending;
            props.setSelectorsData({ selectorsStatus: selectionsToSelState.selectorsStatus });
            props.generateStory(selectionsToSelState);
        }
    };

    const submitCharacteristicSelection = (submitType) => {
        let currentSelectionTemp = {
            characteristicValue: characteristicValue,
        };

        setCurrentSelection(currentSelectionTemp);

        let currentSelectorStatusState = JSON.parse(JSON.stringify(props.selectorState.selectorsStatus));
        currentSelectorStatusState[props.stepNo].status = selectorStates.completed;

        props.setSelectorsData({
            selectorsStatus: currentSelectorStatusState,
            activeCollapsePanel: submitType !== 'Generate' ? [props.stepNo + 1] : [], 
            characteristicSelections: characteristicValue,
            characteristicOptions: props.selectorState?.characteristicOptions,
        });

        if (props.fromBack !== true) {
            let selectionsToSelState = { ...props.selectorState };
            selectionsToSelState.selectorsStatus = currentSelectorStatusState;
            props.generateStory(selectionsToSelState);
        }

        if (props.fromBack === true) {
            let selectionsToSelState = { ...props.selectorState };
            selectionsToSelState.characteristicSelections = characteristicValue;
            selectionsToSelState.characteristicOptions = props.selectorState?.characteristicOptions;
            selectionsToSelState.selectorsStatus = currentSelectorStatusState;
            props.generateStory(selectionsToSelState);
        }
    };

    const index = 0;
    return (
        <div className='product-selector'>
            {props.productType === 'story' && (
                <div className="product-selector__subset-option-text-two">
                    {`${props?.selector?.subSelectors?.subSelectorDescription}`}
                </div>
            )}

            {props.selectorState?.characteristicOptions?.length > 0 ? (
                <>
                    <div className="product-selector__competitive-set">
                        <div className="product-selector__competitive-set-item">
                            <label className="product-selector__competitive-set-item-label">
                                Choose your characteristic dimension:
                            </label>
                        </div>
                        <div className="product-selector__competitive-set-select">
                            <ByzzerSelect
                                value={characteristicValue} 
                                placeholder={'Select from the list'} 
                                options={props.selectorState?.characteristicOptions}
                                onChange={(e) => selectCharFilterData(e, 'characteristic', index)}
                                allowClear={true}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <><ByzzerMask loading={loading}/></>
            )}

            {props.productType === 'story' && (
                <div className="product-selector__subset-option-text-two">
                    The dimension you choose will break the category down into comparative groups based on that
                    characteristic. Do you want to compare different brands or the performance of different features
                    such as flavor or pack type?
                </div>
            )}

            <div className="product-selector__submit-btn-container" style={{ gridColumnGap: '3%' }}>
                {props.fromBack !== true && (
                    <ByzzerButton
                        label={`${
                            props.saveBtnType === 'Generate' || !props.setSelectorsData ? 'Generate My Story' : 'Next'
                        }`}
                        onClick={() => submitCharacteristicSelection(props.saveBtnType)}
                        disabled={!globalEnable}
                    />
                )}
                {props.fromBack === true && !props?.objectivesHasChanged && (
                    <ByzzerButton
                        label={showUpdate ? 'Update' : 'Next'}
                        onClick={() => submitCharacteristicSelection(props.saveBtnType)}
                        disabled={showUpdate ? !globalEnable : true}
                    />
                )}
                {props.fromBack === true && props?.objectivesHasChanged && (
                    <ByzzerButton
                        label={showUpdate ? 'Update' : 'Next'}
                        onClick={() => submitCharacteristicSelection(props.saveBtnType)}
                        disabled={!globalEnable}
                    />
                )}
            </div>
        </div>
    );
}

export default CharacteristicSelector;
