import React, { useState } from 'react';
import './MarketSelector.scss';
import MultiSelectMarkets from '@/components/MultiSelectMarkets';
import SelectMarketType from '@/components/SelectMarketType';

function MarketSelector(props) {
    const {
        selector: { subSelectors },
        isSubMarket,
    } = props;
    const { markets, subSelectorType } = subSelectors;
    const [fromMarketTypeSelector, setFromMarketTypeSelector] = useState(false);
    if (subSelectorType === 'marketTypeReport') {
        return (
            <SelectMarketType
                from="reportSelector"
                selectorState={props.selectorState}
                setSelectorsData={props.setSelectorsData}
                reportSelectors={props.reportSelectors}
                setReportSelectors={props.setReportSelectors}
                stepNo={props.stepNo}
                subSelectors={subSelectors}
                fromBack={props.fromBack}
                generateReport={props.generateReport}
                saveBtnType={props.saveBtnType}
                setFromMarketTypeSelector={setFromMarketTypeSelector}
                productType={props?.productType}
            />
        );
    } else {
        return (
            <MultiSelectMarkets
                from="reportSelector"
                fromMarketTypeSelector={fromMarketTypeSelector}
                reportType={props.reportType}
                userPrefState={props.userPrefData}
                selectorState={props.selectorState}
                setSelectorsData={props.setSelectorsData}
                marketGroup={markets.marketGroup}
                multiSelect={markets.multi}
                maxLimit={markets.maxLimit}
                stepNo={props.stepNo}
                subSelectors={subSelectors}
                saveBtnType={props.saveBtnType}
                generateReport={props.generateReport}
                actionConfig={props.actionConfig}
                refreshMarkets={props.refreshMarkets}
                refreshComparisonMarkets={props.refreshComparisonMarkets}
                productType={props.productType}
                marketType={props.marketType}
                fromBack={props.fromBack}
                productSku={props?.productSku}
                objectivesHasChanged={props?.objectivesHasChanged}
                selectorConfigs={props?.selectorConfigs}
                reportViewPage={props.reportViewPage}
                setNextButtonStatus={props.setNextButtonStatus}
                reportSelectors={props.reportSelectors}
                setReportSelectors={props.setReportSelectors}
                isSubMarket={isSubMarket}
            />
        );
    }
}

export default MarketSelector;
