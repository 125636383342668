import classnames from "classnames";
import SmartReportBadge from "@images/badges/smart-report.svg";
import CoreAdvancedBadge from "@images/badges/core-advanced-report.svg";
import React from "react";

export function ReportCardBadge({type, className}) {

    const baseClassName = 'report-card-badge';
    switch (type) {
        case 'smart':
            return <img
                className={classnames(baseClassName, className)}
                src={SmartReportBadge}
                alt="smart"
            />
        case 'core_advanced':
            return <img
                className={classnames(baseClassName, className)}
                src={CoreAdvancedBadge}
                alt="smart"
            />
    }

    return <></>
}
