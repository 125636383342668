import './SubscriptionReportRuns.scss';
import React, {useEffect, useState} from 'react';
import ReportRunUsage from './ReportRunUsage';
import {useTenantApi} from '@/hooks/useTenantApi';
import {useEvents, useUser} from '@/contexts/UserContext';
import {useModals} from '@/components/form/ByzzerModal';
import {ByzzerMask} from "@/components/ByzzerMask/ByzzerMask";
import {TeamCreditUsage} from './types';

export const SubscriptionReportRuns = () => {
    const { getMyCompanySubscriptionUsage, getTeams, updateTeamReportRunAllocation, getCompanySetting, setCompanySetting } = useTenantApi();
    const {user, subscription} = useUser();
    const events = useEvents();
    const {openErrorModal} = useModals();

    const [loading, setLoading] = useState(false);
    const [totalCoreRuns, setTotalCoreRuns] = useState<number>(0);
    const [totalSmartRuns, setTotalSmartRuns] = useState<number>(0);
    const [usedCoreRuns, setUsedCoreRuns] = useState<number>(0);
    const [usedSmartRuns, setUsedSmartRuns] = useState<number>(0);
    const [remainingCoreRuns, setRemainingCoreRuns] = useState<number>(0);
    const [remainingSmartRuns, setRemainingSmartRuns] = useState<number>(0);
    const [teamCoreRuns, setTeamCoreRuns] = useState<TeamCreditUsage[]>([]);
    const [teamSmartRuns, setTeamSmartRuns] = useState<TeamCreditUsage[]>([]);
    const [totalTeamUsedCore, setTotalTeamUsedCore] = useState<number>(0);
    const [totalTeamUsedSmart, setTotalTeamUsedSmart] = useState<number>(0);
    const [allocateCoreReportRunsByTeam, setAllocateCoreReportRunsByTeam] = useState<boolean>(false);
    const [allocateSmartReportRunsByTeam, setAllocateSmartReportRunsByTeam] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(user?.role?.toLowerCase() !== 'admin' || subscription?.metadata?.isFree);

    useEffect(() => {
        setReadOnly(user?.role?.toLowerCase() !== 'admin' || subscription?.metadata?.isFree);
    }, [user?.role])

    useEffect(() => {
        loadSubscription();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const event = events?.[0];
        switch (event?.type) {
            case 'contract_items_changed':
            case 'subscription_report_run':
                loadSubscription(false);
                break;
        }
    }, [events]);

    async function loadSubscription(showLoadingIndicator = true) {
        try {
            if (showLoadingIndicator) setLoading(true);
            const [{
                basicReports,
                premiumReports
            }, teams, allocateCoreReportTeamUsage, allocateSmartReportTeamUsage] = await Promise.all([
                getMyCompanySubscriptionUsage(),
                getTeams(),
                getCompanySetting<boolean>('allocateCoreReportRunsByTeams'),
                getCompanySetting<boolean>('allocateSmartReportRunsByTeams')
            ]);

            // NOTE: this is excluding reports that don't have a sectionHeader value
            setTotalCoreRuns(Number(basicReports.limit));
            setTotalSmartRuns(Number(premiumReports.limit));
            setUsedCoreRuns(Number(basicReports.used));
            setUsedSmartRuns(Number(premiumReports.used));
            setRemainingCoreRuns(Number(basicReports.limit - basicReports.used));
            setRemainingSmartRuns(Number(premiumReports.limit - premiumReports.used));
            setTeamUsageFromTeams(teams);
            setAllocateCoreReportRunsByTeam(allocateCoreReportTeamUsage);
            setAllocateSmartReportRunsByTeam(allocateSmartReportTeamUsage);

        } catch (err: any) {
            return openErrorModal({
                title: `Something Unexpected Happened`,
                content: (
                    <>
                        <p>Fear not our engineering team is on the job.</p>
                    </>
                ),
                errorId: err.id
            });
        } finally {
            setLoading(false);
        }
    }

    let setTeamUsageFromTeams = (teams) => {
        let basic: TeamCreditUsage[] = [];
        let premium: TeamCreditUsage[] = [];
        let basicTotalTeamUsed = 0;
        let premiumTotalTeamUsed = 0;

        teams.forEach((team) => {
            let name = team.title;
            let id = team.id;
            let basicReports = team.usage.basicReports ?? {limit: 0, used: 0};
            let premiumReports = team.usage.premiumReports ?? {limit: 0, used: 0};
            basic.push({
                id,
                name,
                totalAllocated: basicReports.limit,
                used: basicReports.used,
                remainingAllocated: basicReports.limit - basicReports.used
            });
            premium.push({
                id,
                name,
                totalAllocated: premiumReports.limit,
                used: premiumReports.used,
                remainingAllocated: premiumReports.limit - premiumReports.used
            });
        });
        setTeamCoreRuns(basic);
        setTeamSmartRuns(premium);
        setTotalTeamUsedCore(basicTotalTeamUsed);
        setTotalTeamUsedSmart(premiumTotalTeamUsed);


    }

    let updateCoreAllocation = async (teamAllocations) => {
        if (readOnly) {
            return;
        }
        return updateAllocation('basic_reports', teamAllocations);
    }

    let updateSmartAllocation = async (teamAllocations) => {
        if (readOnly) {
            return;
        }
        return updateAllocation('premium_reports', teamAllocations);
    }

    let updateAllocation = async (productType, teamAllocations) => {

        if (readOnly) {
            return;
        }

        await Promise.all(teamAllocations.map((teamAllocation) => {
            return updateTeamReportRunAllocation(teamAllocation.id, [
                {type: productType, total: teamAllocation.remainingAllocated + teamAllocation.used}
            ]);
        }));
        return loadSubscription(false);
    }

    let setAllocateCoreTeamUsage = (setting) => {
        if (readOnly) {
            return;
        }
        setCompanySetting('allocateCoreReportRunsByTeams', {value: setting});
        setAllocateCoreReportRunsByTeam(setting);
    }

    let setAllocateSmartTeamUsage = (setting) => {
        if (readOnly) {
            return;
        }
        setCompanySetting('allocateSmartReportRunsByTeams', {value: setting});
        setAllocateSmartReportRunsByTeam(setting);
    }

    return (
        <div className="subscription-report-runs-container">

            <ByzzerMask show={loading} loading={loading}/>
            {teamCoreRuns && (

                <ReportRunUsage
                    reportType="core"
                    totalRuns={totalCoreRuns}
                    usedRuns={usedCoreRuns}
                    remainingRuns={remainingCoreRuns}
                    teamUsages={teamCoreRuns}
                    readOnly={readOnly}
                    totalTeamUsed={totalTeamUsedCore}
                    updateAllocation={updateCoreAllocation}
                    setAllocateTeamUsage={setAllocateCoreTeamUsage}
                    allocateTeamUsage={allocateCoreReportRunsByTeam}/>

            )
            }
            {teamSmartRuns && (

                <ReportRunUsage
                    reportType="smart"
                    totalRuns={totalSmartRuns}
                    usedRuns={usedSmartRuns}
                    remainingRuns={remainingSmartRuns}
                    teamUsages={teamSmartRuns}
                    readOnly={readOnly}
                    totalTeamUsed={totalTeamUsedSmart}
                    updateAllocation={updateSmartAllocation}
                    setAllocateTeamUsage={setAllocateSmartTeamUsage}
                    allocateTeamUsage={allocateSmartReportRunsByTeam}/>)}
        </div>
    );
};

SubscriptionReportRuns.displayName = 'SubscriptionReportRuns';

export default SubscriptionReportRuns;
