import { ByzzerRadio, ByzzerButton } from '@/components/form';
import React from 'react';

function ModifyExtract(props) {
    const modifyOptions = [
        {
            name: `Edit the selections for the entire series "${props?.seriesName}"`,
            value: 'editScheduleSeries',
        },
        {
            name: `Edit only the schedule for the series "${props?.seriesName}"`,
            value: 'editSchedule',
        },
        {
            name: `Create a new run by copying the selections from "${props?.seriesName}"`,
            value: 'edit',
        },
    ];

    const radioChanged = (event) => {
        props.saveEditOption(event.target.value);
    };

    const isRadioChecked = (value) => {
        return value === props.editOption;
    };

    const nextClickedHandler = () => {
        props.onNextClicked(props.editOption);
    };

    return (
        <>
            {modifyOptions.map((val, index) => {
                return (
                    <React.Fragment key={index}>
                        <div className="extracts-edit-container__edit-option">
                            <ByzzerRadio
                                key={val.value}
                                label={val.name}
                                value={val.value}
                                onChange={radioChanged}
                                checked={isRadioChecked(val.value)}
                            />
                        </div>
                    </React.Fragment>
                );
            })}
            <br />
            <div className="modify-dod-btn-modal">
                <ByzzerButton
                    label="Next"
                    onClick={nextClickedHandler}
                    disabled={props.disableNextButton}
                ></ByzzerButton>
            </div>
        </>
    );
}

export default ModifyExtract;