/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useReducer} from 'react';
import hashObject from 'object-hash';
import {errorCodesInfo} from '@/config/errorCodesInfo.config';
import defaultImage from '@/images/defaultImage.png';
import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom';
import _ from 'lodash';
import { DepartmentsHierarchy } from '@/types/ApiTypes';

export function useBetterNavigate() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    return useCallback(
        (to, options = {}) => {
            // @ts-ignore
            let { params, search = params } = options;

            if (_.isNumber(to)) {
                return navigate(to);
            }

            if (_.isObject(search)) {
                search = toSearchParams(search);
            }

            if (_.isString(to)) {
                to = {
                    pathname: to,
                };
            }
            navigate(
                {
                    search,
                    ...to,
                },
                {
                    ...options,
                    // @ts-ignore
                    search: undefined,
                }
            );
        },
        [navigate, searchParams]
    );
}

export function toSearchParams(map) {
    return createSearchParams(map ?? {}).toString();
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useClickOutsideHandler(ref, fn) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                fn?.();
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
}

export function removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
}

export function sortArrayOfObjectsByProperty(key, order = 'asc') {
    return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
        }

        const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
        const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return order === 'desc' ? comparison * -1 : comparison;
    };
}

export const useSetState = (initialState) => {
    const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), initialState);
    return [state, setState];
};

export const errorCodeDetails = (errorCode, formType) => {
    let lowerCaseErrorCode = errorCode ? errorCode.toLowerCase() : errorCode;
    let lowerCaseFormType = formType ? formType.toLowerCase() : formType;

    return errorCodesInfo[lowerCaseErrorCode]
        ? errorCodesInfo[lowerCaseErrorCode]
        : errorCodesInfo[lowerCaseFormType]
        ? errorCodesInfo[lowerCaseFormType]
        : errorCodesInfo['generic'];
};

export function arrayIntersection(arrays) {
    return arrays.reduce((intersection, array) => intersection.filter((v) => array.includes(v)), arrays[0]);
}

export function getDefaultImageSrc(event) {
    event.target.src = defaultImage;
}

export function oneDayLess(date) {
    var d = new Date(date);
    d.setDate(d.getDate() - 1);
    return d.toLocaleString().substring(0, 10);
}


export const convertStrToNum = (inputStr = '0') => {
    if(typeof inputStr === 'string') {
        return Number(inputStr.replace(/[^0-9]/g, ""));
    } 
    return inputStr;
}

export const  MrktEntitlementType = {
    PRIOR_APPROVAL              : "prior_approval",
    NOT_PRIOR_APPROVAL          : "not_prior_approval",
    PARTIAL_PRIOR_APPROVAL      : "partial_prior_approval",
    REV_SHARE                   : "rev_share",    
    NOT_REV_SHARE               : "not_rev_share",   
    CATEGORY_COVERED            : "category_covered",
    CATEGORY_NOT_COVERED        : "category_not_covered",
    DISABLE_STACK_BY_IN_DOD       : "disable_stackby",      
}

/**
 * Capitalize the first letter of the given string
 */
export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export function simulateMouseClick(element) {
    ['mousedown', 'click', 'mouseup'].forEach((mouseEventType) => {
        element.dispatchEvent(
            new MouseEvent(mouseEventType, {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1
            })
        )
    });
}

export function openChat() {
    const intercomChatElement = document.querySelector('div[class="intercom-chat"]');
    simulateMouseClick(intercomChatElement);
}

export const isValidArray = (value: unknown): value is any[] => {
    return Array.isArray(value) && value.length > 0;
};

export const arrayRange = (start, stop, step) =>
    Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);

export function getObjectHash<T extends { [key: string]: any }>(object: T, excludeKeys: string[] = []): string {
    return hashObject(object, {
        excludeKeys(key: string) {
            return excludeKeys.includes(key);
        },
    });
}

export function listCategoriesForDepartment(department: string, hierarchyTree: DepartmentsHierarchy): string[] {
    if (!department || !hierarchyTree) return [];
    return Object.values(hierarchyTree?.[department.toUpperCase()])?.map(catSubCatGroup => Object.keys(catSubCatGroup ?? {})).flat();
}