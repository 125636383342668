import SelectDimension from '@/components/SelectDimension';
import TimePeriod from '@/components/TimePeriodSelector';
import { useSetState } from '@/utils/utils';
import CharacteristicSelector from '@/views/CharacteristicSelector';
import MarketSelector from '@/views/MarketSelector';
import ProductSelector from '@/views/ProductSelector';
import SegmentSelector from '@/views/SegmentSelector';
import { Collapse } from 'antd';
import React, { memo, useEffect, useRef, useState } from 'react';
import './DataSpecification.scss';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import classnames from 'classnames';
import { selectorStates } from '@/config/globalVars';

const DataSpecification = memo(
    function DataSpecification({
        props,
        fromBack,
        setDataSpecificationValue,
        storySelectorVal,
        selectorStateDataObj,
        fromRunAgain,
        objectivesHasChanged,
        onStepLoad,
        marketGroup,
        selectorConfigsData,
        setNextButtonStatus
    }) {
        const [selectorState, setSelectorState] = useSetState(selectorStateDataObj);
        const [selectorConfigs, setselectorConfigs] = useSetState(selectorConfigsData);
        const { Panel } = Collapse;
        const [loading, setLoading] = useState(false);
        const [storySelectors, setStorySelectors] = useState(storySelectorVal); //useState([]);
        const { userData } = props;
        const { from } = props;
        const { storyType, actionConfig } = props;
        const [userPrefData, setUserPrefData] = useState(null);
        const [characteristicSelData, setCharacteristicSelData] = useState([
            {
                options: [],
                characteristicValue: '',
            },
        ]);
        const selectedObjectives = JSON.parse(localStorage['selectedObjectives'] || '[]');

        useEffect(() => {
            onStepLoad?.('DataSpecification');
        }, []);

        const statusLabels = (status) => (
            <div className={classnames('story-selector__status', `story-selector__status--${status}`)}>{status}</div>
        );

        const renderCollapseExpandIcon = (isActive) => (
            <div
                className={classnames('story-selector__section-toggle', {
                    'story-selector__section-toggle--open': isActive,
                })}
            />
        );

        const collapseClick = (index) => {
            setSelectorState({ activeCollapsePanel: index || [] });
        };

        const getPPGId = (selectionsToSelState) => {
            const focusProdSelectionSet = selectionsToSelState
                ? selectionsToSelState.focusProductSelections
                : selectorState.focusProductSelections;
            return focusProdSelectionSet?.ppgId || null;
        };

        const generateStory = (selectionsToSelState = '', stepNo = -1) => {
            setLoading(true);
            const selectorData = {
                focusProductSelections: selectionsToSelState
                    ? selectionsToSelState.focusProductSelections
                    : selectorState.focusProductSelections,
                focusMarketSelections: selectionsToSelState
                    ? selectionsToSelState.focusMarketSelections
                    : selectorState.focusMarketSelections,
                marketPerformanceSelections: selectionsToSelState
                    ? selectionsToSelState.marketPerformanceSelections
                    : selectorState.marketPerformanceSelections,
                marketsToWatchSelection: selectionsToSelState
                    ? selectionsToSelState.marketsToWatchSelection
                    : selectorState.marketsToWatchSelection,
                marketSelectionsSummary: selectionsToSelState
                    ? selectionsToSelState.marketSelectionsSummary
                    : selectorState.marketSelectionsSummary,
                timePeriodSelections: selectionsToSelState
                    ? selectionsToSelState.timePeriodSelections
                    : selectorState.timePeriodSelections,
                dimension: selectionsToSelState ? selectionsToSelState.dimension : selectorState.dimension,
                characteristicSelections: selectionsToSelState
                    ? selectionsToSelState.characteristicSelections
                    : selectorState.characteristicSelections,
                characteristicOptions: selectionsToSelState
                    ? selectionsToSelState.characteristicOptions
                    : selectorState.characteristicOptions,
                comparisonMarketSelections: selectionsToSelState
                    ? selectionsToSelState.comparisonMarketSelections
                    : selectorState.comparisonMarketSelections,
                focusMarketsSelectedData: selectionsToSelState
                    ? selectionsToSelState.focusMarketsSelectedData
                    : selectorState.focusMarketsSelectedData,
                focusMarketsPerformanceSelectedData: selectionsToSelState
                    ? selectionsToSelState.focusMarketsPerformanceSelectedData
                    : selectorState.focusMarketsPerformanceSelectedData,
                comparisonMarketsSelectedData: selectionsToSelState
                    ? selectionsToSelState.comparisonMarketsSelectedData
                    : selectorState?.comparisonMarketsSelectedData,
                panelTimePeriodSelections: selectionsToSelState
                    ? selectionsToSelState.panelTimePeriodSelections
                    : selectorState.panelTimePeriodSelections,
                shopperSegmentSelections: selectionsToSelState
                    ? selectionsToSelState.shopperSegmentSelections :
                    selectorState.shopperSegmentSelections,
                ppgId: getPPGId(selectionsToSelState) || undefined,
                reportCategory: from,
                saveDraftEnabled: selectorState.saveDraftEnabled || false,
                activeCollapsePanel: selectionsToSelState.activeCollapsePanel
                    ? selectionsToSelState.activeCollapsePanel
                    : selectorState.activeCollapsePanel || [],
                focusMarket: selectorState.focusMarket || '',
                timePeriodWeeks: selectorState.timePeriodWeeks || 0,
                timePeriodEndDate: selectorState.timePeriodEndDate || '',
                selectorsStatus: selectionsToSelState
                    ? selectionsToSelState.selectorsStatus
                    : selectorState.selectorsStatus || [],
                refreshMarkets: selectionsToSelState
                    ? selectionsToSelState.refreshMarkets
                    : selectorState.refreshMarkets || false,
                refreshCharacteristicFeature: selectionsToSelState
                    ? selectionsToSelState.refreshCharacteristicFeature
                    : selectorState.refreshCharacteristicFeature,
                selectedMarketItemLvlData: selectionsToSelState
                    ? selectionsToSelState.selectedMarketItemLvlData
                    : selectorState.selectedMarketItemLvlData,
                selectedMarketPerformanceItemLvlData: selectionsToSelState
                    ? selectionsToSelState.selectedMarketPerformanceItemLvlData
                    : selectorState.selectedMarketPerformanceItemLvlData,
                marketOption: selectionsToSelState
                    ? selectionsToSelState.marketOption
                    : selectorState.marketOption,
            };
            const selectedReportParams = {
                reportType: userData.reportType,
                productSku: userData.productSku,
                reportParams: selectorData,
            };

            let selectionsValues = {
                from: 'subscription',
                userData: selectedReportParams,
                selectorData: userData,
            };

            setDataSpecificationValue(selectionsValues, stepNo);

            setLoading(false);
        };

        const setSelectorsData = async (dataObj) => {
            await setSelectorState(dataObj);
        };

        const setCharacteristicSelDataValue = (data) => {
            data = {
                options: data[0]?.length > 0 ? data[0] : [],
                characteristicValue: '',
            };
            setCharacteristicSelData(data);
        };

        const renderSelector = (stepNo, totalSelectorCount, selector, userData) => {
            const saveBtnType = stepNo + 1 === totalSelectorCount ? 'Generate' : 'Continue'; // show Generate only for last panel, as stepNo+1 == length of selectors, thus last one

            switch (selector.selectorType) {
                case 'product':
                    return (
                        <ProductSelector
                            productType={'story'}
                            key={stepNo + selector}
                            selectorState={selectorState}
                            selector={selector}
                            saveBtnType={saveBtnType}
                            reportSelectors={storySelectors}
                            setSelectorsData={(dataObj) => setSelectorsData(dataObj)}
                            stepNo={stepNo}
                            showAll={false} // Need to check for story config
                            actionConfig={from !== 'ad-hoc' ? actionConfig : null}
                            generateReport={(selectionsToSelState) => generateStory(selectionsToSelState, stepNo)}
                            sku={userData.productSku ?? userData.reportSku}
                            ppgReport={selector?.subSelectors?.ppgReport ?? false}
                            runType={"subscription"}
                            setCharacteristicSelDataValue={(data) => setCharacteristicSelDataValue(data)}
                            fromBack={fromBack}
                            objectivesHasChanged={objectivesHasChanged}
                            marketGroup={marketGroup}
                            selectorConfigs={selectorConfigs}
                            selectedObjectives={selectedObjectives}
                            storyType={userData.storyType}
                        />
                    );
                case 'shopper_segment':
                    return (
                        <SegmentSelector
                            key={stepNo + selector.selectorLabelName}
                            selectorState={selectorState}
                            setSelectorsData={setSelectorsData}
                            stepNo={stepNo}
                            selector={selector}
                            sku={userData.reportSku ?? userData.productSku}
                        />
                    );
                case 'market':
                    return (
                        <MarketSelector
                            reportType={from === 'ad-hoc' ? 'adhoc' : 'subscription'}
                            key={stepNo + selector}
                            userPrefData={userPrefData}
                            selectorState={selectorState}
                            selector={selector}
                            productSku={userData.productSku} // confirm once
                            saveBtnType={saveBtnType}
                            reportSelectors={storySelectors}
                            setReportSelectors={setStorySelectors}
                            setSelectorsData={(dataObj) => setSelectorsData(dataObj)}
                            stepNo={stepNo}
                            actionConfig={from !== 'ad-hoc' ? actionConfig : null}
                            generateReport={(selectionsToSelState) => generateStory(selectionsToSelState, stepNo)}
                            refreshMarkets={selectorState.refreshMarkets}
                            productType={'story'}
                            marketType={'focusMarket'}
                            fromBack={fromBack}
                            objectivesHasChanged={objectivesHasChanged}
                            selectorConfigs={selectorConfigs}
                            setNextButtonStatus={(flag) => setNextButtonStatus(flag)}
                        />
                    );
                case 'timePeriod':
                    return (
                        <TimePeriod
                            key={stepNo + selector}
                            userPrefData={userPrefData}
                            selectorState={selectorState}
                            selector={selector}
                            saveBtnType={saveBtnType}
                            reportSelectors={storySelectors}
                            setSelectorsData={(dataObj) => setSelectorsData(dataObj)}
                            stepNo={stepNo}
                            productType={'story'}
                            timePeriodType={'timePeriod'}
                            generateReport={(selectionsToSelState) => generateStory(selectionsToSelState, stepNo)}
                            fromBack={fromBack}
                        />
                    );
                case 'characteristicFeature':
                    return (
                        <CharacteristicSelector
                            key={stepNo + selector}
                            selectorState={selectorState}
                            characteristicSelData={characteristicSelData}
                            selector={selector}
                            setSelectorsData={(dataObj) => setSelectorsData(dataObj)} // to receive user selections
                            stepNo={stepNo}
                            saveBtnType={saveBtnType}
                            generateStory={(selectionsToSelState) => generateStory(selectionsToSelState, stepNo)}
                            refreshCharacteristicFeature={selectorState.refreshCharacteristicFeature}
                            productType={'story'}
                            fromBack={fromBack}
                            objectivesHasChanged={objectivesHasChanged}
                        />
                    );
                case 'comparisonMarket':
                    return (
                        <MarketSelector
                            reportType={from === 'ad-hoc' ? 'adhoc' : 'subscription'}
                            key={stepNo + selector}
                            userPrefData={userPrefData}
                            selectorState={selectorState}
                            selector={selector}
                            saveBtnType={saveBtnType}
                            reportSelectors={storySelectors}
                            setReportSelectors={setStorySelectors}
                            setSelectorsData={(dataObj) => setSelectorsData(dataObj)}
                            stepNo={stepNo}
                            actionConfig={from !== 'ad-hoc' ? actionConfig : null}
                            generateReport={(selectionsToSelState) => generateStory(selectionsToSelState, stepNo)}
                            refreshMarkets={selectorState.refreshMarkets}
                            refreshComparisonMarkets={selectorState.refreshComparisonMarkets}
                            productType={'story'}
                            marketType={'comparisonMarket'}
                            fromBack={fromBack}
                            objectivesHasChanged={objectivesHasChanged}
                        />
                    );
                case 'panelTimePeriod':
                    return (
                        <TimePeriod
                            key={stepNo + selector}
                            userPrefData={userPrefData}
                            selectorState={selectorState}
                            selector={selector}
                            saveBtnType={saveBtnType}
                            reportSelectors={storySelectors}
                            setSelectorsData={(dataObj) => setSelectorsData(dataObj)}
                            stepNo={stepNo}
                            productType={'story'}
                            timePeriodType={'panelTimePeriod'}
                            generateReport={(selectionsToSelState) => generateStory(selectionsToSelState, stepNo)}
                            fromBack={fromBack}
                            objectivesHasChanged={objectivesHasChanged}
                        />
                    );
                case 'dimension':
                    return (
                        <SelectDimension
                            key={stepNo + selector}
                            selectorState={selectorState}
                            selector={selector}
                            saveBtnType={saveBtnType}
                            setSelectorsData={(dataObj) => setSelectorsData(dataObj)}
                            stepNo={stepNo}
                            sku={userData.productSku}
                            generateReport={(selectionsToSelState) => generateStory(selectionsToSelState, stepNo)}
                            reportSelectors={storySelectors}
                            setReportSelectors={setStorySelectors}
                            productType={'story'}
                            fromBack={fromBack}
                        />
                    );
                default:
                    break;
            }
        };

        return (
            <>
                <div className="story-run-selector___container">
                    <ByzzerMask loading={loading} />
                    {!fromRunAgain && (
                        <span>
                            <header className="my-story my-story__step-heading">
                                <b>Step 3 : Data Specifications</b>
                            </header>
                            <p className="my-story my-story__step-details">
                                Identify the data that will be featured in your story
                            </p>
                        </span>
                    )}
                </div>

                <div className="story-selector__container">
                    <div className="story-selector__accordion-section">
                        <Collapse
                            activeKey={selectorState.activeCollapsePanel}
                            onChange={(index) => collapseClick(index)}
                            expandIcon={({ isActive }) => renderCollapseExpandIcon(isActive)}
                            accordion
                        >
                            {storySelectors &&
                                storySelectors.map((selector, stepNo) => {
                                    return (
                                        <Panel
                                            header={selector.selectorLabelName}
                                            key={stepNo}
                                            extra={statusLabels(selectorState.selectorsStatus[stepNo]?.status)}
                                            disabled={
                                                stepNo !== 0 &&
                                                selectorState.selectorsStatus[stepNo - 1]?.status === selectorStates.pending
                                            }
                                            forceRender={true}
                                        >
                                            {renderSelector(stepNo, storySelectors.length, selector, userData)}
                                        </Panel>
                                    );
                                })}
                        </Collapse>
                    </div>
                </div>
            </>
        );
    },
    (prevProps, nextProps) => {
        if (prevProps.props.userData === nextProps.props.userData) {
            return true; // props are equal
        }
        return false; // props are not equal -> update the component
    }
);

export default memo(DataSpecification);