import React from 'react';
import { create as createModal } from 'react-modal-promise';
import ByzzerModal2 from '@/components/modals/ByzzerModal2';
import './AggregationMoreDetailModal.scss';

export function AggregationMoreDetailModal({ onResolve, ...props }) {
    function onCloseClick() {
        onResolve({ function: 'close' });
    }

    return (
        <ByzzerModal2 className={'aggregation-modal'}>
            <ByzzerModal2.Header onClose={onCloseClick}></ByzzerModal2.Header>
            <ByzzerModal2.Content className={'aggregation-modal__subclass-content'}>
                <div>
                    This report is at the brand level, with the option to view brands at different aggregations.
                    Whichever hierarchy level you choose will split the brands at that level.
                </div>

                <div>
                    <strong>Examples:</strong>
                </div>

                <div>
                    Choosing <strong>Total Store</strong> will aggregate the brand-level data across the full scope of
                    your report, so each brand will have 1 total row of data in the report.
                </div>
                <div className="detail-table">
                    <table className="">
                        <tbody>
                            <tr>
                                <th>Brand</th>
                                <th>Total Store</th>
                                <th>$ Sales</th>
                            </tr>
                            <tr>
                                <td>Brand A</td>
                                <td>Total Store</td>
                                <td>$550,000</td>
                            </tr>
                            <tr>
                                <td>Brand B</td>
                                <td>Total Store</td>
                                <td>$220,000</td>
                            </tr>
                            <tr>
                                <td>Brand C</td>
                                <td>Total Store</td>
                                <td>$75,000</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    Choosing <strong>Category</strong> will split the brands at the <strong>category</strong> level, so
                    each brand will have a row per
                    <strong>category</strong> it sells in. If the <strong>categories</strong> in your report are very
                    different so brand performance is unique by <strong>category</strong>, this may be the right choice
                    for your report. If your report scope only includes 1 category, this would be the same as choosing
                    Total Store or another higher level.
                </div>
                <div>
                    In the same example as above, Brand A only sells in 1 category, Brand B sells in 3, and Brand C
                    sells in 2. Choosing <strong>Category</strong> will result in 6 rows for the 3 brands.
                </div>
                <div className="detail-table">
                    <table className="">
                        <tbody>
                            <tr>
                                <th>Brand</th>
                                <th>Category</th>
                                <th>$ Sales</th>
                            </tr>
                            <tr>
                                <td>Brand A</td>
                                <td>CEREAL</td>
                                <td>$550,000</td>
                            </tr>
                            <tr>
                                <td>Brand B</td>
                                <td>CEREAL</td>
                                <td>$120,000</td>
                            </tr>
                            <tr>
                                <td>Brand B</td>
                                <td>CEREAL AND GRANOLA BARS</td>
                                <td>$80,000</td>
                            </tr>
                            <tr>
                                <td>Brand B</td>
                                <td>HOT CEREAL</td>
                                <td>$20,000</td>
                            </tr>
                            <tr>
                                <td>Brand C</td>
                                <td>CEREAL</td>
                                <td>$60,000</td>
                            </tr>
                            <tr>
                                <td>Brand C</td>
                                <td>CEREAL AND GRANOLA BARS</td>
                                <td>$15,000</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ByzzerModal2.Content>
        </ByzzerModal2>
    );
}

export const openAggregationMoreDetail = createModal(AggregationMoreDetailModal);
