import React from 'react';
import './ByzzerCard.scss';


export const ByzzerCard = ({title, className, subtitle,subtitleHighlight,...props}) => {
    return(
            <div className={`byzzer-card ${className}`}>
                <div className='byzzer-card__title'>
                    {title}
                </div>
                <div className='byzzer-card__subtitle'>
                    {subtitle}<span className='byzzer-card__subtitle-highlight'>{subtitleHighlight}</span>
                </div>
                <div className='byzzer-card__content'>
                    {props.children}
                </div>
            </div>
    )
}
export default ByzzerCard;
