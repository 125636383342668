import './StackedReportFilters.scss';
import React, { useEffect, useState } from 'react';
import { ByzzerSwitch } from '@/components/form';
import classnames from 'classnames';
import { orderBy } from 'lodash';
import { getAllReports } from '@/services/product.service';
import { ExpandableFilter } from '@/components/ExpandableFilter/ExpandableFilter';
import { Spinner } from '@/components/Spinner/Spinner';
import { useApp } from '@/contexts/UserContext';
import FavoriteIcon from '@/components/icons/FavoriteIcon';

const baseClassName = 'stacked-report-filters';

type FilterSelection = {
    matchType: 'all' | 'any';
    ids: string[];
};

type FilterOptions = {
    id: number;
    code: string;
    display: string;
    matchOn: 'all' | 'any';
    allowMultiAssignment: boolean;
    appliesTo: string[];
    active: boolean;
    values: FilterOptionValues[];
};

type FilterOptionValues = {
    id: number;
    code: string;
    display: string;
    value: string;
};

type ReportFilters = {
    reportHistory: any[];
    favoriteSkus: any[];
    onFilter: any;
    onChange: any;
    from: string;
    className: any;
    changeReport: boolean;
};

export function StackedReportFilters({ onFilter, favoriteSkus = [], className }: ReportFilters) {
    const [selectedFilterGroups, setSelectedFilterGroups] = useState<FilterSelection[]>([]);
    const [filterFavorites, setFilterFavorites] = useState<boolean>(false);
    const [filterOptions, setFilterOptions] = useState<FilterOptions[]>([]);
    const { reportFilterOptions } = useApp();

    useEffect(() => {
        loadFilterOptions();
    }, []);

    const loadFilterOptions = () => {
        if (reportFilterOptions) {
            let filtersObjects: FilterOptions[] = reportFilterOptions.map((filter) => ({
                ...filter,
                values: filter.values.map(({ id, display }) => ({ display, value: id })),
            }))
            filtersObjects = orderBy(filtersObjects, ['display'], "asc") // Sorting report filters alphabetically
             setFilterOptions(filtersObjects);
        }
    };

    useEffect(() => {
        let filteredReports = getAllReports();
        filteredReports = filterByFilterOptions(filteredReports);
        filteredReports = filterByFavorites(filteredReports);
        const skus = filteredReports.map(({ sku }) => sku);
        onFilter?.(skus);
    }, [selectedFilterGroups, filterFavorites]);

    function filterByFavorites(reports) {
        if (filterFavorites) {
            return reports.filter(({ sku }) => favoriteSkus.includes(sku));
        }
        return reports;
    }

    function filterByFilterOptions(reports) {
        if (selectedFilterGroups.length) {
            const filteredData = reports.filter(({ filterValueIds }) => {
                return selectedFilterGroups
                    .filter((filter) => filter?.ids.length) //#Added this line to check the array of selected filter `ids` contains value or not
                    .every((filter) => {
                        if (filter.matchType === 'all') {
                            return filter.ids.every((id) => filterValueIds.includes(id));
                        } else if (filter.matchType === 'any') {
                            return filter.ids.some((id) => filterValueIds.includes(id));
                        }
                    });
            });
            return filteredData;
        }
        return reports;
    }

    const handleFavorites = () => {
        setFilterFavorites(!filterFavorites);
    };

    const handleFilterChange = (index: number, value: string[]) => {
        const filterChange = [...selectedFilterGroups];
        filterChange[index] = { matchType: filterOptions[index].matchOn, ids: value };
        setSelectedFilterGroups(filterChange);
    };

    return (
        <div className={classnames(baseClassName, className)}>
            {!filterOptions.length && (
                <div className={baseClassName}>
                    <Spinner />
                </div>
            )}
            <div className={baseClassName + '__favorites'}>
                <FavoriteIcon
                    className={baseClassName + '__favorites--icon'}
                    trackClick={`Filter by favorites`}
                    selected={filterFavorites}
                    onClick={handleFavorites}
                />
                <h1 className={baseClassName + '__favorites--title'}>Filter by favorites</h1>
            </div>
            {filterOptions.map((filter, index) => {
                const filterValue = selectedFilterGroups[`${index}`]?.ids || [];
                return (
                    <ExpandableFilter
                        title={filter.display}
                        onChange={({ value }) => handleFilterChange(index, value)}
                        value={filterValue}
                        key={`report-filter-${index}`}
                        options={filter.values}
                    />
                );
            })}
        </div>
    );
}

export default StackedReportFilters;
