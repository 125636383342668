import './ReportRunUsage.scss';
import React, {useEffect, useState} from 'react';
import {ByzzerInput, ByzzerModal, ByzzerPercentageBar, ByzzerSwitch} from '@/components/form';
import exclamationIcon from '@/images/icons/yellow-exclamation.svg';
import {confirm} from '@/components/form/ByzzerModal';
import {toRunsText} from '@/utils';
import classnames from 'classnames';
import {TrackClick} from "@/analytics";
import {TeamCreditUsage} from "./types";
import {ByzzerButton} from "@byzzer/ui-components";

const ReportRunUsage = ({ readOnly, ...props }) => {
    const [busy, setBusy] = useState<boolean>(false);
    const [allocateUsageFromTeam, setAllocateUsageFromTeam] = useState<boolean>(false);
    const [showAllocationModal, setShowAllocationModal] = useState<boolean>(false);
    const [showUnallocatedInfoModal, setShowUnallocatedInfoModal] = useState<boolean>(false);
    const [teamUsages, setTeamUsages] = useState<TeamCreditUsage[]>([]);
    const [remainingUnallocated, setRemainingUnallocated] = useState(props.remainingRuns);
    const [remainingAllocated, setRemainingAllocated] = useState(0);
    const className = classnames('subscription-report-runs', {
        'subscription-report-runs--readonly': readOnly,
    });

    useEffect(() => {
        setAllocateUsageFromTeam(props.allocateTeamUsage);
    }, [props.allocateTeamUsage]);

    useEffect(() => {
        setTeamUsages(props.teamUsages);
    }, [props.teamUsages]);

    let calculateRemainingUnallocated = () => {
        let totalUnallocated = props.remainingRuns;
        let totalAllocated = 0;
        teamUsages.forEach((teamUsage) => {
            totalUnallocated = totalUnallocated - teamUsage.remainingAllocated;
            totalAllocated += teamUsage.remainingAllocated;
        });
        setRemainingUnallocated(totalUnallocated);
        setRemainingAllocated(totalAllocated);
    };

    let setTeamRemainingAllocation = () => {
        teamUsages.forEach((teamUsage, index) => {
            teamUsages[index].remainingAllocated = teamUsage.totalAllocated - teamUsage.used;
        });
        setTeamUsages(teamUsages);
    };

    let capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const toggleAllocateTeamUsage = async () => {
        if (readOnly) {
            return;
        }

        if (!allocateUsageFromTeam) {
            if (
                !(await confirm({
                    title: 'Allocate Runs by Team?',
                    content: (
                        <div className="byzzer-allocation-warning">
                            By allocating runs by team each member will use runs for their associated team. Any
                            administrator will be able to manage the report runs for each team. Please make sure to
                            assign members to teams then allocate runs to those teams otherwise members will not be able
                            to run reports.
                            <br />
                            <br />
                            Do you still want to proceed with allocating runs by team ?
                        </div>
                    ),
                    yesLabel: 'Yes',
                    noLabel: 'No',
                }))
            ) {
                return;
            }
        }
        let newSetting = !allocateUsageFromTeam;
        props.setAllocateTeamUsage(newSetting);
        setAllocateUsageFromTeam(newSetting);
    };

    const onAllocateRuns = () => {
        if (readOnly) {
            return;
        }
        setTeamUsages(props.teamUsages);
        setShowAllocationModal(true);
        setTeamRemainingAllocation();
        calculateRemainingUnallocated();
    };

    const showUnallocatedInfo = () => {
        setShowUnallocatedInfoModal(true);
    };

    const closeAllocationModal = () => {
        setShowAllocationModal(false);
    };

    const onSaveAllocation = async () => {
        try {
            setBusy(true);
            await props.updateAllocation(teamUsages);
            setShowAllocationModal(false);
        } finally {
            setBusy(false);
        }
    };

    const handleAllocationChange = (e, index) => {
        if (readOnly) {
            return;
        }
        let data = teamUsages;
        data[index].remainingAllocated = +e.target.value;
        setTeamUsages(data);
        calculateRemainingUnallocated();
    };

    const checkAllocationChange = (e) => {
        if (isNaN(e.key.trim()) || e.key === ' ') {
            e.preventDefault();
        }
    };

    return (
        <div className={className}>
            <div className="subscription-report-runs__data-heading">
                <div className="subscription-report-runs__data-heading-title">
                    {capitalize(props.reportType)} Report Run Usage
                </div>
            </div>
            <div className="subscription-report-runs__data-usage-count-summary">
                <div className="subscription-report-runs__data-usage-count-summary-group1-container">
                    {props.totalRuns === Infinity ? (
                        <div className="subscription-report-runs__data-usage-count-summary-group1-description">
                            Your subscription includes {toRunsText(props.totalRuns)} {props.reportType} report runs, and
                            your team has used {toRunsText(props.usedRuns)} {props.reportType} report runs.
                        </div>
                    ) : (
                        <div className="subscription-report-runs__data-usage-count-summary-group1-description">
                            Your subscription includes {toRunsText(props.totalRuns)} {props.reportType} report runs, and
                            your team has used {toRunsText(props.usedRuns)} {props.reportType} report runs. There are{' '}
                            <span className="subscription-report-runs__data-contents-info-strong">
                                {toRunsText(props.remainingRuns)} {props.reportType} report runs{' '}
                            </span>{' '}
                            left in your subscription.
                        </div>
                    )}
                    <div className="subscription-report-runs__data-usage-count-summary-company">
                        <div className="subscription-report-runs__data-usage-count-summary-company-text">
                            Company Account:
                        </div>
                        <div className="subscription-report-runs__data-usage-count-summary-company-bar">
                            <ByzzerPercentageBar
                                total={Number(props.totalRuns)}
                                totalAllocation={Number(props.totalRuns)}
                                width={350}
                                used={(Number(props.totalRuns) - Number(props.remainingRuns)) ?? 0}
                                usedRuns={Number(props.usedRuns)}
                            />
                        </div>
                    </div>
                </div>
                <div className="subscription-report-runs__data-usage-count-summary-group2-container">
                    <div
                        className={
                            'subscription-report-runs__data-usage-count-summary-group2-toggle report-run__toggle' +
                            (props.totalRuns === Infinity ? '_unlimited' : '')
                        }
                    >
                        {props.totalRuns !== Infinity && (
                            <div className="subscription-report-runs__data-usage-count-summary-group2-toggle-text-left report-run__toggle-text-left">
                                {capitalize(props.reportType)} Report Runs are not restricted by teams
                            </div>
                        )}
                        {props.totalRuns === Infinity && (
                            <div className="report-run__toggle-text_unlimited">
                                You have unlimited report runs and do not need to allocate runs by team
                            </div>
                        )}
                        <ByzzerSwitch
                            key={props.reportType}
                            name={'byzzer-team-allocation-toggle'}
                            onChange={toggleAllocateTeamUsage}
                            value={allocateUsageFromTeam}
                            disabled={props.totalRuns === Infinity || readOnly}
                        />
                        {props.totalRuns !== Infinity && (
                            <div className="subscription-report-runs__data-usage-count-summary-group2-toggle-text-right report-run__toggle-text-right">
                                Allocate {capitalize(props.reportType)} Report Runs by teams
                            </div>
                        )}
                    </div>
                    {!readOnly && props.totalRuns !== Infinity && (
                        <div className="subscription-report-runs__data-usage-count-summary-group2-button">
                            <TrackClick name={`Sub Report Runs Allocate ${capitalize(props.reportType)} Runs clicked`}>
                                <ByzzerButton
                                    label={'Allocate Runs'}
                                    onClick={onAllocateRuns}
                                    disabled={!allocateUsageFromTeam}
                                />
                            </TrackClick>
                        </div>
                    )}
                </div>
            </div>
            <div className="line"></div>
            {allocateUsageFromTeam && (
                <div className="subscription-report-runs__data-usage-count-team-summary">
                    <table className="subscription-report-runs__data-usage-count-summary-table">
                        <tbody>
                            <tr>
                                <th>Usage By Team:</th>
                                <th className="text-center">Total runs allocated to each team</th>
                            </tr>
                            {teamUsages.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div className="subscription-report-runs__data-usage-count-summary-company-team">
                                                <div className="subscription-report-runs__data-usage-count-summary-company">
                                                    <div className="subscription-report-runs__data-usage-count-summary-company-text">
                                                        {item.name}
                                                    </div>

                                                    <div className="subscription-report-runs__data-usage-count-summary-company-bar">
                                                        <ByzzerPercentageBar
                                                            total={Number(props.totalRuns)}
                                                            totalAllocation={Number(item.totalAllocated)}
                                                            used={Number(item.used) ?? 0}
                                                            usage={true}
                                                        />

                                                        {item.totalAllocated > 0 && (
                                                            <div className="subscription-report-runs__data-contents-text">
                                                                {item.name} has used {item.used} of their{' '}
                                                                {item.totalAllocated} {props.reportType} report runs
                                                                with {item.totalAllocated - item.used} remaining.
                                                            </div>
                                                        )}

                                                        {item.totalAllocated === 0 && (
                                                            <div className="subscription-report-runs__data-contents-text">
                                                                {item.name} has no {props.reportType} report runs
                                                                allocated.
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="subscription-report-runs__data-usage-count-summary-company-percent-wrapper">
                                                <div className="subscription-report-runs__data-usage-count-summary-company-percent">
                                                    {props.totalRuns > 0
                                                        ? Math.round((item.totalAllocated / props.remainingRuns) * 100)
                                                        : 0}{' '}
                                                    %
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
            {allocateUsageFromTeam && <div className="line"></div>}

            <ByzzerModal
                show={showAllocationModal}
                heading={'Allocate ' + capitalize(props.reportType) + ' Report Runs'}
                onClose={() => closeAllocationModal()}
                size={'large'}
                type={'info'}
                headerType={'none'}
            >
                <div className="subscription-report-runs__data-contents-info">
                    Identify the number of report runs each team will have access to run from the account subscription.
                    Members who are not assigned to any team will not have access to any report runs.
                    <br />
                </div>
                <div className="subscription-report-runs__data-contents-info">
                    Note: Viewers won't be able to run reports even if they are on a team. If you want a Viewer to run
                    reports, update their member type to User. All members can view all reports, even if run by another
                    team.
                    <br />
                    <br />
                </div>
                <div className="subscription-report-runs__data-contents-info-strong">
                    Your subscription has {props.remainingRuns} {props.reportType} report runs remaining.
                </div>
                <div className="subscription-report-runs__data-contents">
                    <table className="subscription-report-runs__data-contents-table">
                        <tbody>
                            <tr>
                                <th></th>
                                <th>Remaining Report Run Allocation</th>
                                <th>Report Runs Already Used</th>
                                <th>Total Report Runs Allocated</th>
                            </tr>
                            {teamUsages.length > 0 &&
                                teamUsages.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                            <td>
                                                {/* @ts-ignore */}
                                                <ByzzerInput
                                                    onKeyPress={(target) => checkAllocationChange(target)}
                                                    onChange={(target) => {
                                                        handleAllocationChange(target, index);
                                                    }}
                                                    value={item.remainingAllocated}
                                                ></ByzzerInput>
                                            </td>
                                            <td>{item.used}</td>
                                            <td>{item.totalAllocated}</td>
                                        </tr>
                                    );
                                })}
                            <tr className="subscription-report-runs__data-contents-table-fotter">
                                <td>Not allocated to a team</td>
                                <td>
                                    {remainingUnallocated > 0 && (
                                        <span
                                            style={{ paddingRight: '10px' }}
                                            onClick={() => {
                                                showUnallocatedInfo();
                                            }}
                                        >
                                            <img alt="pointer" style={{ cursor: 'pointer' }} src={exclamationIcon} />
                                        </span>
                                    )}

                                    {remainingUnallocated}
                                </td>
                                <td></td>
                                <td>{remainingAllocated}</td>
                            </tr>
                        </tbody>
                    </table>
                    <TrackClick name={`Sub Report Runs Allocate ${capitalize(props.reportType)} Runs Save clicked`}>
                        <ByzzerButton
                            className="subscription-report-runs__data-usage-count-summary-group2-button"
                            label={'Save'}
                            // @ts-ignore
                            busy={busy}
                            onClick={() => {
                                onSaveAllocation();
                            }}
                            disabled={remainingUnallocated < 0}
                        />
                    </TrackClick>
                </div>
            </ByzzerModal>

            <ByzzerModal
                show={showUnallocatedInfoModal}
                heading={"Don't lose these runs!"}
                onClose={() => {
                    setShowUnallocatedInfoModal(false);
                }}
                size={'extra-small'}
                type={'info'}
                headerType={'none'}
            >
                <div className="subscription-report-runs__data-contents-info">
                    These report runs are part of your subscription, but can't be used unless they're assigned to a
                    team. You can keep them unassigned for now to preserve them, but don't forget about them. Make sure
                    you assign these to a team, or disable "Allocate by Team" before your subscription is up.
                </div>
            </ByzzerModal>
        </div>
    );
};

export default ReportRunUsage;
