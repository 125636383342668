import './Opportunities.scss';

import { ByzzerBrandSearch } from '@/components/ByzzerBrandSearch';
import { ByzzerCategorySelect } from '@/components/ByzzerCategorySelect';
import { ByzzerMenu } from '@/components/ByzzerMenu';
import ByzzerPPGSelect from '@/components/ByzzerPPGSelect/ByzzerPPGSelect';
import { ByzzerTable } from '@/components/ByzzerTable';
import { LimitedLabel } from '@/components/LimitedLabel';
import ByzzerSearchableSelect from '@/components/form/ByzzerSearchableSelect';
import { brandScoreMessages } from '@/constants/scorecard.constants';
import { useTenantApi } from '@/hooks';
import { OpportunityRunConfig,OpportunityTypeMenuItems} from '@/types/ScoreCard';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Tooltip } from 'antd';
import classnames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useBetterNavigate } from '@/utils';
import { openErrorModal } from '@/components/form';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { useUser } from '@/contexts/UserContext';

export type OpportunitiesProps = {
    filters: OpportunityRunConfig;
    onFiltersChange?: (filter: OpportunityRunConfig[]) => void;
    hasScorecardAccess?: boolean;
    className?: string;
    activeTab?: string;
};

export function Opportunities({
    filters,
    onFiltersChange,
    hasScorecardAccess,
    className,
    activeTab,
    ...props 
}: OpportunitiesProps) {
    const baseClassName = 'my-scorecard-opportunities';
    const {defaultRunConfig} = useUser();
    const [internalValue, setInternalValue] = useState<OpportunityRunConfig>(filters);
    const [categoriesToCheckForIntersect, setCategoriesToCheckForIntersect] = useState<string[]>([]);
    const { getCategoriesByBrands } = useTenantApi();
    const itemRef = useRef<HTMLDivElement>(null);
    const [opportunityType, setOpportunityType] = useState(
        OpportunityTypeMenuItems.filter((item) => item.key === activeTab)[0].name
    );
    const [recommendations, setRecommendations] = useState<any>();
    const { getBrandScoreOpportunities } = useTenantApi();
    const gridRef = useRef<AgGridReact>(null);
    const interValueRef = useRef(internalValue);
    const navigate = useBetterNavigate();
    const [loading, setLoading] = useState(false);
    const MAX_RECOMMENDATIONS_COUNT = 10;

    const defaultColDef = useMemo(
        () => ({
            filter: true, // make every column use 'text' filter by default
            sortable: true,
            floatingFilter: true, // enable floating filters by default
            autoHeight: false,
            wrapText: false,
            suppressMenu: true,
        }),
        []
    );

    useEffect(() => {        
        if (Array.isArray(filters.markets)) {
            const market = filters?.markets?.[0] || defaultRunConfig?.markets?.[0]?.name ;
            const updatedFilters =  { ...filters, markets: market?.length ? [market as string] : [], ppgId: -1 };
            setInternalValue(updatedFilters);
        }else{
            setInternalValue(filters);
        }
        
    }, [filters]);

    useEffect(() => {
        interValueRef.current = internalValue;
    }, [internalValue]);

    useEffect(() => {
        (async () => {
            const { brands } = internalValue;
            const categories = await Promise.all([brands?.length ? await getCategoriesByBrands(brands) : []]);
            setCategoriesToCheckForIntersect(categories.flat());
        })();
    }, [internalValue.brands]);

    useEffect(() => {
        if (
            Boolean(internalValue.brands?.length && internalValue.categories?.length && internalValue.markets?.length)
        ) {
            loadOpportunities(OpportunityTypeMenuItems.filter(item => item.name === opportunityType)[0].key)
        } else {
            // alert('please select all the mandatory values') //TODO:implement alert
            setRecommendations([]);
        }
    }, [internalValue]);

    const handleFilterChange = (event) => {
        const { name, value } = event;

        if (name === 'categories') {
            setInternalValue({ ...internalValue, markets: undefined,  });
        }

        setInternalValue((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleMarketSearchChange = async (market) => {
        setInternalValue({ ...internalValue, markets:market.length ? [market] : [], ppgId:internalValue?.ppgId ?? -1 });
    };

    async function loadOpportunities(type: string) {
        try {
            let allSelection;
            allSelection = {
                brands: internalValue.brands,
                categories: internalValue.categories,
                markets: internalValue.markets?.length ? [internalValue?.markets?.[0]] : [],
                ppgIds: ['assortment', 'product'].includes(type) ? undefined : internalValue?.ppgId?[internalValue.ppgId]:undefined,               
            };

            if (Boolean(internalValue?.markets?.length)) {
                setLoading(true);
                const recommendations = await getBrandScoreOpportunities(allSelection, type);

                setRecommendations(
                    recommendations.slice(0, MAX_RECOMMENDATIONS_COUNT).map((opportunities, index) => ({ value: opportunities, key: index + 1, opptype: type }))
                );
            }
        } catch (error: any) {
            openErrorModal({
                title: `Something Unexpected Happened`,
                errorId: error.id
            });            
        } finally {
            setLoading(false);
        }
    }

    //internalValue doesn't give the latest values,so am using interValueRef below
    function loadReport(item: any) {
        navigate(`/scorecard/${item.data.opptype}_action_plan`, {
            state: {
                focusBrands: interValueRef.current.brands,
                focusCategories: interValueRef.current.categories,
                markets: interValueRef.current.markets,
                activeLever: item.data.opptype,
                currentLeverSku: `${item.data.opptype.toLowerCase()}_action_plan`,
                ppgId: ['assortment', 'product'].includes(item.data.opptype) ? undefined : interValueRef.current.ppgId,
            },
        });
    }

    const [columnDefs] = useState<ColDef[]>([
        {
            headerName: '',
            field: 'value',
            flex: 1,
            wrapText: true,
            autoHeight: true,
            cellRenderer: ({value}) => <span className={`${baseClassName}__cell-content`}>{value}</span>,
        },
        {
            filter: false,
            sortable: false,
            floatingFilter: false,
            cellRenderer: (item) => {
                return (
                    <a className={`${baseClassName}__actions`} onClick={() => loadReport(item)}>
                        &#8594;
                    </a>
                );
            },
            width: 65
        },
    ]);

    function runReport(str) {
        setOpportunityType(str);
        loadOpportunities(OpportunityTypeMenuItems.filter(item => item.name === str)[0].key);
    }

    return (
        <div className={classnames(`${baseClassName}`)}>
            <header className={classnames(`${baseClassName}__header`)}>
                <span>{brandScoreMessages.opportunity.headerMessage}​</span>
            </header>

            <main className={classnames(`${baseClassName}__selectors`)}>
                <ByzzerBrandSearch
                    className={`${baseClassName}__selectors--selector`}
                    name={'brands'}
                    label={<LimitedLabel label="Brand(s) :" />}
                    value={internalValue?.brands}
                    onChange={handleFilterChange}
                />
                <ByzzerCategorySelect
                    className={`${baseClassName}__selectors--selector`}
                    name={'categories'}
                    value={internalValue?.categories}
                    onChange={handleFilterChange}
                    placeholder={'Select from the list'}
                    label={<LimitedLabel label="Category(s) :" />}
                    categoriesToCheckForIntersect={categoriesToCheckForIntersect}
                    shouldDisplayIntersectIndicators={internalValue?.brands?.length! > 0 || false}
                    categorySelectionAggregationLevel={'category'}
                />

                {['Assortment', 'New Products'].includes(opportunityType) ? (
                    <>
                        <div className={`${baseClassName}__selectors--selector`}>
                            <Tooltip
                                placement="bottomRight"
                                title={`PPGs do not apply to the ${opportunityType} function.`}
                            >
                                <span>
                                    <ByzzerPPGSelect
                                        className={`${baseClassName}__selectors--selector`}
                                        name={'ppgId'}
                                        label={'PPG Definition :'}
                                        // onChange={handleFilterChange}
                                        value={internalValue?.ppgId ?? -1}
                                        categories={internalValue?.categories}
                                        allowClear={true}
                                        dontUseDefault={true}
                                        showDescription={false}
                                        disabled={['Assortment', 'New Products'].includes(opportunityType)}
                                    />
                                </span>
                            </Tooltip>
                        </div>
                    </>
                ) : (
                    <ByzzerPPGSelect
                        className={`${baseClassName}__selectors--selector`}
                        name={'ppgId'}
                        label={<LimitedLabel label="PPG Definition :" />}
                        onChange={handleFilterChange}
                        value={internalValue?.ppgId ?? -1}
                        categories={internalValue?.categories}
                        allowClear={true}
                        dontUseDefault={true}
                        showDescription={false}
                        disabled={['Assortment', 'New Products'].includes(opportunityType)}
                    />
                )}

                <ByzzerSearchableSelect
                    name="markets"
                    className={`${baseClassName}__selectors--selector`}
                    disabled={false}
                    value={internalValue?.markets ?? []}
                    placeholder="Search for Market"
                    label={<LimitedLabel label="Market :" />}
                    options={internalValue?.markets ? internalValue?.markets : []}
                    searchPlaceholder="Search for Market"
                    onChange={handleFilterChange}
                    searchOnChange={handleMarketSearchChange}
                    searchType="marketSingleSearch"
                    categories={internalValue?.categories ?? []}
                    searchValue={internalValue?.markets ? internalValue?.markets : undefined}
                    optionKey={undefined}
                    seperator={undefined}
                    selectedMarkets={undefined}
                    productType={undefined}
                    multiBrandSelect={undefined}
                    fromFilter={true}
                />
            </main>

            <div className={classnames(`${baseClassName}__midselectors`)}>
                <header className={classnames(`${baseClassName}__header`)}>
                    <span>
                        <b>Opportunities</b>
                    </span>
                </header>
                <div
                    ref={itemRef}
                    className={classnames(`${baseClassName}__nav-item`, `${baseClassName}__nav-item--my-items`)}
                >
                    {opportunityType}
                </div>
                <ByzzerMenu
                    className={`${baseClassName}__menu`}
                    offset={[0, 2]}
                    items={[
                        {
                            content: 'Promotion',
                            onClick: function () {
                                runReport('Promotion');
                            },
                        },
                        {
                            content: 'Pricing',
                            onClick: function () {
                                runReport('Pricing');
                            },
                        },
                        {
                            content: 'Assortment',
                            onClick: function () {
                                runReport('Assortment');
                            },
                        },
                        {
                            content: 'New Products',
                            onClick: function () {
                                runReport('New Products');
                            },
                        },
                    ]}
                    reference={itemRef}
                    triggerTarget={itemRef.current}
                ></ByzzerMenu>
            </div>
            <ByzzerMask show={loading} loading={loading} />
            {recommendations && recommendations.length > 0 ? (
                <ByzzerTable
                    ref={gridRef} // Ref for accessing Grid's API
                    rowData={recommendations} // Row Data for Rows
                    columnDefs={columnDefs} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Column Defs for Columns
                />
            ) : (
                <div className={`${baseClassName}__emptygrid`}>
                    <p>{brandScoreMessages.opportunity.noOpportunities}​</p>
                </div>
            )}
        </div>
    );
}
