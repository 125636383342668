import React from "react";
import closeIcon from '@images/icons/errorAlert/RoundedClose.png';
import ChatIcon from '@images/icons/errorAlert/ChatWithUs.png';
import CopyImg from '@images/icons/errorAlert/Copy.png';
import Mail from '@images/icons/errorAlert/mail.png';
import './ErrorModal.scss';
import { message } from 'antd';

/**
 * Global component to show the given content in a modal with contact functionality
 * @param  props to use in this component
 */
const ErrorModal = ({ onClick, heading, show, errorInfo, errorResolution, traceId }) => {

    const showHideClassName = show ? "error-modal display-block" : "error-modal display-none";
    const copyToClipboard = (traceId) => {
      navigator.clipboard.writeText(traceId);

      message.destroy();
      message.success('Copied!');
    };

    return (
        <div className={showHideClassName}>
            <section className="error-modal-main">
                <span className={'error-modal-main__close_icon'}>
                    <img width={16} height={16} src={closeIcon}   onClick={() => onClick()} />
                </span>
                <div className="error-modal-main__heading">
                    <div className="error-modal-main__heading__mask1">
                        <div className="error-modal-main__heading__mask1__img">

                        </div>
                    </div>
                    <div className="error-modal-main__heading__text">{heading}</div>
                    <div className="error-modal-main__heading__mask2">
                        <div className="error-modal-main__heading__mask2__img">
                            
                        </div>
                    </div>
                </div>
                <div className="error-modal-main__child">
               

                    {  
                        <>
                            <div className="error-modal-main__child__error_resolution">
                                <label className="byzzer-label">{errorInfo}</label>
                            </div>
                        </>
                    }

                    {
                        <>
                            <div className="error-modal-main__child__error_info">
                                <label className="byzzer-label">{errorResolution ? errorResolution : 'Please contact Byzzer Customer Service for support'}</label>
                            </div>
                        </>
                    }
                    
                    { 
                        <div className="error-modal-main__child__contact">
                            <label className="byzzer-label"><span style={{cursor: 'pointer'}} onClick={() => { window.HubSpotConversations.widget.load(); window.HubSpotConversations.widget.open(); }} ><img  src={ChatIcon}  />&nbsp;&nbsp;Chat With Us &nbsp;&nbsp;</span> |&nbsp;&nbsp; <a style={{color: "black"}} href={"mailto:byzzer.support@smb.nielseniq.com?subject=Enquiry Regarding Issue: " + traceId + "&body=Hi, %0d%0a   I am getting error with trace id: " + traceId + ". Please let me know the solution."}><img  src={Mail} />&nbsp;&nbsp;Email Us</a></label>
                        </div>
                    }

                    {
                        traceId &&
                        <div className="error-modal-main__child__footer">
                            <div className="error-modal-main__child__path">

                            </div>
                            <div className="error-modal-main__child__trace_info">
                                <label className="byzzer-label" onClick={() => {copyToClipboard(traceId)}} style={{cursor:'pointer'}}>Your Request ID: {traceId} &nbsp;&nbsp;
                                <img className="error-modal-main__child__copy"  src={CopyImg}  />
                                <span style={{fontSize: '16px'}}>&nbsp;Copy</span></label>
                            </div>
                        </div>
                    }

                    
                </div>
            </section>

        </div>
    );
};

export default ErrorModal;
