import './DodRunNowModal.scss';
import {ByzzerCheckableChangeEvent, ByzzerCheckbox, ByzzerTextInput, ByzzerTipIcon} from '@byzzer/ui-components';
import {ByzzerLink, ModalAction, openModal} from '@/components/form';
import {AppliedPresets, DodRunConfig, DodSchedule} from "@/types/DodRun";
import {createExtract, createExtractSchedule, runExtractLayoutChange, updateExtractName, updateExtractSchedule} from "@/api";
import {ByzzerMask} from "@/components/ByzzerMask/ByzzerMask";

const baseClassName = 'dod-run-now-modal';

function isDuplicateSeries({ seriesName, seriesNames }: { seriesName?: string; seriesNames: Set<string> }) {
    return seriesNames.has((seriesName || "").trim().toLowerCase());
}

interface CreateDodRunModalParams {
    runConfig: DodRunConfig,
    isFutureTemplate?: boolean,
    name?: string;
    seriesName?: string;
    seriesNames: Set<string>;
    appliedPresets?: AppliedPresets;
}

export async function openCreateDodRunModal({
  runConfig,
  name = runConfig.name,
  seriesName = runConfig.seriesName || '',
  seriesNames,
  appliedPresets,
  isFutureTemplate,
}: CreateDodRunModalParams) {
    return openModal<Omit<CreateDodRunModalParams, "seriesNames">>({
        className: baseClassName,
        title: 'Run Now',
        initialState: {
            runConfig,
            name,
            seriesName,
            appliedPresets,
            isFutureTemplate
        },
        showCloseOption: false,
        content: ({busy, state, setState}) => {

            function handleDisplayNameChange(e: ByzzerChangeEvent<string>) {
                setState(state => ({
                    ...state,
                    [e.name as string]: e.value
                }))
            }

            function handleTemplateChange(e: ByzzerCheckableChangeEvent<string>) {
                setState(state => ({
                    ...state,
                    isFutureTemplate: e.checked
                }))
            }

            return (
              <>
                  <ByzzerMask loading={busy}>Creating Data On Demand Run</ByzzerMask>
                  <ByzzerTextInput
                      className={`${baseClassName}__name`}
                      name="name"
                      placeholder="Enter a title"
                      label="Name your Data On Demand run"
                      value={state.name}
                      onChange={handleDisplayNameChange}
                  />
                  <ByzzerTextInput
                      className={`${baseClassName}__series-name`}
                      name="seriesName"
                      placeholder="Optional (will act as a folder when filtering history)"
                      label="Name your Series"
                      value={state.seriesName}
                      onChange={handleDisplayNameChange}
                  />
                  <div className={`${baseClassName}__templatize`}>
                      <ByzzerCheckbox
                          checked={state.isFutureTemplate}
                          onChange={handleTemplateChange}
                          label="I plan to use this run with a template"
                      />
                      <ByzzerTipIcon tipTrigger="hover" interactive preventDefaultClickEvent tipLocation={'left'}>
                          Read this{' '}
                          <ByzzerLink
                              theme={'light'}
                              className={`${baseClassName}__tip-link`}
                              href="https://learn.byzzer.ai/en/articles/6506400-how-to-use-data-on-demand-excel-templates"
                              target="_blank"
                          >
                              article
                          </ByzzerLink>{' '}
                          for more information about Excel templates and the use cases.
                      </ByzzerTipIcon>
                  </div>
              </>
          );
        },
        actions: [{
            key: 'cancel',
            type: 'negative',
            label: 'Cancel',
            disableIf({busy}) {
                return busy
            },
            action({resolve}) {
                resolve();
            }
        }, {
            key: 'run',
            label: 'Run',
            getDisableTip({state: {seriesName} }) {
                return isDuplicateSeries({seriesName, seriesNames}) ? "You already have a scheduled series with this name. Please choose a different series name." : ""
            },
            disableIf({busy, state: {name, seriesName}}) {
                return isDuplicateSeries({seriesName, seriesNames}) || !name || busy
            },
            async action({resolve, reject, setBusy, state}) {
                try {
                    if(!isDuplicateSeries({seriesName: state.seriesName, seriesNames})) {
                        setBusy(true);
                        resolve(await createExtract({
                            ...state.runConfig,
                            name: state.name,
                            seriesName: state.seriesName,
                            appliedPresets: state.appliedPresets,
                        }, state.isFutureTemplate))
                    } else {
                        reject("You already have a scheduled series with this name. Please choose a different series name.");
                    }
                } catch(err) {
                    reject(err);
                } finally {
                    setBusy(false)
                }
            }
        }]
    });
}

interface ApplyDodLayoutModalParams {
    runConfig: DodRunConfig,
    isFutureTemplate?: boolean,
    extractId: number;
    name?: string;
    seriesName?: string;
    seriesNames: Set<string>;
}

export async function openApplyDodLayoutModal({
  runConfig,
  name = runConfig.name,
  seriesName = runConfig.seriesName || '',
  seriesNames,
  isFutureTemplate,
  extractId,
}: ApplyDodLayoutModalParams) {
    return openModal<Omit<ApplyDodLayoutModalParams, "seriesNames">>({
        className: baseClassName,
        title: 'Run Now',
        initialState: {
            runConfig,
            name,
            seriesName,
            isFutureTemplate,
            extractId
        },
        showCloseOption: false,
        content: ({busy, state, setState}) => {

            function handleDisplayNameChange(e: ByzzerChangeEvent<string>) {
                setState(state => ({
                    ...state,
                    [e.name as string]: e.value
                }))
            }

            function handleTemplateChange(e: ByzzerCheckableChangeEvent<string>) {
                setState(state => ({
                    ...state,
                    isFutureTemplate: e.checked
                }))
            }

            return (
                <>
                    <ByzzerMask loading={busy}>Creating Data On Demand Run</ByzzerMask>
                    <ByzzerTextInput
                        className={`${baseClassName}__name`}
                        name="name"
                        placeholder={"Enter a title."}
                        label="Name your Data On Demand run."
                        value={state.name}
                        onChange={handleDisplayNameChange}
                    />
                    <ByzzerTextInput
                        className={`${baseClassName}__series-name`}
                        name="seriesName"
                        placeholder="Optional (will act as a folder when filtering history)"
                        label="Name your Series"
                        value={state.seriesName}
                        onChange={handleDisplayNameChange}
                    />
                    <div className={`${baseClassName}__templatize`}>
                        <ByzzerCheckbox
                            checked={state.isFutureTemplate}
                            onChange={handleTemplateChange}
                            label="I plan to use this run with a template"
                        />
                        <ByzzerTipIcon tipTrigger="hover" interactive preventDefaultClickEvent tipLocation={'left'}>
                            Read this{' '}
                            <ByzzerLink
                                theme={'light'}
                                className={`${baseClassName}__tip-link`}
                                href="https://learn.byzzer.ai/en/articles/6506400-how-to-use-data-on-demand-excel-templates"
                                target="_blank"
                            >
                                template
                            </ByzzerLink>{' '}
                            for more information about Excel templates and the use cases.
                        </ByzzerTipIcon>
                    </div>
                </>
            );
        },
        actions: [{
            key: 'cancel',
            type: 'negative',
            label: 'Cancel',
            disableIf({busy}) {
                return busy
            },
            action({resolve}) {
                resolve();
            }
        }, {
            key: 'run',
            label: 'Run',
            getDisableTip({state: {seriesName} }) {
                return isDuplicateSeries({seriesName, seriesNames}) ? "You already have a scheduled series with this name. Please choose a different series name." : ""
            },
            disableIf({busy, state: {name, seriesName}}) {
                return isDuplicateSeries({seriesName, seriesNames}) || !name || busy
            },
            async action({resolve, reject, setBusy, state}) {
                try {
                    if(!isDuplicateSeries({seriesName: state.seriesName, seriesNames})) {
                        setBusy(true);
                        resolve(await runExtractLayoutChange(extractId, {
                            ...state.runConfig,
                            name: state.name,
                            seriesName: state.seriesName,
                        }, state.isFutureTemplate))
                    } else {
                        reject("You already have a scheduled series with this name. Please choose a different series name.");
                    }
                } catch(err) {
                    reject(err);
                } finally {
                    setBusy(false)
                }
            }
        }]
    });
}

interface CreateDodScheduleModalParams {
    runConfig: DodRunConfig,
    schedule: DodSchedule,
    isFutureTemplate?: boolean,
    name?: string;
    seriesNames: Set<string>;
    appliedPresets?: AppliedPresets;
}

interface ModifyDodScheduleModalParams {
  runConfig: DodRunConfig,
  schedule: DodSchedule,
  extractId: number,
  appliedPresets?: AppliedPresets;
}

export async function openCreateDodScheduleModal({runConfig, name = runConfig.name, seriesNames, ...params}: CreateDodScheduleModalParams) {
    return openModal<Omit<CreateDodScheduleModalParams, "seriesNames">>({
        className: baseClassName,
        title: 'Create Data On Demand Schedule',
        initialState: {
            ...params,
            runConfig,
            name
        },
        showCloseOption: false,
        content: ({busy, state, setState}) => {

            function handleDisplayNameChange(e: ByzzerChangeEvent<string>) {
                setState(state => ({
                    ...state,
                    name: e.value
                }))
            }

            function handleTemplateChange(e: ByzzerCheckableChangeEvent<string>) {
                setState(state => ({
                    ...state,
                    isFutureTemplate: e.checked
                }))
            }

            return <>
                <ByzzerMask loading={busy}>Creating Your Schedule</ByzzerMask>
                <ByzzerTextInput className={`${baseClassName}__name`} name={'name'} placeholder={'Enter a title'}
                                 label={<>
                                     Name your Data On Demand series{' '}
                                     <ByzzerTipIcon>
                                         The series name will allow you to quickly find all runs in the same
                                         series or setup. Each run will have the week ending date added to
                                         differentiate each run in the series.
                                     </ByzzerTipIcon>
                                 </>}
                                 value={state.name}
                                 onChange={handleDisplayNameChange}/>
                <div className={`${baseClassName}__templatize`}>
                    <ByzzerCheckbox
                        checked={state.isFutureTemplate}
                        onChange={handleTemplateChange}
                        label="I plan to use this run with a template"/>
                    <ByzzerTipIcon tipTrigger='hover' interactive preventDefaultClickEvent tipLocation={'left'}>
                        Read this <ByzzerLink theme={'light'} className={`${baseClassName}__tip-link`}
                                              href="https://learn.byzzer.ai/en/articles/6506400-how-to-use-data-on-demand-excel-templates"
                                              target="_blank">
                        article
                    </ByzzerLink> for more information about Excel templates and the use cases.
                    </ByzzerTipIcon>
                </div>
            </>
        },
        actions: [{
            key: 'cancel',
            type: 'negative',
            label: 'Cancel',
            disableIf({busy}) {
                return busy
            },
            action({resolve}) {
                resolve();
            }
        }, {
            key: 'create',
            label: 'Create',
            getDisableTip({ state: { name: seriesName } }) {
                return isDuplicateSeries({seriesName, seriesNames}) ? "You already have a series with this name. Please choose a different series name." : ""
            },
            disableIf({busy, state: { name: seriesName }}) {
                return isDuplicateSeries({seriesName, seriesNames}) || !seriesName || busy
            },
            async action({resolve, reject, setBusy, state}) {
                try {
                    if(!isDuplicateSeries({seriesName: state.name, seriesNames})) {
                        setBusy(true);
                        resolve(await createExtractSchedule({
                            ...state.runConfig,
                            name: state.name,
                            appliedPresets: state.appliedPresets,
                        }, state.schedule, state.isFutureTemplate));
                    } else {
                        reject("You already have a series with this name. Please choose a different series name.");
                    }
                } catch(err) {
                    reject(err);
                } finally {
                    setBusy(false)
                }
            }
        }]
    });
}

export async function openModifyDodScheduleModal(params: ModifyDodScheduleModalParams) {
    return openModal<ModifyDodScheduleModalParams>({
        className: baseClassName,
        initialState: { ...params },
        showCloseOption: false,
        content: ({ busy }) => (
            <>
                <ByzzerMask loading={busy}>Updating Your Schedule</ByzzerMask>
                <p>Are you sure you want to modify all scheduled runs for this series?</p>
            </>
        ),
        actions: [
            {
                key: 'no',
                type: 'negative',
                label: 'No',
                disableIf({ busy }) {
                    return busy;
                },
                action({ resolve }) {
                    resolve();
                },
            },
            {
                key: 'yes',
                label: 'Yes',
                disableIf({ busy }) {
                    return busy;
                },
                async action({ resolve, reject, setBusy, state }) {
                    try {
                        setBusy(true);
                        resolve(
                            await updateExtractSchedule(
                                state.extractId,
                                { ...state.runConfig, appliedPresets: state.appliedPresets },
                                state.schedule
                            )
                        );
                    } catch (err) {
                        reject(err);
                    } finally {
                        setBusy(false);
                    }
                },
            },
        ],
    });
}

export function openModifyReportName(id: number, name: string) {
    return openModal({
        className: baseClassName,
        title: 'Rename',
        initialState: {
            id,
            name,
            oldName: name
        },
        showCloseOption: false,
        content: ({busy, state, setState}) => {

            function handleDisplayNameChange(e: ByzzerChangeEvent<string>) {
                setState(state => ({
                    ...state,
                    name: e.value
                }))
            }

            return <>
                <ByzzerMask loading={busy}>Renaming your report</ByzzerMask>
                <ByzzerTextInput className={`${baseClassName}__name`} name={'name'} placeholder={'Enter a title'}
                                //  label={<>
                                //      Name your Data On Demand Run{' '}
                                //  </>}
                                 value={state.name}
                                 onChange={handleDisplayNameChange}/>
            </>
        },
        actions: [{
            key: 'cancel',
            type: 'negative',
            label: 'Cancel',
            disableIf({busy}) {
                return busy
            },
            action({resolve}) {
                resolve();
            }
        }, {
            key: 'update',
            label: 'Update',
            getDisableTip({ state: { name } }) {
                return !name ? "Name is required" : ""
            },
            disableIf({busy, state: { name }}) {
                return !name || busy
            },
            async action({resolve, reject, setBusy, state}) {
                try {
                    if(state.name) {
                        setBusy(true);
                        const response = await updateExtractName(state.id, state.name);
                        if(response === "Updated") {
                            resolve(state.name);
                        }else {
                            reject("unable to update");
                        }
                    } else {
                        reject("Name is required.");
                    }
                } catch(err) {
                    reject(err);
                } finally {
                    setBusy(false)
                }
            }
        }]
    });
}

export function openModifySeriesName(id: number, seriesName: string, isSchedule: boolean = false, seriesNames: Set<string> = new Set()) {
    return openModal({
        className: baseClassName,
        title: <div className={`${baseClassName}__templatize`}>
            Rename across the entire series{' '}
            <ByzzerTipIcon>
                You are editing the name of a scheduled run. Because scheduled runs are a concatenation of the series name and the week ending date, you will be updating the run names across the entire scheduled series.
            </ByzzerTipIcon>
        </div>,
        initialState: {
            id,
            seriesName: seriesName,
            oldSeriesName: seriesName
        },
        showCloseOption: false,
        content: ({busy, state, setState}) => {

            function handleDisplayNameChange(e: ByzzerChangeEvent<string>) {
                setState(state => ({
                    ...state,
                    seriesName: e.value
                }))
            }

            return <>
                <ByzzerMask loading={busy}>Renaming your report</ByzzerMask>
                <ByzzerTextInput className={`${baseClassName}__name`} name={'name'} placeholder={'Enter a title'}
                                //  label={<>
                                //      Name your Data On Demand Run{' '}
                                //  </>}
                                 value={state.seriesName}
                                 onChange={handleDisplayNameChange}/>
            </>
        },
        actions: [{
            key: 'cancel',
            type: 'negative',
            label: 'Cancel',
            disableIf({busy}) {
                return busy
            },
            action({resolve}) {
                resolve();
            }
        }, {
            key: 'update',
            label: 'Update',
            getDisableTip({ state: { seriesName } }) {
                if(!seriesName){
                    return "Series Name is required";
                }
                return isDuplicateSeries({seriesName, seriesNames}) ? "You already have a series with this name. Please choose a different series name." : ""
            },
            disableIf({busy, state: { seriesName }}) {
                return isDuplicateSeries({seriesName, seriesNames}) || !seriesName || busy
            },
            async action({resolve, reject, setBusy, state}) {
                try {
                    if(state.seriesName) {
                        setBusy(true);
                        const response = await updateExtractName(state.id, state.seriesName, 'series', isSchedule);
                        if(response === "Updated") {
                            resolve(state.seriesName);
                        }else {
                            reject("unable to update");
                        }
                    } else {
                        reject("Series Name is required.");
                    }
                } catch(err) {
                    reject(err);
                } finally {
                    setBusy(false)
                }
            }
        }]
    });
}