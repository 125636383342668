import { ByzzerButton, ByzzerInput, ByzzerModal } from '@/components/form'
import React from 'react'
import "./StoryModal.scss"
import {TrackClick} from "@/analytics";

function StoryModal({showModal,editModal=false, children, onChangeFileAlias, size, onModalclose, item, fileName, remainingReportRuns, panelStatusCompleted, storyCountDetails, ...props}) {
    const fileNameAlias = fileName !== null ? fileName : item ? item?.actionConfig?.storyCustomName : ""

    function renderUsage() {
        return (
            <header className="my-story my-story__sub-heading">
                <span>
                    This story will deduct {storyCountDetails?.userData?.reportRunValue} core report {storyCountDetails?.userData?.reportRunValue > 1 ? `runs` : `run`} from your team, after which your
                    team will have {remainingReportRuns - storyCountDetails?.userData?.reportRunValue} report {remainingReportRuns - storyCountDetails?.userData?.reportRunValue > 1 ? `runs` : `run`}.
                </span>
            </header>
        );
    }

    const getModalHeader = (heading, text) => {
        return (
            <div className="story-modal-header">
                <header className="story-modal-header__heading">
                    <span>{heading}</span>
                </header>
                <header className="story-modal-header__text">
                    <span>{text}</span>
                </header>
               {editModal && renderUsage()}
            </div>
        );
    };

    const getBody = () =>{
        return <div class = 'run-again-container'>{children}</div>
    }

    const getFooter = () => {
        return (<div className="story-modal-footer">
            <div className="story-modal-footer__heading">
                Name Your Story
            </div>
            <ByzzerInput
                className="story-modal-footer__input"
                value={fileNameAlias} //Retailer Review Story
                onChange={onChangeFileAlias}
                placeholder={'Build Your Own Story [Market] [Date]'}
                required
            />
            <div className={"story-modal-footer__button"}> 
            <TrackClick name={`My Stories - Run ${fileNameAlias} clicked`}>
                <ByzzerButton onClick={props.onRunStoryClicked} disabled ={!panelStatusCompleted}>Run Story</ByzzerButton>
            </TrackClick>
            </div>
        </div>)
    }


    return (
        <ByzzerModal
                    show={true}
                    onClose={() => onModalclose()}
                    size={size}
                    type={'info'}
                    headerType={'none'}
                > 
            {getModalHeader(props.title, props.description)} 
            {getBody()}
            {!props.hideFooter && getFooter()}
        </ByzzerModal>
    )
}

export default StoryModal
