import './DodCustomTimePeriod.scss';
import { useMemo, useState } from 'react';
import classNames from 'classnames';
import { format as formatDate } from 'date-fns';
import { ByzzerButton, ByzzerSelect, ByzzerSelectOption } from '@byzzer/ui-components';
import { useDodWizard } from '@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext';
import { useUser } from '@/contexts/UserContext';
import { TimePeriodRangeArray } from '@/utils/timePeriod/TimePeriodRangeArray';
import { TimePeriod } from '@/utils/timePeriod/TimePeriod';

export type CustomTimePeriodType = {
    duration?: string;
    repeat?: string;
    endDate?: string;
};
export type DodCustomTimePeriodProps = {
    className?: string;
    onApply(e: string[]): void;
    limit?: number
};
const baseClassName = `dod-custom-timeperiod`;
const periodOptions: ByzzerSelectOption[] = Array.from(Array(156).keys(), (n) => {
    return {
        display: `${n + 1} Week Periods`,
        value: `${n + 1}`,
    };
});

export function DodCustomTimePeriod({className, onApply, limit=Infinity}: DodCustomTimePeriodProps) {

    const {maxDataDates} = useUser();
    const {presets, deletePreset} = useDodWizard();
    const [maxDate] = useState<TimePeriod>(() => {
        if (maxDataDates.rms instanceof Date) {
            return new TimePeriod(formatDate(maxDataDates.rms!, 'yyyyMMdd'));
        }
        return TimePeriod.Latest;
    });
    const periodEndingDateOptions = useMemo(() => [
        {
            display: `Ending On Latest Available Date (${maxDate.toDateString()})`,
            value: `latest`
        },
        ...Array.from({length: 156}, (_, n) => ({
            display: `Ending ${maxDate.subtract(n).toDateString()}`,
            value: `${maxDate.subtract(n).valueOf()}`,
        }))
    ], [maxDate]);
    const [customConfig, setCustomConfig] = useState<CustomTimePeriodType>({});
    const [historyOptions, setHistoryOptions] = useState<ByzzerSelectOption[]>([]);

    function handlePeriodChange({value}: ByzzerChangeEvent<string>) {
        const duration = Number(value);
        setCustomConfig((prev) => ({
            ...prev,
            duration: value,
        }));
        const options: ByzzerSelectOption[] = [];
        for (let i = duration; i < 156; i += duration) {
            options.push({
                display: `For ${i} Week${i === 1 ? 's' : ''}`,
                value: `${Math.ceil(i / duration)}`
            });
        }

        setHistoryOptions(options);
    }

    function onHistoryChange(e) {
        setCustomConfig((prev) => ({
            ...prev,
            repeat: e.value,
        }));
    }

    function onDateChange(e) {
        setCustomConfig((prev) => ({
            ...prev,
            endDate: e.value,
        }));
    }

    function checkFormValues() {
        return (
            customConfig.endDate &&
            customConfig.repeat &&
            customConfig.duration
        );
    }

    function isValid() {
        return checkFormValues();
    }

    function errorMessage() {
        if (!checkFormValues()) {
            return "Please fill all fields.";
        }
    }

    function displaySummary() {
        if (isValid()) {

            const weeks = (Number(customConfig?.repeat) * Number(customConfig?.duration));
            const ending = customConfig.endDate === 'latest' ?
                `on latest available date` :
                new TimePeriod(Number(customConfig.endDate)).toDateString();

            return <>
                <p className={`${baseClassName}__series`}>
                    {customConfig.duration} week periods for {weeks} weeks ending {ending}. 
                </p>
                <p>This will output {customConfig.repeat} time periods.</p>
            </>
        }
    }

    function handleApply() {

        const {endDate, repeat, duration} = customConfig;
        let rangeArray: TimePeriodRangeArray;
        if (endDate === 'latest') {
            rangeArray = new TimePeriodRangeArray(`cl:${duration}:${repeat}`);
        } else {
            rangeArray = new TimePeriodRangeArray(`ca:${duration}:${repeat}`, Number(endDate));
        }

        // remove selected preset values
        if (presets?.time_period) {
            deletePreset('time_period');
        }

        onApply(
            rangeArray.ranges
        );
        setCustomConfig({});
    }

    return (
        <div className={classNames(baseClassName, className)}>
            <div className={`${baseClassName}__options`}>
                <ByzzerSelect
                    label={<>Periods <small>(What length should each period be?)</small></>}
                    placeholder="Period In Weeks"
                    value={customConfig.duration}
                    options={periodOptions}
                    onChange={handlePeriodChange}
                />
                <ByzzerSelect
                    label={<>History <small>(How far back should the data go?)</small></>}
                    placeholder="History In Weeks"
                    value={customConfig.repeat}
                    options={historyOptions}
                    disabledOptions={historyOptions?.filter((history)=> Number(history?.value) > limit) }
                    onChange={onHistoryChange}
                />
                <ByzzerSelect
                    label={<>End date <small>(When should the date end?)</small></>}
                    placeholder="End Date"
                    value={customConfig.endDate}
                    options={periodEndingDateOptions}
                    onChange={onDateChange}
                />
            </div>
            <div className={`${baseClassName}__summary`}>
                {displaySummary()}
            </div>
            <div className={`${baseClassName}__actions`}>
                <ByzzerButton disabled={!isValid()} onClick={() => handleApply()}
                              disabledTip={errorMessage()}>
                    Apply
                </ByzzerButton>
            </div>
        </div>
    );
}

export default DodCustomTimePeriod;
