

// TODO: Refactor this component to use CSS and remove inline styles






import React from 'react';


export function ByzzerPercentageBar({
  total,
  totalAllocation,
  used,
  height = '19px',
  width = 350,
  radius = '50px',
  usedFillColor = '#d9d9d9',
  remainingFillColor = '#000000',
  usage = false,
  usedRuns = 0
}) {

  return totalAllocation === Infinity ? (
        <div
            style={{
                height: '100%',
                width: `${usedRuns}px`,
                backgroundColor: usedFillColor,
                border: ((used) / totalAllocation) >= 1 ? '1px solid #ffffff' : '',
                boxSizing: 'border-box',
                textAlign: 'center',
                maxWidth: '100%',
            }}
        >
            {' '}
            {usedRuns}
        </div>
    ) : (
        <div
            style={{
                boxSizing: 'border-box',
                borderLeftStyle: 'solid',
                borderLeftColor: '#000',
                borderLeftWidth: '1px',
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: usage == true ? 'column' : 'unset',
                height,
                width: `${(totalAllocation / total) * width}px`,
            }}
        >
            <div
                style={{
                    height: '100%',
                    width: `${(used / totalAllocation) * 100}%`,
                    backgroundColor: usedFillColor,
                    border: (used / totalAllocation) >= 1 ? '1px solid #ffffff' : '',
                    boxSizing: 'border-box',
                }}
            ></div>

            {totalAllocation - used > 0 && (
                <div
                    style={{
                        width: `${((totalAllocation - used) / totalAllocation) * 100}%`,
                        height: '100%',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyItems: 'center',
                        border: ((totalAllocation - used) / totalAllocation) >= 1 ? '1px solid #ffffff' : '',
                        boxSizing: 'border-box',
                        backgroundColor: remainingFillColor,
                        fontSize: '12px',
                        color: '#fff',
                    }}
                >
                    {!usage && totalAllocation - used}
                </div>
            )}
            {usage && (
                <div
                    style={{ // todo: Get rid of this inline style, if this component is even used?
                        paddingLeft: '2px',
                        height: '100%',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyItems: 'center',
                        border: ((totalAllocation - used) / totalAllocation) >= 1 ? '1px solid #ffffff' : '',
                        boxSizing: 'border-box',
                        fontWeight: '500',
                        fontSize: '12px',
                        color: '#000',
                    }}
                >
                    {totalAllocation == 0 ? '' : totalAllocation - used}
                </div>
            )}
            {!usage ? (
                <>
                    <span
                        style={{
                            width: (used / totalAllocation) === 0 ? '1%' : `${(used / totalAllocation) * 100}%`,
                            textAlign: 'right',
                            fontSize: '12px',
                            color: '#000',
                        }}
                    >
                        {used}
                    </span>
                    <span
                        style={{
                            width: (used / totalAllocation) === 0 ? '99%' : `${((totalAllocation - used) / totalAllocation) * 100}%`,
                            textAlign: 'right',
                            fontSize: '12px',
                            color: '#000',
                        }}
                    >
                        {totalAllocation}
                    </span>
                </>
            ) : (
                ''
            )}
        </div>
    );
}

export default ByzzerPercentageBar;
