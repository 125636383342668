import React, { useState } from 'react';
import classNames from 'classnames';
import { ByzzerButton, ByzzerRadio } from './form';
import { setSelectorAttributes } from '@/utils/reportSelectorUtil';
import './SelectDimension.scss';
import { selectorStates } from '@/config/globalVars';

const SelectDimension = (props) => {
    const [dimensionSelected, setDimensionSelected] = useState(props?.selectorState?.dimension !== '' && props.reportViewPage ? props?.selectorState?.dimension : '');
    const [dimensionData, setDimensionData] = useState([
        {
            title: "my product's performance to other products",
            type: 'products',
            selected: false,
        },
        {
            title: "my product's performance across markets",
            type: 'markets',
            selected: false,
        },
    ]);

    const handleSelectDimension = (index) => {
        let data = dimensionData;
        data[index].selected = true;
        let productOption = '';
        let marketOption = '';
        if (data[index].type === 'products') {
            productOption = 'product:option5';
            marketOption = 'market:option1';
        } else {
            productOption = 'product:option1A';
            marketOption =
                String(props.sku) === '29' || String(props.sku) === '44' ? 'market:option5' : 'market:option2'; // As per #448 comment, Promotion Efficiency and Promotion Support are only ready for option-5
        }
        let selectorsObj = [...props.reportSelectors];
        const dimIndex = selectorsObj.findIndex((element) => element.selectorType === 'dimension');
        selectorsObj[dimIndex] = { ...selectorsObj[dimIndex], selectedItem: data[index].type };
        const prodIndex = selectorsObj.findIndex((element) => element.selectorType === 'product');
        selectorsObj[prodIndex] = {
            ...selectorsObj[prodIndex],
            subSelectors: setSelectorAttributes(productOption).subSelectors,
        };
        const marketIndex = selectorsObj.findIndex((element) => element.selectorType === 'market');
        selectorsObj[marketIndex] = {
            ...selectorsObj[marketIndex],
            subSelectors: setSelectorAttributes(marketOption).subSelectors,
        };
        props.setReportSelectors(selectorsObj);
        setDimensionSelected(data[index].type);
        setDimensionData(data);
    };

    const submitDimensionSelections = (submitType) => {
        let currentSelectorStatusState = JSON.parse(JSON.stringify(props.selectorState.selectorsStatus));
        currentSelectorStatusState[props.stepNo].status = selectorStates.completed;
        props.setSelectorsData({
            selectorsStatus: currentSelectorStatusState,
            activeCollapsePanel: submitType !== 'Generate' ? [props.stepNo + 1] : [],
            dimension: dimensionSelected,
        });
        if (submitType === 'Generate') {
            let selectionsToSelState = { ...props.selectorState };
            selectionsToSelState.dimension = dimensionSelected;
            props.generateReport(selectionsToSelState);
        }
    };

    const wrapperClasses = classNames('select-dimension');

    return (
        <div className={wrapperClasses}>
            <div className="select-dimension__title">
                <div>What type of analysis is right for you?</div>
                <div>I want to compare…</div>
            </div>
            <div className="select-dimension__data">
                {dimensionData.map((item, index) => {
                    return (
                        <div key={index}>
                            <ByzzerRadio
                                label={item.title}
                                checked={item.type === dimensionSelected}
                                onChange={() => handleSelectDimension(index)}
                            />
                        </div>
                    );
                })}
            </div>
            <div className="select-dimension__continue-btn">
                <ByzzerButton
                    disabled={dimensionSelected !== '' ? false : true}
                    label={`${props.saveBtnType === 'Generate' ? 'Generate My Report' : 'Next'}`}
                    onClick={() => submitDimensionSelections(props.saveBtnType)}
                />
            </div>
        </div>
    );
};

export default SelectDimension;
