import React, {ReactNode, useEffect, useRef, useState, useMemo} from 'react';
import classnames from 'classnames';
import {AgGridReact} from 'ag-grid-react';
import {ColDef, GridOptions} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './DodExcelLayoutPreview.scss';
import {DodCharacteristicFilter, DodColDef, DodDimension, DodDimensionType, DodRowDef} from '@/types/DodRun';
import {ByzzerMessagePopUp} from '@byzzer/ui-components';
import {DodPanel} from '@/components/DodConfigEditor/common/DodPanel';
import {uniq} from 'lodash';
import {format as formatDate} from "date-fns";
import {ErrorBoundary} from "react-error-boundary";
import {useDodWizard} from "@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext";
import {caseInsensitiveSort} from "@/utils/Sort";
import cartesian from 'cartesian';
import {TimePeriodRange} from "@/utils/timePeriod/TimePeriodRange";
import { useApp } from "@/contexts/UserContext";
import { factSetToCoreDisplayNames, factSetToDisplayNames, factSetToSharedDisplayNames } from '@/components/DodConfigEditor/common/utils';

const baseClassName = 'dod-excel-layout-preview';

export type DodExcelLayoutPreviewProps = {
    className?: string;
    name?: string;
    title?: string;
    maxSampleSize?: number;
}

const gridOptions: GridOptions = {
    rowHeight: 25,
    suppressAnimationFrame: true,
    animateRows: false,
    suppressCellFocus: true,
    suppressRowHoverHighlight: true,
    headerHeight: 30,
    components: {
        agColumnHeader: PreviewGridHeader,
    },
    defaultColDef: {
        wrapText: false,
        wrapHeaderText: true,
        autoHeight: true,
        autoHeaderHeight: true,
    }
};

export const SUB_DIMENSION_VALUE_TO_DISPLAY: Record<DodDimension | string, string> = {
    departments: 'Departments',
    superCategories: 'Super Category',
    categories: 'Category',
    subcategories: 'Subcategory',
    upcs: 'UPC',
    productDescriptions: 'Product Description',
    parentCompanies: 'Parent Company',
    brands: 'Brand',
    manufacturers: 'Manufacturer',
    markets: 'Markets',
    timePeriods: 'Time Periods',
    facts: 'Facts',
    date: 'Date'
};

interface PreviewColDef extends ColDef {
};

type PreviewRow = Record<string, string>;

interface StackedDimension {
    dim: string;
    value: string;
}

type DimensionValueMap = Record<string, string[] | StackedDimension[]>;

const FILTER_TO_DIMENSION: Record<string, DodDimension> = {
    markets: 'markets',
    timePeriods: 'timePeriods',
    facts: 'facts',
};

interface DimensionComparator {
    (a: string, b: string, values: string[]): number;
}

const comparatorMap: Record<string, DimensionComparator> = {}
interface PreviewGridState {
    colDefs: ColDef[];
    rowData: Record<string, string | StackedDimension>[];
}

export function DodExcelLayoutPreview({
                                          className,
                                          title = 'Preview',
                                          maxSampleSize = 25,
                                      }: DodExcelLayoutPreviewProps) {

    const { maxDataDates } = useApp();
    const maxDate = useMemo<string>(() => {
        return formatDate(maxDataDates.rms!, 'yyyyMMdd');
    }, [maxDataDates]);

    const {runConfig} = useDodWizard();
    // this is to test if a single unified value improves performance
    const [gridState, setGridState] = useState<PreviewGridState>({
        colDefs: [],
        rowData: [],
    })
    const [rowData, setRowData] = useState<Record<string, string | StackedDimension>[]>([])
    const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
    const [pageTitles, setPageTitles] = useState<ReactNode[]>([]);
    const gridRef = useRef<AgGridReact>(null);

    useEffect(() => {
        try {
            // don't bother doing anything if the dimensionValues haven't been populated
            const dimensionValues = generateDimensionValues();
            const rows = runConfig.layout.rows;
            const cols = runConfig.layout.columns;
            const rowTypeOrder: DodDimensionType[] = uniq(rows.map(v => v.type));
            // if Extract Date is clicked, then date field will be added in row/column based on timeperiod dimension selection
            if(runConfig.layout.includeExtractDate){
                const timePeriodIndex = rows.findIndex(row => row.type === "time_periods");

                if(timePeriodIndex !== -1){
                    rows.splice(timePeriodIndex+1, 0, {
                        "type": "date",
                        "dim": "date",
                        "sortType": "manual",
                        "hide": false,
                        "pageBy": false,
                        "stack": false,
                        "order": []
                    });
                }

                const timePeriodColIndex = cols.findIndex(row => row.type === "time_periods");

                if(timePeriodColIndex !== -1){
                    cols.splice(timePeriodColIndex+1, 0, {
                        "type": "date",
                        "dim": "date",
                        "sortType": "manual",
                        "hide": false,
                        "pageBy": false,
                        "stack": false,
                        "order": []
                    });
                }
            }else {
                // if unchecked 
                const timePeriodIndex = rows.findIndex(row => row.type === "date");
                if(timePeriodIndex !== -1) rows.splice(timePeriodIndex, 1);
                const timePeriodColIndex = cols.findIndex(row => row.type === "date");
                if(timePeriodColIndex !== -1) cols.splice(timePeriodColIndex, 1);
            }
            const dimensionHeaders: PreviewColDef[] = getRegularColDefs(rows);
            // if any products are stacked we need to add the columns for that
            if (rows.some(v => v.stack)) {
                // stacked products and details must always follow the
                // @ts-ignore
                let insertionPosition = dimensionHeaders.findLastIndex(v => v.type === 'products') + 1;
                if (insertionPosition === 0 && rowTypeOrder.length > 1) {
                    insertionPosition = rowTypeOrder.indexOf('products');
                }
                dimensionHeaders.splice(insertionPosition, 0, {
                        headerName: 'Stacked Products',
                        type: 'stackedProducts',
                        field: 'stackedProducts.value',
                    },
                    {
                        headerName: 'Stacked Product Details',
                        type:'stackedProductDetails',
                        field: 'stackedProducts.dim',
                    });
            }

            const aggregatedHeadings: PreviewColDef[] = getAggregateColDefs(cols, dimensionValues);
            const pageTitles = getPageTitles(rows, cols, dimensionValues);
            setPageTitles(pageTitles);

            const colDefs: ColDef[] = [
                // limit to 100 columns
                ...[...dimensionHeaders, ...aggregatedHeadings].slice(0, 20),
                // make sure table is always filled horizontally
                {flex: 1}
            ];

            const rowData: PreviewRow[] = generateRowData(dimensionHeaders, dimensionValues).slice(0, 100);
            setColumnDefs(colDefs);
            setRowData(rowData);

        } catch (err) {
            console.error(err)
        }
    }, [runConfig]);

    function generateDimensionValues(): DimensionValueMap {
        let valueMap: DimensionValueMap = {};
        try {
            // loop through all of the filters and facts and grab the first 5 values to use as preview of the real data
            for (let key in runConfig.filters) {
                const valueKey = FILTER_TO_DIMENSION[key] ?? key;
                switch (key) {
                    case 'markets': {
                        const {values, summedSelections} = runConfig.filters[key];
                        valueMap[valueKey] = [
                            ...values.slice(0, maxSampleSize).map((market) => market.name),
                            ...summedSelections.slice(0, maxSampleSize).map(sum => sum.name)
                        ].sort(caseInsensitiveSort)
                        break;
                    }
                    case 'timePeriods': {
                        const { values, summedSelections } = runConfig.filters[key];
                        valueMap[valueKey] = [
                            ...values.flat().map((tp) => new TimePeriodRange(tp, maxDate))
                                .sort(TimePeriodRange.compareDesc)
                                .map((tp) => tp.toLegacyDodString())
                                .slice(0, maxSampleSize),
                            ...summedSelections.map((sum) => sum.name),
                        ];
                        valueMap['date'] = [
                            ...values.flat().map((tp) => new TimePeriodRange(tp, maxDate))
                                .sort(TimePeriodRange.compareDesc)
                                .map((tp) => (tp.toLegacyDodDateString()))
                                .slice(0, maxSampleSize),
                            ...summedSelections.map((sum) => sum.name),
                        ];
                        break;
                    }
                    case 'characteristics':
                    case 'customCharacteristics': {
                        Object.values<DodCharacteristicFilter>(runConfig.filters[key]).forEach(filter => {
                            let {displayName, values, summedSelections} = filter;
                            if (values === 'all') {
                                values = ['All'];
                            }
                            valueMap[displayName] = [
                                ...values.slice(0, maxSampleSize),
                                ...summedSelections.slice(0, maxSampleSize).map((sum) => sum.name),
                            ]
                        });
                        break;
                    }
                    default: {
                        let {values, summedSelections} = runConfig.filters[key];
                        if (values === 'all') {
                            values = ['All']
                        }
                        valueMap[valueKey] = [
                            ...values.slice(0, maxSampleSize),
                            ...summedSelections.slice(0, maxSampleSize).map(sum => sum.name)
                        ]
                    }
                }
            }

            // make sure we have values for product descriptions if it was auto-included b/c of upcs
            if (valueMap.upcs.length && !valueMap.productDescriptions.length) {
                valueMap.productDescriptions = valueMap.upcs;
            }

            if (runConfig.facts.length) {
                valueMap.facts = runConfig.facts.map(factSetToDisplayNames).flat().slice(0, maxSampleSize).sort(caseInsensitiveSort);

                // BYZ-12122 - had to explicitly map for core and share facts and remove caseInsensitiveSort to maintain
                // the same order as in facts tab preview
                // const coreFacts: string[] = runConfig.facts.map(factSetToCoreDisplayNames).flat();
                // const shareFacts: string[] = runConfig.facts.map(factSetToSharedDisplayNames).flat();
                // valueMap.facts = [...coreFacts, ...shareFacts].slice(0, maxSampleSize);
            }

            // stacked product details needs to include the names of the non-stacked product sub dimensions
            let stackedDetailPrefix = runConfig.layout.rows
                .filter(row => !row.stack && row.type === 'products')
                .map(row => SUB_DIMENSION_VALUE_TO_DISPLAY[row.dim] ?? row.dim)
                .join(' | ');
            if(stackedDetailPrefix) {
                stackedDetailPrefix = `${stackedDetailPrefix} | `
            }

            valueMap.stackedProducts = runConfig.layout.rows
                .filter(v => v.stack)
                .map(v => v.dim)
                .map(dim => valueMap[dim].map(value => ({
                    dim: `${stackedDetailPrefix}${SUB_DIMENSION_VALUE_TO_DISPLAY[dim] ?? dim}`,
                    value
                }))).flat();

        } catch (err) {
            console.error(err);
        }
        return valueMap;
    }

    /**
     * Produces an array that is the cartesian product of the dimension's values
     * @param dimensionNames Array of dimension names
     * @param dimensionValues
     */
    function generateCombinations(dimensionNames: string[], dimensionValues: DimensionValueMap): PreviewRow[] {

        // reduce the array dimension names to a map with keys for each dimension's values
        const map = dimensionNames.reduce((map, dim) => ({
            ...map,
            [dim]: dimensionValues[dim]
        }), {});

        return cartesian(map);
    }

    function getRegularColDefs(rows: DodRowDef[]): PreviewColDef[] {

        const defs = rows.filter(v => !v.hide && !v.stack)
            .map(({dim, type}: DodRowDef) => ({
                headerName: SUB_DIMENSION_VALUE_TO_DISPLAY[dim] ?? dim,
                type,
                field: dim
            }));

        return defs;
    }

    function getAggregateColDefs(columns: DodColDef[], dimensionValues: DimensionValueMap): PreviewColDef[] {

        const dimensions: string[] = columns.filter(v => !v.hide).map(v => v.dim);
        const isDateField = dimensions.find(v => v === 'date');
        return generateCombinations(dimensions.filter(v => v !== 'date'), dimensionValues).map((group: PreviewRow) => {
            return dimensions.map(dimension => {
                if(isDateField && dimension === "date"){
                    const index = dimensionValues.timePeriods.findIndex(val => val === group["timePeriods"])
                    return `${dimensionValues['date'][index]}`;
                }
                return group[dimension]
            }).join('\n')
        }).map(headerName => ({
            headerName,
            type: 'aggregated',
            field: 'aggregated',
        }));
    }

    function generateRowData(cols: ColDef[], dimensionValues: DimensionValueMap): PreviewRow[] {
        // find all of the non-stacked dimensions
        const dimensionColumns: string[] = cols
            .filter(col => [
                'products',
                'markets',
                'time_periods',
                'facts'
            ].includes(col.type as string))
            .map(v => v.field!);

        // if we have stacked dimensions make sure they're included in the data
        if (cols.some(col => col.type === 'stackedProducts')) {
            dimensionColumns.push('stackedProducts')
        }
        let data: PreviewRow[] = generateCombinations(dimensionColumns, dimensionValues);
        if(cols.find(c => c.type == "date")){
            data = data.map(d => {
                const index = dimensionValues.timePeriods.findIndex(val => val === d.timePeriods);
                d.date = dimensionValues['date'][index] ?? "";
                return d;
            })
        }
        return data;    
    }

    function getPageTitles(rows, columns, dimensionValues: DimensionValueMap): ReactNode[] {

        const pagedDims: string[] = [
            ...rows.filter((rowItem) => rowItem.pageBy),
            ...columns.filter((columnItem) => columnItem.pageBy),
        ].map((item) => item.dim);

        let pageByGroups: PreviewRow[] = generateCombinations(pagedDims, dimensionValues);

        return pageByGroups.map(group => (<>
            {pagedDims.map(dim => <p key={dim}>{group[dim]}</p>)}
        </>));
    }

    function handleDataChanged() {
        // this has to be triggered after everything else finishes,
        // the 0 second setTimeout pushes this to end of the queue
        setTimeout(resizeColumns, 0);
    }

    function resizeColumns() {
            const columnApi = gridRef.current?.columnApi;

            if (!columnApi) return;

            // we want to ressize all but the last flex column
            const columnIds = columnApi.getColumns()?.slice(0, -1).map(column => column.getId())
            if (columnIds?.length) {
                columnApi.autoSizeColumns(columnIds);
            }
    }

    function handleError(err) {
        console.error(err);
    }

    return (
        <ErrorBoundary fallback={<>Preview Failed</>} onError={handleError}>
            <DodPanel
                className={classnames(baseClassName, className)}
                expandable={true}
                name={'dod-layout-preview'}
                title={title}
                enableFilter={false}
            >
                <AgGridReact
                    ref={gridRef}
                    className={classnames('ag-theme-alpine', `${baseClassName}__preview-table`)}
                    gridOptions={gridOptions}
                    columnDefs={columnDefs}
                    rowData={rowData}
                    onRowDataUpdated={handleDataChanged}
                    overlayNoRowsTemplate="No preview rows available."
                    onFirstDataRendered={resizeColumns}
                />
                <div className={`${baseClassName}__tabs`}>
                    {pageTitles.map((title, index) => (
                        <div className={`${baseClassName}__tab`} key={index}>
                            <ByzzerMessagePopUp tipDelay={[500, 0]} tip={title} tipLocation="top-start">
                                Page {index + 1}
                            </ByzzerMessagePopUp>
                        </div>
                    ))}
                </div>
            </DodPanel>
        </ErrorBoundary>
    );
}

function isCharacteristic(type: string): boolean {
    return ['characteristics', 'customCharacteristics'].includes(type);
}

function PreviewGridHeader({displayName}) {

    const displayNames = displayName.split('\n');

    if (displayNames.length > 1) {
        return <div className={`${baseClassName}__header--multi-dim`}>
            {displayNames.map(name => <div key={name} className={`${baseClassName}__header-dim`}>{name}</div>)}
        </div>
    }

    return displayName;
}

export default DodExcelLayoutPreview;
