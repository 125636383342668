import './MyHomePage.scss';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { HomePageHeader, HomePageNotifications, HomePageFilter, TileConfigEditor } from '@/components/MyHomePage';
import DashboardSimpleContent from '@/components/dashboard/DashboardSimpleContent';
import { useUser } from '@/contexts/UserContext';
import _ from 'lodash';
import { addMinutes } from 'date-fns';
import CaseStudy from '../HomePage/CaseStudy';

const baseClassName = 'my-home-page';

export default function MyHomePage({ className, ...props }) {
    const [loading, setLoading] = useState<Boolean>(false);
    const { user, categories, subscription, company, preSales } = useUser();
    let preSalesLogic =
        (subscription.metadata.isFree && !preSales) ||
        (subscription.metadata.isFree &&
            preSales &&
            company?.features?.freeAccountGroup !== 'A');

    const [enableFilterPanel, setEnableFilterPanel] = useState<Boolean>(false);
    const [confirmClose, setConfirmClose] = useState<Boolean>(false);
    const [showWarningMessagePopup, setShowWarningMessagePopup] = useState<Boolean>(false);
    const freePackageTileContainerClass = preSalesLogic ? `${baseClassName}__tiles` : '';
    const onFilterPanelOpen = () => {
        setEnableFilterPanel(!enableFilterPanel);
    };
    const onFilterPanelClose = () => {
        if (confirmClose) {
            setShowWarningMessagePopup(true);
        } else {
            setShowWarningMessagePopup(false);
            setEnableFilterPanel(false);
        }
    };

    const onModalFilterPanelClose = () => {
        setShowWarningMessagePopup(false);
        setEnableFilterPanel(false);
    };

    const handleConfirmClose = (state) => {
        setConfirmClose(state);
    };
    /** fetch the contract expiry date for display */
    const subscriptionEndDate = subscription?.endDate;
    const formattedSubscriptionEndDate = new Date(
        addMinutes(new Date(subscriptionEndDate), new Date(subscriptionEndDate).getTimezoneOffset())
    );

    const handleFilterState = async () => {
        setShowWarningMessagePopup(false);
        setEnableFilterPanel(false);
        setConfirmClose(false);
    };
    return (
        <DashboardSimpleContent className={classnames(baseClassName, className)} {...props}>
            <div className={`${baseClassName}__dashboard`}>
                {!subscription?.active ? (
                    <div className={`${baseClassName}__dashboard__expiry`}>
                        <p>
                            <span className={`${baseClassName}__title__expiry`}>Welcome{`, ${user?.firstName}`}!</span>
                        </p>
                        <p>
                            <span>{`
       Your contract has expired on ${formattedSubscriptionEndDate}. Please reach out to your sales representative to renew your contract.`}</span>
                        </p>
                    </div>
                ) : (
                    <>
                        <HomePageHeader
                            className={`${baseClassName}__greeting`}
                            onFilterPanelOpen={onFilterPanelOpen}
                            onFilterPanelClose={onFilterPanelClose}
                            enableFilterPanel={enableFilterPanel}
                        />
                        <HomePageFilter
                            className={'filter_panel__dropdown'}
                            handleFilterState={handleFilterState}
                            enableFilterPanel={enableFilterPanel}
                            handleConfirmClose={(state) => handleConfirmClose(state)}
                            closeFilterPanel={onFilterPanelClose}
                            showWarningMessagePopup={showWarningMessagePopup}
                            onModalFilterPanelClose={onModalFilterPanelClose}
                            filterValues1={undefined}
                            handleApply={undefined}
                            setUserUpdatedFilterValues={undefined}
                            reportletID={undefined}
                        />
                        <div className={freePackageTileContainerClass}>
                            <TileConfigEditor />
                            {preSalesLogic && (
                                <CaseStudy baseClassName={`${baseClassName}__tiles`} />
                            )}
                        </div>
                    </>
                )}
            </div>
            {subscription.metadata.isFree &&
            preSales &&
            company?.features?.freeAccountGroup === 'A' ? (
                <CaseStudy baseClassName={`${baseClassName}__tiles`} />
            ) : (
                <div className={`${baseClassName}__notifications`}>
                    <h2 className={`${baseClassName}__title`}>Latest Updates</h2>
                    <HomePageNotifications category={categories} dashboardLoading={loading} />
                </div>
            )}
        </DashboardSimpleContent>
    );
}
