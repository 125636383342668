import { ByzzerRadio } from '@/components/form';
import React, {useEffect, useState} from 'react'
import './StoryModal.scss'
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { storySkus, selectorLabels } from '@/config/globalVars';

function RunAgainModal(props) {
    const [loading, setLoading] = useState(false);
    const [runAgainRadioList, setRunAgainRadioList] = useState([]);
    let isPriorApprovedMarketSelected = props?.isPriorApprovalMarketPresent; // mrkts that are prod dependent, walmart for ex

    const runAgainRadioListOptions = {
      product: {
        name: "Change Products",
        value: "change-category"
      },
      retailer: {
        name: props?.sku === storySkus.shopperSnapshot ? "Change Channels or Outlets": "Change Retailer",
        value: "change-retailer"
      },
      marketPerformance: {
        name: "Change Market Performance",
        value: "change-market-performance"
      },
      timePeriod: {
        name: "Change Time Period",
        value: "new-time-period"
      },
      editStory: {
        name: "Edit Story",
        value: "edit-story"
      }
    };

    useEffect(() => {
      getRadioButtonOptions();
    }, [])

    const getRadioButtonOptions = () => {
      let runAgainRadioListTemp = [];

      if (props?.sku === storySkus.categoryManagement)
        runAgainRadioListTemp = [runAgainRadioListOptions.product, runAgainRadioListOptions.retailer, runAgainRadioListOptions.timePeriod, runAgainRadioListOptions.editStory]
      if (props?.sku === storySkus.shopperSnapshot)
        runAgainRadioListTemp = [runAgainRadioListOptions.product, runAgainRadioListOptions.retailer, runAgainRadioListOptions.timePeriod, runAgainRadioListOptions.editStory]
      if (props?.sku === storySkus.categoryReview)
        runAgainRadioListTemp = [
        ...(
          isPriorApprovedMarketSelected
            ? []
            : [runAgainRadioListOptions.product, runAgainRadioListOptions.retailer]
          ),
          runAgainRadioListOptions.timePeriod,
          runAgainRadioListOptions.editStory
      ];
      if (props?.sku === storySkus.brandReview) {
        runAgainRadioListTemp = [
          ...(
            isPriorApprovedMarketSelected || props?.isPriorApprovalMarketPresentInMarketPerf
              ? []
              : [runAgainRadioListOptions.product]
          ),
          ...(
            isPriorApprovedMarketSelected
              ? []
              : [runAgainRadioListOptions.retailer]
          ),
          ...(
            (props?.isPriorApprovalMarketPresentInMarketPerf || props?.selectors?.findIndex(e => e?.selectorName === selectorLabels.marketPerformanceObjective) === -1)
              ? []
              : [runAgainRadioListOptions.marketPerformance]
          ),
          runAgainRadioListOptions.timePeriod,
          runAgainRadioListOptions.editStory
        ];
      }
      if (props?.sku === storySkus.priceAndPromotion) {
        runAgainRadioListTemp = [
          ...(
            isPriorApprovedMarketSelected
              ? []
              : [runAgainRadioListOptions.product, runAgainRadioListOptions.retailer]
          ),
          runAgainRadioListOptions.timePeriod,
          runAgainRadioListOptions.editStory
        ];
      }
      if (props?.sku === storySkus.categoryTrendAnalysis) {
        runAgainRadioListTemp = [
          ...(
            isPriorApprovedMarketSelected
              ? []
              : [runAgainRadioListOptions.product]
          ),
          runAgainRadioListOptions.timePeriod,
          runAgainRadioListOptions.editStory
        ];
      }
      setRunAgainRadioList(runAgainRadioListTemp);
  }

    const [radioValue, setRadioValue] = useState()

    const radioChanged = (event) => {
        setRadioValue(event.target.value)
    }

    const isRadioChecked = (value) =>{
        return value === radioValue
    }

    const nextClickedHandler = () => {
        setLoading(true);
        props.onNextClicked(radioValue);
        if(radioValue !== 'edit-story')
          setLoading(false);
    }

    return (
        <div>
          <ByzzerMask loading={loading}/>
          {runAgainRadioList.map(val=>{
          return (
              <div>
                  <ByzzerRadio 
                      key={val.value}
                      label={val.name} 
                      value={val.value} 
                      onChange={radioChanged} 
                      checked={isRadioChecked(val.value)}/>
              </div>  
          )              
              })}
        <i onClick={() => nextClickedHandler()} className={'story-modal-rightArrow'}/>
        </div>
    )
}

export default RunAgainModal