import ErrorModal from '@/components/ErrorModal';
import { alert as byzzerAlert, confirm, openErrorModal } from '@/components/form';
import RunAgainModal from '@/components/story/storyModals/RunAgainModal';
import StoryModal from '@/components/story/storyModals/StoryModal';
import { useEvents, useUser, useApp } from '@/contexts/UserContext';
import {
    alertMessages,
    channelList,
    eventTypes,
    selectorLabels,
    selectorStates,
    storySkus,
    storySkusForInsightValidation,
} from '@/config/globalVars';
import { getInsightsWarningMsg } from '@/services/story.service';
import { useTenantApi } from '@/hooks/useTenantApi';
import { errorCodeDetails, showErrorModal, useBetterNavigate, useSetState } from '@/utils';
import {
    getFileAlias,
    getStoryPayload,
    initializeStoredValues,
    modifyObjectiveList,
    storyAppendMore,
    getAccessLevel,
} from '@/utils/storiesUtil';
import { storySelectorAttributes } from '@/utils/storySelectorUtil';
import DataSpecification from '@/views/story/buildStory/DataSpecification';
import { Tooltip } from 'antd';
import className from 'classnames';
import { addMinutes, format, isWithinInterval } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RenderTableView } from './RenderTable';
import './StoriesHistory.scss';
import '@/pages/StoryViewer/BuildStory.scss';
import { TrackClick } from '@/analytics';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import DeleteIcon from '@images/icons/storyIcons/deleteIconNew.svg';
import ViewIcon from '@images/icons/storyIcons/eyeIcon.svg';
import RunIcon from '@images/icons/storyIcons/runAgainIcon.svg';
import DownloadIcon from '@images/icons/storyIcons/downloadIcon.svg';
import warningIcon from '@images/icons/warningIcon.svg';
function StoriesHistory() {
    const { getStoryRunById } = useTenantApi();
    const { buildStories,    deleteProductUsingActionId: deleteStoryHistory,    downloadStory: downloadGeneratedSlide,    getProductChars,    getStoriesHistory,    isPriorApprovalMarket,    getMySubscriptionUsage} = useTenantApi();
    const navigate = useBetterNavigate();
    const wrapperClass = className('story-history');
    const { user, company, subscription, categories: subscriptionCategories } = useUser();
    const events = useEvents();
    const [pageSize, setPageSize] = useState(10);
    const defaultFilterSelectedValues = {
        storyName: null,
        storyType: null,
        userData: null,
        ranBy: null,
        market: null,
        brand: null,
        categories: null,
        runFrom: null,
        runTo: null,
        limit: pageSize,
        offset: 0,
    };
    const defaultFilterValues = {
        storyName: [],
        storyType: [],
        userData: [],
        ranBy: [],
        market: '',
        brand: '',
        categories: '',
        runFrom: null,
        runTo: null,
    };
    const [loading, setLoading] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [modalTitle, setModalTitle] = useState('');
    const [selectorState, setSelectorState] = useState({});
    const [fileNameAlias, setFileNameAlias] = useState(null);
    const [remainingReportRuns, setRemainingReportRuns] = useState();
    const remainingRunsRef = useRef();
    const [storyCountDetails, setStoryCountDetails] = useState({});
    let selectorStateDefaultVal = {
        saveDraftEnabled: false,
        activeCollapsePanel: [],
        focusMarket: '',
        timePeriodWeeks: 0,
        timePeriodEndDate: '',
        selectorsStatus: [],
        dimension: '',
        focusProductSelections: {},
        focusMarketSelections: {},
        timePeriodSelections: {},
        refreshMarkets: false,
        refreshCharacteristicFeature: false,
        refreshComparisonMarkets: false,
        characteristicSelections: '',
        characteristicOptions: [],
        comparisonMarketSelections: {},
        panelTimePeriodSelections: {},
        selectedMarketItemLvlData: {},
    };
    const [selectorStateData, setSelectorStateData] = useSetState(selectorStateDefaultVal);
    const [dataSpecificationSelectors, setDataSpecificationSelectors] = useState([]);
    const [panelStatusCompleted, setPanelStatusCompleted] = useState(true);
    const [runAgainSelector, setRunAgainSelector] = useState([]);
    const [filterSelectedValues, setFilterSelectedValues] = useSetState({ ...defaultFilterSelectedValues });
    const filterSelectedValuesRef = useRef();
    filterSelectedValuesRef.current = filterSelectedValues;
    const [isFilterSelected, setIsFilterSelected] = useState(false);
    const [clearAllFilters, setClearAllFilters] = useState(false);
    const [filterValues, setFilterValues] = useSetState({ ...defaultFilterValues });
    const [dateSelected, setDateSelected] = useState(false);
    const byzzerDateRange = useRef();
    const [storyHistoryFound, setStoryHistoryFound] = useState(false);
    const [showModal, enableshowModal] = useState({
        displayModal: false,
        title: '',
        message: '',
        errorInfo: '',
    });
    const [runAgainStorySku, setRunAgainStorySku] = useState();
    const [showRunAgainModal, setShowRunAgainModal] = useState(false);
    const [marketGroup, setMarketGroup] = useState('');
    const [selectorConfigsData, setSelectorConfigsData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [pageIndexCount, setPageIndexCount] = useState(0);
    const [refreshTableData, setRefreshTableData] = useState(false);
    const reportDetailRef = useRef();
    const [clearDateRange, setClearDateRange] = useState(false);
    const [isPriorApprovalMarketPresent, setIsPriorApprovalMarketPresent] = useState(false);
    const [isPriorApprovalMarketPresentInMarketPerf, setIsPriorApprovalMarketPresentInMarketPerf] = useState(false);
    const [reportLimit, setReportLimit] = useSetState(0);
    const userAccessLevel = parseInt(getAccessLevel(company?.accessLevel));

    const storyStatus = {
        completed: 'Story Complete',
        running: 'Story Running',
        failed: 'Story Failed',
    };
    /**
     * function to hide error modal(pop-up)
     */
    const hideErrorModal = () => {
        enableshowModal(false);
    };
    const columnData = [
        {
            Header: 'Story Name',
            accessor: 'story_name',
            sticky: 'left',
            maxWidth: 400,
            minWidth: 140,
            width: 250,
            filter: 'includes',
        },
        {
            Header: 'Type',
            accessor: 'story_type',
            sticky: 'left',
            filter: 'includes',
        },
        {
            Header: 'Market(s)',
            accessor: 'market_name',
            sticky: 'left',
            disableSortBy: true,
        },
        {
            Header: 'Date Run',
            accessor: 'date_time_run',
            sticky: 'left',
            Cell: ({ value }) => {
                return format(new Date(value), 'MM/dd/yyyy HH:mm');
            },
        },
        {
            Header: 'Brand(s)',
            accessor: 'brand',
            sticky: 'left',
            disableSortBy: true,
        },
        {
            Header: 'Categories',
            accessor: 'categories',
            sticky: 'left',
            disableSortBy: true,
        },
        {
            Header: 'Status',
            accessor: 'stories_status',
            sticky: 'left',
            Cell: ({ value }) => {
                if (value.results.slideStatus === selectorStates.completed) {
                    return <span className={'story-run-again-text'}>{storyStatus.completed}</span>;
                } else if (value.results.slideStatus === 'processing') {
                    return <span className={'story-run-again-text'}>{storyStatus.running}</span>;
                } else if (value.results.slideStatus === 'failed') {
                    return <span className={'story-run-again-text'}>{storyStatus.failed}</span>;
                } else {
                    return <span>{value.results.slideStatus}</span>;
                }
            },
        },
        {
            Header: 'Actions',
            accessor: 'action_data',
            sticky: 'left',
            disableSortBy: true,
            Cell: ({ value }) => {
                if (value.results.slideStatus === selectorStates.completed) {
                    return (
                        <div className="storyAction__actionContainer">
                            <Tooltip
                                placement="top"
                                title={'Download'}
                                overlayClassName="tooltip-story .ant-tooltip-inner"
                            >
                                <span onClick={() => downloadStory(value)}>
                                    <TrackClick
                                        name="story-download"
                                        data={{
                                            id: value.id,
                                            sku: value.productSku
                                        }}
                                    >
                                        <img
                                            src={DownloadIcon}
                                            alt={'active download'}
                                            className={'storyAction__actionIcon'}
                                        />
                                    </TrackClick>
                                </span>
                            </Tooltip>
                            {subscription?.active && user?.role !== 'viewer' && (
                                <>
                                    <Tooltip
                                        placement="bottom"
                                        title={'Run Again'}
                                        overlayInnerStyle={{ width: '85px' }}
                                        overlayClassName="tooltip-story .ant-tooltip-inner"
                                    >
                                        <span onClick={() => fetchStoryData(value, 'editStory')}>
                                            <img src={RunIcon} alt={'run again'} className={'storyAction__actionIcon'} />
                                        </span>
                                    </Tooltip>

                                    <Tooltip
                                        placement="top"
                                        title={'Delete'}
                                        overlayClassName="tooltip-story .ant-tooltip-inner"
                                    >
                                        <span onClick={() => deleteStory(value.id)}>
                                           <TrackClick
                                               name={`story-delete`}
                                               data={{
                                                   id: value.id,
                                                   productSku: value.productSku,
                                                   name: value.actionConfig?.storyCustomName,
                                                   state: value.results?.slideStatus
                                               }}
                                           >
                                                <img
                                                    src={DeleteIcon}
                                                    alt={'delete'}
                                                    className={'storyAction__actionIcon storyAction__tooltip'}
                                                />
                                            </TrackClick>
                                        </span>
                                    </Tooltip>
                                </>

                            )}

                        </div>
                    );
                } else if (value.results.slideStatus === 'processing') {
                    return (
                        <div className="storyAction__actionContainer">
                            <img
                                src={DownloadIcon}
                                alt={'download disabled'}
                                className={'storyAction__actionIcon storyAction__disable_btn'}
                            />
                        </div>
                    );
                } else if (value.results.slideStatus === 'failed') {
                    return (
                        <div className="storyAction__actionContainer">
                            <Tooltip
                                placement="top"
                                title={'Warning'}
                                overlayClassName="tooltip-story .ant-tooltip-inner"
                            >
                                <span onClick={() => fetchStoryData(value, 'modifySelections')}>
                                    <img src={warningIcon} alt={'warning'} className={'storyAction__actionIcon'} />
                                </span>
                            </Tooltip>

                            <Tooltip
                                placement="top"
                                title={'Delete'}
                                overlayClassName="tooltip-story .ant-tooltip-inner"
                            >
                                <span onClick={() => deleteStory(value.id)}>
                                    <TrackClick
                                        name={`story-delete`}
                                        data={{
                                            id: value.id,
                                            productSku: value.productSku,
                                            name: value.actionConfig?.storyCustomName,
                                            state: value.results?.slideStatus
                                        }}
                                    >
                                        <img
                                            src={DeleteIcon}
                                            alt={'delete story'}
                                            className={'storyAction__actionIcon storyAction__deleteIcon'}
                                        />
                                    </TrackClick>
                                </span>
                            </Tooltip>
                        </div>
                    );
                } else {
                    return '';
                }
            },
        },
    ];

    useEffect(() => {
        fetchAllData();
        initializeStoredValues();
    }, []);

    const updateFilterOptionsList = (result) => {
        let categories = [];
        let runBy = [];
        let brands = [];
        let markets = [];
        let storyNames = [];
        let storyType = [];
        for (const item of result) {
            if (item.action_data.actionConfig.category) {
                for (const item1 of item.action_data.actionConfig.category) {
                    if (!categories.includes(item1)) {
                        categories.push(item1);
                    }
                }
            }
            if (item.action_data.actionConfig.market) {
                for (const item1 of item.action_data.actionConfig.market) {
                    if (!markets.includes(item1)) {
                        markets.push(item1);
                    }
                }
            }
            if (item.action_data.actionConfig.brand) {
                if (typeof item.action_data.actionConfig.brand === 'string') {
                    if (!brands.includes(item.action_data.actionConfig.brand)) {
                        brands.push(item.action_data.actionConfig.brand);
                    }
                } else if (Array.isArray(item.action_data.actionConfig.brand)) {
                    for (const item1 of item.action_data.actionConfig.brand) {
                        if (!brands.includes(item1)) {
                            brands.push(item1);
                        }
                    }
                }
            }

            if (!runBy.includes(item.action_data.userEmail)) {
                runBy.push(item.action_data.userEmail);
            }
            if (!storyNames.includes(item.story_name)) {
                storyNames.push(item.story_name);
            }
            if (!storyType.includes(item.story_type)) {
                storyType.push(item.story_type);
            }
        }
        // setFilterSelectedValues({ ...defaultFilterSelectedValues  });
        setFilterValues({
            categories: categories.sort((a, b) => a.trim().localeCompare(b.trim())),
            brands: brands.sort((a, b) => {
                if (typeof a === 'string' && typeof b === 'string') {
                    a.trim().localeCompare(b.trim());
                }
            }),
            markets: markets.map((a) => { return a.name ?? a }).sort((a, b) => a.trim().localeCompare(b.trim())),
            ranBy: runBy.sort((a, b) => a.trim().localeCompare(b.trim())),
            storyName: storyNames.sort((a, b) => a.trim().localeCompare(b.trim())),
            storyType: storyType.sort((a, b) => a.trim().localeCompare(b.trim())),

        });
    };
    useEffect(() => {
        const [event] = events;
        loadSubscriptionUsage();
        if (event?.type === eventTypes.storyGenerated || event?.type === eventTypes.storyFailed) {
            clearFilters();
            fetchAllData();
        }
    }, [events]);

    const [storiesData, setStoriesData] = useState([]);
    const [allStoriesData, setAllStoriesData] = useState([]);
    // const [storiesCount, setStoriesCount] = useState(0);
    const loadSubscriptionUsage = async () => {
        let [usage] = await Promise.all([getMySubscriptionUsage()]);
        setRemainingReportRuns(usage.basicReports.limit - usage.basicReports.used);
        remainingRunsRef.current = usage.basicReports.limit - usage.basicReports.used;
        setReportLimit(usage.basicReports.limit ?? 0);
    };
    const parseStoryType = (prouctSku) => {
        if (prouctSku === storySkus.categoryReview) {
            return 'Category Review'
        }
        if (prouctSku === storySkus.categoryManagement) {
            return 'Category Management'
        }
        if (prouctSku === storySkus.shopperSnapshot) {
            return 'Shopper Snapshot'
        }
        if (prouctSku === storySkus.brandReview) {
            return 'Brand Review'
        }
        if (prouctSku === storySkus.priceAndPromotion) {
            return 'Pricing and Promotion'
        }
        if (prouctSku === storySkus.categoryTrendAnalysis) {
            return 'Category Trend Analysis'
        }
    }
    const historyDataParse = async (historyData) => {
        let responseData = [];
        let datetime = [];
        historyData = historyData?.[0] === undefined ? [] : historyData;
        historyData.forEach((item, _index) => {
            let marketName = storyAppendMore(item?.actionConfig?.market, '', '', false);
            let productTitle = item?.product?.title === undefined ? '' : item.product.title;
            let storyName =
                item.actionConfig.storyCustomName === undefined ? productTitle : item.actionConfig.storyCustomName;
            storyName = storyName !== null ? storyName.replace(/[\\\["\\\]"]/g, '') : '';
            let storyResult = item.results;
            let brandName =
                item.actionConfig?.brand === undefined ? '' : storyAppendMore(item.actionConfig.brand, '', '', false);
            let categoriesData = storyAppendMore(item?.actionConfig?.category, '', '', false);
            let storyType = parseStoryType(item?.productSku)
            let productSku = item.actionConfig.productSku;
            if (storyResult.slideStatus !== 'deleted') {
                responseData.push({
                    story_name: storyName,
                    story_type: storyType,
                    market_name: marketName,
                    date_time_run: item.actionDtm,
                    brand: brandName,
                    categories: categoriesData,
                    stories_status: item,
                    action_data: item,
                    productSku: productSku
                });
                datetime.push(format(new Date(item.actionDtm), 'yyyy-MM-dd hh:mm'));
            }
        });
        if (historyData?.length === 0) {
            setStoryHistoryFound(false);
        } else {
            setStoryHistoryFound(true);
        }
        return responseData;
    };

    const downloadStory = (story) => {
        try {
            byzzerAlert({
                content: <div className="story_modal_alertText">{alertMessages.storyDownloading}</div>,
            });
            const key = story.downloadKey;
            const productSku = story.productSku;

            if(['793', '842', '521'].includes(productSku)){
                downloadGeneratedSlide(key);
            } else {
                downloadGeneratedSlide(key, 'blob');
            }
        } catch(err){
            openErrorModal({
                title: 'Download Failed!',
                content: (
                    <>
                        <p>Fear not our engineering team is on the job.</p>
                    </>
                ),
                errorId: err.id
            });
        }
    };

    async function retainObjectives(keys, storyConfig) {
        getObjectiveList(storyConfig?.metadata?.env?.objective_lists ?? storyConfig?.metadata?.objective_lists, keys);
    }

    const getObjectiveList = async (objectivesListFromConfig, objectiveKeys) => {
        let objectivesInfo = await modifyObjectiveList(objectivesListFromConfig, company?.accessLevel, objectiveKeys);
        let objectiveList = objectivesInfo.list ?? [];

        localStorage['objectivesList'] = JSON.stringify(objectiveList);
        let selectedObjectives = objectiveList.filter((item) => item.isChecked === 'checked' && item.serialNo !== '');
        localStorage['selectedObjectives'] = JSON.stringify(selectedObjectives);
    };

    // navigate to story page to edit or view
    const navigateToStoryPage = () => {
        let itemInfo = JSON.parse(localStorage['storyData'] || '{}');
        let type = localStorage['action'];

        let dataSpecificationData = JSON.parse(localStorage['dataSpecification'] || '{}');

        if (dataSpecificationData) {
            let storyName =
                itemInfo?.actionConfig?.storyCustomName.length === 1
                    ? itemInfo?.actionConfig?.storyCustomName[0]
                    : itemInfo?.actionConfig?.storyCustomName;
            storyName = storyName.replace(/[\\\["\\\]"]/g, '');
            let storyConfig = reportDetailRef.current;
            localStorage.setItem('storySku', itemInfo?.productSku);
            localStorage.setItem('fromBack', true);
            localStorage['activeStep'] = JSON.stringify(type === 'viewStory' ? 4 : 1);
            localStorage.setItem(type, true);
            localStorage.setItem('storyName', storyName);
            localStorage['storiesTraining'] = JSON.stringify(
                storyConfig?.metadata?.env?.storiesTraining ?? storyConfig?.metadata?.storiesTraining
            );
            const path =
                type === 'viewStory'
                    ? '/dashboard/story_builder/run_story'
                    : '/dashboard/story_builder/objective_selection'; // incase of edit story or modify selections

            const fromSelection = type === 'viewStory' ? 'viewStories' : type;
            navigate(path, {
                state: {
                    from: fromSelection,
                    storyType: itemInfo.actionType,
                    storyConfig: {
                        storyConfig,
                    },
                    itemInfo: itemInfo,
                    remainingReportRuns: remainingRunsRef.current,
                    storyTitle: itemInfo?.product?.title,
                },
            });
        }
        setShowRunAgainModal(false);
        localStorage.removeItem('storyData');
        localStorage.removeItem('action');
    };

    // fetch story details
    const fetchStoryData = async (itemInfo, action) => {
        setLoading(true);
        localStorage['storyData'] = JSON.stringify(itemInfo);
        let actionValue = action;
        if (action === 'modifySelections') {
            actionValue = 'editStory';
            localStorage['modifySelections'] = true;
        }
        localStorage['action'] = actionValue; // viewStory, editStory
        let {configuration,sku, story} = await getStoryRunById(itemInfo.id);
        const usage = await getMySubscriptionUsage();
        localStorage.setItem('storySku', sku);
        navigate('/dashboard/story_builder', {
            params: {},
            state: {
                storyConfig:{...story} ,
                defaultValues: { ...configuration.runConfig },
                remainingReportRuns:usage?.basicReports ? usage.basicReports.limit - usage.basicReports.used : 0
            },
        });
        setLoading(false);
    };

    const deleteStory = async (storyId) => {
        if (
            !(await confirm({
                title: 'Delete Story',
                content: <div className="byzzer-allocation-warning">Are you sure you want to delete this story?</div>,
                yesLabel: 'Yes',
                noLabel: 'No',
            }))
        ) {
            return;
        }
        setLoading(true);
        let delResponse = await deleteStoryHistory(storyId);
        setLoading(false);
        let alertContent = delResponse === 'Success' ? 'Story deleted successfully!' : 'Something went wrong';
        byzzerAlert({
            content: <div className="story_modal_alertText">{alertContent}</div>,
        });
        clearFilters();
        fetchAllData();
    };

    // open modal or navigate based on view, edit or modify action
    const performViewEditAction = () => {
        let modifySelectionsFlag = localStorage['modifySelections'] ? true : false;
        if (localStorage['action'] === 'viewStory') {
            navigateToStoryPage();
        } else if (localStorage['action'] === 'editStory' && !modifySelectionsFlag) {
            // open modal to edit panel/story
            setShowRunAgainModal(true);
        } else if (localStorage['action'] === 'editStory' && modifySelectionsFlag) {
            // open modal to modify selections and display error message to User
            modifySelections();
        }
    };

    // modify selections for existing story
    const modifySelections = async () => {
        let itemInfo = JSON.parse(localStorage['storyData'] || '{}');
        let storyName =
            itemInfo?.actionConfig?.storyCustomName.length === 1
                ? itemInfo?.actionConfig?.storyCustomName[0]
                : itemInfo?.actionConfig?.storyCustomName;

        let errorMessage = '';
        if (
            itemInfo?.results?.error?.message !== undefined &&
            itemInfo?.results?.error?.message !== null &&
            typeof itemInfo?.results?.error?.message !== 'object' &&
            !Array.isArray(itemInfo?.results?.error?.message)
        ) {
            errorMessage = itemInfo?.results?.error?.message;
        } else {
            errorMessage = `Story generation failed. Please try re-running ${storyName} or reach out to Customer Service.`;
        }

        if (
            !(await confirm({
                closeButton: false,
                className: 'my-story__warning-container',
                content: (
                    <>
                        <p>{errorMessage}</p>
                        <p>Do you want to modify the selections?</p>
                    </>
                ),
                // content: <div className="byzzer-allocation-warning">Are you sure you want to modify this story?</div>,
                yesLabel: 'Yes, Modify the Selections',
                noLabel: 'No',
            }))
        ) {
            return;
        }
        navigateToStoryPage();
    };

    const modalCloseHandler = () => {
        setSelectedRowData(null);
        setModalTitle(null);
        setShowRunAgainModal(false);
        setSelectorStateData(selectorStateDefaultVal);
    };

    const checkFilters = async () => {
        let isFilterSelected = false;
        Object.entries(filterSelectedValues).forEach(([key, value]) => {
            if (key !== 'limit' && key !== 'offset' && value !== null && value !== '' && value !== undefined) {
                isFilterSelected = true;
            }
        });
        setIsFilterSelected(isFilterSelected);
    };

    const fetchHistoryData = async (pageSizeVal, pageIndex) => {
        let requestBody = JSON.parse(JSON.stringify(filterSelectedValuesRef.current));
        if (pageSizeVal) {
            requestBody.limit = pageSizeVal;
        } else {
            requestBody.limit = pageSize;
        }
        if (pageIndex) {
            requestBody.offset = pageIndex;
        } else {
            requestBody.offset = 0;
        }
        if (requestBody.ranBy === 'All') {
            requestBody.ranBy = null;
        }
        if (requestBody.storyName === 'All') {
            requestBody.storyName = null;
        }
        /**
         * Removed story overall row count size from API
         */
        let historyData = await getStoriesHistory(requestBody);
        let historyTableData = historyData.products;
        return { products: historyTableData, limit: historyData.limit ?? 0, requestBodyVal: requestBody };
    };

    const fetchIdRef = useRef(0);

    const fetchAllData = useCallback(async () => {
        // This will get called when the table needs new data
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current;

        // Set the loading state
        setLoading(true);
        if (fetchId === fetchIdRef.current) {
            try {
                let responseData = {};
                // Todo: changing limit from 10000 t0 3000 to resolve prod issue in release/1.50.0.
                // Need to add a better solution to resolve this issue
                let storiesHistory = await fetchHistoryData(10000, 0);
                try {
                    responseData = await historyDataParse(storiesHistory.products);
                    // setStoriesCount(storiesHistory.limit);
                    setAllStoriesData(responseData);
                    setStoriesData(responseData);
                    updateFilterOptionsList(responseData);
                } catch (err) {
                    throw err;
                }
            } catch (error) {
                console.log(error);
                const errorDetails = errorCodeDetails(error.code, 'stories');
                enableshowModal({
                    ...showModal,
                    title: errorDetails.title,
                    displayModal: true,
                    message: errorDetails.description,
                    errorInfo: errorDetails.errorInfo,
                });
            }
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (allStoriesData?.length) {
            filterTableDataForAllData(allStoriesData, filterSelectedValues);
            // fetchData({ pageIndex: 0, pageSize }, filterSelectedValues);
            checkFilters();
        }
    }, [filterSelectedValues]);
    const setDateSelectedFn = useCallback(async (val) => {
        setDateSelected(val);
    }, []);

    const updateStoriesFilters = (value, type) => {
        setFilterSelectedValues({
            [type]: value ? value : null,
        });
    };
    const filterTableDataForAllData = async (dataset = allStoriesData, filters = filterSelectedValues) => {
        if (
            !dataset?.length ||
            (!filters?.market &&
                !filters?.categories &&
                !filters?.brand &&
                !filters?.ranBy &&
                !filters.storyName &&
                !filters?.storyType &&
                !filters.runFrom)
        ) {
            // setStoriesData(dataset);
            setStoriesData(allStoriesData);
            return dataset;
        }
        let runFromDate;
        let runToDate;
        if (filters.runFrom && filters.runTo) {
            runFromDate = new Date(filters.runFrom.concat(' 00:00'));
            runToDate = new Date(filters.runTo.concat(' 23:59'));
            runFromDate = new Date(addMinutes(runFromDate, runFromDate.getTimezoneOffset()));
            runToDate = new Date(addMinutes(runToDate, runToDate.getTimezoneOffset()));
        }
        const newData = {};
        Object.entries(filters)
            .filter(([, value]) => value !== null)
            .forEach(([key, value]) => {
                if (['brand', 'market', 'categories', 'ranBy', 'runFrom', 'runTo', 'storyName', 'storyType'].includes(key)) {
                    newData[key] = value;
                }
            });
        const applyFilter = (data, filter) => {
            return data.filter((storiesDataPoint) => {
                return Object.entries(filter).every(([prop, find]) => {
                    const brandEval =
                        Array.isArray(storiesDataPoint?.action_data?.actionConfig?.brand) ? (typeof storiesDataPoint?.action_data?.actionConfig?.brand === 'string'
                            ? storiesDataPoint?.action_data?.actionConfig?.brand === filters?.brand
                            : storiesDataPoint?.action_data?.actionConfig?.brand?.includes(filters?.brand)) : false;
                    const marketEval = storiesDataPoint?.action_data?.actionConfig?.market?.includes(filters?.market);
                    const categoriesEval = storiesDataPoint?.action_data?.actionConfig?.category?.includes(
                        filters?.categories
                    );
                    const runByEval = storiesDataPoint?.action_data?.userEmail === filters?.ranBy;

                    const storyNameEval = storiesDataPoint?.story_name === filters?.storyName;
                    const storyTypeEval = storiesDataPoint?.story_type === filters?.storyType;
                    if (prop === 'brand') {
                        return brandEval;
                    } else if (prop === 'market') {
                        return marketEval;
                    } else if (prop === 'runFrom' || prop === 'runTo') {
                        const dateRangeEval = isWithinInterval(new Date(storiesDataPoint.date_time_run), {
                            start: runFromDate,
                            end: runToDate,
                        });
                        return dateRangeEval;
                    } else if (prop === 'categories') {
                        return categoriesEval;
                    } else if (prop === 'ranBy') {
                        return runByEval;
                    } else if (prop === 'storyName') {
                        return storyNameEval;
                    }
                    else if (prop === 'storyType') {
                        return storyTypeEval;
                    }
                    // return find.includes(storiesDataPoint[prop])
                    return false;
                });
            });
        };
        const data = applyFilter(dataset, newData);
        setStoriesData(data);
        return data;
    };
    const filterTableData = useCallback(async (value, type, pageSizeVal) => {
        if (type !== 'dateRange') {
            updateStoriesFilters(value, type);
            return;
        } else {
            setFilterSelectedValues({
                runFrom: value.runFrom,
                runTo: value.runTo,
            });
            return;
        }
    }, []);

    const clearFilters = () => {
        setFilterSelectedValues({ ...defaultFilterSelectedValues });
        // setFilterValues({ ...defaultFilterValues });
        filterSelectedValuesRef.current = { ...defaultFilterSelectedValues };
        setClearDateRange(true);
        setDateSelectedFn(false);
        setIsFilterSelected(false);
    };

    const onClickClear = useCallback(async (e, pageSize, pageIndex) => {
        clearFilters();
        setRefreshTableData(true);
        setClearAllFilters(true);
        // filterTableDataForAllData()
        setStoriesData(allStoriesData);
        // fetchData({ pageIndex: 0, pageSize }, defaultFilterSelectedValues);
    }, []);

    const nextClickedHandler = (radioValue) => {
        let reportSelectors = [];
        let selectorSatus = [];
        let modalTitleForRadioValue = '';
        setFileNameAlias(null);
        switch (radioValue) {
            case 'change-market-performance':
                selectorSatus =
                    selectorStateData?.selectorsStatus !== undefined
                        ? selectorStateData?.selectorsStatus.filter(
                            (val) => val.selectorName === selectorLabels.marketPerformanceObjective
                        )
                        : [];
                modalTitleForRadioValue = 'Select New Markets for Market Performance Objective';
                setSelectorStateData({
                    selectorsStatus: selectorSatus,
                });
                break;
            case 'new-time-period':
                selectorSatus =
                    selectorStateData?.selectorsStatus !== undefined
                        ? selectorStateData?.selectorsStatus.filter(
                            (val) =>
                                val.selectorName === selectorLabels.timePeriod ||
                                val.selectorName === selectorLabels.panelTimePeriod
                        )
                        : [];
                modalTitleForRadioValue = 'Select a New Time Period';

                setSelectorStateData({
                    selectorsStatus: selectorSatus,
                });
                break;
            // Changes due to BYZ-5159
            // Stories might have common option to edit product and retailer later
            // case 'change-category-retailer':
            //     selectorSatus =
            //         selectorStateData?.selectorsStatus !== undefined
            //             ? selectorStateData?.selectorsStatus.filter(
            //                     (val) =>
            //                         val.selectorName === selectorLabels.product ||
            //                         val.selectorName === selectorLabels.characteristic ||
            //                         val.selectorName === selectorLabels.focusMarket ||
            //                         val.selectorName === selectorLabels.comparisonMarket
            //                 )
            //             : [];
            //     modalTitleForRadioValue = 'Select a New Product and Market';
            //     setSelectorStateData({
            //         selectorsStatus: selectorSatus,
            //     });
            //     break;
            case 'change-category':
                selectorSatus =
                    selectorStateData?.selectorsStatus !== undefined
                        ? selectorStateData?.selectorsStatus.filter(
                            (val) =>
                                val.selectorName === selectorLabels.product ||
                                (runAgainStorySku === storySkus.categoryTrendAnalysis
                                    ? val.selectorName === selectorLabels.trend
                                    : val.selectorName === selectorLabels.characteristic)
                        )
                        : [];

                modalTitleForRadioValue = 'Select a New Product';
                setSelectorStateData({
                    selectorsStatus: selectorSatus,
                });
                break;
            case 'change-retailer':
                selectorSatus =
                    selectorStateData?.selectorsStatus !== undefined
                        ? selectorStateData?.selectorsStatus.filter(
                            (val) =>
                                val.selectorName === selectorLabels.focusMarket ||
                                val.selectorName === selectorLabels.comparisonMarket ||
                                val.selectorName === selectorLabels.channelOutlets
                        )
                        : [];
                modalTitleForRadioValue = 'Select a New Market';
                setSelectorStateData({
                    selectorsStatus: selectorSatus,
                });
                break;
            case 'edit-story':
                navigateToStoryPage();
                break;
            default:
                break;
        }
        if (radioValue !== 'edit-story') setShowRunAgainModal(false);
        if (selectorSatus?.length > 0) {
            selectorSatus.forEach((element) => {
                let temp = storyCountDetails.userData.reportSelectors.filter(
                    (val) => val.selectorLabelName === element.selectorName
                );
                reportSelectors.push(temp[0]);
            });
        }
        setModalTitle(modalTitleForRadioValue);
        setRunAgainSelector(reportSelectors);
    };

    const fileAliasNameChangedHandler = (event) => {
        setFileNameAlias(event.target.value);
    };

    const runStoryClickedHandler = async () => {
        let dataSpecificationData =
            selectorState?.focusProductSelections?.focusBrand === undefined ? selectorStateData : selectorState;
        setLoading(true);
        let selectedObjectives = JSON.parse(localStorage['selectedObjectives'] || '[]');
        let storyType = selectedRowData?.product?.metadata?.storyType ?? '';
        let sku = selectedRowData?.productSku ?? '';

        let warningMsg = '';
        if (
            dataSpecificationData !== undefined &&
            Object.keys(dataSpecificationData).length !== 0 &&
            storySkusForInsightValidation.includes(sku)
        ) {
            [warningMsg] = await Promise.all([
                getInsightsWarningMsg(dataSpecificationData, selectedObjectives, sku, storyType),
            ]);
        }
        setLoading(false);
        if (warningMsg !== '') {
            if (
                await confirm({
                    closeButton: false,
                    className: 'my-story__warning-container',
                    content: <div>{warningMsg}</div>,
                    yesLabel: 'Yes, run the story',
                    noLabel: 'No, I will change selections',
                })
            ) {
                generateStory();
            }
        } else {
            generateStory();
        }
    };

    const generateStory = async () => {
        setLoading(true);
        let requestBody = translateReqBody(selectedRowData, selectedRowData?.productSku);
        let response = await buildStories(requestBody);
        setLoading(false);
        if (response) {
            setFilterValues(defaultFilterValues);
            fetchAllData();
            setSelectedRowData(null);
            setModalTitle(null);
            setShowRunAgainModal(false);
            setSelectorStateData(selectorStateDefaultVal);
            byzzerAlert({
                content: (
                    <>
                        <p>{alertMessages.storyProcessing}</p>
                    </>
                ),
            });
        }
    };

    const translateReqBody = (selectedRowData, productSku = '') => {
        let data = selectorState?.focusProductSelections?.focusBrand === undefined ? selectorStateData : selectorState;
        let characteristicFilters = data?.focusProductSelections?.customCharacteristicFilters.concat(
            data?.focusProductSelections?.characteristicFilters
        );

        let marketType = '';
        if (data?.focusMarketSelections?.focusMarket?.byMarketName?.marketGroup === 'cps') {
            if (data?.focusMarketSelections?.focusMarket?.byMarketName?.markets.includes('All Outlets')) {
            } else {
                if (
                    channelList.some((elem) =>
                        data?.focusMarketSelections?.focusMarket?.byMarketName?.markets.includes(elem)
                    )
                ) {
                    marketType = 'channel';
                } else {
                    marketType = 'outlet';
                }
            }
        }

        let reqBody = {
            productSku: selectedRowData?.productSku,
            storyCustomName: fileNameAlias ? fileNameAlias : selectedRowData?.actionConfig?.storyCustomName,
            storyType: selectedRowData?.product?.metadata?.storyType,
            storyParams: {
                brand:
                    data?.focusMarketSelections?.focusMarket?.byMarketName?.marketGroup === 'cps'
                        ? data?.focusProductSelections?.brand
                        : data?.focusProductSelections?.focusBrand,
                category: data?.focusProductSelections?.competitiveProductSet?.categories,
                subcategory: data?.focusProductSelections?.competitiveProductSet?.subcategories
                    ? data?.focusProductSelections?.competitiveProductSet?.subcategories
                    : [],
                objectives: selectedRowData?.actionConfig?.objectives,
                categoryAlias:
                    data?.focusProductSelections?.focusProductAlias !== undefined
                        ? data?.focusProductSelections?.focusProductAlias
                        : '', //product selector alias
                productFeature: data?.characteristicSelections, // featured characteristic dropdown value
                marketAlias:
                    data?.focusMarketSelections?.marketAlias !== undefined
                        ? data?.focusMarketSelections?.marketAlias
                        : '',
                comparisonMarketAlias:
                    data?.comparisonMarketSelections?.marketAlias !== undefined
                        ? data?.comparisonMarketSelections?.marketAlias
                        : '',
                market: data?.focusMarketSelections?.focusMarket?.byMarketName?.markets,
                focusMarketKeys:
                    data?.focusMarketSelections?.marketKeys?.length > 0
                        ? data?.focusMarketSelections?.marketKeys.filter((e) => e !== null)
                        : [],
                comparisonMarketKeys:
                    data?.comparisonMarketSelections?.marketKeys?.length > 0
                        ? data?.comparisonMarketSelections?.marketKeys.filter((e) => e !== null)
                        : [],
                competitiveMarket:
                    data?.comparisonMarketSelections?.focusMarket?.byMarketName?.markets[0] === undefined
                        ? []
                        : data?.comparisonMarketSelections?.focusMarket?.byMarketName?.markets,
                timePeriodSelections: {
                    timePeriod: data?.timePeriodSelections?.timePeriod,
                    endDate: data?.timePeriodSelections?.endDate,
                    weeks: data?.timePeriodSelections?.weeks,
                },
                panelTimePeriodSelections: {
                    timePeriod: data?.panelTimePeriodSelections?.timePeriod,
                    endDate: data?.panelTimePeriodSelections?.endDate,
                    weeks: data?.panelTimePeriodSelections?.weeks,
                },
                characteristicFilters: characteristicFilters,
                comparisonMarketType: '', // flag to check comparison market type in backend
                marketSelectionTypes: data?.focusMarketsSelectedData.map((e) => e?.mrkt_sel_typ),
                marketPerformanceSelectionTypes: data?.focusMarketsPerformanceSelectedData.map((e) => e?.mrkt_sel_typ),
                retentionData: {
                    selectorsStatus: dataSpecificationSelectors,
                    marketGroup: data?.focusMarketSelections?.focusMarket?.byMarketName?.marketGroup,
                    timePeriodRmsDate: data?.timePeriodSelections?.rmsDate,
                    panelTimePeriodRmsDate: data?.panelTimePeriodSelections?.rmsDate,
                    byMarketType: data?.focusMarketSelections?.focusMarket?.byMarketType,
                    marketType: marketType,
                    isMarketValidFormItems: data?.focusMarketSelections?.isMarketValidFormItems,
                    showRemainingMarkets: data?.focusMarketSelections?.showRemainingMarkets,
                    focusMarketsSelectedData: data?.focusMarketsSelectedData,
                    focusMarketsPerformanceSelectedData: data?.focusMarketsPerformanceSelectedData,
                    comparisonMarketsSelectedData: data?.comparisonMarketsSelectedData,
                    focusProductSelections: data?.focusProductSelections,
                    characteristicSelections: data?.characteristicSelections,
                    activeCollapsePanel: data?.activeCollapsePanel,
                    saveDraftEnabled: data?.saveDraftEnabled,
                    focusMarketSelectedItemsList: data?.focusMarketSelections?.selectedItemsList,
                    selectedMarketItemLvlData: data?.selectedMarketItemLvlData,
                    selectedMarketPerformanceItemLvlData: data?.selectedMarketPerformanceItemLvlData,
                    timePeriodSelectionType: data?.timePeriodSelections?.selectionType,
                    panelTimePeriodSelectionType: data?.panelTimePeriodSelections?.selectionType,
                },
            },
        };

        const newReqBody = getStoryPayload(reqBody, { dataSpecificationValues: data });
        return newReqBody;
    };

    const setDataSpecificationValue = useCallback(async (selectionsValues) => {
        setSelectorState(selectionsValues?.userData?.reportParams); //setting for retaining values
        setSelectorStateData(selectionsValues?.userData?.reportParams);

        let panelStatus = selectionsValues?.userData?.reportParams.selectorsStatus.every(
            (item) => item.status === selectorStates.completed
        ); // checks panel status on each submition
        setPanelStatusCompleted(panelStatus);
        let storyName = selectionsValues?.selectorData?.title
            ? selectionsValues?.selectorData?.title + ' Story'
            : 'Story';
        let fileName = await Promise.all([
            getFileAlias(
                selectionsValues?.userData?.reportParams?.focusProductSelections,
                storyName,
                selectionsValues?.userData?.reportParams?.focusMarketSelections,
                selectionsValues?.userData?.reportParams?.timePeriodSelections,
                selectionsValues?.selectorData?.reportSku,
                selectionsValues?.selectorData
            ),
        ]);
        setFileNameAlias(fileName);
    }, []);

    //validate story based on runs count
    const validateStorySubscription = async (item) => {
        setLoading(true);
        try {
            localStorage.removeItem('dataSpecification');
            const actionType = localStorage['action'];

            let storyConfig = item.product;
            if (userAccessLevel === 5) {
                storyConfig.metadata.reportSelectors = storyConfig.metadata?.reportSelectors.filter(
                    (e) => e !== 'market:option5'
                );
            }
            storyConfig.reportDetails = {
                reportType: storyConfig.type,
                reportSelectors: storyConfig.metadata.reportSelectors
                    ? storySelectorAttributes(storyConfig.metadata.reportSelectors)
                    : {},
                reportSku: storyConfig.sku,
                storyType: storyConfig.metadata.storyType,
                reportRunValue: storyConfig.metadata.reportRunValue,
                title: storyConfig?.title,
            };
            reportDetailRef.current = storyConfig;
            setSelectorConfigsData(storyConfig.metadata.reportSelectors);
            setSelectedRowData(item);
            const storyRunValue = storyConfig?.metadata?.reportRunValue;

            if (actionType === 'viewStory') {
                await validateReportCountAndShow(storyConfig.reportDetails, item);
            } else if (actionType === 'editStory') {
                if (storyConfig.type === 'story' && remainingRunsRef.current) {
                    if (reportLimit === 0) {
                        setLoading(false);
                        byzzerAlert({
                            onResolve: () => { },
                            content: (
                                <>
                                    <p>{alertMessages.noTeamRunsLeft}</p>
                                </>
                            ),
                        });
                    } else if (remainingRunsRef.current <= 0) {
                        setLoading(false);
                        byzzerAlert({
                            onResolve: () => { },
                            content: (
                                <>
                                    <p>{alertMessages.usedAllPurchasedCredits}</p>
                                </>
                            ),
                        });
                    } else if (storyRunValue !== undefined && remainingRunsRef.current - storyRunValue < 0) {
                        setLoading(false);
                        byzzerAlert({
                            onResolve: () => { },
                            content: (
                                <>
                                    <p>{alertMessages.upgradeSubscription}</p>
                                </>
                            ),
                        });
                    } else if (storyRunValue !== undefined && remainingRunsRef.current - storyRunValue >= 0) {
                        if (item?.actionConfig?.retentionData !== undefined) {
                            await validateReportCountAndShow(storyConfig.reportDetails, item);
                        } else {
                            setLoading(false);
                            byzzerAlert({
                                onResolve: () => { },
                                content: (
                                    <>
                                        <p>{alertMessages.noRunAgainFeature}</p>
                                    </>
                                ),
                            });
                        }
                    }
                }
            }
        } catch (err) {
            setLoading(false);
            showErrorModal(err);
        }
    };

    const validateReportCountAndShow = async (storyConfig, item) => {
        let details;
        details = {
            from: 'stories',
            storyType: storyConfig.reportType,
            userData: {
                ...storyConfig,
            },
        };
        if (details) {
            setStoryCountDetails(details);
        }
        if (item) {
            await retainObjectives(Object.keys(item?.actionConfig?.objectives), reportDetailRef.current);
            await setDataSpecificationData(item);
        }
    };

    const getCharacteristicOptions = async (item) => {
        setLoading(true);
        let characteristicsMap = [];
        let productSetObjStory;
        const themePurchaseType = 'subscription_addon_characteristic_theme';
        const categories = item?.actionConfig?.retentionData?.focusProductSelections?.competitiveProductSet?.categories;
        if (categories === undefined) {
            return [];
        }
        productSetObjStory = {
            categories: categories.map((item) => {
                return {
                    hierarchy: 'CATEGORY',
                    name: item,
                };
            }),
            themePurchaseType,
        };

        const charDimensionResp = await getProductChars(productSetObjStory);
        characteristicsMap = charDimensionResp?.characteristics;
        setLoading(false);

        if (characteristicsMap !== undefined || characteristicsMap?.length > 0) {
            return characteristicsMap?.map((item) => item.chr_display_name);
        } else {
            return [];
        }
    };

    //create story selections object
    const setDataSpecificationData = async (item) => {
        setLoading(true);
        const selectorStateObj = {
            shopperSegmentSelections: item.actionConfig.shopperSegmentSelections,
            focusProductSelections:
                item?.actionConfig?.retentionData?.focusProductSelections !== undefined
                    ? item?.actionConfig?.retentionData?.focusProductSelections
                    : {},

            focusMarketSelections: item
                ? {
                    marketAlias: item?.actionConfig?.marketAlias,
                    isMarketValidFormItems: item?.actionConfig?.retentionData?.isMarketValidFormItems,
                    showRemainingMarkets: item?.actionConfig?.retentionData?.showRemainingMarkets,
                    selectedItemsList:
                        item?.actionConfig?.retentionData?.focusMarketSelectedItemsList !== undefined
                            ? item?.actionConfig?.retentionData?.focusMarketSelectedItemsList
                            : [],
                    marketKeys: item?.actionConfig?.focusMarketKeys,
                    focusMarket: {
                        byMarketName: {
                            marketGroup: item ? item?.actionConfig?.retentionData?.marketGroup : '',
                            markets:
                                item?.productSku === storySkus.categoryManagement
                                    ? [...item?.actionConfig?.market, ...item?.actionConfig?.competitiveMarket]
                                    : item?.actionConfig?.market,
                        },

                        byMarketType: null,
                    },
                }
                : {},

            timePeriodSelections: item
                ? {
                    endDate: item?.actionConfig?.timePeriodSelections?.endDate,
                    weeks: item?.actionConfig?.timePeriodSelections?.weeks,
                    timePeriod: item?.actionConfig?.timePeriodSelections?.timePeriod,
                    rmsDate:
                        item?.actionConfig?.retentionData?.timePeriodRmsDate !== undefined
                            ? item?.actionConfig?.retentionData?.timePeriodRmsDate
                            : '',
                    selectionType:
                        item?.actionConfig?.retentionData?.timePeriodSelectionType !== undefined
                            ? item?.actionConfig?.retentionData?.timePeriodSelectionType
                            : item?.actionConfig?.timePeriodSelections?.weeks !== undefined ||
                                item?.actionConfig?.timePeriodSelections?.weeks !== ''
                                ? 'custom'
                                : 'pre-defined',
                }
                : {},
            dimension: item ? item?.actionConfig?.dimension : '',

            characteristicSelections:
                item?.actionConfig?.retentionData?.characteristicSelections !== undefined
                    ? item?.actionConfig?.retentionData?.characteristicSelections
                    : {},
            characteristicOptions: await getCharacteristicOptions(item),
            comparisonMarketSelections: item
                ? {
                    marketAlias: item?.actionConfig?.comparisonMarketAlias,
                    marketKeys: item?.actionConfig?.comparisonMarketKeys,
                    focusMarket: {
                        byMarketName: {
                            marketGroup:
                                item?.actionConfig?.retentionData?.marketGroup !== undefined
                                    ? item?.actionConfig?.retentionData?.marketGroup
                                    : '',
                            markets:
                                item?.actionConfig?.competitiveMarket === undefined ||
                                    item?.actionConfig?.competitiveMarket?.length === 0 ||
                                    item?.actionConfig?.competitiveMarket[0] === undefined
                                    ? []
                                    : item?.actionConfig?.competitiveMarket,
                        },

                        byMarketType: null,
                    },
                }
                : {},

            panelTimePeriodSelections:
                item?.panelTimePeriodSelections?.endDate !== undefined
                    ? {
                        endDate: item?.actionConfig?.panelTimePeriodSelections?.endDate,
                        weeks: item?.actionConfig?.panelTimePeriodSelections?.weeks,
                        timePeriod: item?.actionConfig?.panelTimePeriodSelections?.timePeriod,
                        rmsDate:
                            item?.actionConfig?.retentionData?.panelTimePeriodRmsDate !== undefined
                                ? item?.actionConfig?.retentionData?.panelTimePeriodRmsDate
                                : '',
                        selectionType:
                            item?.actionConfig?.retentionData?.panelTimePeriodSelectionType !== undefined
                                ? item?.actionConfig?.retentionData?.panelTimePeriodSelectionType
                                : '',
                    }
                    : {},
            reportCategory: 'stories',
            focusMarketsSelectedData: item?.actionConfig?.retentionData?.focusMarketsSelectedData,
            comparisonMarketsSelectedData: item?.actionConfig?.retentionData?.comparisonMarketsSelectedData,
            saveDraftEnabled: false,
            activeCollapsePanel: [],
            focusMarket: item?.actionConfig?.market.length === 1 ? item?.actionConfig?.market[0] : '',
            timePeriodWeeks: item ? item?.actionConfig?.timePeriodSelections?.weeks : 0,
            timePeriodEndDate: item ? item?.actionConfig?.timePeriodSelections?.endDate : '',
            selectorsStatus: item ? item?.actionConfig?.retentionData?.selectorsStatus : [],
            refreshMarkets: false,
            refreshCharacteristicFeature: false,
            refreshComparisonMarkets: false,
            selectedMarketItemLvlData: item ? item?.actionConfig?.retentionData?.selectedMarketItemLvlData : {},
            marketsToWatchSelection: item?.actionConfig?.marketsToWatchSelection || {},
            marketSelectionsSummary: item?.actionConfig?.marketSelectionsSummary || {},
            marketOption: item?.actionConfig?.retentionData?.marketOption || '',
            focusMarketsPerformanceSelectedData:
                item?.actionConfig?.retentionData?.focusMarketsPerformanceSelectedData || [],
            marketPerformanceSelections: item
                ? {
                    marketKeys: item?.actionConfig?.marketKeysForMarketPerformance,
                    focusMarket: {
                        byMarketName: {
                            marketGroup: '',
                            markets: item?.actionConfig?.marketForMarketPerformance,
                        },
                        byMarketType: null,
                    },
                }
                : {},
            selectedMarketPerformanceItemLvlData:
                item?.actionConfig?.retentionData?.selectedMarketPerformanceItemLvlData,
        };

        localStorage['SelectorStateData'] = JSON.stringify(
            item ? item?.actionConfig?.retentionData?.selectorsStatus : []
        );
        localStorage['dataSpecification'] = JSON.stringify(selectorStateObj);
        setDataSpecificationSelectors(item ? item?.actionConfig?.retentionData?.selectorsStatus : []);
        setSelectorStateData(selectorStateObj);
        setMarketGroup(item?.actionConfig?.retentionData?.marketGroup);
        let reportSelectors = [];
        let selectors = reportDetailRef.current?.reportDetails;

        let selectorSatus = item?.actionConfig?.retentionData?.selectorsStatus;
        if (selectorSatus?.length > 0) {
            selectorSatus.forEach((element) => {
                let temp = selectors.reportSelectors.filter((val) => val.selectorLabelName === element.selectorName);
                reportSelectors.push(temp[0]);
            });
        }
        localStorage['storySelectors'] = JSON.stringify(reportSelectors);

        await checkForPriorApprovedMarkets(item?.productSku, selectorStateObj);
        setSelectedRowData(item);
        setRunAgainStorySku(item?.productSku);
        performViewEditAction();
        setLoading(false);
    };

    const checkPriorApprovalMarketPresent = async (marketData = [], marketKeys = []) => {
        let priorApprovedMarketsStatus = false;
        if (marketData?.length) {
            for (let i = 0; i < marketData?.length; i++) {
                let marketObj = {
                    market_key: marketData[i]?.mrkt_key,
                    suppressed_market: 'Y',
                    categories: subscriptionCategories,
                };

                let { is_prior_approved_market } = await isPriorApprovalMarket(marketObj);
                if (!is_prior_approved_market) {
                    priorApprovedMarketsStatus = !is_prior_approved_market;
                    break;
                }
            }
        } else {
            for (let i = 0; i < marketKeys?.length; i++) {
                let marketObj = {
                    market_key: marketKeys[i],
                    suppressed_market: 'Y',
                    categories: subscriptionCategories,
                };

                let { is_prior_approved_market } = await isPriorApprovalMarket(marketObj);
                if (!is_prior_approved_market) {
                    priorApprovedMarketsStatus = !is_prior_approved_market;
                    break;
                }
            }
        }
        return priorApprovedMarketsStatus;
    };

    const checkForPriorApprovedMarkets = async (sku, selectorStateObj) => {
        let priorApprovalMarketPresent = false;
        if (sku === storySkus.categoryReview) {
            priorApprovalMarketPresent = await checkPriorApprovalMarketPresent(
                selectorStateObj?.focusMarketsSelectedData
            );
        }
        if (sku === storySkus.categoryTrendAnalysis) {
            priorApprovalMarketPresent = await checkPriorApprovalMarketPresent(
                [],
                selectorStateObj?.focusMarketSelections?.marketKeys
            );
        }
        if (sku === storySkus.brandReview || sku === storySkus.priceAndPromotion) {
            let marketOption = selectorStateObj?.marketOption;
            priorApprovalMarketPresent = await checkPriorApprovalMarketPresent(
                [],
                selectorStateObj?.focusMarketSelections?.marketKeys
            );
            if (marketOption === 'market:option2') {
                let priorApprovalMarketPerfPresent = await checkPriorApprovalMarketPresent(
                    selectorStateObj?.focusMarketsPerformanceSelectedData
                );
                setIsPriorApprovalMarketPresentInMarketPerf(priorApprovalMarketPerfPresent);
            }
        }
        setIsPriorApprovalMarketPresent(priorApprovalMarketPresent);
    };

    const updateTableStateStatus = useCallback((type) => {
        if (type === 'resetPage') {
            setRefreshTableData(false);
        }
    }, []);

    return (
        <div className={wrapperClass}>
            <ByzzerMask loading={loading} />
            {showRunAgainModal && (
                <StoryModal
                    onModalclose={modalCloseHandler}
                    hideFooter
                    size={'small'}
                    title={'What do you want to run?'}
                >
                    <RunAgainModal
                        onNextClicked={nextClickedHandler}
                        sku={runAgainStorySku}
                        isPriorApprovalMarketPresent={isPriorApprovalMarketPresent}
                        isPriorApprovalMarketPresentInMarketPerf={isPriorApprovalMarketPresentInMarketPerf}
                        selectors={selectorStateData?.selectorsStatus}
                    />
                </StoryModal>
            )}
            {modalTitle && (
                <StoryModal
                    onModalclose={modalCloseHandler}
                    title={modalTitle}
                    item={selectedRowData}
                    size={'large'}
                    editModal
                    remainingReportRuns={remainingReportRuns}
                    storyCountDetails={storyCountDetails}
                    onChangeFileAlias={fileAliasNameChangedHandler}
                    onRunStoryClicked={() => runStoryClickedHandler()}
                    panelStatusCompleted={panelStatusCompleted}
                    fileName={fileNameAlias}
                    loading={loading}
                >
                    <ByzzerMask loading={loading} />
                    <DataSpecification
                        props={storyCountDetails}
                        fromBack
                        selectorStateDataObj={selectorStateData}
                        storySelectorVal={runAgainSelector}
                        setDataSpecificationValue={setDataSpecificationValue}
                        fromRunAgain
                        marketGroup={marketGroup}
                        selectorConfigsData={selectorConfigsData}
                    />
                </StoryModal>
            )}

            {company && <div className={'stories_company_header'}>Filter Stories History</div>}
            {allStoriesData?.length > 0 && (
                <RenderTableView
                    columns={columnData}
                    data={storiesData}
                    filterValues={filterValues}
                    filterSelectedValues={filterSelectedValuesRef.current}
                    filterTableData={filterTableData}
                    isFilterSelected={isFilterSelected}
                    clearAllFilters={clearAllFilters}
                    loading={loading}
                    pageCount={pageCount}
                    pageIndexCount={pageIndexCount}
                    onClickClear={onClickClear}
                    setDateSelectedFn={setDateSelectedFn}
                    dateSelected={dateSelected}
                    byzzerDateRange={byzzerDateRange}
                    storyHistoryFound={storyHistoryFound}
                    refreshTableData={refreshTableData}
                    updateTableStateStatus={updateTableStateStatus}
                    clearDateRange={clearDateRange}
                ></RenderTableView>
            )}

            <ErrorModal
                heading={showModal.title}
                errorInfo={showModal.errorInfo}
                errorResolution={showModal.message}
                show={showModal.displayModal}
                onClick={() => hideErrorModal()}
            />
        </div>
    );
}

export default StoriesHistory;
