import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ByzzerTable } from '@/components/ByzzerTable';
import { useTenantApi } from '@/hooks';
import { ColDef } from 'ag-grid-community';
import { FLOATING_DATE_PICKER_FILTER_PARAMS } from '@/constants/table.constants';
import { format, formatInTimeZone } from 'date-fns-tz';
import { confirm, openErrorModal, alert as byzzerAlert, ByzzerModal } from '@/components/form/ByzzerModal';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import './DodSchedule.scss';
import ModifyExtract from '@/views/Extract/ExtractHistory/ModifyExtract';
import { DodRunConfigWizardMode } from '@/components/DodConfigEditor/types';
import { useNavigate } from 'react-router-dom';
import { DodScheduleHistory } from '@/types/DodRun';
import { frameTimePeriod } from '@/services/extract.service';
import { useUser } from '@/contexts/UserContext';
import { getYearsFromSubscriptionData } from '@/utils/timeperiodUtil';
import DeleteIcon from '@images/icons/DeleteIcon.svg';
import EditIcon from '@images/icons/EditIcon.svg';
import { ByzzerMenu } from '@/components/ByzzerMenu';
import { openModifySeriesName } from '@/components/DodConfigEditor/common/DodRunNowModal/DodRunNowModal';
import classNames from 'classnames';
import { triggerToast } from '@/notifications/ByzzerToast';
import { getDodSeriesNames } from '@/api';
import { isValidArray } from '@/utils';
import useStateRef from 'react-usestateref';

function DodSchedule({handleSelectionChange}) {
    const baseClassName = 'dod-schedule';
    const gridRef = useRef<AgGridReact>(null);
    const [scheduledRuns, setScheduledRuns] = useState<DodScheduleHistory[]>([]);
    const { getScheduledExtracts, deleteExtractReport } = useTenantApi();
    const [rowData, setRowData] = useState<DodScheduleHistory>();
    const [showEditDataModal, setShowEditDataModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [editOption, setEditOption] = useState<string>('');
    const [seriesNames, setSeriesNames, seriesNamesRef] = useStateRef<{
        [key: string]: Set<string>
    }>({});
    const navigate = useNavigate();
    // const defaultTimeZone = 'America/New_York';
    const defaultDateFormat = 'yyyy-MM-dd';
    const { features, user } = useUser();
    let yrs = getYearsFromSubscriptionData(features?.extendedDataYears);
    const menuRefs = useRef<any[]>([]);
    useEffect(() => {
        (async () => {
            await getScheduledRuns();
            handleSelectionChange([]);
        })();
    }, []);

    const defaultDodScheduleColDef = useMemo(
        () => ({
            filter: true, // make every column use 'text' filter by default
            sortable: true,
            floatingFilter: true, // enable floating filters by default
            resizable: true, //set each col resizable
            // width: 200, // set every column width
            flex: 1,
            autoHeight: false,
            wrapText: false,
            suppressMenu: true,
            lockVisible: true,
            sizeColumnsToFit: true,
        }),
        []
    );

    const updateSelectedRows = () => {
        const selectedNodes = gridRef?.current?.api?.getSelectedNodes();
        const selectedData = selectedNodes?.map(node => node.data);
        handleSelectionChange(selectedData);
    };
    
    useEffect(() => {
        if (gridRef.current?.api) {
            gridRef.current?.api?.addEventListener('selectionChanged', updateSelectedRows);
        }
        return () => {
            if (gridRef.current?.api) {
                gridRef.current?.api.removeEventListener('selectionChanged', updateSelectedRows);
            }
        };
    }, [scheduledRuns]);
    const [dodScheduleColumnDefs] = useState<ColDef[]>([
        {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            sortable: false,
            filter: false,
            resizable: false,
            width: 50,
            maxWidth: 50,
            minWidth: 50,
        },
        {
            headerName: 'Run Name',
            width: 300,
            valueGetter: ({ data }) => data.reportName,
            tooltipValueGetter: ({ data }) => data.reportName,
        },
        {
            headerName: 'Series Name',
            width: 300,
            valueGetter: ({ data }) => data.extractInfo?.scheduledInfo?.seriesName,
            tooltipValueGetter: ({ data }) => data.extractInfo?.scheduledInfo?.seriesName,
        },
        ...(window.localStorage['show-schedule-dod-id'] === 'true' ? [{ field: 'id' }] : []),
        {
            ...FLOATING_DATE_PICKER_FILTER_PARAMS,
            headerName: 'Scheduled Date',
            field: 'extractDelivery',
            tooltipValueGetter: ({ data }) => {
                return `${data.extractDelivery}`;
            },
            // width: 200,
            filter: 'agDateColumnFilter',
        },
        {
            headerName: 'Run By',
            valueGetter: ({ data }) => {
                return `${data.user.firstName} ${data.user.lastName}`;
            },
            tooltipValueGetter: ({ data }) => {
                return `${data.user.firstName} ${data.user.lastName}`;
            },
        },
        {
            headerName: 'Time Period',
            valueGetter: ({ data }) => {
                return frameTimePeriod(data.ExtractSelection, yrs);
            },
            tooltipValueGetter: ({ data }) => {
                return frameTimePeriod(data.ExtractSelection, yrs);
            },
        },
        {
            headerName: 'Category',
            // width: 300,
            valueGetter: ({ data }) => {
                let categories =
                    data?.ExtractSelection?.products?.categories?.selections ??
                    data?.ExtractSelection?.products?.categories;
                categories = categories?.length ? categories?.join(', ') : categories === true ? 'ALL' : '';
                const summedCategories = data?.ExtractSelection?.products?.categories?.summedSelections?.map(sc => `${sc.display} (sum)`);
                return `${categories}${(categories && summedCategories?.length > 0 ? ", " : "")} ${ summedCategories?.join(', ')}`;
            },
            tooltipValueGetter: ({ data }) => {
                let categories =
                    data?.ExtractSelection?.products?.categories?.selections ??
                    data?.ExtractSelection?.products?.categories;
                categories = categories?.length ? categories?.join(', ') : categories === true ? 'ALL' : '';
                const summedCategories = data?.ExtractSelection?.products?.categories?.summedSelections?.map(sc => `${sc.display} (sum)`);
        
                return `${categories}${(categories && summedCategories?.length > 0 ? ", " : "")} ${summedCategories?.join(', ')}`;
            },
        },
        {
            headerName: 'Actions',
            field: 'menu',
            suppressMenu: true,
            sortable: false,
            filter: false,
            floatingFilter: false,
            resizable: false,
            pinned: 'right',
            width: 150,
            cellRenderer: ({ data }) => {
                const { id, extractInfo } = data;
                const handleModify = () => {
                    setRowData(data);
                    setShowEditDataModal(true);
                };
                return (
                    <div className={`${baseClassName}__actions-cell`}>
                        <span onClick={() => handleModify()}>
                            <img src={EditIcon} alt="Modify" style={{ cursor: 'pointer', marginRight: '10px' }} />
                        </span>
                        <span onClick={() => onDeleteRun(data.id)}>
                            <img src={DeleteIcon} alt="Delete" style={{ cursor: 'pointer' }} />
                        </span>
                        <div className={`${baseClassName}__actions-cell__dropdown`} >
                            <div 
                                key={data.id} 
                                className={classNames(`${baseClassName}__menu-trigger`)}
                                ref={thisElement => menuRefs.current[String(id)] = {current: thisElement}}
                            />
                            <ByzzerMenu
                                className={`${baseClassName}__menu`}
                                reference={menuRefs.current[String(id)]}
                                offset={[-90, -5]}
                                triggerTarget={menuRefs.current[String(id)]?.current}
                                highlightItemOnHover={true}
                                items={[
                                    {
                                        onClick: async function() {
                                            if(extractInfo.scheduledInfo.seriesName){
                                                try {
                                                    if(data.user.id !== user?.id){
                                                        byzzerAlert({
                                                            content: <>
                                                                <p>You cannot edit this series name because you did not create it.</p>
                                                            </>
                                                        })
                                                        return;
                                                    }
                                                    const updatedReportName = await openModifySeriesName(id, extractInfo.scheduledInfo.seriesName, true, seriesNamesRef?.current?.scheduled);
                                                    if(updatedReportName){
                                                        await getScheduledRuns();
                                                        triggerToast({
                                                            content: 'series name updated successfully',
                                                        });
                                                    }
                                                } catch(error) {
                                                    byzzerAlert({
                                                        type: 'error',
                                                        title: 'Series Name Update Failed',
                                                        content: <>
                                                            <p>There was an unexpected error during this series name update.</p>
                                                            <p>Please contact the support team for additional assistance.</p>
                                                        </>
                                                    })
                                                }
                                            }
                                        },
                                        content: 'Rename Series',
                                        disabled: !extractInfo.scheduledInfo.seriesName
                                    }
                                ]}
                            />
                        </div>
                    </div>
                );
            },
        },
    ]);
    const fetchSeriesNames = useCallback(async () => {
        try {
            const response = await getDodSeriesNames();
            const individual = isValidArray(response.individualSeriesNames)
                ? new Set(response.individualSeriesNames.map((name) => name.trim().toLowerCase()))
                : new Set([]);
            const scheduled = isValidArray(response.scheduledSeriesNames)
                ? new Set(response.scheduledSeriesNames.map((name) => name.trim().toLowerCase()))
                : new Set([]);
            setSeriesNames({ individual, scheduled });
        } catch (error) {
            console.error('Error fetching DOD series names:', error);
        }
    }, [getDodSeriesNames]);

    useEffect(() => {
        fetchSeriesNames();
    }, []);
    async function getScheduledRuns() {
        try {
            setLoading(true);
            const resp = await getScheduledExtracts();
            const sortedRuns = resp.getExtractData.sort((a, b) =>
                b.id > a.id ? 1 : -1 || b.extractDelivery.localeCompare(a.extractDelivery)
            );
            const scheduledDodRuns = sortedRuns.map((item) => ({
                ...item,
                extractDelivery: item.extractDelivery
                    ? format(new Date(item.extractDelivery), defaultDateFormat)
                    : 'NA',
            }));
            setScheduledRuns(scheduledDodRuns);
        } finally {
            setLoading(false);
        }
    }

    async function onDeleteRun(id) {
        try {
            if (
                !(await confirm({
                    title: 'Delete Scheduled Run',
                    content: <div className="byzzer-allocation-warning">Do you want to delete the selected item ?</div>,
                    yesLabel: 'Yes',
                    noLabel: 'No',
                }))
            ) {
                return;
            }
            setLoading(true);
            let resp = await deleteExtractReport(id);
            let alertContent = resp === 'deleted' ? 'Dod Schedule deleted successfully!' : 'Something went wrong';
            setLoading(false);
            byzzerAlert({
                // @ts-ignore
                content: <div className="story_modal_alertText">{alertContent}</div>,
            });
            if (resp === 'deleted') {
                getScheduledRuns();
            }
        } catch (err: any) {
            openErrorModal({
                title: `Something Unexpected Happened`,
                content: (
                    <>
                        <p>Fear not our engineering team is on the job.</p>
                    </>
                ),
                errorId: err.id
            });
            setLoading(false);
        }
    }

    async function closeEditModal() {
        setShowEditDataModal(false);
        setEditOption('');
    }

    const nextClickedHandler = (radioValue: DodRunConfigWizardMode) => {
        navigate(`/dashboard/extract_editor/${rowData?.id}?mode=${radioValue}`);
    };

    const getBody = () => {
        return (
            <div className="btn-run-extract">
                <ModifyExtract
                    onNextClicked={nextClickedHandler}
                    disableNextButton={!editOption}
                    editOption={editOption}
                    saveEditOption={(selectedValue: string) => setEditOption(selectedValue)}
                    seriesName={rowData?.extractInfo?.scheduledInfo?.seriesName}
                />
            </div>
        );
    };

    const getModalContent = (heading, text) => {
        return (
            <div className="extracts_edit_container_header">
                <header className="extracts_edit_container_header__heading">
                    <span>{heading}</span>
                </header>
                <header className="extracts_edit_container_header__text">
                    <span>{text}</span>
                </header>
            </div>
        );
    };

    return (
        <>
            <ByzzerMask show={loading} loading={loading}>
                Loading your Data On Demand runs
            </ByzzerMask>
            {showEditDataModal && (
                <ByzzerModal
                    show={showEditDataModal}
                    onClose={() => closeEditModal()}
                    size={'small'}
                    type={'info'}
                    headerType={'none'}
                    className="extracts_edit_container"
                >
                    {getModalContent('What do you want to modify?', '')}
                    {getBody()}
                </ByzzerModal>
            )}
            <ByzzerTable
                ref={gridRef} // Ref for accessing Grid's API
                rowData={scheduledRuns} // Row Data for Rows
                columnDefs={dodScheduleColumnDefs} // Column Defs for Columns
                defaultColDef={defaultDodScheduleColDef} // Column Defs for Columns
                readOnlyEdit={false}
                singleClickEdit={false}
                rowSelection='multiple'
                suppressRowClickSelection={true}
            />
        </>
    );
}

export default DodSchedule;
