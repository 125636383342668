import React, {useState, useEffect} from 'react';
import {
    ByzzerButton,
    ByzzerSelect,
    ByzzerRadio,
    ByzzerMultiSelect,
} from '@/components/form';
import {PlusOutlined, CloseOutlined} from '@ant-design/icons';
import {useTenantApi} from '@/hooks/useTenantApi';
import {useApp} from '@/contexts/UserContext';
import './SegmentSelector.scss';
import ByzzerSearchableSelect from '@/components/form/ByzzerSearchableSelect';

import {ByzzerMask} from '@/components/ByzzerMask/ByzzerMask';
import classnames from 'classnames';
import {alert} from '@/components/form';

const baseClassName = 'shopper-segment-selector';

const SegmentSelector = ({
                             selectorState,
                             setSelectorsData,
                             productType,
                             stepNo,
                             sku
                         }) => {
    const { getDemographicValues } = useTenantApi();
    const {demographicOptions} = useApp();
    const [showShopperSegmentFilters, setShowShopperSegmentFilters] = useState(false);
    const [loading, setLoading] = useState(false);
    const [segmentFormDataIsValid, setSegmentFormDataIsValid] = useState(false);
    const skusListForAddingDemographicArea = ['38', '239', '42', '842'];

    useEffect(() => {
        if (skusListForAddingDemographicArea.includes(sku)) {
            handleAddDemoAreaFilter(selectorState); // Initial add of demoArea entry on load
        }
    }, [])

    useEffect(() => {
        let demographicFiltersAreValid = false;
        let demographicAreasAreValid = false;
        let shopperSegmentSelectionsAreValid = false;

        if (
            (selectorState?.shopperSegmentSelections?.demographicFilters?.filter((demo) => {
                return demo?.demographic?.value && demo?.condition && demo?.value?.valueSelections?.length // check each item's value to make sure they have at least one value
            })?.length === selectorState?.shopperSegmentSelections?.demographicFilters?.length && selectorState?.shopperSegmentSelections?.demographicFilters?.length && showShopperSegmentFilters) || !showShopperSegmentFilters // if the number of demo items matches the number of complete demo items, or the user skips the selections, ok to proceed
        ) {
            demographicFiltersAreValid = true;
        }

        if (
            selectorState?.shopperSegmentSelections?.demographicArea?.filter((demoArea) => demoArea)?.length === selectorState?.shopperSegmentSelections?.demographicArea?.length
        ) {
            demographicAreasAreValid = true;
        }

        if (skusListForAddingDemographicArea.includes(sku)) {
            shopperSegmentSelectionsAreValid = demographicAreasAreValid;
        } else if (!skusListForAddingDemographicArea.includes(sku)) {
            shopperSegmentSelectionsAreValid = demographicFiltersAreValid;
        }

        setSegmentFormDataIsValid(shopperSegmentSelectionsAreValid);
    }, [selectorState?.shopperSegmentSelections, showShopperSegmentFilters]);

    useEffect(() => {
        if (selectorState?.shopperSegmentSelections?.demographicFilters?.[0]?.demographic?.display) { // if viewing in report viewer
            setShowShopperSegmentFilters(true)
        }
    }, [selectorState?.shopperSegmentSelections]);

    const handleDemoFilterChange = async (selectedValue, selection, type, indexOfDemoItemRow, indexOfSelectedDemoVal, selectorStateVal) => {
        let newSelectorState = {
            shopperSegmentSelections: {
                ...selectorStateVal.shopperSegmentSelections,
                demographicFilters: selectorStateVal.shopperSegmentSelections.demographicFilters
            }
        };

        if (!selectedValue && !selection) {
            newSelectorState.shopperSegmentSelections.demographicFilters[indexOfDemoItemRow] = {
                demographic: {
                    display: '',
                    value: ''
                },
                condition: '',
                value: {
                    valueOptions: [],
                    valueSelections: [],
                }
            };

            setSelectorsData(newSelectorState);
            return;
        }

        let currDemoItem;

        if (type === 'demographic') {
            currDemoItem = {
                demographic: {
                    display: selection.display,
                    value: selection.value
                },
                condition: '',
                value: {
                    valueOptions: [],
                    valueSelections: []
                }
            };

            newSelectorState.shopperSegmentSelections.demographicFilters[indexOfDemoItemRow] = currDemoItem;
            setSelectorsData(newSelectorState); // loading the selection into the UI before retrieving lookup values

            setLoading(true);
            const {values: demoValueOptions} = await getDemographicValues(selectedValue);
            currDemoItem.value.valueOptions = JSON.parse(JSON.stringify(demoValueOptions));
            newSelectorState.shopperSegmentSelections.demographicFilters[indexOfDemoItemRow] = currDemoItem;
            setSelectorsData(newSelectorState);
            setLoading(false);
        } else if (type === 'condition') {
            newSelectorState.shopperSegmentSelections.demographicFilters[indexOfDemoItemRow].condition = selectedValue

            setSelectorsData(newSelectorState);
        } else if (type === 'segment') {
            currDemoItem = {
                ...newSelectorState.shopperSegmentSelections.demographicFilters[indexOfDemoItemRow],
                value: {
                    ...newSelectorState.shopperSegmentSelections.demographicFilters[indexOfDemoItemRow].value,
                    valueSelections: selectedValue
                }
            };

            newSelectorState.shopperSegmentSelections.demographicFilters[indexOfDemoItemRow] = currDemoItem;

            setSelectorsData(newSelectorState);
        }
    }

    const handleProgressButtonClick = (btnValue) => {
        let currentSelectorStatusState = JSON.parse(JSON.stringify(selectorState?.selectorsStatus));
        currentSelectorStatusState[stepNo].status = 'completed';

        if (btnValue === 'Next') {
            setSelectorsData({
                activeCollapsePanel: btnValue !== 'Generate' ? [stepNo + 1] : [],
                selectorsStatus: currentSelectorStatusState
            })
        }
    }

    const handleSegmentFiltersDisplayChange = (newShowFiltersVal, selectorStateVal) => {
        if ((newShowFiltersVal && showShopperSegmentFilters) || (!newShowFiltersVal && !showShopperSegmentFilters)) return; // dont do anything if seleting true and already true; false and already false...
        let newSelectorState = {
            shopperSegmentSelections: {
                ...selectorStateVal.shopperSegmentSelections,
                demographicFilters: []
            }
        };
        if (newShowFiltersVal) { // opening demo selectors, start with a new array with one empty item
            let newDemographicFilters = [{
                demographic: {
                    display: '',
                    value: ''
                },
                condition: '',
                value: {
                    valueSelections: [],
                    valueOptions: []
                }
            }]
            newSelectorState.shopperSegmentSelections.demographicFilters = newDemographicFilters;
            setSelectorsData(newSelectorState);
            setShowShopperSegmentFilters(newShowFiltersVal);
        } else {
            setSelectorsData(newSelectorState);
            setShowShopperSegmentFilters(newShowFiltersVal);
        }
    };

    const handleRemoveDemographic = (indexOfDemoItemRow, selectorStateVal) => {
        let newSelectorState = {
            shopperSegmentSelections: {
                ...selectorStateVal.shopperSegmentSelections,
                demographicFilters: [...selectorStateVal.shopperSegmentSelections.demographicFilters]
            }
        };
        newSelectorState.shopperSegmentSelections.demographicFilters.splice(indexOfDemoItemRow, 1);
        setSelectorsData(newSelectorState);
    }

    const handleAddDemoFilterRow = (selectorStateVal) => {
        let newSelectorState = {
            shopperSegmentSelections: {
                ...selectorStateVal.shopperSegmentSelections,
                demographicFilters: [...selectorStateVal.shopperSegmentSelections.demographicFilters]
            }
        };
        let newDemographicFilter = {
            demographic: {
                display: '',
                value: ''
            },
            condition: '',
            value: {
                valueSelections: [],
                valueOptions: []
            }
        }
        newSelectorState.shopperSegmentSelections.demographicFilters = [
            ...newSelectorState.shopperSegmentSelections.demographicFilters,
            newDemographicFilter
        ];
        setSelectorsData(newSelectorState);
    }

    const handleUnselectZip = (value, indexOfDemoItemRow, indexOfSelectedDemoVal, selectorStateVal) => {
        handleChangeZip(value, false, indexOfDemoItemRow, indexOfSelectedDemoVal, selectorStateVal);
    };

    const handleChangeZip = (value, status, indexOfDemoItemRow, indexOfSelectedDemoVal, selectorStateVal) => {
        let newSelectorState = {
            shopperSegmentSelections: {
                ...selectorStateVal.shopperSegmentSelections,
                demographicFilters: selectorStateVal.shopperSegmentSelections.demographicFilters
            }
        };

        let currDemoItem;

        if (status) {
            currDemoItem = {
                ...newSelectorState.shopperSegmentSelections.demographicFilters[indexOfDemoItemRow],
                value: {
                    ...newSelectorState.shopperSegmentSelections.demographicFilters[indexOfDemoItemRow].value,
                    valueSelections: [
                        ...newSelectorState.shopperSegmentSelections.demographicFilters[indexOfDemoItemRow].value.valueSelections,
                        value
                    ]
                }
            };
        } else {
            if (indexOfSelectedDemoVal > -1) {
                currDemoItem = {
                    ...newSelectorState.shopperSegmentSelections.demographicFilters[indexOfDemoItemRow],
                    value: {
                        ...newSelectorState.shopperSegmentSelections.demographicFilters[indexOfDemoItemRow].value,
                        valueSelections: newSelectorState.shopperSegmentSelections.demographicFilters[indexOfDemoItemRow].value.valueSelections.filter((selection) => selection !== value),
                    }
                };
            }
        }

        newSelectorState.shopperSegmentSelections.demographicFilters[indexOfDemoItemRow] = currDemoItem;

        setSelectorsData(newSelectorState);
    };

    const handleDemoAreaFilterChange = async (selectedValue, selection, selectorStateVal, indexOfDemoAreaSelection) => {
        let newSelectorState = JSON.parse(JSON.stringify(selectorStateVal));
        if (!selectedValue) {
            newSelectorState.shopperSegmentSelections.demographicArea[indexOfDemoAreaSelection] = '';
            setSelectorsData(newSelectorState);
            return;
        }

        if (newSelectorState?.shopperSegmentSelections?.demographicArea?.filter((demoSelection) => demoSelection === selectedValue)?.length) {
            await alert({
                title: 'Duplicate Demographic',
                content: (
                    <>
                        <p><i>{selection?.display}</i> is already included as a filter.</p>
                        <p>Please select a different value.</p>
                    </>
                ),
            });
            return;
        }
        ;

        newSelectorState.shopperSegmentSelections.demographicArea[indexOfDemoAreaSelection] = selection.display;
        setSelectorsData(newSelectorState);
    };

    const handleAddDemoAreaFilter = (selectorStateVal = selectorState) => {
        let newSelectorState = JSON.parse(JSON.stringify(selectorStateVal));

        newSelectorState.shopperSegmentSelections.demographicArea = newSelectorState?.shopperSegmentSelections?.demographicArea?.length ? [
            ...newSelectorState?.shopperSegmentSelections?.demographicArea, " "
        ] : [
            ''
        ]
        setSelectorsData(newSelectorState)
    }

    const handleRemoveDemoAreaFilter = (indexToRemove, selectorStateVal) => {
        let newSelectorState = JSON.parse(JSON.stringify(selectorStateVal));
        const filtedDemoParams = newSelectorState?.shopperSegmentSelections?.demographicArea?.filter((demoParam, selectedDemoParamIndex) => selectedDemoParamIndex !== indexToRemove);
        newSelectorState.shopperSegmentSelections.demographicArea = filtedDemoParams;
        setSelectorsData(newSelectorState);
    }

    const DemoAreaSelectors = ({selectorStateVal}) => {
        return selectorStateVal?.shopperSegmentSelections?.demographicArea?.map((selectedDemoArea, index) => {
            return (
                <div
                    className={`${baseClassName}__demo-area-filter-item`}
                    key={selectedDemoArea + index}
                >
                    <ByzzerSelect
                        label={''}
                        className={`${baseClassName}__demo-area-filter`}
                        value={selectedDemoArea ?? ''}
                        placeholder={'Select from the list'}
                        options={demographicOptions}
                        onChange={(selectedValue, selection) => handleDemoAreaFilterChange(selectedValue, selection, selectorStateVal, index)}
                        allowClear={true}
                    />
                    {selectorStateVal?.shopperSegmentSelections?.demographicArea?.length > 1 ? (
                        <ByzzerButton
                            className={`${baseClassName}__demo-area-filter-remove`}
                            type={'negative'}
                            onClick={() => handleRemoveDemoAreaFilter(index, selectorStateVal)}
                        >
                            <CloseOutlined/>
                        </ByzzerButton>
                    ) : null}
                </div>
            )
        })
    };

    return ( // https://adlm.nielseniq.com/confluence/display/BYZ/Product+Selector+Configuration+Options
        <div className={`${baseClassName}`}>
            <ByzzerMask loading={loading}/>
            {(skusListForAddingDemographicArea.includes(sku)) ? (
                <div className={`${baseClassName}__demo-area`}>
                    <div className={`${baseClassName}__demographics-text-one`}>
                        Choose Demographics to report.
                    </div>
                    <div className={`${baseClassName}__demographics-text-two`}>
                        This report includes an analysis on share of shoppers within different demographic segments.
                        Choose up to 5 demographics broken out by segment in this report.
                    </div>
                    <div className={`${baseClassName}__demo-area-filters-section`}>
                        <div className={`${baseClassName}__demo-area-filters-label`}>
                            Choose your demographic area(s):
                        </div>
                        <div className={`${baseClassName}__demo-area-filters`}>
                            {selectorState?.shopperSegmentSelections?.demographicArea ? (
                                <DemoAreaSelectors selectorStateVal={selectorState}/>
                            ) : null}
                            {selectorState?.shopperSegmentSelections?.demographicArea?.length && selectorState?.shopperSegmentSelections?.demographicArea?.length < 5 ? (
                                <div className={`${baseClassName}__demo-area-filters-add`}
                                     onClick={() => handleAddDemoAreaFilter(selectorState)}
                                >
                                    <PlusOutlined/> Add another
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : (
                <div className={`${baseClassName}__demo-filters`}>
                    <div className={`${baseClassName}__demographics-text-one`}>Would you like to filter
                        the {productType === 'story' ? 'story' : 'report'} to a subset of shoppers?
                    </div>
                    <div className={`${baseClassName}__demographics-text-two`}>
                        Are you interested in a particular demographic, like shoppers who are a certain age or live in a
                        certain environment? Apply demographic filters to view data for a specific population.
                    </div>
                    <div className={`${baseClassName}__demographics-option-container`}>
                        <div className={`${baseClassName}__demographics-option-radio`}>
                            <ByzzerRadio
                                checked={!showShopperSegmentFilters}
                                name="shopper-subset-option"
                                label={`No, run my report on all shoppers.`}
                                onChange={() => handleSegmentFiltersDisplayChange(false, selectorState)}
                            />
                        </div>
                        <div className={`${baseClassName}__demographics-option-radio`}>
                            <ByzzerRadio
                                checked={showShopperSegmentFilters}
                                name="shopper-subset-option"
                                label={`Yes, filter to a certain demographic.`}
                                onChange={() => handleSegmentFiltersDisplayChange(true, selectorState)}
                            />
                        </div>
                    </div>
                    <div className={`${baseClassName}__demographics-container`}>
                        {showShopperSegmentFilters && selectorState?.shopperSegmentSelections?.demographicFilters?.length ? selectorState?.shopperSegmentSelections?.demographicFilters?.map((demoItem, indexOfDemoItemRow) => {
                            return (
                                <div key={'demo-item' + indexOfDemoItemRow}>
                                    {indexOfDemoItemRow > 0 ? (
                                        <div key={indexOfDemoItemRow} className={`${baseClassName}__demographic-and`}>
                                            {'And'}
                                        </div>
                                    ) : null}
                                    <div key={indexOfDemoItemRow + "demoFilters"}
                                         className={classnames(`${baseClassName}__demographic`, {[`${baseClassName}__demographic--removable`]: indexOfDemoItemRow > 0})}>
                                        <ByzzerSelect
                                            label={'Demographic'}
                                            className={`${baseClassName}__demographic-group`}
                                            value={demoItem?.demographic?.display}
                                            placeholder={demoItem?.demographic?.display || 'Select from the list'}
                                            options={demographicOptions}
                                            onChange={(selectedValue, selection) => handleDemoFilterChange(selectedValue, selection, 'demographic', indexOfDemoItemRow, undefined, selectorState)}
                                            allowClear={true}
                                        />
                                        <ByzzerSelect
                                            label={'Condition'}
                                            className={`${baseClassName}__demographic-condition`}
                                            value={demoItem?.condition}
                                            placeholder={''}
                                            options={['is', 'is not']}
                                            onChange={(selectedValue, selection) => handleDemoFilterChange(selectedValue, selection, 'condition', indexOfDemoItemRow, undefined, selectorState)}
                                        />
                                        {demoItem?.demographic?.display !== 'Zip Code' ? (
                                            <ByzzerMultiSelect
                                                label={'Segment'}
                                                className={`${baseClassName}__demographic-value`}
                                                value={demoItem?.value?.valueSelections}
                                                options={demoItem?.value?.valueOptions}
                                                onChange={(selectedValue, selection, indexOfSelectedDemoVal) => handleDemoFilterChange(selectedValue, selection, 'segment', indexOfDemoItemRow, indexOfSelectedDemoVal, selectorState)}
                                                selectedOptions={demoItem?.value?.valueSelections}
                                                name={'demo-value'}
                                                seperator={'OR'}
                                                multiSelectWithCheckBox
                                            />
                                        ) : (
                                            <ByzzerSearchableSelect
                                                label={'Segment'}
                                                className={`${baseClassName}__demographic-value`}
                                                options={demoItem?.value?.valueSelections?.length ? demoItem?.value?.valueSelections : []}
                                                onChange={(e, indexOfSelectedDemoVal) => handleUnselectZip(e, indexOfDemoItemRow, indexOfSelectedDemoVal, selectorState)}
                                                name={'demo-value'}
                                                seperator={', '}
                                                multiBrandSelect={true}
                                                searchPlaceholder={'Search for zip'}
                                                searchOnChange={(value, status) => handleChangeZip(value, status, indexOfDemoItemRow, undefined, selectorState)}
                                                searchValue={demoItem?.value?.valueSelections?.length ? demoItem?.value?.valueSelections : ''}
                                                placeholder={demoItem?.value?.valueSelections?.length ? demoItem?.value?.valueSelections : 'Search for a 5-digit US zip code'}
                                                productSet={demoItem?.value?.valueOptions}
                                                searchType={'zipCodes'}
                                            />
                                        )}

                                        <ByzzerButton
                                            className={`${baseClassName}__demographic-remove`}
                                            type={'negative'}
                                            onClick={() => handleRemoveDemographic(indexOfDemoItemRow, selectorState)}
                                        >
                                            <CloseOutlined/>
                                        </ByzzerButton>
                                    </div>
                                </div>
                            )
                        }) : null}
                    </div>
                </div>
            )}
            {/* {showShopperSegmentFilters && selectorState?.shopperSegmentSelections?.demographicFilters?.length ? ( // TODO - enable adding of multiple demos after fixing issue.  of multiple demos/changing first demo clears others
                    <div className={`${baseClassName}__demographic-add`}>
                        <span
                            onClick={() => handleAddDemoFilterRow(selectorState)}
                            className={`${baseClassName}__demographic-add-icon`}
                        >
                            <PlusOutlined fill="#000000" />
                        </span>
                        <span onClick={() => handleAddDemoFilterRow(selectorState)}>
                            Add another condition
                        </span>
                    </div>
                ) : null} */}
            <div
                className={
                    productType === 'story'
                        ? `${baseClassName}__progress-btn-container alias-container`
                        : `${baseClassName}__progress-btn-container`
                }
            >
                <ByzzerButton
                    label={'Next'}
                    onClick={() => handleProgressButtonClick('Next')}
                    disabled={!segmentFormDataIsValid}
                />
            </div>

        </div>
    )
}

export default SegmentSelector;
// import useState from "react-usestateref"; // USE IF SITLL HAVINGISSUE, GOOGLE DOCS, MAY TAKE THIRD argument
