import { DUMMY_CASE_STUDIES } from '@/constants/homePage.constants';
import Arrow from '~@images/Arrow.svg';
import './CaseStudy.scss';
import { TrackClick } from '@/analytics';
type CaseStudy = {
    baseClassName: string;
    caseStudies?: any[];
};

const CaseStudy = ({ baseClassName, caseStudies = DUMMY_CASE_STUDIES }: CaseStudy) => {
    const classname = `case-study`;

    return (
        <div className={`${classname}__container`}>
            <div className={`${classname}__heading_container`}>
                <div className={`${classname}__heading_container__heading`}>Byzzer Case Studies</div>
                <div className={`${classname}__heading_container__sub-heading`}>
                    See how small and emerging brands like yours have leveraged the power of Byzzer to tackle key
                    business needs.
                </div>
            </div>

            <div className={`${classname}__case-studies`}>
                {caseStudies.map(({ heading, thumbnail, link }, index) => {
                    return (
                        <TrackClick name={`(${heading}) case study was clicked`} key={`${heading}${link}`}>
                            <div className={`${classname}__case-studies__study`}>
                                <div
                                    className={`${classname}__case-studies__study__thumbnail${
                                        index % 2 === 0 ? '__right' : '__left'
                                    }`}
                                >
                                    <img
                                        src={thumbnail}
                                        alt="heading"
                                        className={`${classname}__case-studies__study__thumbnail__image`}
                                    />
                                </div>
                                <div
                                    className={`${classname}__case-studies__study__call_to_action${
                                        index % 2 === 0 ? '__left' : '__right'
                                    }`}
                                >
                                    <div className={`${classname}__case-studies__study__call_to_action__text`}>
                                        <p>{heading}</p>
                                    </div>
                                    <div className={`${classname}__case-studies__study__call_to_action__button`}>
                                        <a href={link} target="_blank">
                                            <img src={Arrow} alt="Learn More" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </TrackClick>
                    );
                })}
            </div>
        </div>
    );
};

export default CaseStudy;
